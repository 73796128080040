var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("BaseFormWithStages", {
        attrs: {
          pageHeaderActions: _vm.actions,
          pageHeaderActionListeners: _vm.pageHeaderActionListeners,
          cardsData: _vm.cardsData,
          loading: _vm.loading
        },
        scopedSlots: _vm._u([
          {
            key: "beforeCards",
            fn: function() {
              return [
                _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _c("InputField", {
                      attrs: { label: "Beschreibung" },
                      model: {
                        value: _vm.processForm.description,
                        callback: function($$v) {
                          _vm.$set(_vm.processForm, "description", $$v)
                        },
                        expression: "processForm.description"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "afterCards",
            fn: function() {
              return [
                _vm.processDateCreated
                  ? _c("div", [
                      _vm._v(" " + _vm._s(_vm.processDateCreated) + " ")
                    ])
                  : _vm._e()
              ]
            },
            proxy: true
          },
          {
            key: "formContent",
            fn: function() {
              return [
                _c("div", [
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", [_vm._v("Finanzbedarf")]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Gesamtkapitalbedarf",
                                value:
                                  _vm.processVorhabenOverview
                                    .gesamtkapitalbedarf
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", { staticClass: "text-small" }, [
                          _vm._v(" Kosten ")
                        ]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Kosten",
                                value: _vm.processVorhabenOverview.kosten
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Kaufpreis",
                              value: _vm.processVorhabenOverview.kaufpreis
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Grunderwerbsteuer",
                              value:
                                _vm.processVorhabenOverview.grunderwerbsteuer
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Mobiliarkosten",
                              value: _vm.processVorhabenOverview.mobiliarkosten
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Notargebühren",
                              value: _vm.processVorhabenOverview.notargebuehr
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Sonstige Kosten",
                              value: _vm.processVorhabenOverview.sonstigeKosten
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Maklergebühren",
                              value: _vm.processVorhabenOverview.maklergebuehr
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Modernisierungskosten",
                              value:
                                _vm.processVorhabenOverview.modernisierungKosten
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Renovierungskosten",
                              value:
                                _vm.processVorhabenOverview
                                  .modernisierungRenovierungsKosten
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", { staticClass: "text-small" }, [
                          _vm._v(" Finanzierungskosten ")
                        ]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Beratungshonorar",
                                value:
                                  _vm.processVorhabenOverview.beratungshonorar
                              }
                            })
                          ],
                          1
                        )
                      ]
                    ),
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "col-2" },
                        [
                          _c("CurrencyLabel", {
                            attrs: {
                              label: "Beratungshonorar",
                              value:
                                _vm.processVorhabenOverview.beratungshonorar
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", [
                          _vm._v(
                            "Eingesetztes Eigenkapital, Vermögen und Drittdarlehen"
                          )
                        ]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Eingesetztes Kapital",
                                value:
                                  _vm.processVorhabenOverview
                                    .eingesetztesKapital
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [_c("div", [_vm._v("Präferenzen")])]
                    )
                  ]),
                  _c("div", { staticClass: "box__container" }, [
                    _c(
                      "div",
                      { staticClass: "box__title process__summary-header" },
                      [
                        _c("div", [_vm._v("Finanzierungswunsch")]),
                        _c(
                          "div",
                          [
                            _c("CurrencyLabel", {
                              staticClass: "currency_label_right",
                              attrs: {
                                label: "Noch benötigte Darlehen",
                                value:
                                  _vm.processVorhabenOverview
                                    .nochBenoetigteDarlehen
                              }
                            })
                          ],
                          1
                        )
                      ]
                    )
                  ])
                ]),
                false
                  ? _c(
                      "div",
                      [
                        _vm.loading.antraegeList
                          ? _c("GhostLoading", {
                              attrs: {
                                useBoxContainer: "",
                                type: "block",
                                config: { block: { height: 100 } }
                              }
                            })
                          : _c("div", [
                              _c(
                                "div",
                                { staticClass: "box__container" },
                                [
                                  _c("Table", {
                                    attrs: {
                                      rowId: "category_index",
                                      headers: _vm.antragListeHeaders(
                                        "Anträge"
                                      ),
                                      rows: _vm.antragListeRows(
                                        _vm.formList,
                                        "Anträge"
                                      ),
                                      hidePagination: ""
                                    },
                                    on: { "click-label": _vm.openNewAntrag }
                                  })
                                ],
                                1
                              )
                            ]),
                        _vm.loading.init
                          ? _c("GhostLoading", {
                              attrs: {
                                useBoxContainer: "",
                                type: "block",
                                config: { block: { height: 100 } }
                              }
                            })
                          : _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _vm.antragRows.length
                                  ? _c("Table", {
                                      attrs: {
                                        title: "Anträge",
                                        headers: _vm.antragHeaders,
                                        rows: _vm.antragRows,
                                        hidePagination: ""
                                      },
                                      on: {
                                        "click-antragBezeichnung":
                                          _vm.openExistingAntrag
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "delete",
                                            fn: function(row) {
                                              return [
                                                _c("PhTrash", {
                                                  staticClass: "ml-3 clickable",
                                                  attrs: { size: 18 },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteAntraegeConfirmation(
                                                        row
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        false,
                                        3622513880
                                      )
                                    })
                                  : _c("NoData", {
                                      attrs: {
                                        noIcon: "",
                                        content: "Keine Anträge vorhanden"
                                      }
                                    })
                              ],
                              1
                            )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "BaseModal",
        {
          ref: "submitProcess",
          attrs: {
            modalTitle: "Vorgang versenden",
            labelButtonConfirm: "Fertig",
            showCancelButton: false
          }
        },
        [
          _c("Table", {
            attrs: {
              headers: _vm.submitProcessHeadersTable,
              rows: _vm.submitProcessRowsTable
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }