<template>
  <label class="radiobox__container" :class="{ 'radiobox__check--disabled': disabled, 'filter-style': isFilter }" aria-label="Radiobutton">
    <span class="radiobox__body">
      <input 
        type="radio" 
        :name="name" 
        :value="value" 
        :checked="checked"
        :disabled="disabled"
        @change="$emit('change', value)">
        <span class="radiobox__checkmark" :class="{ 'radiobox__check--disabled': disabled, 'top-space': isFilter }"></span>
    </span>
    <span 
      @click="disabled ? null : $emit('change', value)" 
      class="radiobox__label" 
      :style="itemStyle"
      :class="{
        'bold': isBold, 
        'radiobox__check--disabled': disabled}" 
      v-html="sanitize(label)">
    </span>
  </label>

</template>

<script>
import {sanitize} from '@/helpers/string-helper.js';

export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    value: {
      default: '',
    },
    label: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: false
    },
    isFilter: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    config: {
    },
    itemStyle: {
    },
  },
  computed: {
    isBold() {
      if (this.config) {
        return this.config.bold || this.config.boldValues?.includes(this.value)
      }
      return false 
    }
  },
    methods: {
        sanitize(htmlString) {
            return sanitize(htmlString);
        },
    },
}
</script>

<style scoped>
  .radiobox__container {
    display: flex;
    padding-right: 1rem;
    width: revert;
  }
  .filter-style {
    padding-right: 0;
  }
  .radiobox__body {
    display: block;
    position: relative;
    user-select: none;
    margin: 0 8px 0 0;
    flex: 0 0 18px;
    height: 18px;
  }
  .radiobox__label {
    display: inline;
  }

  .radiobox__body input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .radiobox__checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    border: 1px solid var(--color-text);
    box-sizing: border-box;
  }
  .top-space {
    top: 4px;
  }

  .radiobox__body input:checked ~ .radiobox__checkmark:after {
    background: var(--color-text);
  }

  .radiobox__checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .radiobox__body input:checked ~ .radiobox__checkmark:after {
    display: block;
  }

  .radiobox__body .radiobox__checkmark:after {
    top: 5px;
    left: 5px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
  }

  .radiobox__check--disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .bold {
  font-weight: bold;
  }
</style>