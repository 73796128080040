<template>
  <div>
    <FinanzenRegelmaessigeRate :disabled="isDisabled || isRefusedPageFinance" :finance="finance" :showHinweisBetragNiedrig="showHinweisBetragNiedrig" 
      @changed="valueChangedFinance"/>
    
    <FinanceLineSum  :summe="summeAusgaben" />
  </div>
</template>

<script>
import anlegerprofilMixin from "@/mixins/anlegerprofil/anlegerprofil-mixin.js";
import FinanzenRegelmaessigeRate from '@/components/anlegerprofil/FinanzenRegelmaessigeRate.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanceLineSum,
    FinanzenRegelmaessigeRate,
  },
  computed: {
    showHinweisBetragNiedrig() {
      if (
        this.labelHinweisBetragNiedrig &&
        this.summeAusgaben &&
        this.limitHinweisBetragNiedrig
      ) {
        return this.summeAusgaben < this.limitHinweisBetragNiedrig;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.updateWarnings();
  },
 
};
</script>

<style scoped>
</style>