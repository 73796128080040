import PROCESS_TYPES from './types';

export default {
  [PROCESS_TYPES.GETTERS.PROCESS_LIST](state) {
    return state.processList || [];
  },
  [PROCESS_TYPES.GETTERS.PROCESS](state) {
    return state.process || {};
  },
  [PROCESS_TYPES.GETTERS.PROCESS_VORHABEN_OVERVIEW](state) {
    return state.processVorhabenOverview;
  }
}