<template>
    <div>
        <InputField
            label="Miete"
            v-model="form.rent"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('rent', $event || null)" />
        <InputField
            label="Leasing"
            v-model="form.leasing"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('leasing', $event || null)" /> 
        <InputField
            label="Kreditrückzahlung"
            v-model="form.creditPayment"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('creditPayment', $event || null)" />
        <InputField
            label="Haushalt"
            v-model="form.household"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('household', $event || null)" />
        <InputField
            label="Unterhalt"
            v-model="form.maintainance"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('maintainance', $event || null)" />
        <InputField
            label="Versicherungsbeiträge"
            v-model="form.insurancePayment"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('insurancePayment', $event || null)" /> 
        <InputField
            label="Sonstiges"
            v-model="form.otherPayment"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('otherPayment', $event || null)" />

        <InputTextArea
            :label="labelHinweisBetragNiedrig"
            v-model="form.textHinweisBetragNiedrig"
            v-if="showHinweisBetragNiedrig"
            :disabled="disabled"
            @change="updateStore('textHinweisBetragNiedrig', $event)" />

    </div>
  </template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import InputTextArea from "@/components/core/forms/InputTextArea.vue";

export default {
    props: {
        showHinweisBetragNiedrig: {
            default: false,
        },
        disabled: {
            default: false,
        },
        finance: {
            default: () => {},
        },
    },
    data() {
        return {
            form: {}
        }
    },
    components: {
        InputField,
        InputTextArea,
    },
    mounted() {
        this.form = Object.assign({}, this.finance);
    },
    methods: {
        updateStore(compId, value) {
            this.$emit('changed', {compId, value});
        },
    },

}
</script>