var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DragnDropArea",
        {
          attrs: { hoverText: "Datei hier ablegen" },
          on: {
            files: function($event) {
              return _vm.openAddDocumentModal($event)
            }
          }
        },
        [
          _c("InsuranceOptionMenu", {
            attrs: {
              headerActions: _vm.headerActions,
              insuranceId: _vm.$route.params.id
            },
            on: {
              "action-NEW-ACTIVITY": function($event) {
                return _vm.newAppointment()
              },
              "action-OLD-POLICE-NUMBERS": function($event) {
                return _vm.showOldPoliceNumbers()
              },
              "action-SCHADEN-MELDEN": function($event) {
                return _vm.createSchaden()
              },
              "action-EMAIL-KUNDE": function($event) {
                return _vm.emailStart(false)
              },
              "action-EMAIL-GESELLSCHAFT": function($event) {
                return _vm.emailStart(true)
              },
              "action-BRIEF-ERSTELLEN": function($event) {
                return _vm.briefStart()
              },
              "action-KUENDIGUNG-VERSICHERUNGEN": function($event) {
                return _vm.emailCancel()
              },
              "action-AUFGABE-HINZUFUEGEN": function($event) {
                return _vm.handleAddActivity()
              },
              "action-VERSICHERUNG-KOPIEREN": function($event) {
                return _vm.versicherungKopierenBestaetigung()
              },
              "action-DOKUMENTE-HOCHLADEN": function($event) {
                return _vm.dateiHochladen()
              },
              "action-EXTERNEN-ZUGANG-AUFRUFEN": function($event) {
                return _vm.externenZugangOeffnen()
              },
              "action-VERS-ADMIN-A": function($event) {
                return _vm.versAdminn("A")
              },
              "action-VERS-ADMIN-M": function($event) {
                return _vm.versAdminn("M")
              },
              action: _vm.executeGenericAction
            },
            scopedSlots: _vm._u([
              {
                key: "title",
                fn: function() {
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function($event) {
                            return _vm.navigateToGesellschaft()
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.gesellschaftName))]
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "subtitle",
                fn: function() {
                  return [
                    _vm.bezeichnung
                      ? [
                          _c("span", [_vm._v(_vm._s(_vm.bezeichnung))]),
                          _vm.nummer || _vm.statusAktuell || _vm.showKennzeichen
                            ? _c("span", [_vm._v(" | ")])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.nummer
                      ? [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: _vm.overviewLink(_vm.$route.params.id)
                              }
                            },
                            [_vm._v(_vm._s(_vm.nummer))]
                          ),
                          _vm.statusAktuell || _vm.showKennzeichen
                            ? _c("span", [_vm._v(" | ")])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.statusAktuell
                      ? [
                          _c(
                            "span",
                            [
                              _c("Pill", {
                                attrs: {
                                  label: _vm.statusAktuell,
                                  type: _vm.statusToPillType[_vm.statusAktuell]
                                }
                              })
                            ],
                            1
                          ),
                          _vm.showKennzeichen
                            ? _c("span", [_vm._v(" | ")])
                            : _vm._e()
                        ]
                      : _vm._e(),
                    _vm.showKennzeichen
                      ? [_c("span", [_vm._v(_vm._s(_vm.kennzeichen))])]
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          _c(
            "div",
            { staticClass: "box__container" },
            [
              _c("BaseCollapsable", {
                scopedSlots: _vm._u([
                  {
                    key: "title",
                    fn: function() {
                      return [
                        _c("span", { staticClass: "box__title" }, [
                          _vm._v(" Hinweise und Fehler ")
                        ])
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "content",
                    fn: function() {
                      return [
                        !_vm.hasHinweis
                          ? _c(
                              "div",
                              {
                                staticClass: "antrag-sidetext__container mt-8px"
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("label", { staticClass: "col-12" }, [
                                    _vm._v(" Keine Hinweise vorhanden. ")
                                  ])
                                ])
                              ]
                            )
                          : _c(
                              "div",
                              [
                                _c("div", { staticClass: "font-bold mt-2" }, [
                                  _vm._v("Hinweise:")
                                ]),
                                _vm._l(_vm.hinweise, function(hinweis, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "row" },
                                    [
                                      _c("div", { staticClass: "col-12" }, [
                                        hinweis && hinweis.label
                                          ? _c(
                                              "span",
                                              { staticClass: "font-bold" },
                                              [
                                                _vm._v(
                                                  _vm._s(hinweis.label + " : ")
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" " + _vm._s(hinweis.text) + " ")
                                      ])
                                    ]
                                  )
                                })
                              ],
                              2
                            ),
                        _vm.errorsAndWarnings && _vm.errorsAndWarnings.length
                          ? _c(
                              "div",
                              {
                                staticClass: "antrag-sidetext__container mt-8px"
                              },
                              [
                                _c("div", { staticClass: "row" }, [
                                  _c("div", { staticClass: "col-12" }, [
                                    _c("span", { staticClass: "font-bold" }, [
                                      _vm._v("Fehler:")
                                    ])
                                  ])
                                ]),
                                _c(
                                  "ul",
                                  _vm._l(_vm.errorsAndWarnings, function(
                                    warning,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      { key: index, staticClass: "row" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "col-12 color-danger",
                                            on: {
                                              click: function($event) {
                                                return _vm.navigateToStepWithWarning()
                                              }
                                            }
                                          },
                                          [
                                            _c("li", [
                                              _c("span", [
                                                _vm._v(_vm._s(warning))
                                              ]),
                                              _c(
                                                "span",
                                                [
                                                  _c("PhPencilLine", {
                                                    attrs: { size: 16 }
                                                  })
                                                ],
                                                1
                                              )
                                            ])
                                          ]
                                        )
                                      ]
                                    )
                                  }),
                                  0
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ],
            1
          ),
          _c("StepperForm", {
            ref: "stepperForm",
            attrs: {
              stepType: "tab",
              stepperName: "insurance-detail",
              stepperMediator: _vm.tabsStepperMediator,
              hasFertig: false,
              selectedStepKey: _vm.selectedStepKey,
              selectedSubstepKey: _vm.selectedSubstepKey,
              additionalActionsForMobile: _vm.baseModalActions
            },
            on: {
              "set-step": _vm.setStepByKey,
              "set-substep": _vm.setSubstepByKey,
              handleOtherActions: _vm.handleOtherActions
            },
            scopedSlots: _vm._u([
              {
                key: "contentTemplate",
                fn: function() {
                  return [
                    _vm.selectedStepKey === "allgemeine"
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "box__container" },
                            [
                              _c("AllgemeineVertragsdaten", {
                                ref: "allgemeine",
                                attrs: {
                                  canChangeVertragKundennr:
                                    _vm.canChangeVertragKundennr
                                },
                                on: {
                                  changeStep: function($event) {
                                    return _vm.setStepByKey($event)
                                  }
                                }
                              })
                            ],
                            1
                          ),
                          _vm.versicherungsSummen.length ||
                          _vm.versicherungsSummenFA.length
                            ? _c(
                                "div",
                                { staticClass: "box__container" },
                                [_c("VersicherungenSummen")],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm.selectedStepKey === "weitere-vertragsdetails"
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "box__container" },
                            [_c("WeitereVertragsdetails")],
                            1
                          )
                        ])
                      : _vm.selectedStepKey === "versichertes-objekt"
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [_c("VersichertesObjekt")],
                          1
                        )
                      : _vm.selectedStepKey === "vertrags-personen"
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [_c("VertragsPersonen")],
                          1
                        )
                      : _vm.selectedStepKey === "sparteUbersicht"
                      ? _c("div", [_c("SparteUbersicht")], 1)
                      : _vm.selectedStepKey === "fahrzeugDaten"
                      ? _c(
                          "div",
                          [
                            _c("FahrzeugDatenEdit", {
                              attrs: { versicherungId: _vm.$route.params.id }
                            })
                          ],
                          1
                        )
                      : _vm.selectedStepKey === "aenderungslog"
                      ? _c(
                          "div",
                          [_c("Aenderungslog", { attrs: { typ: "vers" } })],
                          1
                        )
                      : _vm.selectedStepKey === "sparten"
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("AuswahlSparten", {
                              attrs: {
                                spartenSteps:
                                  _vm.versicherungenDetails &&
                                  _vm.versicherungenDetails.steps,
                                spartenValues: _vm.getSpartenData,
                                comboboxValues: _vm.getSpartenComboboxValues
                              },
                              on: {
                                changeStepVisibility: function($event) {
                                  return _vm.changeStepVisibility($event)
                                }
                              }
                            })
                          ],
                          1
                        )
                      : _vm.selectedStepKey === "activities"
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("PostfachOverviewTable", {
                              attrs: {
                                title: "E-Mails & Aufgaben",
                                isInsurance: "",
                                records: _vm.records,
                                loading: _vm.loadingActivities,
                                parentId: _vm.$route.params.id,
                                disabledColumns: ["name", "contact"]
                              }
                            })
                          ],
                          1
                        )
                      : _vm.selectedStepKey === "dokumente"
                      ? _c(
                          "div",
                          [
                            _c("DocumentsFilter"),
                            _c(
                              "div",
                              { staticClass: "box__container" },
                              [
                                _c("DocumentsTable", {
                                  attrs: {
                                    title: "Dokumente",
                                    nodeId: _vm.nodeId,
                                    versicherungId: _vm.$route.params.id,
                                    rowsPerPage: _vm.rowsPerPage,
                                    unregisterEmailEventsBeforeDestroy: false
                                  },
                                  on: {
                                    sendEmail: function($event) {
                                      return _vm.emailStart(
                                        $event.toCompany,
                                        $event.attachments
                                      )
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm.selectedStepKey === "anmerkungen"
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [_c("Anmerkungen")],
                          1
                        )
                      : _vm.selectedStepKey === "schadensmeldungen"
                      ? _c("div", { staticClass: "box__container" }, [
                          _vm.detailsData
                            ? _c(
                                "div",
                                [
                                  _c("InsuranceDetailSchadensmeldungenTable", {
                                    attrs: {
                                      schadenInfos:
                                        _vm.detailsData.schadenInfos,
                                      isCalledFromMrMoneyPdfbox:
                                        _vm.isCalledFromMrMoneyPdfbox,
                                      mrMoneyId: _vm.mrMoneyId,
                                      vSNR: _vm.vSNR,
                                      gesellName:
                                        _vm.versicherungenDetails.insurance
                                          .gesellschaft.zweigstelleName
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm.selectedStepKey === "wertpapiere"
                      ? _c("div", { staticClass: "box__container" }, [
                          _vm.hasDepotPositions
                            ? _c(
                                "div",
                                [
                                  _c("InsuranceDetailDepotPositionsTable", {
                                    attrs: {
                                      depotPositions: _vm.depotPositions
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm.selectedSubstepKey === "abrechnungDetails"
                      ? _c(
                          "div",
                          [
                            _c("InsuranceAbrechnungDetails", {
                              attrs: {
                                editable:
                                  _vm.detailsData.insurance.allowNewBuchung
                              }
                            })
                          ],
                          1
                        )
                      : _vm.selectedSubstepKey === "zubringercourtage"
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("InsuranceZubringercourtage", {
                              attrs: {
                                editable:
                                  _vm.detailsData.insurance.allowEditCourtage
                              }
                            })
                          ],
                          1
                        )
                      : _vm.isSpartenStepSelected
                      ? _c(
                          "div",
                          [
                            _c("SparteSubstep", {
                              attrs: {
                                spartenComponents: _vm.spartenComponents,
                                hasHauptdaten: _vm.hasHauptdaten,
                                spartenValues: _vm.getSpartenData,
                                comboboxValues: _vm.getSpartenComboboxValues,
                                sparteId: _vm.selectedStepKey,
                                stepConfig: _vm.getSparteStepConfig
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                },
                proxy: true
              }
            ])
          }),
          false
            ? _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-lg-12 col-md-12 col-sm-12 col-12" },
                  [
                    _vm.detailsData && _vm.detailsData.insurance
                      ? _c(
                          "div",
                          { staticClass: "box__container" },
                          [
                            _c("div", { staticClass: "box__title" }, [
                              _vm._v("Sparten")
                            ]),
                            _vm._l(_vm.detailsData.insurance.sparten, function(
                              sp
                            ) {
                              return _c(
                                "div",
                                { key: sp.id },
                                [
                                  _c("BaseCollapsable", {
                                    attrs: { showSection: true },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function() {
                                            return [
                                              _vm._v(_vm._s(sp.typ.label))
                                            ]
                                          },
                                          proxy: true
                                        },
                                        {
                                          key: "content",
                                          fn: function() {
                                            return [
                                              sp.parameters &&
                                              sp.parameters.length
                                                ? _vm._l(
                                                    sp.parameters,
                                                    function(pa) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: pa.id,
                                                          staticClass: "row"
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-6"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(pa.label)
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "col-6"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  pa.parameter
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  )
                                                : _c("div", [
                                                    _vm._v("Keine daten")
                                                  ])
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                )
              ])
            : _vm._e()
        ],
        1
      ),
      _c("EmailVersand"),
      _c("AufgabeBearbeitenModal", {
        ref: "modalAdd",
        attrs: {
          bereichId: _vm.$route.params.id,
          gesellschaftName: _vm.gesellschaftName,
          nummer: _vm.nummer,
          bezeichnung: _vm.bezeichnung,
          bereichText: _vm.bereichText
        }
      }),
      _c("AddDocument", {
        ref: "addDocumentModal",
        attrs: { nodeId: _vm.nodeId, versicherungId: _vm.$route.params.id },
        on: {
          close: function($event) {
            return _vm.goToDownloadArea(_vm.$route.params.id)
          }
        }
      }),
      _c("CopyInsuranceConfirmationModal", {
        ref: "copyInsuranceConfirmationModal",
        on: { "open-item": _vm.openItemSelectedDetail }
      }),
      _c("ShowOldPoliceNumbersModal", {
        ref: "showOldPoliceNumbersModal",
        on: { "open-item": _vm.openItemSelectedDetail }
      }),
      _vm.askOptions
        ? _c("ExterneZugangAskOptions", {
            attrs: {
              askOptions: _vm.askOptions,
              confirmButtonLabel: _vm.actionLabel,
              data: _vm.data,
              title: _vm.externeZugang.companyLabel
            },
            on: {
              close: function($event) {
                return _vm.resetExterneZugang()
              },
              onConfirmButton: function($event) {
                _vm.askOptionsConfirmed($event)
                _vm.navigateToVersicherungstoolsHome()
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }