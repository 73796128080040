var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container" },
    [
      _c("EntitySelector", {
        attrs: {
          label: _vm.depotInhaber1Label,
          isPersonPicker: "",
          isComponentHalfSize: "",
          id: "INPUT_PERSONEN_INHABER",
          values: _vm.comboPerson,
          disabled: _vm.readOnly
        },
        on: {
          new: function($event) {
            return _vm.goToPersonalData("neue_person")
          },
          edit: function($event) {
            return _vm.goToPersonalData(_vm.INPUT_PERSONEN_INHABER)
          },
          change: function($event) {
            return _vm.updateStoreData({
              componentId: "INPUT_PERSONEN_INHABER",
              value: $event
            })
          }
        },
        model: {
          value: _vm.INPUT_PERSONEN_INHABER,
          callback: function($$v) {
            _vm.INPUT_PERSONEN_INHABER = $$v
          },
          expression: "INPUT_PERSONEN_INHABER"
        }
      }),
      _c("EntitySelector", {
        attrs: {
          label: _vm.depotInhaber2Label,
          isPersonPicker: "",
          isComponentHalfSize: "",
          values: _vm.comboPerson2,
          disabled: _vm.readOnly || !_vm.hasDepotinhaber2,
          validateUntouched: ""
        },
        on: {
          new: function($event) {
            return _vm.goToPersonalData("neue_person")
          },
          edit: function($event) {
            return _vm.goToPersonalData(_vm.INPUT_PERSONEN_INHABER2)
          },
          change: function($event) {
            return _vm.updateStoreData({
              componentId: "INPUT_PERSONEN_INHABER2",
              value: $event
            })
          }
        },
        model: {
          value: _vm.INPUT_PERSONEN_INHABER2,
          callback: function($$v) {
            _vm.INPUT_PERSONEN_INHABER2 = $$v
          },
          expression: "INPUT_PERSONEN_INHABER2"
        }
      }),
      _c("InputRadioBoxGroup", {
        attrs: {
          title: "Erklärung / Einwilligungen",
          values: _vm.radioErklaerung,
          disabled: _vm.readOnly
        },
        on: {
          input: function($event) {
            return _vm.updateStoreData({
              componentId: "INPUT_ERKLAERUNG",
              value: $event
            })
          }
        },
        model: {
          value: _vm.INPUT_ERKLAERUNG,
          callback: function($$v) {
            _vm.INPUT_ERKLAERUNG = $$v
          },
          expression: "INPUT_ERKLAERUNG"
        }
      }),
      !_vm.isCustomerLogin
        ? _c("InputRadioBoxGroup", {
            attrs: {
              title: "Maklerunterschrift / Stempelfeld",
              values: _vm.radioMaklerunterschrift,
              disabled: _vm.readOnly
            },
            on: {
              input: function($event) {
                return _vm.updateStoreData({
                  componentId: "INPUT_MAKLERUNTERSCHRIFT",
                  value: $event
                })
              }
            },
            model: {
              value: _vm.INPUT_MAKLERUNTERSCHRIFT,
              callback: function($$v) {
                _vm.INPUT_MAKLERUNTERSCHRIFT = $$v
              },
              expression: "INPUT_MAKLERUNTERSCHRIFT"
            }
          })
        : _vm._e(),
      !_vm.isCustomerLogin
        ? _c("InputRadioBoxGroup", {
            attrs: {
              title: "Ausgabeaufschlag anzeigen",
              values: _vm.radioAusgabeaufschlag,
              disabled: _vm.readOnly
            },
            on: {
              input: function($event) {
                return _vm.updateStoreData({
                  componentId: "INPUT_AUSGABEAUFSCHLAG",
                  value: $event
                })
              }
            },
            model: {
              value: _vm.INPUT_AUSGABEAUFSCHLAG,
              callback: function($$v) {
                _vm.INPUT_AUSGABEAUFSCHLAG = $$v
              },
              expression: "INPUT_AUSGABEAUFSCHLAG"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }