<template>
  <div>
    <HinweiseUndFehler :hints="hints" />
    <div
      v-if="substep === 'stammdaten'"
      key="stammdaten">

      <div class="box__container" v-if="istFPPlus">
        <div class="box__title">FinancePlan+</div>
        <InputToggleSwitch 
          v-model="brokerData.maklerVersVertragVollmachtEtcOhneMaklerdaten"
          label="Keine Vermittlerdaten in Maklervertrag und -vollmacht" 
          @change="addBrokerDataEdited('brokerData')"/>
      </div>
      <div class="box__container">
        <div class="box__title">Stammdaten</div>
        <InputToggleSwitch v-if="isInternOriginal || inaktivAllowed"
          v-model="brokerData.inaktiv"
          label="Vermittler inaktiv" 
          @change="updateInaktiv"/>
        <InputField
          label="Telefonpasswort"
          v-model="brokerData.telefonpasswort"
          :isComponentHalfSize="true"
          :disabled="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <InputField
          label="Registrierungscode"
          v-model="brokerData.registrierungscode"
          :isComponentHalfSize="true"
          :disabled="true"
        />
        <InputField
          v-if="(istFPPlusMaster || istDGFRP || isIntern) && !istFA"
          label="Kategorie"
          v-model="brokerData.kategorie"
          :isComponentHalfSize="true"
          :disabled="true"
        />
        <ComboBox
          v-if="(istFPPlusMaster || istDGFRP || isIntern) && !istFA"
          label="Potential"
          v-model="brokerData.potential"
          :values="brokerDataConfig.potentialValues"
          :isComponentHalfSize="true"
          @change="addBrokerDataEditedCombo('brokerData', 'potential', $event)"
          :disabled="!isIntern"
        />
        <InputCheckboxItem
          v-if="(istFPPlusMaster || istDGFRP || isIntern) && !istFA"
          v-model="brokerData.checkMaklerInteressent"
          label="Interessent"
          @input="checkboxChanged()"
          @change="addBrokerDataEdited('brokerData')"
        />
        <!-- <InputCheckboxItem
        v-if="(istFPPlusMaster || istDGFRP || isIntern) && !istFA"     
        v-model="brokerData.juristischePerson"
        label="Juristische Person"
        @input="checkboxChanged()"
        @change="addBrokerDataEdited('brokerData')"
      /> -->
        <InputField
          label="Maklernummer"
          v-model="brokerData.maklernummer"
          :isComponentHalfSize="true"
          :disabled="true"
        />
        <InputField
          label="Firmenname</br> (Dieser Name wird in der Vermögensübersicht und Quartalsbericht angezeigt.)"
          v-model="brokerData.firmenname"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <InputField
          :label="isIntern ? 'Anzeigename 2' : ''"
          v-model="brokerData.anzeigename2"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <InputField
          label="Firmenkürzel"
          v-model="brokerData.firmenkuerzel"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        
        <div v-if="isIntern || isMaklerAsMakler">
          <InputField
            label="Briefanrede"
            v-model="brokerData.briefanrede"
            :isComponentHalfSize="true"
            @change="addBrokerDataEdited('brokerData')"
          />
          <!-- MSC-21055  juristische Person for FinanzAdmin is in the section  Vertragsdaten -->
          <InputToggleSwitch v-if="!istFA"
            v-model="brokerData.juristischePerson"
            label="Juristische Person" 
            @change="addBrokerDataEdited('brokerData')"
            />
        </div>
        <div v-if="brokerData.telefon !== undefined">
          <InputField
            label="Telefon"
            v-model="brokerData.telefon"
            :isComponentHalfSize="true"
            @change="addBrokerDataEdited('brokerData')"
            inputmode="tel"
            isNotReactive
          />
          <InputField
            label="Mobilfunknummer</br> z.B. zur Passwortrücksetzung oder Zwei-Faktor-Authentifizierung"
            v-model="brokerData.smsStandard"
            :isComponentHalfSize="true"
            @change="addBrokerDataEdited('brokerData')"
            inputmode="tel"
            isNotReactive
          />
          <InputField
            label="Telefax"
            v-model="brokerData.telefax"
            :isComponentHalfSize="true"
            @change="addBrokerDataEdited('brokerData')"
            inputmode="tel"
            isNotReactive
          />
        </div>
        <InputField
          label="USt-ID"
          v-model="brokerData.ustId"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <InputField
          label="Steuernummer"
          v-model="brokerData.steuernummer"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <InputField
          label="Sozialversicherungsnummer"
          v-model="brokerData.sozialversicherugsnummer"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <ComboBox
          label="Staatsangehörigkeit"
          :values="brokerDataConfig.nationalities"
          v-model="brokerData.nationality"
          :isComponentHalfSize="true"
          @change="addBrokerDataEditedCombo('brokerData', 'nationality', $event)"
        />
        <InputField
          label="Referenznummer"
          v-model="brokerData.referenznummer"
          :isComponentHalfSize="true"
          @change="addBrokerDataEdited('brokerData')"
        />
        <template v-if="primaerPersonen">
          <ComboBox
            label="Primärer Ansprechpartner"
            v-if="brokerData.sectionPrimary"
            :values="primaerPersonen.hauptPersonValues"
            v-model="primaerPersonen.hauptPerson"
            :isComponentHalfSize="true"
            @change="
              addBrokerDataEditedCombo('primaerPersonen', 'hauptPerson', $event)
            "
          />
          <ComboBox
            label="Primärer Kundenberater"
            :values="primaerPersonen.kundenBeraterValues"
            v-model="primaerPersonen.kundenBerater"
            :isComponentHalfSize="true"
            @change="
              addBrokerDataEditedCombo('primaerPersonen', 'kundenBerater', $event)
            "
          />
        </template>

        <ComboBox v-if="istDGFRP || isInternOriginal"
          label="Betreuer"
          :values="brokerDataConfig.betreuerDGFRPValues"
          v-model="brokerData.betreuer"
          :isComponentHalfSize="true"
          @change="addBrokerDataEditedCombo('brokerData', 'betreuer', $event)"
        />
        
        <DatePickerField v-if="isBrokerMaster"
          label="Eintrittsdatum" 
          v-model="brokerData.eintrittsdatum"
          isComponentHalfSize
          @input="addBrokerDataEdited('brokerData')" 
        />
        <InputField v-if="(isBrokerMaster || isInternOriginal) && (!isDefaultBroker(brokerData.maklernummer)) "
        isComponentHalfSize 
        label="Strukturleiter" 
        v-model="brokerData.strukturleiter"  
        @change="addBrokerDataEdited('brokerData')" />

        <MaklerPicture />
      </div>
    </div>
    <div v-else-if="substep === 'adresse'" class="box__container" key="adresse">
      <div class="box__title">Adresse</div>
      <div class="row">
        <div class="col-12">
          <InputField
            label="Postleitzahl"
            :isComponentHalfSize="true"
            v-model="brokerData.plz"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            label="Ort"
            :isComponentHalfSize="true"
            v-model="brokerData.ort"
            @change="addBrokerDataEdited('brokerData')"
          />
          <InputField
            label="Straße"
            :isComponentHalfSize="true"
            v-model="brokerData.strasse"
            @change="addBrokerDataEdited('brokerData')"
          />
          <ComboBox
            label="Land"
            :isComponentHalfSize="true"
            :values="brokerDataConfig.countries"
            v-model="brokerData.land"
            @change="addBrokerDataEditedCombo('brokerData', 'land', $event)"
          />
          <ComboBox
            label="Bundesland"
            :isComponentHalfSize="true"
            :values="brokerDataConfig.bundeslaender"
            v-model="brokerData.bundesland"
            :firstEmpty="!brokerData.bundesland"
            @change="
              addBrokerDataEditedCombo('brokerData.', 'bundesland', $event)
            "
          />
        </div>
      </div>
    </div>
    <div
      v-else-if="substep === 'versandadresse'"
      class="box__container"
      key="versandadresse"
    >
      <div class="box__title">Versandadresse</div>
      <InputField
        label="Straße"
        :isComponentHalfSize="true"
        v-model="brokerData.versandAdresseStrasse"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Postleitzahl"
        :isComponentHalfSize="true"
        v-model="brokerData.versandAdressePLZ"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Ort"
        :isComponentHalfSize="true"
        v-model="brokerData.versandAdresseOrt"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Postfach"
        :isComponentHalfSize="true"
        v-model="brokerData.versandAdressePostfach"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="PLZ Postfach"
        :isComponentHalfSize="true"
        v-model="brokerData.versandAdressePLZPostfach"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div
      v-else-if="substep === 'paketadresse'"
      class="box__container"
      key="paketadresse"
    >
      <div class="box__title">Paketadresse</div>
      <InputField
        label="Straße"
        :isComponentHalfSize="true"
        v-model="brokerData.paketAdresseStrasse"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Postleitzahl"
        :isComponentHalfSize="true"
        v-model="brokerData.paketAdressePLZ"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Ort"
        :isComponentHalfSize="true"
        v-model="brokerData.paketAdresseOrt"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div
      v-else-if="substep === 'fp-plus-referenz'"
      class="box__container"
      key="fp-plus-referenz"
    >
      <div class="box__title">Referenznummer FP+</div>
      <InputField
        label="Referenznummer FP+ GmbH"
        isComponentHalfSize
        v-model="brokerData.referenznrPrivat"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Referenznummer FP+ Gewerbe GmbH"
        isComponentHalfSize
        v-model="brokerData.referenznrGewerbe"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Referenznummer FP+ Service GmbH"
        isComponentHalfSize
        v-model="brokerData.referenznrService"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Referenznummer FP+ GBR"
        isComponentHalfSize
        v-model="brokerData.referenznrGbr"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Referenznummer FP+ GmbH DAC"
        isComponentHalfSize
        v-model="brokerData.referenznrPrivatDac"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Referenznummer FP+ Gewerbe GmbH DAC"
        isComponentHalfSize
        v-model="brokerData.referenznrGewerbeDac"
        @change="addBrokerDataEdited('brokerData')"
      />
      <InputField
        label="Referenznummer FP+ Service GmbH DAC"
        isComponentHalfSize
        v-model="brokerData.referenznrServiceDac"
        @change="addBrokerDataEdited('brokerData')"
      />
    </div>
    <div
      v-else-if="substep === 'maklerkriterien'"
      class="box__container"
      key="maklerkriterien"
    >
      <div class="box__title">Makler Kriterien</div>
      <MaklerKriterien
        :maklerKriterien="brokerDataConfig.maklerKriterien"
        :alleStrukturKriterien="brokerDataConfig.alleStrukturKriterien"
      ></MaklerKriterien>
    </div>
  </div>
</template>

<script>
import vermittlerDatenMixin from "../vermittler-daten-mixin";

import ComboBox from "@/components/core/forms/ComboBox.vue";
import InputField from "@/components/core/forms/InputField.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import BROKERDATA_TYPES from "@/store/brokerData/types";
import { mapGetters } from "vuex";
import MaklerPicture from "@/components/broker/MaklerPicture.vue";
import MaklerKriterien from "@/components/broker/MaklerKriterien.vue";
import CORE_TYPES from "@/store/core/types";
import InputCheckboxItem from "@/components/core/forms/checkbox/InputCheckBoxItem.vue";
import { maxLength } from "@/mixins/validator/rules";
import validator from "@/mixins/validator";
import { formatPhone, telefon } from "@/mixins/validator/rules";
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import InputToggleSwitch from '@/components/core/forms/InputToggleSwitch.vue';
import { VIEW_ROLES } from '@/router/roles';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import brokerData from "@/store/brokerData/index";

export default {
  mixins: [vermittlerDatenMixin, validator],
  validators: {
    brokerData: {
      firmenkuerzel: [maxLength(19, "Firmenkuerzel ist zu lang.")],
      telefon: formatPhone(null, { emptyAllowed: true }),
      smsStandard: formatPhone(null, { emptyAllowed: true }),
      telefax: formatPhone('Bitte geben Sie eine gültige Faxnummer ein', { emptyAllowed: true }),
    },
  },

  data() {
    return {
      ...this.initialData(),
    };
  },
  computed: {
    ...mapGetters({
      brokerDataConfig: BROKERDATA_TYPES.GETTERS.GET_BROKER_DATA_CONFIG,
      primaerPersonen: BROKERDATA_TYPES.GETTERS.GET_BROKER_PRIMAER_PERSONEN,
      istFPPlusMaster: CORE_TYPES.GETTERS.IS_FPP_PLUS_MASTER,
      istFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      istDGFRP: CORE_TYPES.GETTERS.IS_DGFRP_MASTER,
      isDefaultBroker: CORE_TYPES.GETTERS.IS_DEFAULT_BROKER_ID,
      istFA: CORE_TYPES.GETTERS.IS_FA,
      isIntern: CORE_TYPES.GETTERS.IS_INTERN,
      isInternOriginal: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isBrokerMaster: CORE_TYPES.GETTERS.IS_BROKER_MASTER,
      inaktivAllowed: CORE_TYPES.GETTERS.IS_RIGHT_SET_MAKLER_INAKTIV,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isInaktiv: CORE_TYPES.GETTERS.IS_INAKTIV,
    }),
    hints() {
      const result = [];
      if (!this.brokerData.smsStandard) {
        result.push({
          title: "Schritt Stammdaten",
          message:
            "Bitte beachten Sie, dass für die Passwortvergabe eine Mobilfunknummer erforderlich ist!",
        });
      }
      if (!this.brokerData.emailStandard) {
        result.push({
          title: "Schritt Email",
          message:
            "Bitte beachten Sie, dass für die Passwortvergabe die Standard E-Mail erforderlich ist!",
        });
      }
      return result;
    },
    isMaklerAsMakler() {
      return this.hasRoles([VIEW_ROLES.VIEW_BROKER_AS_BROKER]);
    },
  },
  mounted() {
    this.retrieveDataPrimaerPersonen();

  },
  components: {
    ComboBox,
    InputField,
    MaklerPicture,
    MaklerKriterien,
    InputCheckboxItem,
    FormLabel,
    HinweiseUndFehler,
    InputToggleSwitch,
    DatePickerField,
  },
  methods: {
    initialData() {
      return {
        vermittlerStammdaten: {
          telefonpasswort: "",
          registrierungscode: "",
          maklernummer: "",
          firmenname: "",
          telefon: "",
          telefax: "",
          ustId: "",
          steuernummer: "",
          sozialversicherungsnummer: "",
          nationality: "",
          referenznummer: "",
          inaktiv: false,
        },
      };
    },

    async retrieveDataPrimaerPersonen() {
      await this.$store.dispatch(
        BROKERDATA_TYPES.ACTIONS.GET_BROKER_PRIMAER_PERSONEN
      );
    },
    async formatValue(fieldName, value) {
      if (fieldName && value) {
        this.brokerData[fieldName] = await this.$store.dispatch(
          CUSTOMERDATA_TYPES.ACTIONS.FORMAT_VALUE,
          { type: "Telefon privat", value }
        );
      }
    },
    updateInaktiv(value) {
      this.$store.dispatch(CORE_TYPES.ACTIONS.UPDATE_IS_INAKTIV, value ? true : false).then(isOk => {
        if (!isOk) {
          this.inaktiv = !value;
        }
      });
    },
  },
  async beforeRouteLeave(to, from, next) {
    await this.saveChanges().then(() => next());
  },
};
</script>