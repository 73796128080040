<template>
  <div class="input-forms__container"
    :class="{'input-forms__container--embedded': isEmbedded}">
    <label class="input-forms__label-container">
      <div class="input-field__label">Eingabe zur Suche</div> 
      <div class="input-forms__label-content" v-if="label" :class="labelClass" v-html="sanitize(label)"></div>
      <div class="input-forms__input-container" :class="{'forms__input--half-size': this.isComponentHalfSize}">
        <input class="fc-input input-forms__input-field" 
          ref="inputField"
          v-model="internalValue"
          :id="id"
          :name="id"
          :type="verifiedInputType"
          :inputmode="computedInputmode"
          :placeholder="placeholder"
          :disabled="disabled"
          :class="getInputClass"
          :autocomplete="autocomplete"
          :maxlength="maxLength"
          :passwordrules="passwordRules"
          :enterkeyhint="enterkeyhint"
          @blur="onBlur"
          @focus="onFocus"
          @change="onChange"
          @keyup.enter="onKeyboardEnter"
          @keydown.tab="onKeyboardTab"
          @keyup="onKeyUp"
          @click="onClick"
          />

        <span v-if="type === 'percent'" 
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side">
          <ph-percent :size="16" />
        </span>
        <span v-else-if="type === 'formpercent'" 
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side">
          <ph-percent :size="16" />
        </span>
        <span v-else-if="type === 'currency'"
          class="inputfield__input-icon inputfield__input-icon--text-right">
          {{this.currency}}
        </span>
        <span v-else-if="type === 'calendar'"
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--left-side">
          <ph-calendar :size="16" />
        </span>
        <span v-else-if="type === 'search'"
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side">
          <ph-magnifying-glass :size="16" />
        </span>
        <span 
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
          v-else-if="isCapslookEnabled && this.type === 'password'">
          <ph-arrow-square-up :size="16" />
        </span>
        <span v-else-if="showCleanButtonIcon" 
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side">
          <ph-x-circle class="clickable" :size="16" @click="clearButton" tabindex="-1"></ph-x-circle>
          <ph-check-circle :size="16" class="color-success" v-if="showConfirmationIcon" @click="confirmationButton($event)"></ph-check-circle>
        </span>
        <span v-else-if="!showCleanButtonIcon && showConfirmationIcon" 
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side">
          <ph-check-circle :size="16" class="color-success" @click="confirmationButton($event)"></ph-check-circle>
        </span>

        <button v-if="canTogglePasswordView" type="button"
          class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side inputfield__input-icon--right-side-2 btn-clear clickable"
          tabindex="-1"
          @click="isPlainPassword = !isPlainPassword"
        >
          <PhEye v-if="isPlainPassword" :size="16" alt="Icon für sichtbaren Text"/>
          <PhEyeSlash v-else :size="16" alt="Icon für verborgenen Text"/>
        </button>

        <span v-else-if="hasIconSlot()" class="inputfield__input-icon forms__input-icon inputfield__input-icon--right-side inputfield__input-icon--right-side-2">
          <slot name="icon" />
        </span>

      </div>
      <template v-if="isValidationConfigured()">
        <div class="input-forms__errors-container" :key="validation.updated" v-if="!suppressValidationMessage && validation">
          <div class="fc-form-danger" 
            v-for="error in validation.getErrors(this.validationPath, this.validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
        </div>
      </template>
    </label>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import { formatNumber, isNumber } from '@/helpers/number-formatter.js'
import currencyMap from '@/assets/currency-map.json'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { sanitize } from '@/helpers/string-helper.js';
import {
PhCheckCircle,
PhXCircle,
PhPercent,
PhCurrencyEur,
PhCalendar,
PhMagnifyingGlass,
PhArrowSquareUp,
PhEye,
PhEyeSlash,
} from "phosphor-vue";

const iconTypesRightSide = [
  'percent', 'currency', 'search', 'foreign_cy', 'formpercent', 
];

const iconTypesLeftSide = [
  'calendar'
];

const numberFormatTypes = ['percent', 'currency', 'number']

const TYPES_TO_USE_INPUTMODE = [ 'tel', 'email', 'url', ]

const DEFAULT_CURRENT_PRECISION = 2

function triggerEvent(el, type) {
  const event = new Event(type, { 
      bubbles: true,
      cancelable: true,
  });
  el.dispatchEvent(event);
}

export default {
  mixins: [validatorComponentUtils],
  components: {
    PhCheckCircle,
    PhXCircle,
    PhPercent,
    PhCurrencyEur,
    PhCalendar,
    PhMagnifyingGlass,
    PhArrowSquareUp,
    PhEye,
    PhEyeSlash,
  },
  props: {
    value: {
      type: [String, Number, Boolean]
    },
    label: {
      type: String
    },
    labelClass: {
      type: String,
    },
    inputClass: { 
      type: Object,
      default: () => {},
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: () => Math.random().toString(36).substr(2, 10)
    },
    isEmbedded: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
    },
    precision: {
      type: [String, Number]
    },
    showConfirmationIcon: {
      type: Boolean,
      default: false
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    foreign_currency: {
      type: String,
      default: ''
    },
    isNotReactive: {
      type: Boolean,
      default: false
    },
    inputmode: {
      type: String,
      default: null
    },
    autocomplete: {
        type: String,
        default: undefined,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    isInteger: {
      type: Boolean,
      default: false,
    },
    renderDanger: {
      type: Boolean,
      default: false
    },
    allowPlainPassword: {
      type: Boolean,
      default: false,
    },
    allowPasswordRules: {
      type: Boolean,
      default: false,
    },
    maxLength : {
      type: Number,
      default: 524288 //html specification default value 
    },
    trimValue: {
      type: Boolean,
      default: false,
    },
    enterkeyhint: {
      type: String,
      default: null
    },
    preventTab: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      isInputActive: false,
      changedValue: '',
      isCapslookEnabled: false,
      isPlainPassword: false,
    }
  },
  mounted() {
    this.$refs.inputField.addEventListener('change', this.onNativeChangeEvent);

    this.$emit('ref', this.$refs.inputField);
    this.$runValidator(this.value, true)
    this.changedValue = this.value;
  },
  beforeDestroy() {
    this.$refs.inputField.removeEventListener('change', this.onNativeChangeEvent);
  },
  computed: {
    ...mapGetters({
      istFA: CORE_TYPES.GETTERS.IS_FA,
    }),
    getInputClass() {
      return {
        'fc-form-danger': this.renderDanger || this.isValidationConfigured() && this.validation.isInvalid(this.validationPath, this.validateUntouched),
        'forms__input--padding-icon-left': this.hasLeftIcon,
        'forms__input--padding-icon-right': this.hasRightIcon,
        'forms__input--small': this.small,
        'forms__input--has-confirmation-icon': this.showConfirmationIcon,
        'forms__input--has-clear-icon': this.showCleanButtonIcon,
        'forms__input--padding-icon-2': this.hasIconSlot() || this.canTogglePasswordView,
        ...this.inputClass,
        }
    },
    showCleanButtonIcon() {
      //how can we get in here when we have an else-if???
      return !!(this.value) && !this.disabled && !numberFormatTypes.includes(this.type);
    },
    verifiedInputType() {
      if(this.type === 'password' && !this.isPlainPassword || this.type === 'email') {
        return this.type;
      }
      return 'text';
    },
    canTogglePasswordView() {
      return this.type === 'password' && this.allowPlainPassword;
    },
    passwordRules() {
      const isPasswordRulesAvailable = this.type === 'password' && this.allowPasswordRules;
      if (!isPasswordRulesAvailable) return null;
      return 'required: upper, lower, digit; allowed: unicode; minlength: 6;';
    },
    computedInputmode() {
      if (this.inputmode) {
        return this.inputmode;
      }
      if(TYPES_TO_USE_INPUTMODE.includes(this.type)) {
        return this.type;
      }
      return null;
    },
    hasRightIcon() {
      return iconTypesRightSide.includes(this.type) || this.showCleanButtonIcon
    },
    hasLeftIcon() {
      return iconTypesLeftSide.includes(this.type)
    },
    computedPrecision() {
      if (!this.precision && this.precision !== 0 && this.type === 'currency') {
        return DEFAULT_CURRENT_PRECISION
      }
      if ( typeof this.precision === 'string') {
        return parseInt(this.precision, 10);
      }
      return this.precision
    },
    currency() {
        return currencyMap[this.foreign_currency || 'EUR'].symbol;
      },
    internalValue: {
      get: function() {
        let currentValue = this.isNotReactive ? this.changedValue : this.value
        currentValue = (currentValue || currentValue === 0) ? currentValue + '' : ''

        if (numberFormatTypes.includes(this.type)) {
          if (this.isInputActive) {
            return currentValue.replace(/[\.]/, ',')
          }
          if(!currentValue.includes(',') && !this.isInteger) {
            return formatNumber(currentValue, this.computedPrecision);
          }

          if(currentValue.includes(',') && this.isInteger) {
            currentValue = this.normalizeChangedValue(currentValue, null);
          }
        }

        if(this.inputmode === 'tel') {
          currentValue = this.formatTelephoneNumber(currentValue);
        }
        return currentValue;
      },
      set: function(modifiedValue) {
        let newValue = this.normalizeChangedValue(modifiedValue);
        if(this.inputmode === 'tel') {
          newValue = this.formatTelephoneNumber(newValue);
        }

        this.$emit('input', newValue);
        this.$emit('inputRawValue', modifiedValue);
        if (this.isValidationConfigured() && !this.validation?.isDirty(this.validationPath)) {
          this.$setDirty();
        }
        if ( this.isNotReactive ) {
          this.changedValue = this.isInputActive ? modifiedValue : newValue;
        }
      }
    },
  },
  watch: {
    value(newValue) {
      this.$runValidator(newValue);
    },
    disabled() {
      if (this.disabled) {
        this.$reset()
      } else { 
        this.$runValidator(this.internalValue);
      }
    },
    autofocus: {
      handler() {
        if(this.autofocus) {
          this.$nextTick(() => requestAnimationFrame(this.focus));
        }
      },
      immediate: true,
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onNativeChangeEvent(event) {
      this.trimValueIfNeeded(event.target);
    },
    trimValueIfNeeded(el) {
      if (!this.trimValue) return;

      const { value } = el;
      const trimmedValue = value.trim();
      if (value !== trimmedValue) {
        el.value = trimmedValue;
        triggerEvent(el, 'input');
      }
    },
    focus() {
      this.$refs.inputField && this.$refs.inputField.focus()
    },
    clearButton(event) {
      event.preventDefault();
      event.stopPropagation();

      this.$refs.inputField?.focus?.();

      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('reset', '');
      this.changedValue = '';
      this.$setDirty();
    },
    confirmationButton($event) {
      this.$emit('blur', $event);
      this.$setDirty();
    },    
    onBlur($event) {
      this.isInputActive = false
      if(this.inputmode === 'tel' && this.internalValue.trim().length < 4) {
        this.internalValue = '';
      }
      this.$setTouched()
      this.$emit('onBlur', $event);
    },
    onFocus($event) {
      this.isInputActive = true
      if(this.inputmode === 'tel' && !this.internalValue) {
        if (this.istFA) {
          this.internalValue += '+43';
        } else {
          this.internalValue += '+49';
        }
      }
      this.$emit('focus', $event)
      this.$runValidator(this.internalValue);
    },
    onChange($event) {
      let newValue = this.normalizeChangedValue($event.target.value, this.computedPrecision);
      
      // if (numberFormatTypes.includes(this.type) && isNumber(newValue)) {
      //   this.internalValue = newValue.split('.').join(',');
      // }

      this.$emit('change', newValue);
      this.$setDirty();
    },
    normalizeChangedValue(value, precision) {
      let newValue = value;

      if (newValue !== '' && numberFormatTypes.includes(this.type)) {
        if (!isNumber(newValue)) {
          newValue = '';
        } else {
          newValue = value.split('.').join('').split(',').join('.');
          newValue = precision >= 0 ? parseFloat(newValue).toFixed(precision) : parseFloat(newValue);
          if(this.isInteger) {
            newValue = Math.floor(newValue)
          }
        }
      }

      return newValue;
    },
    onKeyboardEnter($event) {
      let newValue = this.normalizeChangedValue($event.target.value);
      this.$emit('searchAction', newValue);
    },
    onKeyboardTab($event) {
      if (!$event.altKey && !$event.ctrlKey && this.preventTab) {
        $event.preventDefault();
        this.$emit('tabPressed', {target: $event.target, withShift: $event.shiftKey} );
      }
    },
    resetField(){
      this.$emit('input', '');
      this.$setDirty();
      if (this.isValidationConfigured() && this.validation) {
        for (let i = 0; i < this.validation.getErrors(this.validationPath, this.validateUntouched).length; i++){
          this.validation.removeError(this.validationPath, this.validation.getErrors(this.validationPath, this.validateUntouched)[i]);
        }
      }
    },
    onKeyUp($event) {
      this.isCapslookEnabled = this.type === 'password' && $event?.getModifierState?.("CapsLock")
    },
    onClick($event) {
      this.$emit('contentSelected', $event)
    },
    formatTelephoneNumber (value) {
      if (!value) return '';
      value = value.toString();
      let treatedValue = value.replace(/[\D|\s]+/g, '');
      // Austrian format: +43 1234 5678900 or 01234 5678900
      // German Format: +49 123 4567890 or 0123 4567890
      let pattern = this.istFA ? /^(43)(0?)(\d{1,4})(\d{6,})$/ : /^(49)(0?)(\d{3})(\d{7,8})$/;

      if(pattern.test(treatedValue)) {
        return treatedValue.replace(pattern, "+$1 $3 $4");
      }

      // if it doesn't start with '+' add default country codes 
      if(/^(?!\+)/.test(value)) {
        return this.istFA ? `+43 ${treatedValue}` : `+49 ${treatedValue}`;
      }

      // if it doesn't start with +49 or +43 add it
      // if(/^(?!\+?(49|43))../.test(treatedValue)) {
      //   return this.istFA ? `+43 ${treatedValue}` : `+49 ${treatedValue}`;
      // }
      return value;
    },
    hasIconSlot() {
      return !!this.$slots?.icon;
    },
  }
}
</script>

<style scoped>

  .inputfield__input-icon--right-side {
    right: 0;
    padding: 0 12px 0 4px;
  }
  .inputfield__input-icon--right-side + .inputfield__input-icon--right-side-2 {
    right: 30px;
    padding: 0 2px;
  }
  .forms__input--has-confirmation-icon.forms__input--has-clear-icon + .inputfield__input-icon--right-side + .inputfield__input-icon--right-side-2 {
    right: 46px;
  }
  .inputfield__input-icon--text-right {
    padding: 0 8px;
    right: 0;
  }

  .forms__input--padding-icon-right {
    padding-right: 32px;
  }

  .forms__input--padding-icon-2 {
    padding-right: 48px;
  }

  .forms__input--padding-icon-right.forms__input--has-confirmation-icon {
    padding-right: 50px;
  }

  .forms__input--has-confirmation-icon.forms__input--has-clear-icon.forms__input--padding-icon-2 {
    padding-right: 65px;
  }

  .inputfield__input-icon--left-side {
    left: 0;
  }

  .inputfield__input-icon {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    height: 100%;
  }

  .inputfield__input {
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 2px;
  }

  .inputfield__input--error {
    border: 1px solid var(--color-danger);
  }
  .input-field__label {
    display: none;
  }

/*   .inputfield__input:focus {
    border: 1px solid var(--color-primary);
  } */
  input[type=number] {
    -moz-appearance:textfield;
  }
  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }
</style>