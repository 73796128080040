var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "finance-line-sum" }, [
    _c("div", { staticClass: "my-2" }, [
      _vm._v("Summe Kapitalvermögen / sonstiger Vermögenswerte")
    ]),
    _c(
      "div",
      { staticClass: "my-2 pr-3" },
      [_c("CurrencyLabel", { attrs: { value: _vm.summe } })],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }