<template>
  <BaseModal 
    :showDefaultButtons="false"
    modalTitle="Filterliste"
    size="lg"
    @onCloseButton="emitCloseModalEvent()"
    ref="baseFilterSaveModal"
  >

    <template v-slot:default>
      <div v-if="hasSearchParameters">
        <SortableList :items="storedSearchParameter.searchParameters" @orderChanged="onOrderChanged($event)">
          <ul class="list-bordered" data-sortable-container>
            <li v-for="(item, index) in storedSearchParameter.searchParameters" 
              v-bind:key="index" 
              class="list-bordered-item" 
              data-sortable-item
            >
              <div class="d-flex parameter-search__input-fields">
                <div>
                  <InputField
                    v-if="currentlyEditingIndex === index"
                    v-model="item.searchLabel"
                  />
                  <template v-else >
                    <span :class="{'text-bold': item.isDefault}">{{ item.searchLabel + (item.isDefault ? ' (Standard)' : '') }}</span>

                    <template v-if="!item.visible">
                      <span> | </span>
                      <span class="color-danger">versteckt</span>
                    </template>
                  </template>
                </div>
                <div style="flex: 1 0 auto" class="d-flex align-items-center justify-content-end">
                    <BaseButton 
                      isSecondary 
                      class="ml-3" 
                      @click="toggleDefaultFilter(index)"
                    >
                      <span v-if="item && item.isDefault">
                        Standard löschen
                      </span>
                      <span v-else>
                        Als Standard setzen
                      </span>
                    </BaseButton>
                    <BaseContextMenu>
                      <ContextMenuItem @click="editRow(index)">Filter bearbeiten</ContextMenuItem>
                      <ContextMenuItem @click="exportFilter(index)">Filter exportieren</ContextMenuItem>
                      <ContextMenuItem @click="deleteFilterItem(index)">Filter löschen</ContextMenuItem>
                      <ContextMenuItem @click="hideOrShow(index)">
                        <template v-if="item.visible">
                          Filter verstecken
                        </template>
                        <template v-else>
                          Filter anzeigen
                        </template>
                      </ContextMenuItem>
                    </BaseContextMenu>
                    <ph-list class="ml-2" data-sortable-item-handler :size="iconSize" weight="bold" />
                </div>     
              </div>
            </li>
          </ul>
        </SortableList>
      </div>
      <NoData v-else noIcon />
    </template>

    <template v-slot:footer>
      <BaseFileSelect
        isSecondary
        class="mr-3"
        :disable="!filterSaveKey"
        @files="importFilter"
      >
        Filter laden
      </BaseFileSelect>
      
      <BaseButton 
        isPrimary
        @click="emitCloseModalEvent()">
        <span>Schließen</span>
      </BaseButton>
    </template>
  </BaseModal>
</template>
<script>
import {
  PhList,
} from "phosphor-vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import BaseFileSelect from "@/components/fileUpload/BaseFileSelect.vue";
import InputField from "@/components/core/forms/InputField.vue";
import SortableList from '@/components/core/SortableList.vue';
import axios from 'axios';
import { mapGetters } from 'vuex';
import LOG_TYPES from '@/store/log/types';
import SEARCH_CONFIGS_TYPES from '@/store/searchConfigs/types'
import { buildMessage } from '@/helpers/log-message-helper';
import { downloadFile } from "./GenericPersonFilter.vue";
import CORE_TYPES from '@/store/core/types'
import NoData from '@/components/core/NoData.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';

export default {
  components: {
    PhList,
    BaseButton,
    BaseModal,
    BaseFileSelect,
    InputField,
    SortableList,
    NoData,
    BaseContextMenu,
    ContextMenuItem,
  },
  props: {
    filterSaveKey: {
      type: String,
      default: null,
    }
  },
  data: function () {
    return {
      storedSearchParameter: {},
      currentlyEditingIndex: null,
    };
  },
  mounted: function () {
  },
  watch: {
    loadedFilters: {
      handler() {
        this.storedSearchParameter = JSON.parse(JSON.stringify(this.loadedFilters[this.filterSaveKey] || {}))
      },
      immediate: true,
    }
  },
  computed: {
    ...mapGetters({
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      loadedFilters: SEARCH_CONFIGS_TYPES.GETTERS.LOADED_FILTERS,
    }),
    hasSearchParameters() {
      return !!this.storedSearchParameter?.searchParameters?.length;
    },
    iconSize() {
      return this.$isSmallScreen ? 24 : 20;
    },
  },
  methods: {
    open() {
      this.$refs.baseFilterSaveModal.open();
    },
    close() {
      this.$refs.baseFilterSaveModal.close();
    },
    exportFilter(index) {
      const search = this.storedSearchParameter?.searchParameters?.[index]
      if (search) {
        const payload = {
          ...search,
          parameters: JSON.stringify(search.parameters)
        }
        axios.post(`${this.apiAddress}/stored_search_parameter/export`, payload).then(result => {
          if (result && result.data) {
            downloadFile('filter_' + search.searchLabel, result.data, 'text/plain');
          }
        });
      }
    },
    importFilter(files) {
      const config = {
        defaultSpinner: true,
        headers: { 'Content-Type': 'multipart/form-data' },
      };
      let payload = new FormData();
      payload.append("file", files[0]);
      const errorMessage = buildMessage('Beim importieren des Filters ist ein Fehler aufgetreten.', 'danger');
      axios.post(`${this.apiAddress}/stored_search_parameter/import`, payload, config).then(response => {
        const fileContent = response?.data?.fileContent
        if (fileContent) {
          if (fileContent.searchKey === this.filterSaveKey) {
            this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER, {
              searchKey: this.filterSaveKey,
              searchParameter: {
                ...response.data.fileContent,
                parameters: JSON.parse(response.data.fileContent.parameters)
              },
            })
          } else {
            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 
              buildMessage('Der hochgeladene Filter ist falsch', 'danger'));
          }
        } else {
          this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, errorMessage);
        }
      }).catch(() => {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, errorMessage);
      })
    },
    
    editRow(index) {
      if (this.currentlyEditingIndex === index) {
        this.currentlyEditingIndex = null
      } else {
        this.currentlyEditingIndex = index;
      }
    },
    deleteFilterItem(index) {
      this.storedSearchParameter.searchParameters?.splice(index, 1)
      this.saveParametersList()
    },

    toggleDefaultFilter(editIndex) {
      this.storedSearchParameter.searchParameters.forEach((item, index) => {
        if (editIndex === index) {
          this.$set(item, 'isDefault', !item.isDefault)
        } else {
          this.$set(item, 'isDefault', false)
        }
      })
    },
    hideOrShow(index) {
      const item = this.storedSearchParameter.searchParameters?.[index]
      this.$set(item, 'visible', !item.visible)
    },
    emitCloseModalEvent() {
      this.currentlyEditingIndex = null
      this.saveParametersList()
      this.close()
    },
    onOrderChanged(sortedList) {
      sortedList.forEach((sl, index) => {
        sl.columnOrder = index;
      })

      this.storedSearchParameter?.searchParameters?.sort((a, b) => a.columnOrder - b.columnOrder)
    },
    saveParametersList() {
      this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_LIST, {
        searchKey: this.filterSaveKey, 
        searchParameters: this.storedSearchParameter.searchParameters
      })
    }
  },
};
</script>
<style scoped>
.parameter-search__input-fields {
  margin: 0 -8px;
}

.parameter-search__input-fields > * {
  margin: 0 8px;
}

.parameter-search__input-fields > div > span {
  display:inline;
}
</style>