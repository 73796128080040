<template>
  <div class="base-filter__container box__container" tid="4ec7d0ef-582b-450c-9789-9282c9880a8d">
    <template v-if="$isSmallScreen">
      <MobileInfoRow
        :label="title || 'Filter'"
        @clickRow="$refs.filterModal.open()"
      />

      <BaseButtonsContainer class="action-button__container" v-if="extraButton">
        <template v-if="extraButton && extraButton.buttonText">
          <BaseButton @click="$emit('extraButtonClick', getFilterConfig())" :isPrimary="extraButton.isExtraButtonPrimary" :isSecondary="!extraButton.isExtraButtonPrimary">
            {{extraButton.buttonText}}
          </BaseButton>
        </template>

        <slot />

      </BaseButtonsContainer>
    </template>

    <div v-else class="row mt-0">
      <div class="col-12 col-md-12 col-sm-12">

        <div class="row my-0">
          <div class="col-12">
            <BaseFilterSelect
              ref="baseFilterSelect"
              :title="title"
              :showSaveButton="showSaveButton"
              :storedSearchParameter="storedSearchParameter"
              :programmaticSearchParameter="configFilter && configFilter.programmaticSearchParameter"
              @onEditFilter="filterBearbeiten()"
              @onSelect="selectStoredSearchParameter($event, true)"
            >
              <template #titleToolbar>
                <button aria-label="Einklappen"
                  v-if="hasSmartSearch && getFilterRows.length || getFilterRows.length > 1"
                  type="button" class="btn btn-clear parameter-search__input-fields-toggle clickable" 
                  :class="{ 'active': collapsed, }"
                  @click="setCollapsed()" 
                >
                  <PhCaretUp :size="18" />
                </button>

                <BaseContextMenu v-if="isEditAvailable">
                  <ContextMenuItem @click="filterBearbeiten()">Filterliste</ContextMenuItem>
                  <ContextMenuItem @click="emitFilterSpeichernEvent()">Filter speichern</ContextMenuItem>
                </BaseContextMenu>
              </template>
            </BaseFilterSelect>
          </div>
        </div>

        <div class="parameter-search__input-fields__container">
          <div v-if="!collapsed && hasSmartSearch" class="row parameter-search__input-fields" v-on:submit.prevent="noop" v-on:keyup.enter="emitFilterEvent()">
            <div class="col-10 col-lg-10 col-md-10 col-sm-10">
              <InputField class="filterLabel" ref="defaultSearchInput" 
                :placeholder="configFilter.placeholderForDefSearchInput" 
                v-model="defaultSearchInput" :label="filterLabel"
                :inputClass="setClassTabElement(isTabCtrl,null)" :preventTab="isTabCtrl" @tabPressed="onTabPressed($event, -1)" />
                <div class="dropList" v-if="defaultSearchInput && filteredOptions && filteredOptions.length">
                  <ul>
                    <li v-for="(item, index) in filteredOptions" :key="index" @click="selectFilterDropListItem(item)">
                      {{ item.label }}
                    </li>
                  </ul>
                </div>
            </div>  
            <div v-if="!isSingleFilter" class="parameter-search__buttons-container col-2 col-lg-2 col-md-2 col-sm-2 d-flex align-items-center justify-content-end">
              <div class="parameter-controls__container">
                <button type="button" class="btn btn-clear clickable" @click="enableMoreOptions()" :alt="ariaDescription">
                  <ph-plus :id="filterId + 'base__filter__plus__default'" :size="16" weight="bold" :alt="ariaDescription"/>
                </button>
              </div>
            </div>               
          </div>

          <div v-if="showMoreOptions || !hasSmartSearch || Object.keys(defaultOptions).length" ref="moreOptionsEl"
            v-on:submit.prevent="noop" v-on:keyup.enter="emitFilterEvent()" class="parameter-search__input-fields--more-options"
            :class="{ 'collapsed': collapsed, }"
          >
            <div class="parameter-search__input-fields" v-for="(row, index) in getFilterRows" v-bind:key="`${row.secondaryKey}-${index}`"
              :class="{ 'row': !collapsed, }"
            >
              <template v-if="!collapsed">
                <div
                 v-if="showAlwaysPrimaryselection || row.primarySelection && row.primarySelection.length>1"
                 class="col-12 col-sm-3" :class="{ 'd-none': configFilter.hideFirstColumn, }">
                  <ComboBox
                    v-model="row.key"
                    :values="row.primarySelection"
                    @change="onChangePrimary(row, $event)"
                    :disabled="row.disabled || row.isRequiredFilterGroup"
                  />
                </div>

                <div :class="{ 
                  'col-sm-3': !configFilter.hideFirstColumn, 
                  'col-sm-6': configFilter.hideFirstColumn, 
                  'col-9': !row.componentType || row.componentType === 'default',
                  'col-12': row.componentType && row.componentType !== 'default',
                  'parameter-search__component-default': !row.componentType || row.componentType === 'default',
                }">
                  
                  <ComboBox
                    v-model="row.secondaryKey"
                    :values="getSecondarySelection(row.key, index)"
                    @change="onChange(row, $event)"
                    :disabled="row.disabled"
                  />

                </div>
            
                <template v-if="row.componentType && 
                  row.componentType !== 'dateRange' &&
                  row.componentType !== 'numberRange' && 
                  row.componentType !== 'textRange' && 
                  row.componentType !== 'default' && 
                  row.componentType !== 'doubleCombo'">

                  <div :key="row.__UPDATED_KEY__" class="parameter-search__secondary-key-container col-9 col-sm-4">
                    <InputField
                      :id="filterId+'base__filter__'+row.secondaryKey"
                      v-if="row.componentType == 'text'"
                      v-model="row[row.secondaryKey]"
                      :disabled="row.disabled"
                      :inputmode="getComponentInputmode(row.key, row.secondaryKey)"
                      :inputClass="setClassTabElement(row.isTabCtrl,index)" :preventTab="!!row.isTabCtrl" @tabPressed="onTabPressed($event, index)"
                    />
                    <InputField
                      v-if="row.componentType == 'integer'"
                      :value="row[row.secondaryKey]"
                      type="number"
                      isInteger
                      :disabled="row.disabled"
                      @change="setValueChanged(row, row.secondaryKey, $event)"
                      :inputClass="setClassTabElement(row.isTabCtrl,index)" :preventTab="!!row.isTabCtrl" @tabPressed="onTabPressed($event, index)"
                    />
                    <InputField
                      v-if="row.componentType == 'tel'"
                      :value="row[row.secondaryKey]"
                      type="tel"
                      :disabled="row.disabled"
                      @change="setValueChanged(row, row.secondaryKey, $event)"
                      :inputClass="setClassTabElement(row.isTabCtrl,index)" :preventTab="!!row.isTabCtrl" @tabPressed="onTabPressed($event, index)"
                    />
                    <DatePickerField
                      v-if="row.componentType == 'datepicker'"
                      v-model="row[row.secondaryKey]"
                      :disabled="row.disabled"
                    />
                    <InputToggleSwitch
                      v-if="row.componentType == 'switcher'"
                      class="parameter-search__input-toggle-switch"
                      v-model="row[row.secondaryKey]"
                      :disabled="row.disabled"
                    />
                    <InputMultipleItemSelector 
                      v-if="row.componentType === 'multipleItemSelector'"
                      :value="row[row.secondaryKey]"
                      :values="row.comboOptions"
                      :disabled="row.disabled"
                      @input="updateMultipleSelection(row, $event)"
                    />
                    <InputChips 
                      v-if="row.componentType === 'chips'"
                      class="p-0"
                      v-model="row[row.secondaryKey]"
                      :showToggle="true" 
                      :uniqueSelection="row.uniqueSelection"
                      :availableOptions="row.comboOptions"
                      :supportCustomValue="row.supportCustomValue"
                      @input="updateMultipleSelection(row, $event)"
                    />
                    <div class="row mt-0 mb-0" v-if="row.secondaryItem && row.secondaryItem.parentValue.includes(row[row.secondaryKey])">
                      <div class="col-6 col-sm-6">
                        <ComboBox
                          v-if="row.componentType == 'combobox'"
                          v-model="row[row.secondaryKey]"
                          @change="changeCombobox(row, row.secondaryKey, $event)"
                          :values="filteredComboOptions(row.comboOptions)"
                          :firstEmpty="false"
                          :disabled="row.disabled"
                        />
                      </div>
                      <div class="col-6 col-sm-6">
                        <ComboBox
                          v-if="row.secondaryItem.type == 'combobox'"
                          v-model="row[row.secondaryItem.key]"
                          :values="filteredComboOptions(row.secondaryItem.comboOptions)"
                          :firstEmpty="false"
                          :disabled="row.disabled"
                        />
                        <InputField
                          v-if="row.secondaryItem.type == 'text'"
                          v-model="row[row.secondaryItem.key]"
                          :disabled="row.disabled"
                        />
                      </div>
                    </div>
                    <div v-else-if="row.componentType == 'combobox'">
                      <ComboBox
                        v-if="row.componentType == 'combobox'"
                        v-model="row[row.secondaryKey]"
                        @change="changeCombobox(row, row.secondaryKey, $event)"
                        :values="filteredComboOptions(getComboboxOptions(row.key, row.secondaryKey))"
                        :firstEmpty="false"
                        :disabled="row.disabled"
                      />
                    </div>
                    <SplitedDatePicker
                      v-if="row.componentType == 'splitedDatePicker'"
                      v-model="row[row.secondaryKey]"
                      :disabled="row.disabled"
                    />
                  </div>
                </template>

                <template v-if="row.componentType == 'dateRange'">
                  <div class="parameter-search__secondary-key-container col-9 col-sm-4">
                    <DatePickerField
                      isRangePicker
                      :disabled="row.disabled"
                      :value="getDateRangeValue(row)"
                      
                      @onSelectRangeFromAsText="row[row.secondaryKey + '_1'] = $event"
                      @onSelectRangeToAsText="row[row.secondaryKey + '_2'] = $event"
                      @onSelectPreset="row['presetMeta'] = $event"
                    />
                  </div>
                </template>

                <template v-if="row.componentType == 'numberRange'">
                  <div class="parameter-search__secondary-key-container col-9 col-sm-2">
                    <InputField
                      type="number"
                      placeholder="min."
                      :precision="getPrecision(row)"
                      v-model="row[row.secondaryKey + '_1']"
                      :disabled="row.disabled"
                    />
                  </div>
                  <div class="parameter-search__secondary-key-container col-9 col-sm-2">
                    <InputField
                      type="number"
                      placeholder="max."
                      :precision="getPrecision(row)"
                      v-model="row[row.secondaryKey + '_2']"
                      :disabled="row.disabled"
                    />
                  </div>
                </template>

                <template v-if="row.componentType == 'textRange'">
                  <div class="parameter-search__secondary-key-container col-9 col-sm-2">
                    <InputField
                      type="text"
                      placeholder="min."
                      v-model="row[row.secondaryKey + '_1']"
                      :disabled="row.disabled"
                    />
                  </div>
                  <div class="parameter-search__secondary-key-container col-9 col-sm-2">
                    <InputField
                      type="text"
                      placeholder="max."
                      v-model="row[row.secondaryKey + '_2']"
                      :disabled="row.disabled"
                    />
                  </div>
                </template>

                <template v-if="row.componentType == 'doubleCombo'">
                  <div class="parameter-search__secondary-key-container col-9 col-sm-2">
                    <ComboBox
                      v-model="row[row.secondaryKey + '_1']"
                      @change="changeCombobox(row, row.secondaryKey + '_1', $event)"
                      :values="filteredComboOptions(row.comboOptions1)"
                      :firstEmpty="false"
                      :disabled="row.disabled"
                    />
                  </div>
                  <div class="parameter-search__secondary-key-container col-9 col-sm-2">
                    <ComboBox
                      v-model="row[row.secondaryKey + '_2']"
                      @change="changeCombobox(row, row.secondaryKey + '_2', $event)"
                      :values="filteredComboOptions(row.comboOptions2)"
                      :firstEmpty="false"
                      :disabled="row.disabled"
                    />
                  </div>
                </template>

                <div class="parameter-search__buttons-container col-3 col-sm-2 d-flex align-items-center justify-content-end">
                  <div class="parameter-controls__container">
                    <button v-if="row.isDeniable" type="button" class="btn btn-clear clickable" @click="row.filterNot = !row.filterNot; focusOnRow(getFilterRows.length - 1);">
                      <ph-prohibit :size="16" weight="bold" v-if="row.filterNot" alt="Filter negieren"/>
                      <ph-check-circle :size="16" weight="bold" v-else alt="Filter bestätigen"/>
                    </button>
                    <template v-if="!row.disabled">
                      <button :id="filterId + 'base__filter__minus'+index" v-if="!row.isRequiredFilterGroup && (index > 0 || hasSmartSearch && index === 0)" type="button" class="btn btn-clear clickable" @click="removeRow(index)" alt="Minus">
                        <ph-minus :size="16" weight="bold" alt="Minus"/>
                      </button>
                    </template>
                    <button :id="filterId + 'base__filter__plus'+index" v-if="!isSingleFilter" type="button" class="btn btn-clear clickable" @click="addRow(); focusOnRow(getFilterRows.length - 1);" alt="Plus">
                      <ph-plus :size="16" weight="bold" alt="Plus" />
                    </button>
                  </div>
                </div>
              </template>
              <Pill v-else type="black" @click="setCollapsed(false); focusOnRow(index)">
                <template v-if="!configFilter.hideFirstColumn">
                  <span>{{ mapListToKeyValue(row.primarySelection)[row.key] }} </span>
                  <span>| </span>
                </template>
                <span>{{ mapListToKeyValue(getSecondarySelection(row.key, index))[row.secondaryKey] }}</span>
                <template v-if="row.secondaryKey && row.componentType !== 'default'">
                  <span>: </span>
                  <span>{{ rowValue(row) }}</span>
                </template>
              </Pill>
            </div>
          </div>
        </div>

        <BaseButtonsContainer class="action-button__container">
          <template v-if="extraButton && extraButton.buttonText">
            <BaseButton @click="$emit('extraButtonClick', getFilterConfig())" :isPrimary="extraButton.isExtraButtonPrimary" :isSecondary="!extraButton.isExtraButtonPrimary">
              {{extraButton.buttonText}}
            </BaseButton>
          </template>

          <slot />

          <BaseButton @click="filterZurucksetzen()" isSecondary>
            Filter zurücksetzen
          </BaseButton> 
          <BaseButton @click="emitFilterEvent()" v-if="showSearchButton">
            {{titleSearchButton}}
          </BaseButton>

          <BaseDropdownMenu placement="bottom-right" style="text-align: right;" v-if="additionalActionButtons && additionalActionButtons.length">
            <template v-slot:hook-target>
              <a class="clickable">Aktionen <ph-caret-down :size="16" /></a>
            </template>
            <div class="action-texts">
              <template v-for="b in additionalActionButtons">
                <p v-bind:key="b.key" v-if="b.type=='ACTION'"><a class="btn btn-clean clickable"  target='_blank' 
                @click="additionalActionButtonClick(b)">{{b.label}}</a></p>                
              
                <p v-bind:key="b.key" v-if="b.type=='LINK'">
                  <DownloadLink
                    :title="b.label"
                    :href="getDownloadButtonUrl(b)"
                  />
                </p>
              </template>
            </div>
          </BaseDropdownMenu>

        </BaseButtonsContainer>
      </div>
    </div>

    <BaseModal
      v-if="$isSmallScreen"
      ref="filterModal"
      :noPrimaryAction="!showSearchButton"
      :modalTitle="title"
      :showDefaultButtons="false"
      :actions="baseFilterSelectActions"
      @action-FILTER_SEARCH="emitFilterEvent"
      @action-FILTER_LIST="filterBearbeiten"
      @action-FILTER_RESET="filterZurucksetzen"
      @action-FILTER_SAVE="emitFilterSpeichernEvent"
    >
      <BaseFilterSelect
        v-if="showSaveButton && hasSearchParameters"
        ref="baseFilterSelect"
        class="filter-row__border"
        :showSaveButton="showSaveButton"
        :storedSearchParameter="storedSearchParameter"
        :programmaticSearchParameter="configFilter && configFilter.programmaticSearchParameter"
        @onEditFilter="filterBearbeiten()"
        @onSelect="selectStoredSearchParameter($event, true)"
      />
      
      <div class="row mt-0">
        <div class="col-12 col-md-12 col-sm-12">

          <div class="parameter-search__input-fields__container">
            <div v-if="!collapsed && hasSmartSearch" class="row parameter-search__input-fields filter-row__border"
              v-on:submit.prevent="noop" v-on:keyup.enter="emitFilterEvent()">
              <div class="col-9 col-md-9 col-sm-9">
                <InputField ref="defaultSearchInput" 
                  :placeholder="configFilter.placeholderForDefSearchInput" 
                  v-model="defaultSearchInput"  />
                  <div class="dropList" v-if="defaultSearchInput && filteredOptions && filteredOptions.length">
                    <ul>
                      <li v-for="(item, index) in filteredOptions" :key="index" @click="selectFilterDropListItem(item)">
                        {{ item.label }}
                      </li>
                    </ul>
                  </div>
              </div>  
              <div v-if="!isSingleFilter" class="parameter-search__buttons-container col-3 col-md-3 col-sm-3 d-flex align-items-center justify-content-end">
                <div class="parameter-controls__container">
                  <button type="button" class="btn btn-clear clickable" @click="enableMoreOptions()">
                    <ph-plus :size="16" weight="bold" />
                  </button>
                </div>
              </div>               
            </div>

            <div v-if="showMoreOptions || !hasSmartSearch || Object.keys(defaultOptions).length" ref="moreOptionsEl"
              v-on:submit.prevent="noop" v-on:keyup.enter="emitFilterEvent()" class="parameter-search__input-fields--more-options"
            >
              <div class="row parameter-search__input-fields filter-row__border" v-for="(row, index) in getFilterRows" :key="`${row.secondaryKey}-${index}`">
                <template>
                  <div class="row parameter-search__input-fields col-9" style="flex-wrap: wrap; padding: 0">
                    <div
                    v-if="showAlwaysPrimaryselection || row.primarySelection && row.primarySelection.length>1"
                    class="col-12 col-sm-3" :class="{ 'd-none': configFilter.hideFirstColumn, }">
                      <ComboBox
                        v-model="row.key"
                        :values="row.primarySelection"
                        @change="onChangePrimary(row, $event)"
                        :disabled="row.disabled || row.isRequiredFilterGroup"
                      />
                    </div>

                    <div class="col-12" :class="{ 
                      'col-sm-3': !configFilter.hideFirstColumn, 
                      'col-sm-6': configFilter.hideFirstColumn, 
                      'parameter-search__component-default': !row.componentType || row.componentType === 'default',
                    }">
                      
                      <ComboBox
                        v-model="row.secondaryKey"
                        :values="getSecondarySelection(row.key, index)"
                        @change="onChange(row, $event)"
                        :disabled="row.disabled"
                      />

                    </div>
                
                    <template v-if="row.componentType && 
                      row.componentType !== 'dateRange' &&
                      row.componentType !== 'numberRange' && 
                      row.componentType !== 'textRange' && 
                      row.componentType !== 'default' && 
                      row.componentType !== 'doubleCombo'">

                      <div :key="row.__UPDATED_KEY__" class="parameter-search__secondary-key-container col-12 col-sm-6">
                        <InputField
                          v-if="row.componentType == 'text'"
                          v-model="row[row.secondaryKey]"
                          :disabled="row.disabled"
                          :inputmode="getComponentInputmode(row.key, row.secondaryKey)"
                        />
                        <InputField
                          v-if="row.componentType == 'integer'"
                          :value="row[row.secondaryKey]"
                          type="number"
                          isInteger
                          :disabled="row.disabled"
                          @change="setValueChanged(row, row.secondaryKey, $event)"
                        />
                        <InputField
                          v-if="row.componentType == 'tel'"
                          :value="row[row.secondaryKey]"
                          type="tel"
                          :disabled="row.disabled"
                          @change="setValueChanged(row, row.secondaryKey, $event)"
                        />
                        <DatePickerField
                          v-if="row.componentType == 'datepicker'"
                          v-model="row[row.secondaryKey]"
                          :disabled="row.disabled"
                        />
                        <InputToggleSwitch
                          v-if="row.componentType == 'switcher'"
                          class="parameter-search__input-toggle-switch"
                          v-model="row[row.secondaryKey]"
                          :disabled="row.disabled"
                        />
                        <InputMultipleItemSelector 
                          v-if="row.componentType === 'multipleItemSelector'"
                          :value="row[row.secondaryKey]"
                          :values="row.comboOptions"
                          :disabled="row.disabled"
                          @input="updateMultipleSelection(row, $event)"
                        />
                        <InputChips 
                          v-if="row.componentType === 'chips'"
                          class="p-0"
                          v-model="row[row.secondaryKey]"
                          :showToggle="true" 
                          :uniqueSelection="row.uniqueSelection"
                          :availableOptions="row.comboOptions"
                          @input="updateMultipleSelection(row, $event)"
                        />
                        <div class="row mt-0 mb-0" v-if="row.secondaryItem && row.secondaryItem.parentValue.includes(row[row.secondaryKey])">
                          <div class="col-6 col-sm-6">
                            <ComboBox
                              v-if="row.componentType == 'combobox'"
                              v-model="row[row.secondaryKey]"
                              @change="changeCombobox(row, row.secondaryKey, $event)"
                              :values="filteredComboOptions(row.comboOptions)"
                              :firstEmpty="false"
                              :disabled="row.disabled"
                            />
                          </div>
                          <div class="col-6 col-sm-6">
                            <ComboBox
                              v-if="row.secondaryItem.type == 'combobox'"
                              v-model="row[row.secondaryItem.key]"
                              :values="filteredComboOptions(row.secondaryItem.comboOptions)"
                              :firstEmpty="false"
                              :disabled="row.disabled"
                            />
                            <InputField
                              v-if="row.secondaryItem.type == 'text'"
                              v-model="row[row.secondaryItem.key]"
                              :disabled="row.disabled"
                            />
                          </div>
                        </div>
                        <div v-else-if="row.componentType == 'combobox'">
                          <ComboBox
                            v-if="row.componentType == 'combobox'"
                            v-model="row[row.secondaryKey]"
                            @change="changeCombobox(row, row.secondaryKey, $event)"
                            :values="filteredComboOptions(getComboboxOptions(row.key, row.secondaryKey))"
                            :firstEmpty="false"
                            :disabled="row.disabled"
                          />
                        </div>
                        <SplitedDatePicker
                          v-if="row.componentType == 'splitedDatePicker'"
                          v-model="row[row.secondaryKey]"
                          :disabled="row.disabled"
                        />
                      </div>
                    </template>

                    <template v-if="row.componentType == 'dateRange'">
                      <div class="parameter-search__secondary-key-container col-12 col-sm-6">
                        <DatePickerField
                          isRangePicker
                          :disabled="row.disabled"
                          :value="getDateRangeValue(row)"
                          @onSelectRangeFromAsText="row[row.secondaryKey + '_1'] = $event"
                          @onSelectRangeToAsText="row[row.secondaryKey + '_2'] = $event"
                          @onSelectPreset="row['presetMeta'] = $event"
                        />
                      </div>
                    </template>

                    <template v-if="row.componentType == 'numberRange'">
                      <div class="parameter-search__secondary-key-container col-12 col-sm-3">
                        <InputField
                          type="number"
                          placeholder="min."
                          :precision="getPrecision(row)"
                          v-model="row[row.secondaryKey + '_1']"
                          :disabled="row.disabled"
                        />
                      </div>
                      <div class="parameter-search__secondary-key-container col-12 col-sm-3">
                        <InputField
                          type="number"
                          placeholder="max."
                          :precision="getPrecision(row)"
                          v-model="row[row.secondaryKey + '_2']"
                          :disabled="row.disabled"
                        />
                      </div>
                    </template>

                    <template v-if="row.componentType == 'textRange'">
                      <div class="parameter-search__secondary-key-container col-12 col-sm-3">
                        <InputField
                          type="text"
                          placeholder="min."
                          v-model="row[row.secondaryKey + '_1']"
                          :disabled="row.disabled"
                        />
                      </div>
                      <div class="parameter-search__secondary-key-container col-12 col-sm-3">
                        <InputField
                          type="text"
                          placeholder="max."
                          v-model="row[row.secondaryKey + '_2']"
                          :disabled="row.disabled"
                        />
                      </div>
                    </template>

                    <template v-if="row.componentType == 'doubleCombo'">
                      <div class="parameter-search__secondary-key-container col-12 col-sm-3">
                        <ComboBox
                          v-model="row[row.secondaryKey + '_1']"
                          @change="changeCombobox(row, row.secondaryKey + '_1', $event)"
                          :values="filteredComboOptions(row.comboOptions1)"
                          :firstEmpty="false"
                          :disabled="row.disabled"
                        />
                      </div>
                      <div class="parameter-search__secondary-key-container col-12 col-sm-3">
                        <ComboBox
                          v-model="row[row.secondaryKey + '_2']"
                          @change="changeCombobox(row, row.secondaryKey + '_2', $event)"
                          :values="filteredComboOptions(row.comboOptions2)"
                          :firstEmpty="false"
                          :disabled="row.disabled"
                        />
                      </div>
                    </template>
                  </div>

                  <div class="parameter-search__buttons-container col-3 col-sm-3 d-flex align-items-center justify-content-end" style="align-self: baseline; margin-top: 4px">
                    <div class="parameter-controls__container">
                      <button v-if="row.isDeniable" type="button" class="btn btn-clear clickable" @click="row.filterNot = !row.filterNot; focusOnRow(getFilterRows.length - 1);">
                        <ph-prohibit :size="16" weight="bold" v-if="row.filterNot"/>
                        <ph-check-circle :size="16" weight="bold" v-else />
                      </button>
                      <template v-if="!row.disabled">
                        <button v-if="!row.isRequiredFilterGroup && (index > 0 || hasSmartSearch && index === 0)" type="button" class="btn btn-clear clickable" @click="removeRow(index)">
                          <ph-minus :size="16" weight="bold" />
                        </button>
                      </template>
                      <button v-if="!isSingleFilter" type="button" class="btn btn-clear clickable" @click="addRow(); focusOnRow(getFilterRows.length - 1);">
                        <ph-plus :size="16" weight="bold" />
                      </button>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseModal>

    <BaseFilterSave
      ref="filterSaveModal"
      :storedSearchParameter="storedSearchParameter"
      :filterSaveKey="filterId"
    />

    <BaseModal ref="filterSpeichernModal" modalTitle="Filter speichern" size="sm" @onConfirmButton="saveSearchEdit">
      <template #default>
        <InputField label="Filter Name" v-model="newFilterName" type="text"></InputField>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import dayjs from 'dayjs';

import ComboBox from "@/components/core/forms/ComboBox.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import InputField from "@/components/core/forms/InputField.vue";
import InputToggleSwitch from "@/components/core/forms/InputToggleSwitch.vue";
import { PhMinus, PhPlus, PhCaretUp, PhCaretDown, PhCheckCircle, PhProhibit } from "phosphor-vue";
import BaseButton from "@/components/core/BaseButton.vue";
import BaseFilterSelect from "@/components/core/BaseFilterSelect.vue";
import BaseFilterSave from "@/components/core/BaseFilterSave.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import InputMultipleItemSelector from '@/components/core/forms/InputMultipleItemSelector.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import SEARCH_CONFIGS_TYPES from '@/store/searchConfigs/types'
import FC_CONFIG_TYPES from '@/store/fcConfig/types'
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex'
import { DatePickerUtils } from '@/components/core/forms/DatePicker/date-picker-utils';
import BaseButtonsContainer from '@/components/core/BaseButtonsContainer.vue';
import Pill from '@/components/core/Pill.vue';
import { mapListToKeyValue } from '@/helpers/mapping.js';
import { isDate } from '@/helpers/commonfunctions.js';
import { makeQueryParam } from '@/helpers/utils-helper';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import DownloadLink from '@/components/core/download/DownloadLink.vue';
import InputChips from '@/components/core/input-chips/InputChips.vue';
import SplitedDatePicker from '@/components/core/forms/splitedDatePicker/SplitedDatePicker.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import CORE_TYPES from '@/store/core/types';
import { isObject, } from '@/helpers/commonfunctions.js';
import MobileInfoRow from "@/components/core/MobileInfoRow.vue";
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

const onlyNumbersUpToSevenChars = /^\d{3,7}$/
const onlyNumbersUpToAnyCharsAmount = /^\d{3,}$/;
const namePatern   = /([A-Za-zäÄöÖüÜß]){1,}/

// if there is numbers and letters mixed up, it's probably an insurance number and each company have it's own pattern
const insuranceNumber = /\b((?:\d+[a-z]|[a-z]+\d|[a-z]+[\-\/\.]|[\d\-\/\.]+)[a-z\d]*)/i

export const ADDITIONAL_BUTTON_TYPE_ACTION = "ACTION";
export const ADDITIONAL_BUTTON_TYPE_LINK = "LINK";

export default {
  mixins: [
    InteractiveHelpCommonsMixin,
  ],  
  components: {
    ComboBox,
    DatePickerField,
    InputMultipleItemSelector,
    InputField,
    InputToggleSwitch,
    PhMinus,
    PhPlus,
    BaseButton,
    BaseFilterSave,
    BaseFilterSelect,
    BaseModal,
    BaseButtonsContainer,
    PhCaretUp,
    Pill,
    BaseDropdownMenu,
    PhCaretDown,
    DownloadLink,
    InputChips,
    PhCheckCircle,
    PhProhibit,
    SplitedDatePicker,
    BaseContextMenu,
    ContextMenuItem,
    MobileInfoRow,
  },
  props: {
    metadata: {
      type: [String, Array],
      default: function () {
        return [];
      },
      
    },
    title: {
      default: "Filter",
    },
    showSaveButton: {
      type: Boolean,
      default: false
    },
    defaultOptions: {
      type: Object,
      default: () => ({})
    },
    predefinedFilter: {
      type: Object,
      default: () => ({})
    },
    hasSmartSearch: {
      type: Boolean,
      default: false
    },
    configFilter: {
      type: Object,
      default: () => ({
        placeholderForDefSearchInput: 'Nachname, Nachname und Vorname, Kundennummer',
        checkDefaultSearchInput: true,
        noResetOnDefaultSearchInputExit: false,
        cbDefSearchInput: null,
        hideFirstColumn: false,
        filterZurucksetzen: null, // () => {},
        singleFilter: false,
        kundenNummerKey: 'customerId',
        lastNameKey: 'lastName',
        programmaticSearchParameter: [],
        defaultFiltersNum: 1,
      })
    },
    filterLabel: {
      type: String,
      default: "Filter"
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
    extraButton: {
      type: Object,
      default: () => (null)
    },
    isCustomerSearch: {
      type: Boolean,
      default: true,
    },
    showAlwaysPrimaryselection: {
      type: Boolean,
      default: true,
    },
    filterId: {
      type: String,
    },
    searchFromParam: {
      type: String,
    },
    immidiateSearch: {
      type: Boolean,
      default: false,
    },
    replacePreviousSelectedValues: {
      type: Boolean,
      default: false,
    },
    showSearchButton: {
      type: Boolean,
      default: true,
    },
    titleSearchButton: {
      type: String,
      default: 'Suchen',
    },

    // Array of objects containing the following structure:
    // [
    //   {
    //     label: 'Name this button',
    //     key: null,
    //     type: (Default: ADDITIONAL_BUTTON_TYPE_ACTION or ADDITIONAL_BUTTON_TYPE_LINK)
    //     linkMetadata: {} // see the getDownloadButtonUrl method
    //   }
    // ]
    additionalActionButtons: {
      type: Array,
      default: () => ([])
    },
    isCache: {
      type: Boolean,
      default: false,
    },
    openFilterOnMount: {
      type: Boolean,
      default: true,
    },
    ariaDescription: {
      type: String,
      default: 'Icon zum Hinzufügen eines weiteren Filters'
    },
  },
  data: function () {
    return {
      filterRows: [],
      searchForm: {
        parameters: [], //[this.createItem()]
      },
      saveSearchForm: {
        searchLabel: '',
        searchKey: 'customerSearchAdvanced', // this.storedSearchDefaultKey,
        parameters: '',
      }, 
      savedSearch: null,     
      changedRows: [],
      defaultSearchInput: '',
      showMoreOptions: false,
      newFilterName: '',
      hasDefaultValue: false,
      defaultFilterLoaded: false,
      collapsedView: false,
      defaultOptionsMenu: [],
      isTabCtrl: false,
    };
  },
  mounted: async function () {
    if (!this.defaultFilterLoaded) {
      this.recreateRows();
    }

    if (this.immidiateSearch && this.predefinedFilter && (!!Object.keys(this.predefinedFilter).length) && !this.defaultFilterLoaded && !this.$route.query?.backAction && !this.isCache) {
      this.$nextTick(() => {
        this.emitFilterEvent();
      });
    }

    if(this.filterId && (!this.configFilter?.ignoreStoredFilter || this.showSaveButton)) {
      await this.loadFiltersFromStoredSearchParameters();
    }

    if (this.immidiateSearch && !this.loadCacheFilterOptions()) {
      this.$nextTick(() => {
        this.emitFilterEvent();
      });
    }

    if (this.$isSmallScreen && !this.immidiateSearch && this.openFilterOnMount && !this.$route.query?.backAction) {
      this.$refs.filterModal?.open()
    }
  },
  computed: {
    ...mapGetters({
      filterOptions: SEARCH_CONFIGS_TYPES.GETTERS.FILTER_OPTIONS,
      loadedFilters: SEARCH_CONFIGS_TYPES.GETTERS.LOADED_FILTERS,
      isConfigurationReadOnly: FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY,
      // filteredItems: function(){
      //   const menu = this.metadata.find((md) => md.key == key);
      //   const selectedItems = this.getFilterRows.map(row=>row.secondaryKey);
      //  return menu.menuItems.filter(item => !selectedItems.some(v => v[v.secondaryKey] == item.key))

      // }
    }),
    isEditAvailable() {
      return this.filterId && this.showSaveButton && !this.isConfigurationReadOnly;
    },
    primarySelection() {
      return (
        (this.metadata &&
          this.metadata.length &&
          this.metadata.map((md) => ({
            value: md && md.key,
            label: md && md.label,
            type: md && md.type,
          }))) ||
        []
      );
    },
    parameters() {
      return this.searchForm.parameters;
    },
    hasDefaultSearchInputValue() {
      return this.defaultSearchInput && this.defaultSearchInput.trim();
    },
    storedSearchParameter() {
      if (!this.filterId)
        return {};
      return this.loadedFilters[this.filterId] || {};
    },
    hasStoredSearchParamterDefault() {
      return this.loadedFilters?.[this.filterId]?.searchParameters?.some(param => param.isDefault);
    },
    storedSearchParameterDefault() {
      return this.loadedFilters?.[this.filterId]?.searchParameters?.find(param => param.isDefault)?.parameters;
    },
    getFilterRows() {
      return this.filterRows || []
    },
    isSingleFilter() {
      return this.configFilter.singleFilter === true;
    },
    defaultSearchInputKeysGrouped(){
      
      if(!this.configFilter?.defaultSearchInputKeys?.length){
        return null
      }

      const defaultSearchInputFilterMetadata = this.metadata.flatMap(group => group.menuItems)
            .filter(menuItem => {
              if(menuItem && menuItem.key && menuItem.key.includes('versSparten')){
                return this.configFilter.defaultSearchInputKeys.includes('versSparten')
              } else {
                return this.configFilter.defaultSearchInputKeys.includes(menuItem.key)
              }
            });
      return defaultSearchInputFilterMetadata.groupBy(menu => menu.type);
    },
    filteredOptions() {
      const menusByType = this.defaultSearchInputKeysGrouped;

      const mappedComboOptions = [];
      menusByType?.combobox?.forEach(menuGroup => {
         menuGroup.comboOptions.forEach(comboOption => mappedComboOptions.push({...comboOption, key: menuGroup.key }))
       });
      return mappedComboOptions.filter(option => option.label.toLowerCase().includes(this.defaultSearchInput.toLowerCase()));
    },
    /**
     * This fixed default filter options will be always added if it does not exists in the selected saved filters
     */
    fixedDefaultOptions() {
      const { defaultOptions } = this;
      return Object.keys(defaultOptions).reduce((acc, key) => {
        if(!defaultOptions?.[key]?.fixed) return acc;
        return {
          ...acc,
          [key]: defaultOptions[key],
        };
      }, {});
    },
    baseFilterSelectActions() {
      if (!this.isEditAvailable) {
        return [
          BaseModalSimpleAction('FILTER_SEARCH', 'Suchen').withVisible(() => this.showSearchButton),
          BaseModalSimpleAction('FILTER_RESET', 'Filter zurücksetzen'),
        ]
      }

      return [
        BaseModalSimpleAction('FILTER_SEARCH', 'Suchen').withVisible(() => this.showSearchButton),
        BaseModalSimpleAction('FILTER_LIST', 'Filterliste'),
        BaseModalSimpleAction('FILTER_RESET', 'Filter zurücksetzen'),
        BaseModalSimpleAction('FILTER_SAVE', 'Filter speichern'),
      ]
    },
    hasSearchParameters() {
      return !!this.storedSearchParameter?.searchParameters?.length || (this.configFilter && !!this.configFilter.programmaticSearchParameter?.length)
    },
    collapsed() {
      return (this.hasSmartSearch && this.getFilterRows.length || this.getFilterRows.length > 1) && this.collapsedView
    },
  },
  watch: {
    predefinedFilter(val) {
      if (val) {
        this.selectStoredSearchParameter(val);
        // setDefaultFilters is used to fill the predefined filters with its respective default values
        this.setDefaultFilters(val)
      }
    },
    defaultOptions(val) {
      if (val && Object.keys(val).length) {
        this.recreateRows();
      }
    },
    immidiateSearch(val) {
      if (val) {
        this.$nextTick(() => {
          this.emitFilterEvent();
        });
      }
    },
    filterId() {
      this.loadFiltersFromStoredSearchParameters();
    },
    searchFromParam(newValue) {
      this.defaultSearchInput = newValue;
    },
    filterRows: {
      handler() {
        this.$refs?.baseFilterSelect?.clearSelectedStoredSearchParameter();
      },
      deep: true,
    },
    metadata: {
      handler(newVal) {
        if (JSON.stringify(this.metadata) !== JSON.stringify(newVal)) {
          this.recreateRows();
        }
      },
      deep: true,
    },
    storedSearchParameter: {
      handler() { 
        this.loadDefaultFilter();
      }
    },
  },
  methods: {
    // loads the filteroptions on the top which the customer can create/edit like 'Favoritenliste'
    loadDefaultFilter() {
      if (this.loadedFilters[this.filterId] && this.loadedFilters[this.filterId].searchParameters?.length) {
        this.loadedFilters[this.filterId].searchParameters.forEach(x => {
          if (x.isDefault && !this.defaultFilterLoaded) {
            this.selectStoredSearchParameter(x)
            this.defaultFilterLoaded = true;
            this.$nextTick(() => {
              this.$refs?.baseFilterSelect?.clearSelectedStoredSearchParameter();
              this.$refs?.baseFilterSelect?.setSelectedStoredSearchParameter(x);
            });
          }
        })
      }
    },
    getPrecision(row) {
      if (row.comboOptions.length) {
        const precision = row.comboOptions.find( o => o.label === 'precision')
        if (precision) {
          return precision.value;
        }
      }
      return 2;
    },
    setFixedDefaultOptions() {
      const { fixedDefaultOptions } = this;
      if(!Object.keys(fixedDefaultOptions).length) return;

      const fixedOptionsNotAdded = Object.keys(fixedDefaultOptions)
        .filter(key => !this.filterRows.some(fr => fr.secondaryKey == key))
        .reduce((acc, key) => ({ ...acc, [key]: fixedDefaultOptions[key], }), {});

      this.mapDefaultMenuItems(fixedOptionsNotAdded)?.forEach(item => this.addRow(item));
    },
    mapDefaultMenuItems(defaultOptions) {
      return Object.keys(defaultOptions).flatMap(key => {
        const menuGroupKey = this.metadata?.find(menuGroup => menuGroup?.menuItems?.some(v => v.key === key));
        if (menuGroupKey) {
            let values = defaultOptions[key].valueList || [(isObject(defaultOptions[key]) && 'value' in defaultOptions[key] ? defaultOptions[key].value : defaultOptions[key])];
            return values.map(value => {
                return {
                    key: menuGroupKey.key,
                    primarySelection: this.primarySelection,
                    type: menuGroupKey.type,
                    disabled: defaultOptions[key].disabled,
                    secondaryKey: key,
                    [key]: value,
                    value,
                    isMounted:true,
                    isRequiredFilterGroup: menuGroupKey?.isRequiredFilterGroup || false,
                    isDeniable: !!defaultOptions[key].isDeniable,
                    filterNot: !!defaultOptions[key].filterNot,
                };
            });
        }
        return null;
      }).filter(result => !!result);
    },
    filteredComboOptions(options) {
      return options.map(mi => ({
        ...mi,
        disabled: mi.disabled || (this.filterRows.some(v => v[v.secondaryKey] && v[v.secondaryKey] === mi.value) && !mi.allowDuplicated)
      }))
    },
    onChange(row, value){
      // value does not need to be updated because there's a v-model on it
      this.afterChangeSubFilterItem(row, this.hasStoredSearchParamterDefault)
      if (!row.isDeniable) {
        row.filterNot = false;
      }
    },

    onChangePrimary(row, value){
      if(value && value != 'fremdGesellschaft' &&  value != 'vertragGesellschaft' &&  value !='kategorie' &&  value !='potential'){
        if(this.getFilterRows.some(v=>v.primarySelection.some(v=>v.value ==value))){
              const menu = this.metadata.find((md) => md.key == value);
              const selectedItems = this.getFilterRows.map(r=>r.secondaryKey);
              const filtered = menu.menuItems.filter(item=> !selectedItems.some(v=> v == item.key && !menu.allowMultipleSearch));
              const firstUnusedItem = filtered[0];
              const secondaryKey = firstUnusedItem?.key

              row.secondaryKey = secondaryKey;
              this.afterChangeFilterItem(row)
        }
      } else {
        this.afterChangeFilterItem(row)
      }
    },
 
    getSecondarySelection(key, index, isMounted) {
      const menu = this.metadata.find((md) => md.key == key);

      const mappedSelectedItems = menu?.menuItems?.map(
        mi => (
          {
            value: mi && mi.key,
            label: mi && mi.label,
            disabled: (
              mi.disabled
              || (this.filterRows.find(v=> v.secondaryKey == mi.key) && !(mi.allowDuplicated || menu.allowMultipleSearch))
              && (!(mi.allowDuplicated || menu.allowMultipleSearch)
                  || !((mi.maxOccurences && this.filterRows.filter(v=> v.secondaryKey == mi.key).length < mi.maxOccurences)))
              && mi.key != 'kategorie' 
              && mi.key != 'potential' 
              && mi.key != 'fremdGesellschaft' 
              && mi.key != 'vertragGesellschaft'),

            isDeniable: mi?.deniable || false,
            allowDuplicated: !!(mi.allowDuplicated || menu.allowMultipleSearch),
            supportCustomValue: !!(mi.supportCustomValue || menu.supportCustomValue),
            uniqueSelection: !!(mi.uniqueSelection || menu.uniqueSelection),
            maxOccurences: mi?.maxOccurences
          }
        )
      ) || [];

      return mappedSelectedItems;

    },
    getComponentType(key, subKey) {
      const menu = this.metadata.find((md) => md.key == key);
      const subItem = menu?.menuItems?.find((mi) => mi.key == subKey);
      return subItem?.type;
    },
    /**
     * It is to improve keyboard appearance on mobile screen
     */
    getComponentInputmode(key, subKey) {
      const menu = this.metadata.find((md) => md.key == key);
      const subItem = menu?.menuItems?.find((mi) => mi.key == subKey);
      return subItem.type !== 'text' ? null : subItem?.inputmode;
    },
    getComboboxOptions(key, subKey, position='') {
      const menu = this.metadata.find((md) => md.key == key);
      const subItem = menu?.menuItems?.find((mi) => mi.key == subKey);
      return (
        (subItem?.['comboOptions' + position]?.map((mi) => ({
            value: mi?.value,
            label: mi?.label,
            isDefault: mi?.isDefault,
            allowDuplicated: mi?.allowDuplicated,
            supportCustomValue: mi?.supportCustomValue,
            uniqueSelection: mi?.uniqueSelection,
          }))) ||
        []
      );
    },
    getSecondaryItem(key, subKey) {
      const menu = this.metadata.find((md) => md.key == key);
      const subItem = menu?.menuItems?.find((mi) => mi.key == subKey);
      return subItem?.secondaryItem;
    },
    removeRow(index) {
      const removedRow = this.filterRows[index];
      this.filterRows.splice(index, 1);
      this.$emit("changeSubFilter", removedRow, this.filterRows);
    },
    removeRowsByKey(primaryKey, secondaryKey) {

      const indexToRemove = [...this.filterRows].findIndex(row => row.key === primaryKey && row.secondaryKey === secondaryKey);
      if(indexToRemove > -1){
        this.removeRow(indexToRemove);
        return;
      }
    },
    /**
     * defaultValue row object to be added. Could be a new filter row or prefefined filter row object
     * replaceDefaultValue used exclusively when there are predefined saved filters and the values saved must to override filter default value when it exists 
     */
    addRow(defaultValue = {}, replaceDefaultValue = false) {
    
      const primaryItem = this.primarySelection[0];
      /* secondaryItems contains only selectable items, with item.disabled = false */
      const secondaryItems = this.getSecondarySelection(primaryItem.value, this.filterRows.length)?.filter(item => !item.disabled) || [];
      const componentType = this.getComponentType(
        defaultValue.key || primaryItem.value,
        defaultValue.secondaryKey || secondaryItems[0] && secondaryItems[0].value
      );
   
      
      let secondaryKey = '';
      if(defaultValue.key){
          secondaryKey = defaultValue.secondaryKey;
          this.hasDefaultValue = true;
      }else{ 
          if(!this.hasDefaultValue){
            secondaryKey = defaultValue.secondaryKey || secondaryItems[0]?.value;
          }else{
            secondaryKey = defaultValue.secondaryKey || secondaryItems[this.filterRows.length]?.value;
            if( this.filterRows.find(v=>v.secondaryKey == secondaryKey)){
              const menu = this.metadata.find((md) => md.key == primaryItem.value);
              const selectedItems = this.getFilterRows.map(r=>r.secondaryKey);
              const filtered=  menu.menuItems.filter(item=> !selectedItems.some(v=> v == item.key ));
              const firstUnusedItem = filtered[0]?.key;
              secondaryKey = firstUnusedItem;
            }
          }
      }
      let value1 = (componentType == "dateRange" || componentType == "numberRange" || componentType == "textRange" || componentType == "doubleCombo") && defaultValue.value && defaultValue.value.length > 0 && defaultValue.value[0].value;
      value1 = value1 === undefined ? null : value1;
      let value2 = (componentType == "dateRange" || componentType == "numberRange" || componentType == "textRange" || componentType == "doubleCombo") && defaultValue.value && defaultValue.value.length > 1 && defaultValue.value[1].value;
      value2 = value2 === undefined ? null : value2;

      let presetMeta = null;
      if(componentType == "dateRange" && !!defaultValue.preset){
        presetMeta = defaultValue.presetMeta
      }
      this.filterRows.push({
        key: defaultValue.key || primaryItem.value,
        primarySelection: defaultValue.primarySelection || this.primarySelection,
        secondaryKey,
        componentType: componentType,
        type: defaultValue.type || primaryItem.type,
        disabled: defaultValue.disabled || false,
        [secondaryKey]: (defaultValue.value === undefined ? null : defaultValue.value),
        [secondaryKey + "_1"]: value1,
        [secondaryKey + "_2"]: value2,
        isRequiredFilterGroup : defaultValue?.isRequiredFilterGroup || primaryItem?.isRequiredFilterGroup || false,
        isDeniable: !!defaultValue.isDeniable,
        filterNot: !!defaultValue.filterNot,
        secondaryItem: defaultValue.secondaryItem,
        presetMeta: presetMeta,
      });
      this.afterChangeFilterItem(this.filterRows[this.filterRows.length - 1], replaceDefaultValue) 
    },
    afterChangeFilterItem(row, replaceDefaultValue = false) {
      row.comboOptions = [];
      this.afterChangeSubFilterItem(row, replaceDefaultValue);
    },
    afterChangeSubFilterItem(row, replaceDefaultValue = false) {
      row.componentType = this.getComponentType(row.key, row.secondaryKey);
      row.comboOptions = this.getComboboxOptions(row.key, row.secondaryKey);
      row.comboOptions1 = this.getComboboxOptions(row.key, row.secondaryKey, 1);
      row.comboOptions2 = this.getComboboxOptions(row.key, row.secondaryKey, 2);
      row.secondaryItem = this.getSecondaryItem(row.key, row.secondaryKey);
      const secondaryItems = this.getSecondarySelection(row.key, this.filterRows.length);
      const secondaryItem = secondaryItems.find(item => item.value===row.secondaryKey);
      row.isDeniable = !!secondaryItem?.isDeniable;
      row.allowDuplicated = !!secondaryItem?.allowDuplicated;
      row.supportCustomValue = !!secondaryItem?.supportCustomValue;
      row.uniqueSelection = !!secondaryItem?.uniqueSelection;
      row.maxOccurences = secondaryItem?.maxOccurences;
      if(!this.defaultSearchInput) {
        if (row.componentType === 'combobox') {
          this.selectTheDefaultOptionOfSecondaryComboBoxes(row)
        } else if (row.componentType === 'multipleItemSelector' || row.componentType === 'chips') {
          const selectedItems = this.filterRows.filter(v => v.secondaryKey == row.secondaryKey) || []

          if (selectedItems.length) {
            const selectedValues = selectedItems.map(v => v[row.secondaryKey])
            const filteredValues =  row.comboOptions.filter(item => !selectedValues.some(v => v == item.value));
            if (!row[row.secondaryKey]) {
              row[row.secondaryKey] = this.getFirstValue(row, filteredValues)
            }
          }
        } else {
          const menuItemWithPredefinedDefaultValue = this.defaultOptionsMenu.find(defaultMenuItem => row.secondaryKey === defaultMenuItem?.secondaryKey);
          if(menuItemWithPredefinedDefaultValue){
            row = menuItemWithPredefinedDefaultValue;
            const index = this.filterRows.findIndex(obj => obj.secondaryKey === row.secondaryKey);
            if(index > -1 && replaceDefaultValue) {
              this.filterRows[index] = {...this.filterRows[index], ...row };
            }
          }
        }
      }
      this.$emit("changeSubFilter", row, this.filterRows);
    },
    selectTheDefaultOptionOfSecondaryComboBoxes(row) {
      if (row.componentType !== 'combobox') {
        return
      }

      const defaultItem = row.comboOptions?.find(comboItem => comboItem.isDefault)
      if (defaultItem) {
        row[row.secondaryKey] = defaultItem.value
      } else {
        // if the combobox has no previosly selected value
        if (this.replacePreviousSelectedValues || (!row[row.secondaryKey] && !row[row.secondaryKey] !== "")) {

          const selectedItems = this.filterRows.filter(v => v.secondaryKey == row.secondaryKey) || []

          if (selectedItems.length) {
            const selectedValues = selectedItems.map(v => v[row.secondaryKey])
            const filteredValues =  row.comboOptions.filter(item => !selectedValues.some(v => v == item.value));
            row[row.secondaryKey] = this.getFirstValue(row, filteredValues)
          } else {
            row[row.secondaryKey] = row.comboOptions?.[0]?.value;
          }
        }
      }
    },
    emitFilterSpeichernEvent() {
      if (this.filterId) {
        this.newFilterName = '';
        this.$refs.filterSpeichernModal.open();
      }
    },
    additionalActionButtonClick(b) {
      const additionalButtonData = {
        button: b,
        filterConfig: this.getFilterConfig()
      }
      this.$emit("additionalActionButtonClick", additionalButtonData);
    },
    emitFilterEvent() {
      this.$refs.filterModal?.close()
      
      this.defaultSearchInputExitDebounce()

      let filterConfig = this.getFilterConfig();

      this.$emit("onFilter", filterConfig);
      /* could be undefined because nor all places configure this parameter.
      * Only if it was explicitally declared false must to skip saving cache filter.
      */
      if(this.configFilter.keepFiltersStateBeforeRoutingChange == false ){
        return;
      }
      this.saveCacheFilterOptions()
    },
    saveCacheFilterOptions() {
      if (this.filterId) {
        const saveCachePayload = {
          filterId: this.filterId,
          filterOptions : {
            filterRows: this.exportData(),
            defaultSearchInput: this.defaultSearchInput
          }
        }
        this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_OPTIONS, saveCachePayload)
      }
    },
    loadCacheFilterOptions() {
      if (!this.$route.query?.backAction && !this.isCache) {
        return
      }
      if (this.filterId) {
        const filterOptions = this.filterOptions?.(this.filterId)

        if (filterOptions) {
          this.selectStoredSearchParameter({parameters: filterOptions.filterRows})
          this.defaultSearchInput = filterOptions.defaultSearchInput

          if (this.hasSmartSearch && this.getFilterRows.length) {
            this.collapsedView = !!filterOptions.collapsed
          }
          this.emitFilterEvent()
          return true
        }
      }
    },
    filterBearbeiten() {
      this.$refs.filterSaveModal.open();
    },
    setDefaultFilters(defaultFilters = {}) {
      let storedParams = defaultFilters.parameters;

      if (storedParams && typeof storedParams === 'string') {
        storedParams = JSON.parse(storedParams)
      }

      if (storedParams && storedParams.length) {
        const originalOrder = this.filterRows.map(row => row.secondaryKey);
        storedParams = storedParams.sort((a,b) => originalOrder.indexOf(a.secondaryKey) - originalOrder.indexOf(b.secondaryKey));
        this.filterRows = [];
        const replaceDefaultValue = true;
        storedParams.forEach(param => this.addRow(param, replaceDefaultValue));
        this.showMoreOptions = true;
      }
    },
    normalizeStoredSearchParameter(storedParams) {
      return [ ...storedParams || [], ]
        .filter(row => {
          const primaryKeyExists = this.primarySelection.findIndex(ps => ps.value == row.key) >= 0;

          const secondarySelection = this.getSecondarySelection(row.key) || [];
          const secondaryKeyExists = secondarySelection.findIndex(ps => ps.value == row.secondaryKey) >= 0;

          return primaryKeyExists && secondaryKeyExists;
        })
        .map(row => ({
          ...row,
          primarySelection: this.primarySelection,
        }));
    },
    selectStoredSearchParameter(selectedStoreParam, shouldSearch = false ) {
        const storedParams = this.normalizeStoredSearchParameter(selectedStoreParam.parameters);
        this.defaultSearchInput = selectedStoreParam.defaultSearchInput
        this.$set(this, 'filterRows', this.normalizeStoredSearchParameter(selectedStoreParam.parameters));
        if (shouldSearch) {
          this.isTabCtrl = false;
          (this.filterRows || []).forEach(f => { // MSC-29199
            if (f.componentType == 'text') {
              this.isTabCtrl = true;
              f.isTabCtrl = true;
            }
          })
          if (this.isTabCtrl) {
            this.$nextTick(() => this.onTabPressed({target:null, withShift: false}, -1))
          }
        }
        if (storedParams && storedParams.length) {
            this.searchForm.parameters = [];
            
            const parameters = this.parameters;
            while (parameters.length) {
            parameters.splice(0);
            }

            for (let index = 0; index < storedParams.length; index++) {
            const storedParam = storedParams[index];
            const newItem = this.createItem();

            newItem['menuGroupKey'] = (storedParam.menuGroupKey);
            this.afterSelectMenuGroupKeyComboBox(newItem, newItem.menuGroupKey);

            newItem['menuItemKey'] = (storedParam.menuItemKey);
            this.afterSelectMenuItemKeyComboBox(newItem, newItem.menuGroupKey, newItem.menuItemKey);

            newItem['menuItemValue'] = (storedParam.menuItemValue);

            parameters.push(newItem);
            }
            this.searchForm.parameters = parameters;
        } 

        this.setFixedDefaultOptions();

        this.showMoreOptions = true;
        if(shouldSearch){
          this.emitFilterEvent();
        }
    },
    createItem() {
      return {
        menuGroupKey: '',
        menuItemKey: '',
        menuItemValue: '',
        type: '',
        invertedWithParameter: '',
        menuItemsComboOptions: [],
        comboOptions: [],
        footnotes: [],
        emptyDenied: false
      };
    },  
    afterSelectMenuGroupKeyComboBox(param, menuGroupKey) {
      let result = [];
      const foundMenuGroup = this.getMenuGroupByKey(menuGroupKey);
      if (foundMenuGroup) {
        let comboOptions = [];
        let menuItemValue = '';
        let type = '';
        if (foundMenuGroup.menuItems) {
          if (foundMenuGroup.menuItems[0] && foundMenuGroup.menuItems[0].type === 'array') {
            comboOptions = foundMenuGroup.menuItems[0].comboOptions;
            type = 'array';
            menuItemValue = foundMenuGroup.key;
            param['menuItemKey'] = (foundMenuGroup.key);
          }

          result.push(...foundMenuGroup.menuItems
            .filter(menuItem => menuItem.type !== 'group')
            .map(menuItem => ({ value: menuItem.key, label: menuItem.label })));

          result.push(...foundMenuGroup.menuItems
            .filter(menuItem => menuItem.type === 'group')
            .flatMap((item) => (item.menuItems ? item.menuItems.map(grandItem => ({menuItem: grandItem, group: item})) : []))
            .map(finalItem => ({value: finalItem.menuItem.key, label: finalItem.group.label + ': ' + finalItem.menuItem.label})));
        }

        param['menuItemsComboOptions'] = (result);
        param['footnotes'] = (foundMenuGroup.footnotes);
        param['comboOptions'] = comboOptions;
        // param['menuItemValue'].clearValidators();
        param['menuItemValue'] = (menuItemValue);
        param['type'] = (type);
      }
    }, 
    getMenuGroupByKey(menuGroupKey) {
      return this.metadata.find(menuGroup => menuGroup.key === menuGroupKey);
    },
    afterSelectMenuItemKeyComboBox(param, menuGroupKey, menuItemKey) {
      const foundMenuGroup =  this.metadata.find(menuGroup => menuGroup.key === menuGroupKey);

      if (foundMenuGroup && foundMenuGroup.menuItems) {
        let foundMenuItem = foundMenuGroup.menuItems.find(menuItem => menuItem.key === menuItemKey);
        let foundMenuItemGroup;

        if (!foundMenuItem) {
          const foundGroupMenuItem = foundMenuGroup.menuItems
            .filter(menuItem => menuItem.type === 'group')
            .flatMap((item) => (item.menuItems.map(grandItem => ({menuItem: grandItem, group: item}))))
            .filter(menuGroup => menuGroup.menuItem.key === menuItemKey);

          if (foundGroupMenuItem && foundGroupMenuItem.length && foundGroupMenuItem[0].menuItem) {
            foundMenuItem = foundGroupMenuItem[0].menuItem;
            foundMenuItemGroup = foundGroupMenuItem[0].group;
          }
        }

        if (foundMenuItem) {
          const newComboOption = foundMenuItem.comboOptions ? foundMenuItem.comboOptions : [];
          const newMenuItemValue = foundMenuItem.type === 'switcher' ? false : '';

          param['comboOptions'] = (newComboOption);
          param['type'] = (foundMenuItem.type);
          param['invertedWithParameter'] = (foundMenuItem.invertedWithParameter);
          param['menuItemValue'] = (newMenuItemValue);
          param['emptyDenied'] = (foundMenuItem.emptyDenied === true);

          if (foundMenuItem.emptyDenied) {
            // param['menuItemValue'].setValidators(Validators.required);
          }

        }
      }
    },
    getDefaultOption(key) {
      return this.defaultOptions[key] || {};
    },  
    enableMoreOptions() {
      this.addRow();
      this.showMoreOptions = true;
      this.$nextTick(() => this.focusOnRow(this.filterRows.length - 1));
    },
    defaultSearchInputExit(eventValue) {
      // Right now the code is hard coded and is intended to be used only for Customer search
      // TODO: emit this method so that the caller may implement specific code
      
      // resets the filter
      if ( !this.configFilter.noResetOnDefaultSearchInputExit ) {
        this.filterRows.splice(1);
      }

      let checkDefaultSearchInput = true;
      try {
        if (this.configFilter && this.configFilter.cbDefSearchInput) {
          this.configFilter.cbDefSearchInput(this.defaultSearchInput);
        }

        if (this.configFilter.checkDefaultSearchInput !== undefined) {
          checkDefaultSearchInput = this.configFilter.checkDefaultSearchInput;
        }
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.LOG, {message: 'defaultSearchInputExit', error, configFilter: this.configFilter});
      }

      if (checkDefaultSearchInput && this.defaultSearchInput && this.defaultSearchInput.length) {
        const defaultSearchInput = this.defaultSearchInput;
        const inputValue = eventValue;
        let primaryKey = '';
        let secondaryKey = '';

        const menusByType = this.defaultSearchInputKeysGrouped;
        if(menusByType && this.defaultSearchInput) {
          const searchInputTreated = defaultSearchInput.trim();

          // Must to be sorted to avoid menu's type 'text' being processed first
          Object.entries(menusByType).sort((a, b) => a[0].localeCompare(b[0])).forEach(([type, componentMenu]) => {
            let menuItemFound = null;
            let comboItemFoundIndex = null;

            if('combobox' === type && componentMenu?.length && this.defaultSearchInput) {
              componentMenu.some(menu => {
                const comboItemIndex = menu.comboOptions.findIndex(option => option.label.toLowerCase() === searchInputTreated.toLowerCase());
                if(comboItemIndex > -1){
                  comboItemFoundIndex = comboItemIndex;
                  menuItemFound = menu;
                  return true;
                }
                return false;
              })
              //combobox default selections
              if(menuItemFound && comboItemFoundIndex >= 0) {
                primaryKey = this.metadata.find( menu => {
                  return menu.menuItems.some(menuItem => menuItem.key === menuItemFound.key);
                })?.key;
                const itemFound = menuItemFound?.comboOptions[comboItemFoundIndex];
                secondaryKey = menuItemFound?.key;
    
                this.removeRowsByKey(primaryKey, secondaryKey);
                this.addRow({
                  key: primaryKey,
                  primarySelection: this.primarySelection,
                  type: 'group',
                  disabled: false,
                  secondaryKey: secondaryKey,
                  [secondaryKey]: itemFound.value,
                  value: itemFound.value,
                  isRequiredFilterGroup : menuItemFound?.isRequiredFilterGroup || menuItemFound?.isRequiredFilterGroup || false,
                  isDeniable: menuItemFound?.deniable || false,
                  maxOccurences: menuItemFound?.maxOccurences,
                });
                this.defaultSearchInput = null;
                // exit in case found the correspondent filter
                return;
              }
            }

            if('text' === type && componentMenu?.length && this.defaultSearchInput) {
              const isNumber = onlyNumbersUpToAnyCharsAmount.test(searchInputTreated);
              const isText = namePatern.test(searchInputTreated);

              const isInsurance = this.configFilter?.filterType==='versicherung' && insuranceNumber.test(searchInputTreated);

              if (isInsurance) {
                const menuItem = componentMenu.find(menu => menu?.key === 'versNummer');
                if(menuItem) {
                  this.findAndAddNewFilterRowByMenuItemKey(menuItem, searchInputTreated)
                  this.defaultSearchInput = null;
                    // exit in case found the correspondent filter
                  return;
                }
              }

              if (isNumber && this.configFilter?.filterType && this.configFilter?.filterType !== 'wertpapierinfo') {
                const menuItem = componentMenu.find(menu => menu?.dataType?.toLowerCase() === 'number');
                if(menuItem) {
                  this.findAndAddNewFilterRowByMenuItemKey(menuItem, searchInputTreated)
                  this.defaultSearchInput = null;
                    // exit in case found the correspondent filter
                  return;
                }
              }

              // 'WKN' filter could be number or aplhanumeric almost similar to 'ISIN' filter but with less characters
              if((isNumber && this.configFilter?.filterType && this.configFilter?.filterType === 'wertpapierinfo') || isText){

                const menuFiltered = componentMenu.filter(menu => menu?.dataType?.toLowerCase() === 'text' || 
                    (menu?.type?.toLowerCase() === 'text' && menu?.dataType?.toLowerCase() !== 'number'));
                let menuItem = menuFiltered[0]; // default value must to be the first result
                if(menuFiltered.length > 1) {
                  const resultWithPattern = menuFiltered.find(item => ((item?.pattern && item?.pattern.test(searchInputTreated))));
                  if(resultWithPattern) {
                    menuItem = resultWithPattern;
                  } else if(menuFiltered.map(menu => menu.key).some(item => ['lastName', 'firstName'].includes(item))) {
                    menuItem = menuFiltered;
                  } else {
                    menuItem = menuFiltered.find(item => !item.pattern)
                  }
                  
                }
                if(menuItem && !Array.isArray(menuItem)) {
                  this.findAndAddNewFilterRowByMenuItemKey(menuItem, searchInputTreated)
                  this.defaultSearchInput = null;
                  this.showMoreOptions = this.filterRows.length > 0;
                    // exit in case found the correspondent filter
                  return;
                }

                let lastName = null;
                let firstName = null;

                let foundArray = inputValue.split(","); // "von Mustermann, Max"
                if (foundArray.length > 1) {
                  lastName = foundArray[0];
                  firstName = foundArray[1];  
                } else {
                  let foundArray = inputValue.split(" "); // "Musterfrau Sabine"
                  lastName = foundArray[0];
                  if (foundArray.length > 1) {
                    firstName = foundArray[1];
                  }
                }

                const menuItemLastName = componentMenu.find(menu => menu?.dataType?.toLowerCase() === 'string' && menu?.key === 'lastName');
                const menuItemFirstName = componentMenu.find(menu => menu?.dataType?.toLowerCase() === 'string' && menu?.key === 'firstName');
                if(lastName && !firstName){
                  if(menuItemLastName) {
                    this.findAndAddNewFilterRowByMenuItemKey(menuItemLastName, lastName)
                  }
                } else if(!lastName && firstName){
                  if(menuItemFirstName) {
                    this.findAndAddNewFilterRowByMenuItemKey(menuItemLastName, firstName)
                  }

                } else {
                  this.findAndAddNewFilterRowByMenuItemKey(menuItemLastName, lastName)
                  this.findAndAddNewFilterRowByMenuItemKey(menuItemFirstName, firstName)
                }
                this.defaultSearchInput = null;
                this.showMoreOptions = this.filterRows.length > 0;
                // exit in case found the correspondent filter
                return;
              }

            }

          })
          // exit in case found the correspondent filter
          return;
        }

        // The code bellow was kept only for compatibility with customer search, must to be removed after tested the new algorithm above in all pages wich uses base filter
        let result = defaultSearchInput.match(namePatern);
        primaryKey = 'allgemein';

        if (result && result.length) {
          const isVermittlersuche = this.configFilter?.filterType === 'vermittlersuche';
          const firmennameKey = 'firma';

          if (isVermittlersuche) {

            let firmenname = inputValue;

            this.removeRowsByKey(primaryKey, firmennameKey);
            this.addRow({
              key: primaryKey,
              primarySelection: this.primarySelection,
              type: 'text',
              disabled: false,
              secondaryKey: firmennameKey,
              lastName: firmenname,
              value: firmenname
            });

          } else {

            let lastName = null;
            let firstName = null;

            let foundArray = inputValue.split(","); // "von Mustermann, Max"
            if (foundArray.length > 1) {
              lastName = foundArray[0];
              firstName = foundArray[1];  
            } else {
              let foundArray = inputValue.split(" "); // "Musterfrau Sabine"
              lastName = foundArray[0];
              if (foundArray.length > 1) {
                firstName = foundArray[1];
              }
            }

            const lastNameKey = this.isCustomerSearch ? (this.configFilter?.lastNameKey || 'lastName') : 'surname';
            this.removeRowsByKey(primaryKey, lastNameKey);
            this.addRow({
              key: primaryKey,
              primarySelection: this.primarySelection,
              type: 'text',
              disabled: false,
              secondaryKey: lastNameKey,
              lastName: lastName,
              value: lastName
            });

            const firstNameKey = 'firstName';
            this.removeRowsByKey(primaryKey, firstNameKey);
            if (firstName) {
              this.addRow({
                key: primaryKey,
                primarySelection: this.primarySelection,
                type: 'text',
                disabled: false,
                secondaryKey: firstNameKey,
                firstName: firstName,
                value: firstName
              });
            }

          }
 
        } else {
          result = defaultSearchInput.match(onlyNumbersUpToSevenChars);
          if (result && result.length) {
            let kundenNummer = inputValue.split(" ")[0];

            const kundenNummerKey = this.isCustomerSearch ? (this.configFilter?.kundenNummerKey || 'customerId') : 'brokerId';
            this.removeRowsByKey(primaryKey, kundenNummerKey);
            this.addRow({
              key: primaryKey,
              primarySelection: this.primarySelection,
              type: 'text',
              disabled: false,
              secondaryKey: kundenNummerKey,
              customerId: kundenNummer,
              value: kundenNummer,
            });
          }
        }

        this.showMoreOptions = this.filterRows.length > 0;
        this.defaultSearchInput = null;
      }
    },
    findAndAddNewFilterRowByMenuItemKey(menuItemObject, searchInputValue){
      if(menuItemObject){
        const primaryKey = this.metadata.find( menu => {
          return menu.menuItems.some(menuItem => menuItem.key === menuItemObject.key);
        })?.key;

        const secondaryKey = menuItemObject?.key;

        this.removeRowsByKey(primaryKey, secondaryKey);
        this.addRow({
          key: primaryKey,
          primarySelection: this.primarySelection,
          type: 'text',
          disabled: false,
          secondaryKey: secondaryKey,
          [secondaryKey]: searchInputValue,
          value: searchInputValue,
          isDeniable: menuItemObject?.deniable || false,
          maxOccurences: menuItemObject?.maxOccurences,
        });
      }
    },
    recreateRows() {
      if(!this.isSingleFilter || (this.defaultOptions && Object.keys(this.defaultOptions))) {
        this.filterRows.length = 0;
      }
      

      /**
       * in StornoWarnungen filter gets saved in store and set as defaultOptions... if that's the case
       * we must ignore the defaultSearch.
       */
      let ignoreStoredFilter = this.configFilter && this.configFilter.ignoreStoredFilter
      if (this.hasStoredSearchParamterDefault && !ignoreStoredFilter) {
        this.defaultFilterLoaded = false;
        this.loadDefaultFilter();
      } else if (this.defaultOptions && Object.keys(this.defaultOptions).length) {
        const mappedDefaultMenuItems = this.mapDefaultMenuItems(this.defaultOptions);

        mappedDefaultMenuItems.forEach(item => this.addRow(item));

        this.defaultOptionsMenu = mappedDefaultMenuItems;
      } else if (!this.hasSmartSearch) {
        // this is needed for example in Courtagetabelle Beteiligungen/Versicherungen when there
        // is no defaultInputField
        this.addRow();
      }
    },
    filterZurucksetzen() {
      if (this.hasSmartSearch == true) {
        this.defaultSearchInput = null;
        this.filterRows.splice(this.configFilter?.defaultFiltersNum || 1);
      } else {
        this.filterRows.splice(this.configFilter?.defaultFiltersNum || 1);
        this.setFirstOption();
      }
      this.configFilter.filterZurucksetzen && this.configFilter.filterZurucksetzen();

      this.recreateRows();
      this.$store.commit(CORE_TYPES.MUTATIONS.RESET_EVENT_BASE_FILTER);

      this.$emit("onFilterZurucksetzen");
    },
    setFirstOption() {
      const row = this.filterRows[0];

      const primarySelection = row?.primarySelection || [];
      const primary = primarySelection[0];
      const secondarySelecion = this.getSecondarySelection(primary?.value) || [];
      const secondary = secondarySelecion[0];

      // set option
      row.key = primary?.value;
      row.secondaryKey = secondary?.value;
      this.afterChangeFilterItem(this.filterRows[0]);
    },
    getTid(row, index) {
      return this._generateTidFromString(row.key + row.secondaryKey + index);
    },      
    getTidFromString(baseString) {
      return this._generateTidFromString(baseString);
    },      

    exportData() {
      return JSON.parse(JSON.stringify(this.filterRows, (key, value) => {
        if (value?.componentType == "dateRange") {
          return {
            ...value,
            [value.secondaryKey + "_1"]: DatePickerUtils.toDateStr(value[value.secondaryKey + "_1"]),
            [value.secondaryKey + "_2"]: DatePickerUtils.toDateStr(value[value.secondaryKey + "_2"]),
            presetMeta: value.presetMeta
          };
        }
        else if (value?.componentType == "datepicker") {
          return {
            ...value,
            [value.secondaryKey]: DatePickerUtils.toDateStr(value[value.secondaryKey]),
          };
        }
        return value;
      }));
    },
    saveSearchEdit() {
      const savePayload = {
        searchKey: this.filterId,
        searchParameter: {
          searchLabel: this.newFilterName,
          searchKey: this.filterId,
          defaultSearchInput: this.defaultSearchInput,
          parameters: this.exportData(),
          visible: true,
        },
      };
      this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER, savePayload);
    },
    mapListToKeyValue(list) {
      return mapListToKeyValue(list, 'value', 'label');
    },
    rowValue(row) {
      if(row.componentType === 'default') return '';

      let value = null;
      switch(row.componentType) {
        case 'combobox':
          value = this.mapListToKeyValue(this.filteredComboOptions(row.comboOptions))[row[row.secondaryKey]];
          break;
        case 'multipleItemSelector':
          value = row?.[row.secondaryKey]?.map(item => item.label)?.join(', ');
          break;
        case 'switcher':
          value = row?.[row.secondaryKey] ? 'ja' : 'nein';
          break;
        case 'datepicker':
          value = isDate(row?.[row.secondaryKey]) ? dayjs(row[row.secondaryKey]).format('DD.MM.YYYY') : row?.[row.secondaryKey];
          break;
        case 'dateRange':
          let value1 = row?.[row.secondaryKey + '_1']
          let value2 = row?.[row.secondaryKey + '_2']

          if (value1 && isDate(value1)) {
            value1 = dayjs(value1).format('DD.MM.YYYY')
          }

          if (value2 && isDate(value2)) {
            value2 = dayjs(value2).format('DD.MM.YYYY')
          }

          value = value1 || value2 ? [value1 || '', value2 || ''].join('-') : null;
          break;
        case 'numberRange':
        case 'textRange':
        case 'doubleCombo':
          value = row?.[row.secondaryKey + '_1'] || row?.[row.secondaryKey + '_2'] ? [row?.[row.secondaryKey + '_1'] || '', row?.[row.secondaryKey + '_2'] || ''].join('-') : null;
          break;
        default:
          value = row?.[row.secondaryKey];
          break;
      }

      return value || 'Alle';
    },
    focusOnRow(index) {
      requestAnimationFrame(() => {
        const row = this.filterRows?.[index];
        if(!row) return;

        const { moreOptionsEl } = this.$refs;

        if(row.componentType === 'default' || row.componentType === 'multipleItemSelector') {
          const rowEl = moreOptionsEl?.children?.[index];
          rowEl.setAttribute('tabindex', 0);
          rowEl.focus();
          rowEl.removeAttribute('tabindex');
        } else {
          const colIndex = !row.componentType ? 1 : 2;
          const colEl = moreOptionsEl?.children?.[index]?.children[colIndex];
          colEl?.querySelector('input, select, textarea')?.focus();
        }
      });
    },
    selectFilterDropListItem(filteredItem) {
      if(filteredItem) {
        this.$set(this, 'defaultSearchInput', filteredItem.label);
        this.emitFilterEvent()
      }
    },
    defaultSearchInputExitDebounce() {
      if (this.defaultSearchInput) {
        this.defaultSearchInputExit(this.defaultSearchInput);
      }
    },    
    getDownloadButtonUrl(whatButton) {

      const linkMetadata = whatButton?.linkMetadata;
      const apiAddress = linkMetadata?.apiAddress;
      const token = linkMetadata?.token;
      const downloadMethod = linkMetadata?.downloadMethod;
      const fileName = linkMetadata?.fileName;
      const DATE_FORMAT = "DD.MM.YYYY";

      const formValues = {};

      const filterConfig = this.getFilterConfig();
      if (filterConfig) {
        linkMetadata?.params?.forEach( p => {

          if (p.type=="group") {
            let value = filterConfig.find(fc => fc.group == p.key)?.key;
            formValues[p.key] = value;
          } else if (p.type=="dateRange") {
            const period = filterConfig.find(fc => fc.group==p.key);
            if (period) {         
              if (period?.value) {
                const parsedFilterVon = dayjs(period.value.find(v => v.key == 'min').value, DATE_FORMAT).toDate();
                const parsedFilterBis = dayjs(period.value.find(v => v.key == 'max').value, DATE_FORMAT).toDate();

                formValues[p.fromParam] = dayjs(parsedFilterVon).format(DATE_FORMAT);
                formValues[p.toParam] = dayjs(parsedFilterBis).format(DATE_FORMAT);
              }            
            }
          } else {
            let value = filterConfig.find(fc => fc.key == p.key)?.value;
            formValues[p.param || p.key] = value;
          }

        });
      }

      const params = makeQueryParam({ ...formValues, token })

      return `${apiAddress}/download_service/${downloadMethod}/${fileName}?${params}`;

    },
    async loadFiltersFromStoredSearchParameters() {

      if (this.filterId && this.showSaveButton ) {
        await this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.LOAD_FILTERS, this.filterId);
      }

      const storedFiltersLoaded = this.hasStoredSearchParamterDefault && this.showSaveButton ? this.mapFilterConfig(this.storedSearchParameterDefault) : null;
      return this.$emit("storedFiltersLoaded", storedFiltersLoaded);
    },
    mapFilterConfig(rows) {
      let filterConfig = [];

      if(this.hasDefaultSearchInputValue) {
        filterConfig.push({
          group: 'baseFilter',
          key: 'defaultSearchInput',
          value: this.defaultSearchInput,
        })
      }

      rows?.forEach((fr) => {
        switch (fr.componentType) {
          case "dateRange":
          case "numberRange":
          case "textRange":{
            const element = {
              group: fr.key,
              key: fr.secondaryKey,
            };

            const minValueKey = fr.secondaryKey + "_1"
            const maxValueKey = fr.secondaryKey + "_2"
            const minValue = fr[minValueKey]
            const maxValue = fr[maxValueKey]

            element.value = [
              {
                key: "min",
                value: minValue,
              },
              {
                key: "max",
                value: maxValue,
              }
            ];

            filterConfig.push(element);
            if(fr.componentType == 'dateRange'){
              break;
            }
            filterConfig.push({ key: minValueKey, value: minValue })
            filterConfig.push({ key: maxValueKey, value: maxValue })
            }
            break;
          case "doubleCombo":{
            const element = {
              group: fr.key,
              key: fr.secondaryKey,
            };

            element.value = [];

            element.value.push({
              key: "value1",
              value: fr[fr.secondaryKey + "_1"],
            });
            element.value.push({
              key: "value2",
              value: fr[fr.secondaryKey + "_2"],
            });
            filterConfig.push(element);
            }
            break;
          case "chips":{
              filterConfig.push({
                group: fr.key,
                key: fr.secondaryKey,
                value: fr[fr.secondaryKey] ? fr[fr.secondaryKey] : [],
              });
            }
            break;

          default: {
            const newObjectFilter = {
              group: fr.key,
              key: fr.secondaryKey,
              value: fr.componentType=="default" ? true : (fr[fr.secondaryKey] === undefined ? null : fr[fr.secondaryKey]),
            };


            if(fr.filterNot) {
              newObjectFilter.filterNot = fr.filterNot
            }
            if (fr.secondaryItem && fr[fr.secondaryItem.key]) {
              filterConfig.push({
                group: fr.key,
                key: fr.secondaryItem.key,
                value: fr[fr.secondaryItem.key],
              });
            }
            filterConfig.push(newObjectFilter);
            break;
          }
        }
      });

      return filterConfig;      

    },
    getFilterConfig() {
      return this.mapFilterConfig(this.filterRows);
    },
    changeCombobox(row, key, value) {
      if (row.secondaryItem?.key) {
        row[row.secondaryItem.key] = '';
      }
      this.filterRows = [...this.filterRows]
      this.$emit("changeCombobox", row, key, value);
    },
    updateMultipleSelection(row, value) {
      if (row?.secondaryKey) {
        const index = this.filterRows?.findIndex(r => r.secondaryKey === row.secondaryKey);
        if (index >= 0) {
          row[row.secondaryKey] = value
          this.filterRows[index] = row;
          this.filterRows = Object.assign([], this.filterRows);
        }
      }
    },
    getFirstValue(row = {}, filtered) {
      return (row?.componentType === 'multipleItemSelector' || row?.componentType === 'chips') 
          && filtered?.[0] ? [filtered[0]] : filtered[0]?.value;
    },
    setValueChanged(row, secondaryKey, value) {
      this.$set(row, secondaryKey, value);
      this.$set(row, '__UPDATED_KEY__', new Date().getTime());
    },
    setCollapsed(collapsed = !this.collapsedView) {
      this.collapsedView = collapsed

      // save the collapsed state in the filterOptions in the store
      if (this.filterId) {
        const filterOptions = this.filterOptions?.(this.filterId)

        if (filterOptions) {
          const saveCollapsedPayload = {
            filterId: this.filterId,
            filterOptions : {
              ...filterOptions,
              collapsed: this.collapsedView,
            }
          }
          this.$store.dispatch(SEARCH_CONFIGS_TYPES.ACTIONS.SAVE_FILTER_OPTIONS, saveCollapsedPayload)
        }
      }
    },
    setClassTabElement(isTabCtrl, index) {
      if (!isTabCtrl) {
        return {};
      }
      return {['tab-element' + (index == null ? '' : index)]: true}
    },
    onTabPressed({target, withShift}, index) {
      if (index == -1 && withShift) {
        index = this.filterRows.length;
      }
      const step = withShift ? -1 : 1;
      const { moreOptionsEl } = this.$refs;
      let child = null;
      do {
        index += step;
        child = moreOptionsEl.querySelector('.tab-element'+index) ;
      } while(!child && index >= 0 && index < this.filterRows.length);
      if (child) {
        requestAnimationFrame(() => {child.focus();});
      } else {
        child = this.$refs.defaultSearchInput.$el.querySelector('.tab-element') ;
        if (child) {
          requestAnimationFrame(() => {child.focus();});
        }
      }
    },
    getDateRangeValue(row){
      if(row.presetMeta){
        const today = dayjs().utc(); 
        return {
          from: today.clone(),
          to: today.subtract(row.presetMeta.number, row.presetMeta.type)
        }
      }
      return {
        from: row[row.secondaryKey + '_1'],
        to: row[row.secondaryKey + '_2'],
      }
    },
  }
};
</script>

<style scoped lang="scss">
.filterLabel {
  display: none;
}

.parameter-search__input-fields__container {
  font-size: 14px;
  margin: 0;
}

.parameter-search__input-fields--more-options.collapsed {
  display: flex;
  flex-wrap: wrap;
  margin: -4px -6px 8px;
}

.parameter-search__input-fields {
  display: flex;
  margin-top: 0;
  margin-bottom: 8px;
}

.parameter-search__input-toggle-switch {
  margin-top: 6px;
}

.parameter-search__input-fields--more-options.collapsed .parameter-search__input-fields {
  margin: 2px 4px 2px 2px;
}

.parameter-search__input-fields > * {
  margin: 0;
  white-space: normal;
  word-break: break-word;
}

.parameter-controls__container {
  display: flex;
  flex: 0 0 auto;
  margin: 0 -6px;
}

.parameter-controls__container > * {
  margin: 0 4px;
  padding: 4px;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }
}

hr {
  margin: 16px 0px;
}

.line__controls {
  flex-grow: 1; 
  padding-bottom: 16px;
  min-width: fit-content;
}
.parameter-search__buttons-container {
  margin-left: auto;
}
.action-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.parameter-search__input-fields-toggle svg {
  transition: transform .5s ease;
  will-change: transform;
}

.parameter-search__input-fields-toggle.active svg {
  transform: rotate(-180deg);
}

.filter-row__border {
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.filter-row__border:after {
  border-bottom: 1px solid #c4c4c4;
  content: "";
  display: block;
  margin: 0 auto -10px;
  width: 100%;
}

.row.filter-row__border:after {
  width: calc(100% - 24px);
}

@media (max-width: 575px) {
  .parameter-search__input-fields,
  .parameter-search__input-fields > * {
    margin-bottom: 6px;
  }

  .parameter-search__input-fields > *:last-child {
    margin-bottom: 0;
  }

  .collapsed .parameter-search__input-fields > * {
    margin-bottom: 0;
  }

  .filter-row__border {
    margin-bottom: 10px;
    padding-bottom: 4px;
  }

  .filter-row__border:after {
    margin-bottom: -4px;
  }
}

.filter-row__border:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}

.filter-row__border:last-child:after {
  display: none;
}

.dropList {
  padding: 0 !important;
  overflow: auto;
  max-height: 200px;
  width: 100%;
  background-color: var(--color-box);
  position: absolute;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,.5);
  z-index: 9999;
  top: 32px;
  border: black;
  > ul {
    list-style: none;
    padding: 0 !important;
    margin: 0;
    > li {
      cursor: pointer;
      line-height: 1.2em;
      min-height: 20px;
      font-size: 14px;
      padding: 5px 10px;
      color: #515a6e;
      transition: background 0.2s ease-in-out;
      &:hover {
        background: #686767;
        color: var(--color-box) !important;
      }
    }
  }
}

/** Responsive */
@media screen and (max-width: 767px) {
  .base-filter__container {
    hr {
      margin: 12px 0;
    }

    .parameter-search__input-fields__container {
      margin: 0 0 12px;
    }
  }
}

@media screen and (max-width: 358px) {
  .base-filter__container {
    .parameter-search__component-default,
    .parameter-search__secondary-key-container {
      padding-right: 28px;
    }
  }
}

</style>
