var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("PageHeaderTitleNavigation", {
        attrs: {
          defaultMenu: _vm.$appNavigation.currentTabMenu,
          title: "Courtagetabellen",
          subtitle: "Versicherung"
        }
      }),
      _vm.searchMetadata && _vm.searchMetadata.length
        ? _c("BaseFilter", {
            attrs: {
              filterId: "courtagetabellesuche",
              title: "Courtagetabelle",
              showSaveButton: "",
              saveKey: "customerSearchAdvanced",
              metadata: _vm.searchMetadata,
              configFilter: _vm.configFilter,
              replacePreviousSelectedValues: true
            },
            on: {
              onFilter: _vm.handleSearch,
              changeSubFilter: _vm.handleFilterChanges
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading
            ? _c("GhostLoading", { attrs: { type: "table" } })
            : _vm.rowCount == 0
            ? _c("NoData")
            : _vm.rowCount
            ? [
                _c("PaginatedTable", {
                  attrs: {
                    tableId: "383abbeb-da72-4638-8d24-090a439c215a",
                    title: "Courtagetabelle",
                    headers: _vm.headers,
                    pages: _vm.pages,
                    pageCount: _vm.pageCount,
                    rowCount: _vm.rowCount,
                    page: _vm.selectedPage,
                    exportConfig: _vm.exportConfig,
                    sorted: _vm.orderBy
                  },
                  on: {
                    page: function($event) {
                      _vm.selectedPage = $event
                    },
                    "click-infoButton": _vm.loadInfo,
                    requestPage: _vm.loadPage,
                    "action-BEARBEITEN": _vm.bearbeiten,
                    "action-DELETE": _vm.removeRow,
                    sort: function($event) {
                      return _vm.sortDataByColumn($event)
                    }
                  },
                  model: {
                    value: _vm.selectedRow,
                    callback: function($$v) {
                      _vm.selectedRow = $$v
                    },
                    expression: "selectedRow"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm._m(0),
      _c(
        "BaseModal",
        {
          ref: "infoModal",
          attrs: { labelButtonCancel: "Ok", showConfirmButton: false },
          on: { onCancelButton: _vm.closeInfoModal },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [_c("ph-info"), _vm._v(" Information ")]
              },
              proxy: true
            }
          ])
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.selectedInfo)) }
              })
            ])
          ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "editRowModal",
          attrs: { showDefaultButtons: false, modalTitle: "Eintrag bearbeiten" }
        },
        [
          _c(
            "div",
            [
              _vm._l(_vm.formMetadataKeys, function(key) {
                return _c(
                  "div",
                  { key: key },
                  [
                    _vm.formMetadata[key].type == "LABEL"
                      ? _c("div", { staticClass: "courtage___label" }, [
                          _c("b", [
                            _vm._v(_vm._s(_vm.formMetadata[key].label) + ":")
                          ]),
                          _vm._v(" " + _vm._s(_vm.formMetadata[key].value))
                        ])
                      : _vm._e(),
                    _vm.formMetadata[key].type == "STRING"
                      ? _c("InputField", {
                          attrs: { label: _vm.formMetadata[key].label },
                          model: {
                            value: _vm.formMetadata[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.formMetadata[key], "value", $$v)
                            },
                            expression: "formMetadata[key].value"
                          }
                        })
                      : _vm._e(),
                    _vm.formMetadata[key].type == "TEXT_PROZENT"
                      ? _c("InputField", {
                          attrs: {
                            type: "percent",
                            precision: 2,
                            label: _vm.formMetadata[key].label
                          },
                          model: {
                            value: _vm.formMetadata[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.formMetadata[key], "value", $$v)
                            },
                            expression: "formMetadata[key].value"
                          }
                        })
                      : _vm._e(),
                    _vm.formMetadata[key].type == "SWITCHER"
                      ? _c("InputToggleSwitch", {
                          attrs: {
                            label: _vm.formMetadata[key].label,
                            inLineLabel: "",
                            suppressValidationMessage: ""
                          },
                          model: {
                            value: _vm.formMetadata[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.formMetadata[key], "value", $$v)
                            },
                            expression: "formMetadata[key].value"
                          }
                        })
                      : _vm._e(),
                    _vm.formMetadata[key].type == "NUMBER"
                      ? _c("InputField", {
                          attrs: {
                            type: "number",
                            label: _vm.formMetadata[key].label
                          },
                          model: {
                            value: _vm.formMetadata[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.formMetadata[key], "value", $$v)
                            },
                            expression: "formMetadata[key].value"
                          }
                        })
                      : _vm._e(),
                    _vm.formMetadata[key].type == "TEXTAREA"
                      ? _c("InputTextArea", {
                          attrs: { label: _vm.formMetadata[key].label },
                          model: {
                            value: _vm.formMetadata[key].value,
                            callback: function($$v) {
                              _vm.$set(_vm.formMetadata[key], "value", $$v)
                            },
                            expression: "formMetadata[key].value"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "div",
                { staticStyle: { display: "flex" } },
                [
                  _c(
                    "BaseButton",
                    {
                      attrs: { isPrimary: "" },
                      on: {
                        click: function($event) {
                          return _vm.saveOrUpdateItem()
                        }
                      }
                    },
                    [_vm._v(" Bestätigen ")]
                  ),
                  _c(
                    "BaseButton",
                    {
                      attrs: { isSecondary: "" },
                      on: {
                        click: function($event) {
                          return _vm.cancelUpdateItem()
                        }
                      }
                    },
                    [_vm._v(" Abbrechen ")]
                  )
                ],
                1
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "box__container" }, [
      _c("div", { staticClass: "box__title" }, [_vm._v("Info")]),
      _c("p", [
        _vm._v(
          "Konditionsänderungen vorbehalten. Alle Angaben wurden sorgfältig geprüft, die FondsKonzept Assekuranzmakler GmbH übernimmt keine Haftung für die Richtigkeit der Angaben. Falls Sie über andere Maklerpools höhere Courtagen erhalten, teilen Sie uns dies bitte umgehend unter courtage@fondskonzept.ag mit. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }