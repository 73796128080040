<template>
  <div>
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" tid="3c886523-0f75-4a62-bb04-2bd422c441b8" />
    
    <div class="" v-if="gesamt">
      <div v-if="!$isSmallScreen" class="overview__container">
        <div v-for="(sum, index) of gesamt.summen" :key="'sum'+index">
          <div v-if="sum && sum[1] !== ''" class="box__container" >
            <div class="text-small text-centered mp-0">{{sum[0]}}</div>
            <div class="text-centered mp-0" :class="getBadgesClassCategory(sum)">
              <span><CurrencyLabel :value="sum[1]" :symb="sum[2]"/></span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="box__container">
        <template v-for="(sum, index) of gesamt.summen">
          <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
            <template #value >
              <span>
                <span><CurrencyLabel :value="sum[1]" :symb="sum[2]"/></span>
              </span>
            </template>
          </MobileInfoRow>
        </template>
      </div>
    </div>
    <div class="" v-for="(kunde,index) of depots" :key="index" >
      <div v-if="kunde.kname" class="box__title box__container mt-4">{{kunde.kname}}</div>
      <div v-else class="mt-4"></div>
      <div class="kacheln" >
        <template v-for="item of kunde.data">
          <div class="box__container kachel" :key="item.key">
            <div class="box__title">{{item.depotname}}</div>
            <template v-for="(sum, index) of item.summen">
              <MobileInfoRow :key="'sum'+index" :label="sum[0]" >
                <template #value >
                  <span>
                    <span><CurrencyLabel :value="sum[1]" :symb="sum[2]"/></span>
                  </span>
                </template>
              </MobileInfoRow>
            </template>
          </div>

        </template>
      </div>
    </div>
    <div v-if="!depots.length">
      <GhostLoading :repeat="3" useBoxContainer inline>
        <Block />
        <Block />
      </GhostLoading>
      <div  class="kacheln">
        <GhostLoading class="kachel">
          <Block type="title" />
          <Block :height="175" />
        </GhostLoading>
        <GhostLoading class="kachel">
          <Block type="title" />
          <Block :height="175" />
        </GhostLoading>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import MobileInfoRow from '@/components/core/MobileInfoRow.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';

export default {
  props: {
  },
  components: {
    OptionMenu, CurrencyLabel, MobileInfoRow, GhostLoading, Block
  },
  data() {
    return {
    };
  },
  mounted() {
    this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.LOAD_DEPOTPOSITIONS_GUV)
  },
  computed: {
    ...mapGetters({
      vermoegen: DEPOTPOSITIONS_TYPES.GETTERS.GET_DEPOTPOSITIONS_GUV,
    }),
    depots() {
      return this.vermoegen.virtualDepots || []
    },
    gesamt() {
      return this.vermoegen.gesamt
    },
    depotauszugGuvFarbe() {
      return this.vermoegen.guvFarbe
    },
  },
  methods: {
    getBadgesClassCategory(sum) {
      if (!this.depotauszugGuvFarbe || !sum[0] || sum[0] === 'Ein-/Auszahlungen') {
        return '';
      }
      if (sum[1] > 0) {
        return "color-success";
      } else if (sum[1] < 0) {
        return "color-danger"
      }
      return "text-color";
    },
  },
};
</script>

<style scoped>

.overview__container {
  display: flex;
  flex-wrap: wrap;
}
.overview__container div {
  margin: 0;
}
.overview__container > div {
  flex-grow: 1;
  padding: 0 12px;
  margin: 0 0 8px;
}
.kacheln {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(25rem,auto));
  margin-bottom: 24px;
}
.kachel {
  min-width: 25rem;
  margin: 0;
}
::v-deep .row-content {
  font-weight: normal;
}
</style>