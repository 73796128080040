var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label:
            "Einnahmen aus unselbständiger Arbeit (Gehaltsabrechnung / netto pro Monat)",
          type: "currency",
          disabled: _vm.disabled,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("salary", $event || null)
          }
        },
        model: {
          value: _vm.form.salary,
          callback: function($$v) {
            _vm.$set(_vm.form, "salary", $$v)
          },
          expression: "form.salary"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Einnahmen aus selbständiger Arbeit (laut Einkommensteuerbescheid / netto pro Monat)",
          type: "currency",
          disabled: _vm.disabled,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("freelancerRevenue", $event || null)
          }
        },
        model: {
          value: _vm.form.freelancerRevenue,
          callback: function($$v) {
            _vm.$set(_vm.form, "freelancerRevenue", $$v)
          },
          expression: "form.freelancerRevenue"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Einnahmen aus Kapitalvermögen (z.B. Zinsen, Dividenden, Ausschüttungen pro Monat)",
          type: "currency",
          disabled: _vm.disabled,
          isComponentHalfSize: ""
        },
        on: {
          input: function($event) {
            return _vm.updateStore("capitalRevenue", $event || null)
          }
        },
        model: {
          value: _vm.form.capitalRevenue,
          callback: function($$v) {
            _vm.$set(_vm.form, "capitalRevenue", $$v)
          },
          expression: "form.capitalRevenue"
        }
      }),
      _c("InputField", {
        attrs: {
          label:
            "Sonstiges (z.B. Unterhaltsbeträge, Mieteinnahmen usw. pro Monat)",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("otherRevenue", $event || null)
          }
        },
        model: {
          value: _vm.form.otherRevenue,
          callback: function($$v) {
            _vm.$set(_vm.form, "otherRevenue", $$v)
          },
          expression: "form.otherRevenue"
        }
      }),
      !_vm.isAnlegerprofil
        ? _c("DatePickerField", {
            attrs: { label: "Letzte Aktualisierung", isComponentHalfSize: "" },
            on: {
              input: function($event) {
                return _vm.updateStore("lastUpdate", $event)
              }
            },
            model: {
              value: _vm.form.lastUpdate,
              callback: function($$v) {
                _vm.$set(_vm.form, "lastUpdate", $$v)
              },
              expression: "form.lastUpdate"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }