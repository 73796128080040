import { isRolesIncludes } from '@/router/guards'
import { ROLES, EMPLOYEE_ROLES, BROKER_LEVEL_ROLES, VIEW_ROLES, BROKER_STRUCTURE_ROLES } from '@/router/roles'
import configsRoutes from './configs'
import { redirectToFirstAllowedChild } from '@/router';

export default [
  {
    path: '',
    redirect: 'ubersicht'
  },
  ...configsRoutes,
  {
    path: 'vermittlerdaten',
    component: () => import(/* webpackChunkName: "customer-data" */  '@/views/intern/VermittlerDaten.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_PERSDATEN, ROLES.FK],
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_PERSDATEN, ROLES.FA, VIEW_ROLES.VIEW_BROKER_AS_INTERN],
          [VIEW_ROLES.VIEW_BROKER, EMPLOYEE_ROLES.ZUGRIFF_PERSDATEN, ROLES.FA, ROLES.IS_FA_VERMITTLER_DATEN_ALLOWED],
        ],
      }
    },
    children: [
      {
        path: '',
        redirect: 'vermittlerdaten'
      },
      {
        path: 'vermittlerdaten',
        component: () => import(/* webpackChunkName: "stammdaten-vermittler" */ '@/views/intern/vermittler-daten/Stammdaten.vue'),
      },
      {
        path: 'email',
        component: () => import(/* webpackChunkName: "email" */ '@/components/broker/Email.vue')
      },
      {
        path: 'bankverbindung',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          }
        },
        component: () => import(/* webpackChunkName: "bankverbindungBroker" */ '@/components/broker/Bankverbindung.vue')
      },      
      {
        path: 'courtage',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          }
        },
        component: () => import(/* webpackChunkName: "informationsversand" */ '@/components/broker/Courtage.vue')
      },
      {
        path: 'facsimile',
        component: () => import(/* webpackChunkName: "informationsversand" */ '@/components/broker/FacsimileVermittlerDaten.vue')
      },
      {
        path: 'zulassung',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
            denied: [ROLES.FA],
          }
        },
        component: () => import(/* webpackChunkName: "informationsversand" */ '@/components/broker/Zulassung.vue')
      },
      {
        path: 'vertragsdaten',
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_BROKER_AS_INTERN],[ ROLES.FA,  ROLES.IS_USER_UNTERNR]],
            denied: [ROLES.FK],
          }
        },
        component: () => import(/* webpackChunkName: "vertragsdaten" */ '@/components/broker/Vertragsdaten.vue')
      },
      {
        path: 'activities-termine',
        component: () => import(/* webpackChunkName: "activities-termine" */ '@/components/broker/ActivityBrokerList.vue'),
        meta: {
          roles: {
            allowed:[
              [VIEW_ROLES.VIEW_BROKER_ONLY],
            ] 
          }
        }
      },
      {
        path: 'aenderungslog',
        component: () => import(/* webpackChunkName: "vermittlerdaten-aenderungslog" */ '@/components/broker/VermittlerdatenAendeungslog.vue'),
        meta: {
          roles: {
            allowed: [ROLES.IS_FPP_PLUS_MASTER, ROLES.IS_FPP_PLUS,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],               
          }
        }
      },
      {
        path: 'stornoreserve',
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          }
        },
        component: () => import(/* webpackChunkName: "fondsshop" */ '@/components/broker/Konto.vue')
      },
      {
        path: 'bemerkungen',
        component: () => import(/* webpackChunkName: "bemerkungen" */ '@/components/broker/BemerkungenVermittler.vue'),
        meta: {
          roles: {
            allowed:[ VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
            denied: [VIEW_ROLES.VIEW_BROKER_AS_INTERN]
          },
        },
      },
      {
        path: 'besuchsberichte',
        component: () => import(/* webpackChunkName: "makler-besuchsberichte" */ '@/components/broker/BesuchsberichteVermittler.vue'),
        meta: {
          roles: {
            allowed:[VIEW_ROLES.VIEW_BROKER_AS_INTERN]
          },
        },
      },
      {
        path: 'sonstiges',
        component: () => import(/* webpackChunkName: "makler-sonstiges" */ '@/components/broker/SonstigesVermittler.vue'),
        meta: {
          roles: {
            allowed:[[ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN]]
          },
        },
      },
    ]
  },
  {
    path: 'bemerkung/:commId?',
    component: () => import(/* webpackChunkName: "kunde-bemerkung-bearbeiten" */ '@/views/communication/PostfachBemerkung.vue'),
    meta: {
      roles: {
        allowed:[ VIEW_ROLES.VIEW_BROKER_AS_BYPASS]
      },
    },
  },
  {
    path: 'vermittlerdaten/besuchsberichte/bemerkung/:commId?',
    component: () => import(/* webpackChunkName: "kunde-bemerkung-bearbeiten" */ '@/views/communication/PostfachBemerkung.vue'),
    meta: {
      roles: {
        allowed:[VIEW_ROLES.VIEW_BROKER_AS_INTERN]
      },
    },
  },
  {
    path: 'vermittlerdaten/besuchsberichte/besuchsbericht/:id?',
    component: () => import(/* webpackChunkName: "makler-besuchsbericht" */ '@/views/gesellschaft/intern/Besuchsbericht.vue'),
    meta: {
      roles: {
        allowed:[VIEW_ROLES.VIEW_BROKER_AS_INTERN]
      },
    },
  },
  {
    path: 'ubersicht',
    component: () => import(/* webpackChunkName: "ubersicht" */ '@/views/intern/Overview.vue'),
  },
  {
    path: 'dashboard-view',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER_AS_BYPASS, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK, BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK],
          [ROLES.IS_MAKLER_KPI, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK, BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK]
        ]
      }
    },
    component: () => import(/* webpackChunkName: "dashboardview" */ '@/views/home/Dashboard.vue'),
  },
  {
    path: 'homepageselection',
    component: () => import(/* webpackChunkName: "homepageselection" */ '@/views/intern/HomePageSelection.vue'),
    meta: {
      roles: {
        allowed: [ROLES.HAS_HOMEPAGE]
      }
    },
  },
  {
    path: 'news-msc',
    name: 'news-msc',
    component: () => import(/* webpackChunkName: "news-msc" */ '@/views/intern/MSCNews.vue')
  },
  {
    path: 'news-msc-edit',
    name: 'news-msc-edit',
    component: () => import(/* webpackChunkName: "news-msc-add" */ '@/views/intern/MSCNewsEdit.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN]
      }
    },
  },
  {
    path: 'news-msc-edit/:id',
    component: () => import(/* webpackChunkName: "news-msc-edit" */ '@/views/intern/MSCNewsEdit.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN]
      }
    },
  },
  {
    path: 'marketing-campaign',
    component: () => import(/* webpackChunkName: "marketing-campaign-router-view" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN]
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "marketing-campaign-list" */ '@/views/intern/MSCMarketingCampaign.vue')
      },
      {
        path: 'marketing-campaign-edit/:id',
        component: () => import(/* webpackChunkName: "marketing-campaign-edit" */ '@/views/intern/MSCMarketingCampaignEdit.vue'),
      },
      {
        path: 'preview-marketing-campaign',
        name: 'preview-marketing-campaign',
        component: () => import(/* webpackChunkName: "marketing-campaign-edit" */ '@/views/marketingCampaign/PreviewMarketingCampaign.vue'),
      }
    ]
  },
  {
    path: 'mailvorlage',
    component: () => import(/* webpackChunkName: "mail-brief-vorlage-ubersicht" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN]
        ]
      },
    },
    children: [
      {
        path: '',
        redirect: (route) => redirectToFirstAllowedChild(route, ['list', 'template-background']),
      },
      {
        path: 'list',
        name: 'intern-mailvorlage',
        component: () => import(/* webpackChunkName: "mail-brief-vorlage-list" */ '@/views/templatesEdit/MailBriefVorlageList.vue'),
      },
      {
        path: 'template-background',
        component: () => import(/* webpackChunkName: "mail-brief-background-template" */ '@/views/templatesEdit/MailBriefeVorlageBackgroundTemplate.vue'),
      },
      {
        path: 'email/:id',
        component: () => import(/* webpackChunkName: "mail-brief-vorlage-edit" */ '@/views/templatesEdit/MailBriefVorlageEdit.vue'),
      },
      {
        path: 'word/:id',
        component: () => import(/* webpackChunkName: "mail-brief-vorlage-word-edit" */ '@/views/templatesEdit/MailBriefVorlageWordEdit.vue'),
      },
    ],
  },
  {
    path: 'spartentypList',
    component: () => import(/* webpackChunkName: "spartentypList" */ '@/views/core/configs/SpartentypList.vue'),
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN],
        ],
      },
    },
  }, 
  {
    path: 'spartentypBearbeiten/:id',
    component: () => import(/* webpackChunkName: "configs" */ '@/views/core/configs/ConfigSpartentyp.vue'),
    meta: {
      breadcrumb: { label: 'Einstellung', },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, ROLES.TEST_USER],
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, ROLES.TEST_USER],
          [VIEW_ROLES.VIEW_INTERN],
        ],
      },
    },
  },
  {
    path: 'notwendige-unterlagen',
    meta: {
      roles: {
        allowed:
          [ [ROLES.FK, VIEW_ROLES.VIEW_BROKER_ONLY], 
            [ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_BYPASS, EMPLOYEE_ROLES.ZUGRIFF_UNTERMAKLER],
          ]
      },
    },
    component: () => import(/* webpackChunkName: "notwendige-unterlagen-by-makler" */ '@/views/home/NotwendigeUnterlagenByMakler.vue'),
  },  
  {
    path: 'dcs-synchronisation',
    meta: {
      roles: {
        allowed: [[ROLES.IS_FPP_PLUS_MASTER, ROLES.IS_FPP_PLUS], [VIEW_ROLES.VIEW_BROKER_AS_INTERN]],
      },
    },
    component: () => import(/* webpackChunkName: "dcs-synchronisation" */ '@/views/home/DCSSynchronisation.vue'),
  },  
  {
    path:'data-control-zuordnungen',
    meta:{
      roles: {
        allowed: [[ROLES.IS_FPP_PLUS_MASTER, ROLES.IS_FPP_PLUS], [VIEW_ROLES.VIEW_BROKER_AS_INTERN]],
      },
    },
    
    component: () => import(/* webpackChunkName: "data-control-zuordnungen" */ '@/views/BaseRouterView.vue'),
    children:[    
      {
        path:'',
        component: () => import(/* webpackChunkName: "data-control-zuordnungen" */ '@/views/home/DataControlZuordnungen.vue'),
      },
      {
        path: 'produkt/:productId',
        component: () => import(/* webpackChunkName: "dcs-synchronisation" */ '@/components/externeZugang/DCProduct.vue'),
      },
    ],
  },

  {
    path: 'change-password',
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/core/ChangePassword.vue')
  },
  {
    path: 'negativerklaerung',
    meta: {
      isPendingAction: false,
      roles: {
        allowed: [ROLES.SHOW_NEGATIVERKLAERUNG_MENU]
      }
    },
    component: () => import(/* webpackChunkName: "negativerklaerung" */ '@/views/core/Negativerklaerung.vue')
  },
  {
    path: 'haftungserklaerung',
    meta: {
      isPendingAction: false,
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER]
      }
    },
    component: () => import(/* webpackChunkName: "haftungserklaerung" */ '@/views/broker/HaftungserklaerungsPage.vue')
  },
  {
    path: 'edit-layout',
    meta: {
      roles: {
        allowed: [ROLES.EDIT_LAYOUT],
      },
    },
    component: () => import(/* webpackChunkName: "change-password" */ '@/views/intern/EditLayout.vue')
  },
  {
    path: 'makler-statistiken',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]
        ],
        denied: [ROLES.BLOCKED_ABRECHNUNGEN_STATISTIKEN]
      }
    },
    component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/BaseRouterView.vue'),
    children:[
      {
        path: '',
        redirect: (route) => redirectToFirstAllowedChild(route, ['allgemein', 'investment-fonds', 'beteiligung', 'versicherung', 'courtagen', 'mitarbeiter']),
      },
      {
        path: 'allgemein',
        component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/intern/MaklerStatistiken.vue'),
        meta: { 
          bezeichnung: 'TYP_ALLGEMEIN', 
        },
      },
      {
        path: 'investment-fonds',
        component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/intern/MaklerStatistiken.vue'),
        meta: { 
          bezeichnung: 'TYP_FONDS', 
          roles: {
            allowed: [
              [BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]
            ],
          },
        },
      },
      {
        path: 'beteiligung',
        component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/intern/MaklerStatistiken.vue'),
        meta: { 
          bezeichnung: 'TYP_BETEILIGUNGEN', 
          roles: {
            allowed: [
              [BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK_BETEILIGUNG, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_BETEILIGUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS],
            ],
          },
        },
      },
      {
        path: 'versicherung',
        component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/intern/MaklerStatistiken.vue'),
        meta: { 
          bezeichnung: 'TYP_VERSICHERUNG', 
          roles: {
            allowed: [
              [BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]
            ],
          },
        },
      },
      {
        path: 'courtagen',
        component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/intern/MaklerStatistiken.vue'),
        meta: { 
          bezeichnung: 'TYP_COURTAGE', 
          roles: {
            allowed: [
              [BROKER_LEVEL_ROLES.ZUGRIFF_COURTAGE, BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK_INVESTMENT, EMPLOYEE_ROLES.ZUGRIFF_COURTAGE, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_INVESTMENT],
              [BROKER_LEVEL_ROLES.ZUGRIFF_COURTAGE, BROKER_LEVEL_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG, EMPLOYEE_ROLES.ZUGRIFF_COURTAGE, EMPLOYEE_ROLES.ZUGRIFF_STATISTIK_VERSICHERUNG],
            ],
          },
        },
      },
      {
        path: 'mitarbeiter',
        component: () => import(/* webpackChunkName: "makler-statistiken" */ '@/views/intern/MaklerStatistiken.vue'),
        meta: { 
          bezeichnung: 'TYP_MITARBEITER', 
          roles: {
            allowed: [
              [BROKER_LEVEL_ROLES.ZUGRIFF_COURTAGE, ROLES.SHOW_BROKER_STATISTICS_MITARBEITER],
            ],
          },
        },
      },
      {
        path: 'view',
        component: () => import(/* webpackChunkName: "makler-statistiken-view" */ '@/components/brokerData/MaklerStatistikView.vue'),
      }
    ]
  },
  {
    path: 'person/:personId',
    name: 'person',
    component: () => import(/* webpackChunkName: "person" */ '@/components/broker/MaklerAnsprechpartner.vue'),
    props: route => ({ personIdParam: route.params.personId, }),
    meta:{
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
      },
      isPerson: true,
    },
  },
  {
    path: 'settings',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_BROKER],
      }
    },
    component: () => import(/* webpackChunkName: "customer-settings" */ '@/views/core/settings/Settings.vue'),
    children: [
      {
        path: '',
        redirect: (route) => redirectToFirstAllowedChild(route, ['access-management', 'change-2fa-interval'])
      },
      {
        path: 'access-management',
        component: () => import(/* webpackChunkName: "broker-access-management" */ '@/components/settings/BrokerAccessManagement.vue'),
        meta: {
          roles: {
            allowed: [VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          },
        },
      },
      {
        path: 'change-2fa-interval',
        component: () => import(/* webpackChunkName: "change-2fa-interval-config" */ '@/views/core/Change2FaInterval.vue'),
        props: () => ({ showOptionen: false }),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_BROKER_ONLY, ROLES.MULTI_FACTOR_AUTHENTICATION_ACTIVATED]],
          },
        },
      },
      {
        path: 'two-factor-sms-option',
        component: () => import(/* webpackChunkName: "two-factor-sms-option" */ '@/components/settings/TwoFactorSMSOption.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_BROKER_ONLY, ROLES.MULTI_FACTOR_AUTHENTICATION_ACTIVATED]],
            denied: [ROLES.IS_USER_UNTERNR],
          }
        },
      },
    ],
  },
  {
    path: 'ansprechpartner',
    component: () => import(/* webpackChunkName: "ansprechpartner" */ '@/components/broker/BaseMaklerAnsprechpartner.vue'),
    meta:{
      roles: {
        allowed: [[ROLES.SHOW_ANSPRECHPARTNER]],
      },
    },
    children:[
      {
        path: '',
        name: 'ansprechpartner',
        component: () => import(/* webpackChunkName: "ansprechpartner" */ '@/components/broker/MaklerPersonen.vue'),
      },
      {
        path: ':personId',
        component: () => import(/* webpackChunkName: "ansprechpartner" */ '@/components/broker/MaklerAnsprechpartner.vue'),
        props: route => ({ personIdParam: route.params.personId, }),
      }
    ]
  },
  {
    path: 'ansprechpartner-daten',
    name: 'ansprechpartner-daten',
    component: () => import(/* webpackChunkName: "ansprechpartner-daten" */ '@/components/broker/AnsprechpartnerDaten.vue'),
    meta:{
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER_ONLY, ROLES.IS_USER_UNTERNR]],
      },
    },
  },
  {
    path: 'mitarbeiterstruktur',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER]],
      }
    },
    component: () => import(/* webpackChunkName: "mitarbeiterstruktur" */ '@/views/intern/Mitarbeiterstruktur.vue'),
    children:[
    ]
  },  
  {
    path: 'aufsichtsrechtliches',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, ROLES.FA],
          [VIEW_ROLES.VIEW_INTERN, ROLES.FA],
        ]
      }
    },
    component: () => import(/* webpackChunkName: "aufsichtsrechliches" */ '@/views/intern/BaseAufsichtsrechtliches.vue'),
    children: [
      {
        path: 'geldwaeschebelehrungmitarbeiter',
        meta: {
          roles: {
            allowed: [
              [ROLES.FA, VIEW_ROLES.VIEW_BROKER]
            ],
          },
          isMitarbeiter: true,
        },
        component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/intern/Geldwaeschebelehrung.vue'),
        children:[
        ]
      },
      {
        path: 'wag-pruefung',
        meta: {
          roles: {
            allowed: [
              [ROLES.FA, VIEW_ROLES.VIEW_BROKER]
            ]
          }
        },
        component: () => import(/* webpackChunkName: "wagpruefung" */ '@/views/intern/WAGPruefung.vue'),
        children: []
      },
      {
        path: 'geldwaeschebelehrung',
        meta: {
          roles: {
            allowed: [
              [ROLES.FA, VIEW_ROLES.VIEW_BROKER]
            ],
          },
          isMitarbeiter: false,
        },
        component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/intern/Geldwaeschebelehrung.vue'),
        children:[
        ]
      },   
      {
        path: 'persoenliches-geschaeft',
        component: () => import(/* webpackChunkName: "persoenliches-geschaeft" */'@/views/persoenlichesGeschaeft/PesrsoenlichesGeschaeftList.vue'),
        meta: {
          menu: { label: 'Persönliches Geschäft'},
          roles: {
            allowed:[
              [VIEW_ROLES.VIEW_INTERN, ROLES.FA],
              [VIEW_ROLES.VIEW_BROKER, ROLES.VIEW_PERSONLICHES_GESCHAEFT, ROLES.FA],
              [ROLES.IS_USER_UNTERNR, ROLES.VIEW_PERSONLICHES_GESCHAEFT, ROLES.FA],
            ]
          },
        },
      },
      {
        path: 'persoenliches-geschaeft-fragegoben/:id',
        component: () => import(/* webpackChunkName: "persoenliches-geschaeft" */'@/views/persoenlichesGeschaeft/Fragebogen.vue'),
        meta: {
          roles: {
            allowed:
            [
            [VIEW_ROLES.VIEW_INTERN, ROLES.FA],
            [VIEW_ROLES.VIEW_BROKER, ROLES.VIEW_PERSONLICHES_GESCHAEFT, ROLES.FA],
            [ROLES.IS_USER_UNTERNR, ROLES.VIEW_PERSONLICHES_GESCHAEFT, ROLES.FA]
            ]
          },
        },
      },
      {
        path: 'persoenliches-geschaeft-overview',
        component: () => import(/* webpackChunkName: "pesrsoenlichesGeschaeftOverview" */'@/views/persoenlichesGeschaeft/PesrsoenlichesGeschaeftOverview.vue'),
        meta: {
          menu: { label: 'Liste der persönlichen Geschäften'},
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.FA]]
          },
        },
      },
      {
        path:'jahrescheckliste',
        meta: {
          roles:{
            allowed:   [
              [VIEW_ROLES.VIEW_BROKER_ONLY, ROLES.FA,  BROKER_LEVEL_ROLES.ZUGRIFF_JAHRESCHECKLISTE_FA], 
              [VIEW_ROLES.VIEW_BROKER_AS_INTERN, ROLES.FA, BROKER_LEVEL_ROLES.ZUGRIFF_JAHRESCHECKLISTE_FA],
              [ROLES.FA, ROLES.IS_USER_UNTERNR, BROKER_LEVEL_ROLES.ZUGRIFF_JAHRESCHECKLISTE_FA]
            ]
          }
        },
        component: () => import(/* webpackChunkName: "maklerAntraege-maklerAntrag" */ '@/views/maklerAntraege/MaklerAntraege.vue'),
      },  
      {
        path:'jahrescheckliste-auswertung',
        meta: {
          roles:{
            allowed:   [
            [ROLES.FA,VIEW_ROLES.VIEW_INTERN_ONLY]
          ]
          }
        },
        component: () => import(/* webpackChunkName: "maklerAntraegeAuswertung" */ '@/views/maklerAntraege/MaklerAntraegeAuswertung.vue'),
      },
    ]
  },  
  {
    path: 'geldwaeschebelehrungmitarbeiter',
    meta: {
      roles: {
        allowed: [
          [ROLES.ZEIGE_GELDWAESCHE, ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN], 
          [ROLES.ZEIGE_GELDWAESCHE, ROLES.FK, VIEW_ROLES.VIEW_BROKER],
        ],
      },
      isMitarbeiter: true,
    },
    component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/intern/Geldwaeschebelehrung.vue'),
  },
  {
    path: 'datenschutzschulungmitarbeiter',
    meta: {
      roles: {
        allowed: [[ROLES.ZEIGE_DATENSCHUTZSCHULUNG, VIEW_ROLES.VIEW_BROKER_AS_INTERN], [ROLES.ZEIGE_DATENSCHUTZSCHULUNG, VIEW_ROLES.VIEW_BROKER]],
      },
      isMitarbeiter: true,
    },
    component: () => import(/* webpackChunkName: "datenschutzschulung" */ '@/views/intern/Datenschutzschulung.vue'),
    children:[
    ]
  },     
  {
    path: 'geldwaeschebelehrung',
    meta: {
      roles: {
        allowed: [
          [ROLES.ZEIGE_GELDWAESCHE, ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN], 
          [ROLES.ZEIGE_GELDWAESCHE, ROLES.FK, VIEW_ROLES.VIEW_BROKER],
        ],
      },
      isMitarbeiter: false,
    },
    component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/intern/Geldwaeschebelehrung.vue'),
  },
  {
    path: 'datenschutzschulung',
    meta: {
      roles: {
        allowed: [[ROLES.ZEIGE_DATENSCHUTZSCHULUNG], [VIEW_ROLES.VIEW_BROKER_AS_INTERN]],
      },
      isMitarbeiter: false,
    },
    component: () => import(/* webpackChunkName: "datenschutzschulung" */ '@/views/intern/Datenschutzschulung.vue'),
    children:[
    ]
  },

  {
    path: 'email-access',
    component: () => import(/* webpackChunkName: "email-access" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER_AS_INTERN], [VIEW_ROLES.VIEW_BROKER]],
      },
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "email-access" */ '@/views/intern/emailAccess/EMailAccessStepper.vue'),
        children: [
          {
            path: '',
            redirect: 'eingang',
          },
          {
            path: 'eingang',
            component: () => import('@/views/intern/emailAccess/EMailAccess.vue'),
          },
          {
            path: 'ausgang',
            component: () => import('@/views/intern/emailAccess/EMailExitEdit.vue'),
          },
        ],
      },
      {
        path: 'email-ignored-addresses/:id',
        component: () => import(/* webpackChunkName: "email-ignored-addresses" */ '@/views/intern/emailAccess/EMailAccessIgnoredAddresses.vue'),
      },
      {
        path: 'email-server-edit/:id',
        component: () => import(/* webpackChunkName: "email-server-edit" */ '@/views/intern/emailAccess/EMailAccesEdit.vue'),
      },
      {
        path: 'email-ordner/:id',
        component: () => import(/* webpackChunkName: "email-ordner" */ '@/views/intern/emailAccess/EMailAccessOrdnerstruktur.vue'),
      },
    ]
  },

  {
    path: 'expost',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]
        ],
      }
    },
    component: () => import(/* webpackChunkName: "expost" */ '@/views/intern/ExPost.vue'),
    children:[]
  },

  {
    path: 'maklerauftrag',
    component: () => import(/* webpackChunkName: "dynamic-maklerauftrag-view" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [
          [ROLES.FK, VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_DYNAMIC_FORMS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
        ],
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "dynamic-maklerauftrag" */ '@/views/dynamicDocumentList/DynamicFormList.vue'),
      },
      {
        path: ':formId',
        component: () => import(/* webpackChunkName: "dynamic-documents" */ '@/views/dynamicDocumentList/DynamicDocumentList.vue'),
      },
      {
        path: ':formId/:documentId/:version/',
        component: () => import(/* webpackChunkName: "dynamic-documents-view" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            redirect: '1',
          },
          {
            path: ':step',
            component: () => import(/* webpackChunkName: "dynamic-documents-edit" */ '@/components/dynamicDocument/DynamicDocumentRender.vue'),
          },
        ]
      },
    ],
  }, 

  {
    path: 'abrechnung',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
        denied: [ROLES.BLOCKED_ABRECHNUNGEN_STATISTIKEN]
      }
    },
    component: () => import(/* webpackChunkName: "abrechnungen" */ '@/views/intern/Abrechnungen.vue'),
  },
  {
    path: 'abrechnungsliste',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
        denied: [ROLES.BLOCKED_ABRECHNUNGEN_STATISTIKEN]
      }
    },
    component: () => import(/* webpackChunkName: "abrechnungsliste" */ '@/views/intern/Abrechnungsliste.vue'),
  },
  {
    path: 'buchungspositionen',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]],
        denied: [ROLES.BLOCKED_ABRECHNUNGEN_STATISTIKEN]
      }
    },
    component: () => import(/* webpackChunkName: "abrechnungsliste" */ '@/views/intern/Abrechnungsliste.vue'),
  },

  {
    path: 'courtageliste',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN], 
          [VIEW_ROLES.VIEW_BROKER], 
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS],
        ],
      },
    },
    component: () => import(/* webpackChunkName: "courtagetabelleliste" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: route => redirectToFirstAllowedChild(route, ['offene-wertpapiere', 'courtagetabelle-investment', 'courtagetabelle-versicherung']),
      },
      {
        path: 'courtagetabelle-versicherung',
        component: () => import(/* webpackChunkName: "courtagetabelle-versicherung" */ '@/views/intern/courtageliste/CourtagetabelleVersicherung.vue'),
        meta: {
          roles: {
            allowed: [[ROLES.VIEW_COURTAGETABELLE_VERSICHERUNG, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN], [VIEW_ROLES.VIEW_INTERN]],
          },
        },
      },
      {
        path: 'courtagetabelle-investment',
        component: () => import(/* webpackChunkName: "courtagetabelle-investment" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            redirect: () => {
              if(isRolesIncludes([BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS])) {
                return 'offene-wertpapiere';
              } else {
                return 'beteiligungen';
              }
            },
          },
          {
            path: 'offene-wertpapiere',
            component: () => import(/* webpackChunkName: "courtagetabelle-offene-wertpapiere" */ '@/views/intern/courtageliste/CourtagetabelleOffeneWertpapiere.vue'),
            meta: {
              roles: {
                allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
              },
            },
          },
          {
            path: 'beteiligungen',
            component: () => import(/* webpackChunkName: "courtagetabelle-beteiligungen" */ '@/views/intern/courtageliste/CourtagetabelleBeteiligungen.vue'),
            meta: {
              roles: {
                allowed: [BROKER_STRUCTURE_ROLES.ZUGRIFF_GESCHLOSSENE_FONDS],
                denied: [ROLES.FA],
              },
            },
          },

        ]
      },
    ]
  },

  {
    path: 'gdv',
    meta: {
        roles: {
            allowed: [
                [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.FK, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN],
                [ROLES.FA, VIEW_ROLES.VIEW_BROKER_AS_INTERN],
                [VIEW_ROLES.VIEW_INTERN],
            ],
        },
    },
    component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BaseBiPRO.vue'),
    children: [
        {
            path: '',
            redirect: 'gdv-import-monitor',
        },
        {
            path: 'import',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/BaseRouterView.vue'),
            children: [
              {
                  path: '',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/GdvFileImportView.vue'),
              },
              {
                  path: 'zuordnen',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/FileImportZuordnen.vue'),
              },
            ],
        },
        {
            path: 'gdv-import-monitor',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/BaseRouterView.vue'),
            children: [
              {
                  path: '',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/GdvImportMonitor.vue'),
              },
              {
                  path: 'settings',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/GdvImportMonitorSettings.vue'),
              },
            ],
        },
        {
            path: 'gdv-interfaces',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/BaseRouterView.vue'),
            meta: {
                roles: {
                    allowed: [
                        [VIEW_ROLES.VIEW_BROKER_AS_INTERN],
                        [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.RIGHT_ABRECHNUNGIMPORT, BROKER_LEVEL_ROLES.ZUGRIFF_COURTAGE],
                    ],
                },
            },
            children: [
              {
                  path: '',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/InterfaceVerwaltenView.vue'),
              },
              {
                  path: 'gdv-interface',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/InterfaceBearbeitenView.vue'),
              },
            ]
        },
    ]
  },

  {
    path: 'bipro',
    meta: {
        roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.TEST_USER, ROLES.FK],
              [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, "BIPRO_TESTER", ROLES.FK],
              [VIEW_ROLES.VIEW_BROKER, ROLES.IS_FPP_PLUS, ROLES.FK],
              [VIEW_ROLES.VIEW_INTERN],
            ],
            // denied: [ROLES.FA],
        },
        requiresLargeScreen: true,
    },
    component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BaseBiPRO.vue'),
    children: [
        {
            path: 'gdv-files',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/GdvFilesView.vue'),
        },
        {
            path: 'documents',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/BaseRouterView.vue'),
            children: [
              {
                  path: '',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/DocumentsView.vue'),
              },
              {
                  path: 'assign_insurance',
                  component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproAssignInsuranceView.vue'),
              },
            ],
        },
        {
            path: 'exceptions',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproExceptionsView.vue'),
        },
        {
            path: 'download_overview',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproDownloadOverview.vue'),
        },
        {
            path: 'lieferungen',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproLieferungen.vue'),
        },
        {
            path: 'abrechnungen_courtage',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/AbrechnungenCourtageView.vue'),
            meta: {
              roles: {
                allowed: [[EMPLOYEE_ROLES.ZUGRIFF_BIPRO_ABRECHNUNG, BROKER_LEVEL_ROLES.ZUGRIFF_BIPRO_ABRECHNUNG]]
              },
            },
            props: {isCourtage: true},
        },
        {
            path: 'mahnungen',
            component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/AbrechnungenCourtageView.vue'),
            props: {isCourtage: false},
        },
        {
          path: 'categories',
          component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproCategories.vue'),
        },   
        {
          path: 'biProGesellschaftZuordnen',
          component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproGesellschaftZuordnen.vue'),
          // children: [
          //   {
          //     path: '',
          //     redirect: 'gesellschat-hinzufuegen',
          //   },
          //   {
          //     path: 'gesellschat-hinzufuegen',
          //     meta: {
          //       roles: {
          //         allowed: [
          //           [[VIEW_ROLES.VIEW_INTERN_ONLY]]
          //         ]
          //       },
          //     },
          //     component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproGesellschaftHinzufuegen.vue'),
          //   },            
          // ],
        },         
      ]
    },
    {
      path: 'gesellschaft-hinzufuegen',
      meta: {
        roles: {
          allowed: [
            [VIEW_ROLES.VIEW_INTERN]
          ]
        },
      },
      component: () => import(/* webpackChunkName: "bipro" */ '@/views/bipro/BiproGesellschaftHinzufuegen.vue'),
    },     
    {
      path: 'wirtschaftspruefung',
      meta: {
        roles: {
          allowed: [
            [ROLES.FK, VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS],
          ]
        },
      },
      component: () => import(/* webpackChunkName: "base-configs" */ '@/views/BaseRouterView.vue'),
      children: [
        {
          path: 'vertrag-wirtschaftspruefung',
          meta: {
            roles: {
              allowed: [
                [ROLES.FK, VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]
              ]
            },
          },
          component: () => import(/* webpackChunkName: "vertrag-wirtschaftspruefung" */ '@/views/intern/VertragWirtschaftspruefung.vue'),
        },
        {
          path: 'informationen-pruefbericht',
          meta: {
            roles: {
              allowed: [
                [ROLES.FK, VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]
              ]
            },
          },
          component: () => import(/* webpackChunkName: "informationen-pruefbericht" */ '@/views/intern/InformationenPruefbericht.vue'),
        },
      ]
    },
  {
    path: 'ansprechpartner-facsimile',
    meta: {
      roles: {
        allowed: [ROLES.IS_USER_UNTERNR]
      },
    },
    component: () => import(/* webpackChunkName: "abrechnungen" */ '@/views/brokerData/FacsimilePage.vue'),
  },
  {
    path: 'pinlist',
    meta: {
      roles: {
        allowed:  [[ROLES.SHOW_PIN_LIST]],
      },
    },
    component: () => import(/* webpackChunkName: "pinlist" */ '@/views/brokerData/TempFilePINs.vue'),
  },
  {
    path: 'mydepot',
    meta: {
      roles: {
        allowed:  [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS], 
        ]
      },
    },
    component: () => import(/* webpackChunkName: "mydepot" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "mydepot-list" */ '@/views/brokerData/MyDepotList.vue'),
      },
      {
        path: 'wertpapiere/:musterdepotId',
        component: () => import(/* webpackChunkName: "mydepot-wertpapiere" */ '@/views/brokerData/MyDepotWertpapiere.vue'),
      },
      {
        meta: {modus: 'musterdepot'},
        path: 'wertpapiere/:musterdepotId/search',
        component: () => import(/* webpackChunkName: "mydepot-wertpapiere-search" */ '@/views/brokerData/FavoritenlistenFondsfinder.vue'),
      },
    ]
  },
  {
    path: 'fondsfavoritenliste',
    meta: {
      roles: {
        allowed:  [
          [VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_FAVORITENLISTE, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS], 
        ]
      },
    },
    component: () => import(/* webpackChunkName: "fondsfavoritenliste" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "fondsfavoritenliste" */ '@/views/brokerData/FondsFavoritenliste.vue'),
      },
      {
        path: 'wertpapiere/:favoritenlisteId',
        component: () => import(/* webpackChunkName: "fondsfavoritenliste" */ '@/views/brokerData/FondsFavoritenlisteWertpapiere.vue'),
      },
      {
        path: 'wertpapiere/search/:favoritenlisteId',
        component: () => import(/* webpackChunkName: "fondsfavoritenliste" */ '@/views/brokerData/FavoritenlistenFondsfinder.vue'),
      },
    ],
  },
  {
    path: 'berichte',
    meta: {
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER], [VIEW_ROLES.VIEW_INTERN]]
      },
    },
    component: () => import(/* webpackChunkName: "intern-berichte" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'risikopruefung-ergebnis',
      },
      {
        path: 'risikopruefung-ergebnis',
        component: () => import(/* webpackChunkName: "intern-berichte" */ '@/views/berichte/RisikopruefungErgebnis.vue'),
        meta: {
          roles: {
            allowed: [[VIEW_ROLES.VIEW_BROKER, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]]
          },          
        },
      },
      {
        path: 'status-ffb-video-idents',
        component: () => import(/* webpackChunkName: "intern-berichte" */ '@/views/berichte/StatusFFBVideoIdent.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },         
        },
      },
      {
        path: 'steuer-daten',
        component: () => import(/* webpackChunkName: "intern-berichte" */ '@/views/berichte/SteuerlicheDaten.vue'),
        meta: {
          roles: {
            allowed: [
              [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]
            ]
          },         
        },
      },
      {
        path: 'steuer-verlust',
        component: () => import(/* webpackChunkName: "intern-berichte" */ '@/views/berichte/SteuerVerlustVerrechnung.vue'),
        meta: {
          roles: {
            allowed:
              [[VIEW_ROLES.VIEW_BROKER]]
          },         
        },
      },
    ]
  },
  {
    path: 'fondsantragcheck',
    meta: {
      roles: {
        allowed: [
          [ROLES.VIEW_ANTRAG_CHECK, BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS, BROKER_LEVEL_ROLES.ZUGRIFF_KUNDEN, EMPLOYEE_ROLES.ZUGRIFF_KUNDEN]
        ],
      },
      isMitarbeiter: true,
    },
    component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/fondsantragcheck/FondsAntragCheck'),
    children:[
    ]
  },
  {
    path: 'marktberichtUploaden',
    component: () => import(/* webpackChunkName: "MarktbereichtUploaden" */'@/views/berichte/MarktberichtUploaden.vue'),
    meta: {
      modus: 'UPLOAD_MODUS',
      roles: {
        allowed:
          [[VIEW_ROLES.VIEW_BROKER, ROLES.SHOW_MARKTBERICHT]]
      },
    },
  },
  {
    path: 'nafi-zugang',
    component: () => import(/* webpackChunkName: "nafi-zugang" */'@/views/nafi/NafiZugang.vue'),
    meta: {
      roles: {
        allowed:
          [
            [ROLES.IS_BYPASS_SLASH, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
            [VIEW_ROLES.VIEW_BROKER_AS_INTERN, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
            [VIEW_ROLES.VIEW_BROKER_AS_BYPASS, ROLES.IS_FPP_PLUS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
          ]
      },
    },
    children: [
      {
        path: '',
        redirect: 'maklerdaten',
      },
      {
        path: 'maklerdaten',
        component: () => import(/* webpackChunkName: "maklerdaten" */'@/views/nafi/NafiMaklerdaten.vue'),
      },
      {
        path: 'anwenderdaten',
        component: () => import(/* webpackChunkName: "anwenderdaten" */'@/views/nafi/NafiAnwenderdaten.vue'),
      },
      {
        path: 'zugangsdaten',
        component: () => import(/* webpackChunkName: "zugangsdaten" */'@/views/nafi/NafiZugangsdaten.vue'),
      },
    ]
  },
  
  {
    path: 'mrmoney',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.IS_MAKLER_EARLY_ACCESS], 
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN, ROLES.IS_FPP_PLUS, ROLES.FK],
          [VIEW_ROLES.VIEW_INTERN_ONLY] // Makler Early Access OR Intern
        ],
      }
    },
    component: () => import(/* webpackChunkName: "mrmoney" */ '@/views/mrmoney/BaseMrMoney.vue'),
    children:[
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "mrmoney" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            component: () => import(/* webpackChunkName: "mrmoney" */ '@/views/intern/MrMoneyPdfBox.vue'),
          },          
          {
              path: 'assign_insurance',
              component: () => import(/* webpackChunkName: "mrmoney" */ '@/views/mrmoney/MrMoneyAssignInsuranceView.vue'),
          },
        ],
    },      
      
    ]
  }, 

  {
    path: 'vublock',
    meta: {
      roles: {
        allowed: [
          [ROLES.FK, VIEW_ROLES.VIEW_BROKER_AS_INTERN],
          [ROLES.FK, VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN]
        ],
      }
    },
    component: () => import(/* webpackChunkName: "vuBlock" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        redirect: 'list',
      },
      {
        path: 'list',
        component: () => import(/* webpackChunkName: "vuBlock" */ '@/views/intern/VersUebertragBlock.vue'),
      },
      {
        path: 'bearbeiten/:vuBlockId',
        component: () => import(/* webpackChunkName: "vuBlock" */ '@/views/intern/VersUebertragBlockBearbeiten.vue'),
      },
    ]
  },

  {
    path: 'infobriefe',
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_BROKER]],
      }
    },
    component: () => import(/* webpackChunkName: "infobriefe" */ '@/views/intern/Infobriefe.vue'),
    children:[
    ]
  },
  {
    path:'mustervorlagen',
    meta:{
      roles:{
        allowed:[VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
        denied: [ROLES.FA]
      }
    },
    component: ()=> import(/* webpackChunkName: "mustervorlage" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        component: ()=> import(/* webpackChunkName: "mustervorlage" */ '@/views/intern/Mustervorlage.vue'),
      },
      {
        path:'subitems',
        roles:{
          allowed:[VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          denied: [ROLES.FA]
        },
        component: () => import(/* webpackChunkName: "mustervorlage" */ '@/views/intern/MustervorlageSubitems.vue'),
      },
      {
        path:'subitem',
        roles:{
          allowed:[VIEW_ROLES.VIEW_BROKER,VIEW_ROLES.VIEW_BROKER_AS_BYPASS],
          denied: [ROLES.FA]
        },
        component: () => import(/* webpackChunkName: "mustervorlage" */'@/components/antrag/Mustervorlage.vue'),
      },
    ],
  },
  {
    path: 'internestatistiken',
    meta:{
      roles:{
        allowed:[VIEW_ROLES.VIEW_INTERN_ONLY]
      }
    },
    component: () => import(/* webpackChunkName: "interneStatistiken" */ '@/views/BaseRouterView.vue'),
    children: [
      {
        path: '',
        name: 'internestatistiken',
        component: () => import(/* webpackChunkName: "interneStatistiken" */ '@/views/intern/InterneStatistiken.vue'),
      },
      {
        path: 'brokerselect',
        component: () => import(/* webpackChunkName: "interneStatistikenBrokerSearch" */ '@/views/intern/InterneStatistikenBrokerSelect.vue'),
      },
      {
        path: 'view',
        component: () => import(/* webpackChunkName: "interneStatistiken-view" */ '@/components/brokerData/MaklerStatistikView.vue'),
        props: {
          useParentOptionMenu: false,
        },
      }
    ],
  },
  { 
    path: 'versicherungstarife',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN], 
          [VIEW_ROLES.VIEW_BROKER, BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN],
          [VIEW_ROLES.VIEW_INTERN]
        ],
      }
    },   
    component: () => import(/* webpackChunkName: "versicherungstarife" */ '@/views/BaseRouterView.vue'),
    children:[
      {
        path: '',
        component: () => import(/* webpackChunkName: "versicherungstarife" */ '@/views/intern/Versicherungstarife.vue'),
      },
      {
        path: 'tarifBearbeiten',
        component: () => import(/* webpackChunkName: "TarifBearbeiten" */ '@/views/intern/TarifBearbeiten.vue'),
      },
      {
        path: 'vorgabeTarifdaten',
        component: () => import(/* webpackChunkName: "TarifdatenVorgabe" */ '@/views/intern/TarifdatenVorgabe.vue'),
      },
    ]
  },
  // {
  //   path: 'vv',
  //   meta: {
  //     roles: {
  //       allowed:
  //         [[VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.FK]]
  //     },
  //   },
  //   component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/BaseRouterView.vue'),
  //   children: vvRoutesReviewed docs
  // },
  {
    path: 'suitability-test',
    component: () => import(/* webpackChunkName: "suitabilityTest" */ '@/views/BaseRouterView.vue'),
    meta: {
      menu: { label: 'Vermögensverwaltung: Suitability Test' },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.FK],
        ],
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "suitabilityTestList" */'@/components/suitabilityTest/SuitabilityTestList.vue'),
      },
      {
      path: 'mappe/:mappenID',
      component: () => import(/* webpackChunkName: "suitabilityTest" */'@/components/suitabilityTest/SuitabilityTest.vue')
      }
    ]
  },
  {
    path: 'vv-portfolio-list',
    component: () => import(/* webpackChunkName: "vermoegensverwaltung" */ '@/views/BaseRouterView.vue'),
    meta: {
      menu: { label: 'Vermögensverwaltung: Portfolios' },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN_ONLY],
        ],
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "vermoegensverwaltung" */'@/components/vermoegensverwaltung/VVPortfolioList.vue'),
      },
    ]
  },
  {
    path: 'eingegangene-dokumente',
    component: () => import(/* webpackChunkName: "eingegangeneDokmente" */ '@/views/BaseRouterView.vue'),
    meta: {
      menu: { label: 'Eingegangene Dokumente' },
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN_ONLY, ROLES.FA],
        ],
      }
    },
    children: [
      {
        path: '',
        component: () => import(/* webpackChunkName: "eingegangeneDokumenteList" */'@/components/suitabilityTest/EingegangeneDokumenteList.vue'),
      },
      {
      path: 'faxeingang/:faxEingangIDs',
      component: () => import(/* webpackChunkName: "eingegangeneDokumentePruefung" */'@/components/suitabilityTest/EingegangenesDokumentPruefung.vue')
      }
    ]
  },
  {
    path: 'build-info',
    meta: {
      roles: {
        allowed: [
          [VIEW_ROLES.VIEW_INTERN_ONLY],
        ],
      },
    },
    component: () => import(/* webpackChunkName: "menu-config" */ '@/views/menu/BuildInfo.vue'),
  },
  {
    path: 'ins_zip_file',
    meta: {
      roles: {
        allowed: [ [ROLES.INS_ZIP_FILE]],
      },
     
    },
    component: () => import(/* webpackChunkName: "geldwaeschebelehrung" */ '@/views/inszipfile/Inszipfile.vue'),
  },
  {
    path: 'infofilme',
    meta: {
      roles: {
        allowed: [VIEW_ROLES.VIEW_INTERN, VIEW_ROLES.VIEW_BROKER],
      },
     
    },
    component: () => import(/* webpackChunkName: "infofilme" */ '@/views/infofilme/Infofilme.vue'),
  },
  {
    path: 'gesellschaften',
    component: () => import(/* webpackChunkName: "gesellschaften" */ '@/views/BaseRouterView.vue'),
    meta: {
      roles: {
        allowed: [[VIEW_ROLES.VIEW_INTERN]],
      },
    },
    children: [
      {
        path: '',
        component:() => import(/* webpackChunkName: "gesellschaft-overview" */ '@/views/gesellschaft/GesellschaftSearch.vue'),
      },
      {
        path: ':gesellId',
        component: () => import(/* webpackChunkName: "gesellschaft" */ '@/views/BaseRouterView.vue'),
        children: [
          {
            path: '',
            redirect: 'daten',
          },
          {
            path: 'daten',
            component:() => import(/* webpackChunkName: "gesell-daten-view" */ '@/views/BaseRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import(/* webpackChunkName: "gesell-daten" */ '@/views/gesellschaft/intern/GesellschaftDaten.vue'),
              },
              {
                path: 'tarifgruppe/:id?',
                component: () => import(/* webpackChunkName: "gesell-tarifgruppe" */ '@/views/gesellschaft/intern/Tarifgruppe.vue'),
              },
            ],
          },
          {
            path: 'ansprechpartnerList',
            component: () => import(/* webpackChunkName: "gesell-apview" */ '@/views/BaseRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import(/* webpackChunkName: "gesell-aplist" */ '@/views/gesellschaft/GesellschaftAnsprechpartner.vue'),
              },
              {
                path: 'ansprechpartner/:personId?',
                component: () => import(/* webpackChunkName: "gesell-ap" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerMain.vue'),
                children: [
                  {
                    path: '',
                    redirect: 'stammdaten'
                  },
                  {
                    path: 'stammdaten',
                    component: () => import(/* webpackChunkName: "gesell-ap-personendaten" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerPersonenDaten.vue')
                  },
                  {
                    path: 'zusatz',
                    component: () => import(/* webpackChunkName: "gesell-ap-zusatz" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerZusatz.vue')
                  },
                  {
                    path: 'vorlieben',
                    component: () => import(/* webpackChunkName: "gesell-ap-vorlieben" */ '@/views/gesellschaft/ansprechpartner/AnsprechpartnerVorlieben.vue'),
                  },
                ]
              },
            ],
          },
          {
            path: 'lesezeichen',
            component: () => import(/* webpackChunkName: "gesell-lese" */ '@/views/gesellschaft/intern/GesellschaftLesezeichen.vue'),
          },
          {
            path: 'einstellungen',
            component: () => import(/* webpackChunkName: "gesell-einstellungen" */ '@/views/gesellschaft/intern/GesellschaftEinstellungen.vue'),
          },
          {
            path: 'log',
            component: () => import(/* webpackChunkName: "gesell-log" */ '@/views/gesellschaft/intern/GesellschaftAenderungslog.vue'),
          },
          {
            path: 'nummern',
            component: () => import(/* webpackChunkName: "gesell-nummern" */ '@/views/gesellschaft/intern/GesellschaftNummern.vue'),
          },
          {
            path: 'mehrfachpos',
            component: () => import(/* webpackChunkName: "gesell-mehrfachpos" */ '@/views/gesellschaft/intern/GesellschaftMehrfachpositionen.vue'),
          },
          {
            path: 'sparten',
            component: () => import(/* webpackChunkName: "gesell-sparten" */ '@/views/gesellschaft/intern/GesellschaftVersicherungssparten.vue'),
          },
          {
            path: 'provision',
            component: () => import(/* webpackChunkName: "gesell-provision" */ '@/views/gesellschaft/intern/GesellschaftProvisionVorgabe.vue'),
          },
          {
            path: 'fondsauftrag',
            component: () => import(/* webpackChunkName: "gesell-fondsauftrag" */ '@/views/gesellschaft/intern/GesellschaftFondsauftrag.vue'),
          },
          {
            path: 'aktivitaeten',
            component: () => import(/* webpackChunkName: "gesell-aktivitaeten" */ '@/views/gesellschaft/intern/GesellschaftAktivitaeten.vue'),
          },
          {
            path: 'kennungen',
            component: () => import(/* webpackChunkName: "gesell-kennungen" */ '@/views/gesellschaft/intern/GesellschaftKennungen.vue'),
          },
          {
            path: 'dokumente',
            component: () => import(/* webpackChunkName: "gesell-dokumente" */ '@/views/gesellschaft/intern/GesellschaftDokumente.vue'),
          },
          {
            path: 'antragsbearbeitung',
            component: () => import(/* webpackChunkName: "gesell-antragsbearbeitung" */ '@/views/gesellschaft/intern/GesellschaftAntragsbearbeitung.vue'),
          },
          {
            path: 'bemerkungen',
            component: () => import(/* webpackChunkName: "gesell-bemerkungen-view" */ '@/views/BaseRouterView.vue'),
            children: [
              {
                path: '',
                component: () => import(/* webpackChunkName: "gesell-bemerkungen" */ '@/views/gesellschaft/intern/GesellschaftBemerkungen.vue'),
              },
              {
                path: 'bemerkung/:commId?',
                component: () => import(/* webpackChunkName: "gesell-bemerkung" */ '@/views/communication/PostfachBemerkung.vue'),
              },
              {
                path: 'besuchsbericht/:id?',
                component: () => import(/* webpackChunkName: "gesell-besuchsbericht" */ '@/views/gesellschaft/intern/Besuchsbericht.vue'),
              },
            ],
          },
         
        ]
      },
    ]
  },
  {
    path: 'exception-log/:id?',
    component: () => import(/* webpackChunkName: "exception-log" */ '@/views/intern/ExceptionLog.vue'),
    meta: {
      roles: {
        allowed:[ VIEW_ROLES.VIEW_INTERN]
      },
    },
  },
]