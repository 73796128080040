<template>
  <div>
    <BaseFormWithStages
      :pageHeaderActions="actions"
      :pageHeaderActionListeners="pageHeaderActionListeners"
      :cardsData="cardsData"
      :loading="loading">
      <template #beforeCards>
        <div class="box__container">
          <InputField
            label="Beschreibung"
            v-model="processForm.description"/>
        </div>
      </template>
      <template #afterCards>
        <div v-if="processDateCreated">
          {{ processDateCreated }}
        </div>
      </template>
      <template #formContent>
        <div>
          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Finanzbedarf</div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Gesamtkapitalbedarf"
                  :value="processVorhabenOverview.gesamtkapitalbedarf"/>
              </div>
            </div>
            
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div class="text-small">
                Kosten
              </div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Kosten"
                  :value="processVorhabenOverview.kosten"/>
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Kaufpreis"
                  :value="processVorhabenOverview.kaufpreis"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Grunderwerbsteuer"
                  :value="processVorhabenOverview.grunderwerbsteuer"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Mobiliarkosten"
                  :value="processVorhabenOverview.mobiliarkosten"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Notargebühren"
                  :value="processVorhabenOverview.notargebuehr"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Sonstige Kosten"
                  :value="processVorhabenOverview.sonstigeKosten"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Maklergebühren"
                  :value="processVorhabenOverview.maklergebuehr"/>
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Modernisierungskosten"
                  :value="processVorhabenOverview.modernisierungKosten"/>
              </div>
              <div class="col-2">
                <CurrencyLabel
                  label="Renovierungskosten"
                  :value="processVorhabenOverview.modernisierungRenovierungsKosten"/>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div class="text-small">
                Finanzierungskosten
              </div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Beratungshonorar"
                  :value="processVorhabenOverview.beratungshonorar"/>
              </div>
            </div>

            <div class="row">
              <div class="col-2">
                <CurrencyLabel
                  label="Beratungshonorar"
                  :value="processVorhabenOverview.beratungshonorar"/>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Eingesetztes Eigenkapital, Vermögen und Drittdarlehen</div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Eingesetztes Kapital"
                  :value="processVorhabenOverview.eingesetztesKapital"/>
              </div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Präferenzen</div>
            </div>
          </div>

          <div class="box__container">
            <div class="box__title process__summary-header">
              <div>Finanzierungswunsch</div>
              <div>
                <CurrencyLabel class="currency_label_right"
                  label="Noch benötigte Darlehen"
                  :value="processVorhabenOverview.nochBenoetigteDarlehen"/>
              </div>
            </div>
          </div>


        </div>
        <div v-if="false">
          <GhostLoading v-if="loading.antraegeList" useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <div v-else>
            <div class="box__container">
              <Table
                rowId="category_index"
                :headers="antragListeHeaders('Anträge')"
                :rows="antragListeRows(formList, 'Anträge')"
                hidePagination
                @click-label="openNewAntrag"
              />
            </div> 
          </div>

          <GhostLoading v-if="loading.init" useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <div v-else class="box__container">
            <Table
              v-if="antragRows.length"
              title="Anträge"
              :headers="antragHeaders"
              :rows="antragRows"
              hidePagination
              @click-antragBezeichnung="openExistingAntrag">
              <template #delete="row">
                <PhTrash class="ml-3 clickable" :size="18" @click="deleteAntraegeConfirmation(row)"/>
              </template>
            </Table>
            <NoData v-else noIcon content="Keine Anträge vorhanden"/>
          </div>
        </div>
      </template>
    </BaseFormWithStages>

    <BaseModal
      ref="submitProcess"
      modalTitle="Vorgang versenden"
      labelButtonConfirm="Fertig"
      :showCancelButton="false">

      <Table
        :headers="submitProcessHeadersTable"
        :rows="submitProcessRowsTable">
      </Table>

    </BaseModal>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import FormUtil from '@/components/beratung/formsMenu/forms-menu.js';
import CORE_TYPES from '@/store/core/types'
import ANTRAG_TYPES from "../../store/antrag/types";
import {sanitize} from '@/helpers/string-helper.js';
import { PageHeaderSimpleAction } from '@/components/core/header-title-navigation/page-header-utils';
import {TextColumn, ActionColumn, ConfirmedAction, SlotColumn} from "@/components/table2/table_util.js";
import BaseFormWithStages from '@/components/base/BaseFormWithStages.vue';
import BaseModal from "@/components/core/BaseModal.vue";
import Table from "@/components/table2/Table.vue";
import PROCESS_TYPES from '@/store/process/types';
import NoData from '@/components/core/NoData.vue';
import dayjs from "dayjs";
import InputField from "@/components/core/forms/InputField.vue";
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import { PhTrash } from 'phosphor-vue';
import CurrencyLabel from "@/components/core/CurrencyLabel.vue";

const FORMULARE_OPTIONS_MENU_ID = '/beratung/formulare';

export default {
  data: function () {
    return {
      FORMULARE_OPTIONS_MENU_ID,
      bemerkung: null,
      lagerstelle: null,
      processForm: {
        description: 'Neu Vorgang',
        processItems: [],
      },
      loading: {
        getSend: false,
        getPdf: false,
        getSign: false,
        close: false,
        open: false,
        send: false,
        pdf: false,
        init: false,
        action: false,
        antraegeList: false,
      },
    };
  },
  components: {
    BaseFormWithStages,
    BaseModal,
    Table,
    NoData,
    InputField,
    GhostLoading,
    PhTrash,
    CurrencyLabel,
  },
  watch: {
    process() {
      Object.assign(this.processForm, this.process)
    },
  },
  computed: {
    ...mapGetters({
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST_PROCESS_FORMULARE,
      addressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      process: PROCESS_TYPES.GETTERS.PROCESS,
      processVorhabenOverview: PROCESS_TYPES.GETTERS.PROCESS_VORHABEN_OVERVIEW,
    }),
    processDateCreated() {
      if (!this.process?.dateCreated) {
        return "";
      }

      return `Erstellungsdatum: ${dayjs(this.process.dateCreated).format('DD.MM.YYYY')}`;
    },
    pageHeaderActionListeners() {
      return {
        'action-SUBMIT_PROCESS' : this.openSubmitProcess,
      }
    },
    actions() {
      return [
        PageHeaderSimpleAction('SUBMIT_PROCESS', 'Vorgang versenden')
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('OPEN_PROCESS_IN_EUROPACE', 'Vorgang in Europace öffnen')
          .withDisabled(() => this.isSomeButtonLoading),
      ]
    },
    cardsData() {
      const cards = [];

      const finanzierung_antragsteller = this.formList.find(item => item.path === 'finanzierung_antragsteller');
      if (finanzierung_antragsteller) {
        const antrag = this.antragRows?.find(antrag => antrag.positionTyp === 'EUROPACE2_FINANZIERUNG_ANTRAGSTELLER');

        const card = {
          key: finanzierung_antragsteller.path,
          description: '',
          label: finanzierung_antragsteller.label,
          onClick: () => {
            if (antrag) {
              this.openExistingAntrag(antrag);
            } else {
              this.openNewAntrag(finanzierung_antragsteller)
            }
          },
          linkText: antrag ? 'anzeigen' : 'neu',
          link: true,
        };

        cards.push(card);
      }

      const finanzierung_immobilie = this.formList.find(item => item.path === 'finanzierung_immobilie');
      if (finanzierung_immobilie) {
        const antrag = this.antragRows?.find(antrag => antrag.positionTyp === 'EUROPACE2_FINANZIERUNG_IMMOBILIE');
        const card = {
          key: finanzierung_immobilie.path,
          description: '',
          label: finanzierung_immobilie.label,
          status: 'warning',
          onClick: () => {
            if (antrag) {
              this.openExistingAntrag(antrag);
            } else {
              this.openNewAntrag(finanzierung_immobilie)
            }
          },
          linkText: antrag ? 'anzeigen' : 'neu',
          link: true,
        };

        cards.push(card);
      }

      const finanzierung_vorhaben = this.formList.find(item => item.path === 'finanzierung_vorhaben');
      if (finanzierung_vorhaben) {
        const antrag = this.antragRows?.find(antrag => antrag.positionTyp === 'EUROPACE2_FINANZIERUNG_VORHABEN');
        const card = {
          key: finanzierung_vorhaben.path,
          description: '',
          label: finanzierung_vorhaben.label,
          status: 'danger',
          onClick: () => {
            if (antrag) {
              this.openExistingAntrag(antrag);
            } else {
              this.openNewAntrag(finanzierung_vorhaben)
            }
          },
          linkText: antrag ? 'anzeigen' : 'neu',
          link: true,
        };

        cards.push(card);
      }

      return cards;
    },
    submitProcessHeadersTable() {
      return {
        center: [
          TextColumn("accountType", "Typ").makeAlwaysVisible(),
          TextColumn("username", "Username"),
        ],
        lockedRight: [ActionColumn('actions', 'Aktionen')],
      }
    },
    submitProcessRowsTable() {
      const sendMessage = `Möchten Sie den Vorgang wirklich an dieses Konto versenden?`
      const actions = [
          ConfirmedAction('SUBMIT_PROCESS','PhPaperPlaneRight','Versenden',sendMessage,'Vorgang versenden','Versenden'),
        ];
      return [
        { accountType: 'Europace2', username: 'test-user', actions }
      ]
    },
    isSomeButtonLoading() {
      return Object.values(this.loading).some(valueLoading => valueLoading)
    },
    selectedLagerstelle() {
      return this.antragList?.formGroups?.find(item => item.id === 'EUROPACE');
    },
    formList() {
      if (!this.selectedLagerstelle) {
        return [];
      }

      return FormUtil.getForms(this.selectedLagerstelle, { processId: this.$route.query.id });
    },
    antragHeaders() {
      return {
        center: [
          TextColumn("antragBezeichnung", "Bezeichnung").makeLink().makeAlwaysVisible(),
          SlotColumn('delete', '').makeAlwaysVisible(),
        ],
      }
    },
    antragRows() {
      return this.processForm?.processItems || []
    },
  },
  methods: {
    openSubmitProcess() {
      this.$refs.submitProcess.open()
    },
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    async save() {
      if (this.processForm.id) {
        await this.$store.dispatch(PROCESS_TYPES.ACTIONS.UPDATE_PROCESS, this.processForm);
      } else {
        const id = await this.$store.dispatch(PROCESS_TYPES.ACTIONS.SAVE_PROCESS, this.processForm);
        if (id) {
          await this.$router.push({ path: this.$route.fullPath, query: { id } });
        }
      }
    },
    resetLoadingState() {
      Object.keys(this.loading).forEach(k => this.loading[k] = false)
    }, 
    async retrieveAntragList() {
      this.loading.antraegeList = true;
      const params = {
        lagerstelle: 'EUROPACE',
      };

      try {
        await this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST_FOR_PROCESS, params);
      } finally {
        this.loading.antraegeList = false
      }
    },
    async loadProcessData() {
      this.$store.commit(PROCESS_TYPES.MUTATIONS.GET_PROCESS_SUCCESS, {});
      const processId = this.$route.query.id;

      if (processId) {
        this.loading.init = true
        try {
          await this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_PROCESS, processId);
          await this.$store.dispatch(PROCESS_TYPES.ACTIONS.GET_PROCESS_VORHABEN_OVERVIEW, processId);
        } finally {
          this.loading.init = false
        }
      }
    },
    loadInitialData() {
      this.loadProcessData();
      this.retrieveAntragList();
    },
    antragListeHeaders(category) {
      return {
        lockedLeft: [
          TextColumn("label", category).makeLink(),
        ],
      }
    },
    antragListeRows(rows, category) {
      return rows.map((row, index) => {
        return {...row, category_index: index + category};
      });
    },
    async openExistingAntrag(row) {
      await this.save();
      const antrag = {
        bezeichnung: row.antragBezeichnung,
        gesellschaft: row.gesellschaft, //'EUROPACE', //row.GesellschaftShort,
        antragsdatenId: row.antragsDatenId,
        hideBeratungsmappepopup: 'true',
        processId: this.$route.query.id || this.process.id,
        antragModus: "",
      }

      const link = FormUtil.getLoadURL(antrag, [this.selectedLagerstelle]);

      if (link) {
        this.$router.push({path: link}).catch(() => {});
      } else {
        this.$router.push({path: '/role-forbidden'});
      }
    },
    async openNewAntrag(row) {
      await this.save();
      const antrag = row.link;
      if (!antrag) {
        return;
      }
      if (!antrag.query) {
        antrag.query = {}
      }

      antrag.query['hideBeratungsmappepopup'] = 'true';
      antrag.query['processId'] = this.$route.query.id || this.process.id;
      this.$router.push(antrag)
    },

    async deleteAntraegeConfirmation(row) {
      try {
        await this.$confirmModal({
          title: 'Antrag löschen?',
          message: `Möchten Sie diesen Antrag löschen?`,
        });
        await this.$store.dispatch(PROCESS_TYPES.ACTIONS.DELETE_PROCESS_ITEM, row.id);
        this.loadProcessData();
      } catch(e) {
        // empty block
      }
    },
  },
  mounted() {
    this.loadInitialData();
  },
  async beforeRouteLeave (to, from, next) {
    this.$store.commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_ANTRAG_LIST_SUCCESS, {});
    await this.save();

    this.$addBreadcrumb({
      label: 'zurück zur Beratungsmappe', 
      to,
      from,
    });

    next()
  },
}
</script>

<style scoped>
.process__summary-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.currency_label_right {
  align-items: end;
}
</style>