var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.parentOptionMenuId,
          defaultMenu: _vm.$appNavigation.parentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Vermittlernummern Bearbeiten"
        }
      }),
      _vm.formData
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: "6a87f26c-3eb8-4874-8e2b-e3742e57f9a1" }
            },
            [
              _c("ComboBox", {
                attrs: {
                  label: "Gesellschaft",
                  values: _vm.gesellschaften,
                  disabled: _vm.gesellschaftDisabled
                },
                model: {
                  value: _vm.formData.gesellschaftId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "gesellschaftId", $$v)
                  },
                  expression: "formData.gesellschaftId"
                }
              }),
              _c("ComboBox", {
                attrs: {
                  label: "Zweigstelle",
                  values: _vm.formData.zweigstelleValues
                },
                model: {
                  value: _vm.formData.comboZweigstelle,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "comboZweigstelle", $$v)
                  },
                  expression: "formData.comboZweigstelle"
                }
              }),
              _c("InputField", {
                attrs: { label: "Fremdnummer" },
                model: {
                  value: _vm.formData.itemNummer,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "itemNummer", $$v)
                  },
                  expression: "formData.itemNummer"
                }
              }),
              _vm.isFPPlus
                ? _c("ComboBox", {
                    attrs: {
                      label: "Anbindung für",
                      values: _vm.formData.anbindungFurValues,
                      disabled: _vm.isSpecificFPPlusMakler
                    },
                    model: {
                      value: _vm.formData.anbindungFur,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "anbindungFur", $$v)
                      },
                      expression: "formData.anbindungFur"
                    }
                  })
                : _c("InputField", {
                    attrs: { label: "Hauptnummer" },
                    model: {
                      value: _vm.formData.hauptNummer,
                      callback: function($$v) {
                        _vm.$set(_vm.formData, "hauptNummer", $$v)
                      },
                      expression: "formData.hauptNummer"
                    }
                  }),
              _c("DatePickerField", {
                attrs: { isValueAsString: "", label: "mitgeteilt am" },
                model: {
                  value: _vm.formData.mitgeteiltAm,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "mitgeteiltAm", $$v)
                  },
                  expression: "formData.mitgeteiltAm"
                }
              }),
              _c("InputField", {
                attrs: { label: "Bemerkung" },
                model: {
                  value: _vm.formData.bemerkung,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "bemerkung", $$v)
                  },
                  expression: "formData.bemerkung"
                }
              }),
              _c("p", [
                _vm._v(
                  "Bitte verifizieren Sie, dass die Fremdnummer auch wirklich zu diesem Vermittler gehört, da eine falsche Fremdnummer zu Sicherheitsproblemen und Fehlverhalten bei der Abrechnung führen kann."
                )
              ]),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Fremdnummer gilt auch für Untermakler",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.formData.chkVererbung,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "chkVererbung", $$v)
                  },
                  expression: "formData.chkVererbung"
                }
              }),
              _c("ComboBox", {
                attrs: { label: "Anbindungstyp", values: _vm.anbindungstyps },
                model: {
                  value: _vm.formData.anbindungstyp,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "anbindungstyp", $$v)
                  },
                  expression: "formData.anbindungstyp"
                }
              }),
              _c("InputRadioBoxGroup", {
                attrs: {
                  title: "Externer Direktvertrag",
                  validateUntouched: true,
                  values: _vm.direktvertragOptions,
                  labelClass: "font-bold",
                  disabled: true
                },
                model: {
                  value: _vm.formData.isExternerDirektvertrag,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "isExternerDirektvertrag", $$v)
                  },
                  expression: "formData.isExternerDirektvertrag"
                }
              }),
              _c("InputToggleSwitch", {
                attrs: {
                  label: "Bevorzugte Fremdnummer",
                  inLineLabel: "",
                  suppressValidationMessage: ""
                },
                model: {
                  value: _vm.formData.itemBevorzugt,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "itemBevorzugt", $$v)
                  },
                  expression: "formData.itemBevorzugt"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }