<template>
  <div class="mb-4">
    <OptionMenu :id="$appNavigation.currentOptionMenuId" :defaultMenu="$appNavigation.currentOptionMenu" tid="41b35e89-c184-4cdf-96f0-5360b8441d58" />

    <DragnDropArea hoverText="Datei hier ablegen" @files="openAddDocumentModal($event)" :disabled="draggingChildComponent || !allowVersUpload">

      <CardOverview 
        id="bafefe38-bef2-451c-bfd7-f4afe290ca3a" 
        :columns="columns" 
        :headerActions="headerActionsWithBearbeiten"
        :noPrimaryAction="false"
        :defaultOptionsMenu="customOptionsMenu"
        @executeAction="handleExecuteAction($event)"
        @action-BEARBEITEN="openMainStep()"
        @action-NEW-ACTIVITY="newAppointment()"
        @action-OLD-POLICE-NUMBERS="showOldPoliceNumbers()"
        @action-SCHADEN-MELDEN="createSchaden()"
        @action-EMAIL-KUNDE="emailStart(false)"
        @action-EMAIL-GESELLSCHAFT="emailStart(true)"
        @action-BRIEF-ERSTELLEN="briefStart()"
        @action-KUENDIGUNG-VERSICHERUNGEN="emailCancel()"
        @action-AUFGABE-HINZUFUEGEN="handleAddActivity()"
        @action-VERSICHERUNG-KOPIEREN="versicherungKopierenBestaetigung()"
        @action-DOKUMENTE-HOCHLADEN="dateiHochladen()"
        @action-EXTERNEN-ZUGANG-AUFRUFEN="externenZugangOeffnen()"
        @action-VERS-ADMIN-A="versAdminn('A')"
        @action-VERS-ADMIN-M="versAdminn('M')"  
        @draggingCard="draggingChildComponent=true" 
        @droppedCard="draggingChildComponent=false"
        @remove="onRemoveCard"
        @action="executeGenericAction"   
        >

        <template #title>
          <span v-if="isCustomerOnly">{{ gesellschaftName }}</span>
          <a v-else @click="navigateToGesellschaft()">{{ gesellschaftName }}</a>
        </template>
        <template #subtitle>
          <template v-if="bezeichnung">
            <span class="subtitle-part">{{ bezeichnung }}</span>
            <span v-if="nummer || statusAktuell || showKennzeichen"> | </span>
          </template>
          <template v-if="nummer">
            <span class="subtitle-part">{{ nummer }}</span>
            <span v-if="statusAktuell || showKennzeichen"> | </span>
          </template>
          <template v-if="statusAktuell">
            <span class="subtitle-part"><Pill :label="statusAktuell" :type="statusToPillType[statusAktuell]" /></span>
            <span v-if="showKennzeichen"> | </span>
          </template>
          <template v-if="showKennzeichen">
            <span class="subtitle-part">{{ kennzeichen }}</span>
          </template>
        </template> 

      </CardOverview>
    </DragnDropArea>

    <EmailVersand/>

    <AufgabeBearbeitenModal 
    :bereichId="$route.params.id" 
    :gesellschaftName="gesellschaftName" 
    :nummer="nummer" 
    :bezeichnung="bezeichnung" 
    :bereichText="bereichText"
    ref="modalAdd"></AufgabeBearbeitenModal>

    <AddDocument ref="addDocumentModal" :nodeId="nodeId" :versicherungId="$route.params.id"  @close="goToDownloadArea($route.params.id, true)" />

    <CopyInsuranceConfirmationModal ref="copyInsuranceConfirmationModal" @open-item="openItemSelectedDetail"></CopyInsuranceConfirmationModal>
    <ShowOldPoliceNumbersModal ref="showOldPoliceNumbersModal" @open-item="openItemSelectedDetail"></ShowOldPoliceNumbersModal> 


    <BaseModal
        ref="editModal"
        :modalTitle="modalTitle"
        size="lg"
        :showCancelButton="false"
        @onCloseButton="saveBeforeLeave()"
        :showConfirmButton="false">

        <AllgemeineVertragsdaten  v-if="editComponent=='allgemeine'" ref="allgemeine" :canChangeVertragKundennr="canChangeVertragKundennr" @changeStep="setStepByKey($event)" />
        <WeitereVertragsdetails v-if="editComponent=='weitere-vertragsdetails'"  ref="weitere-vertragsdetails"/>
        <InsuranceDetailSchadensmeldungenTable v-if="editComponent=='schadensmeldungen'" :schadenInfos="detailsData.schadenInfos" />
        <InsuranceAbrechnungDetails v-if="editComponent=='courtage'" :editable="detailsData.insurance.allowNewBuchung"/>
        <!-- <InsuranceZubringercourtage v-if="substepProperty=='zubringercourtage'" :editable="detailsData.insurance.allowEditCourtage"/> -->
        <FahrzeugDatenEdit  v-if="editComponent=='fahrzeugDaten'" :versicherungId="$route.params.id"/>
        <SparteUbersicht v-if="editComponent=='sparteUbersicht'"/>
        <InsuranceDetailDepotPositionsTable  v-if="editComponent=='wertpapiere'" :depotPositions="depotPositions" />


    </BaseModal>
    
    <ExterneZugangAskOptions 
      v-if="askOptions"
      :askOptions="askOptions"
      :confirmButtonLabel="actionLabel"
      :data="data"
      :title="externeZugang.companyLabel"
      @close="resetExterneZugang();"
      @onConfirmButton="askOptionsConfirmed($event); navigateToVersicherungstoolsHome();"/>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import DEPOTPOSITIONS_TYPES from '@/store/depotpositions/types';
import { mapGetters } from 'vuex'
import { PhCheck, PhWarning  } from 'phosphor-vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import DashboardPanel from '@/components/dashboard/DashboardPanel.vue';
import NoData from '@/components/core/NoData.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import FAHRZEUGDATEN_TYPES from '@/store/fahrzeugdaten/types';
import { TabMenu, } from '@/menu/menu-utils';
import { ROLES, VIEW_ROLES } from '@/router/roles.js'
import EmailVersand from "@/components/core/EmailVersand.vue";
import AufgabeBearbeitenModal from '@/views/versicherungen/AufgabeBearbeitenModal.vue'
import CopyInsuranceConfirmationModal from '@/views/versicherungen/CopyInsuranceConfirmationModal.vue'
import ShowOldPoliceNumbersModal from '@/views/versicherungen/ShowOldPoliceNumbersModal.vue'
import AddDocument from '@/views/versicherungen//AddDocument.vue';
import Pill from '@/components/core/Pill.vue';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import AllgemeineVertragsdaten from '@/components/versicherungen/AllgemeineVertragsdaten.vue'
import WeitereVertragsdetails from '@/components/versicherungen/WeitereVertragsdetails.vue'
import InsuranceDetailSchadensmeldungenTable from '@/components/versicherungen/InsuranceDetailSchadensmeldungenTable.vue';
import InsuranceAbrechnungDetails from '@/components/versicherungen/InsuranceAbrechnungDetails.vue'
import InsuranceZubringercourtage from '@/components/versicherungen/InsuranceZubringercourtage.vue'
import FahrzeugDatenEdit from '@/views/versicherungen/FahrzeugDatenEdit.vue';
import SparteUbersicht from '@/components/versicherungen/SparteUbersicht.vue'
import InsuranceDetailDepotPositionsTable from '@/components/versicherungen/InsuranceDetailDepotPositionsTable.vue';
import CardsOrganizer  from "@/components/core/CardsOrganizer.vue";
import CardOverview  from "@/components/core/CardOverview.vue";

import ExterneZugangAskOptions from '@/components/externeZugang/ExterneZugangAskOptions.vue'
import vergleichsrechnerMixin from '@/components/externeZugang/vergleichsrechner-mixin';

export default {
  props: {
  },
  
  mixins: [vergleichsrechnerMixin, InsuranceDetailMixin, InteractiveHelpCommonsMixin],
  validators: {},

  data: function () {

    return {
      showAdditional: false,
      tableData: {
        headers: {},
        records: []
      },
      selectedRowsValues: [],
      comboboxValues: [{ value: 'MASONRY', label: 'Masonry' }, { value: '3COLUMNS', label: '3 Spalten' }],
      dashboardLayout: '3COLUMNS',
      editComponent: null,
      substepProperty: null,
      modalTitle: null, 
      draggingChildComponent: false,
    }
  },
  computed: {
    ...mapGetters({
      isFA: CORE_TYPES.GETTERS.IS_FA,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      depotPositions: VERSICHERUNG_TYPES.GETTERS.DEPOT_POSITIONS,
      isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
      isCustomerOnly: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
      isTest: CORE_TYPES.GETTERS.IS_TEST,
    }),
    hasImportantBemerkungen() {
      return (this.info?.steckBreifZusatz?.importantBemerkungen || []).length > 0;
    },
    hasInfo() {
      return !!this.info?.customerID;
    },
    columns: {
      get() {
        return [
        [
          { 
            id: 1, 
            visible: true, 
            removable: true,
            title: 'Basisdaten', 
            key: 'allgemeine', 
            component: () => import('@/views/versicherungen/cards/AllgemeineVertragsdaten.vue'),  
            actions: [
              {
                legend: {
                  icon: 'PhList',
                  key: 'EDIT_COLUMNS',
                  label: 'Zeilen anpassen',
                },
                visible: () => true,
              },
            ],            
          },
        ],
        [
          { id: 2, 
            visible: true,
            title: this.getTitle('weitere-vertragsdetails'), 
            key: 'weitere-vertragsdetails', 
            component: () => import('@/views/versicherungen/cards/WeitereVertragsdetails.vue'), 
            actions: [
              {
                legend: {
                  icon: 'PhList',
                  key: 'EDIT_COLUMNS',
                  label: 'Zeilen anpassen',
                },
                visible: () => true,
              },
            ],              
          }
        ],        
        [
          { id: 3, visible: true,title: this.getTitle('schadensmeldungen'), key: 'schadensmeldungen', component: () => import('@/views/versicherungen/cards/Schadensmeldungen.vue'), }, 
          { id: 4, visible: this.computeVisibility('wertpapiere'), title: 'Wertpapiere', key: 'wertpapiere', component: () => import('@/views/versicherungen/cards/Wertpapiere.vue'), },
          { id: 5, visible: this.eigebundeneWertpapiere?.length || this.ruckkaufswerte?.rows?.length, title: 'Fondswerte & Rückkaufswerte', key: 'sparteUbersicht', component: () => import('@/views/versicherungen/cards/EingebundeneWertpapiere.vue'), },
          { id: 6, visible: this.computeVisibility('courtage'), title: 'Courtage', key: 'courtage', component: () => import('@/views/versicherungen/cards/Courtage.vue'), },
          { id: 7, 
            visible: this.computeVisibility('fahrzeugDaten'), 
            title: 'Fahrzeugdaten', key: 'fahrzeugDaten', 
            component: () => import('@/views/versicherungen/cards/FahrzeugDaten.vue'),
            actions: [
              {
                legend: {
                  icon: 'PhList',
                  key: 'EDIT_COLUMNS',
                  label: 'Zeilen anpassen',
                },
                visible: () => true,
              },
            ],             
          }
        ],
      ];  
      },
      set(newValue) {
        return newValue;
      }

    }, 
    dashboardData() {
      const data = {
        widgets: [
          {
            name: 'allgemeine',
            title: 'Basisdaten',
            component: () => import('@/views/versicherungen/cards/AllgemeineVertragsdaten.vue'),
            loading: () => !this.versicherungenDetails?.insurance?.id,
            isNavigable: this.isViewCustomerAsBypass,
            overrideDefaultAction: !this.isViewCustomerAsBypass,
            actions: [
              {
                legend: {
                  icon: 'PhList',
                  key: 'EDIT_COLUMNS',
                  label: 'Zeilen anpassen',
                },
                visible: () => this.hasRoles(ROLES.EDIT_VERSICHERUNGEN_FIELDS),
              },
            ],
          },
          {
            name: 'weitere-vertragsdetails',
            title: this.getLabel,
            component: () => import('@/views/versicherungen/cards/WeitereVertragsdetails.vue'),
            loading: () => !this.versicherungenDetails?.insurance?.id,
            isNavigable: this.isViewCustomerAsBypass,
            overrideDefaultAction: !this.isViewCustomerAsBypass,
            actions: [
              {
                legend: {
                  icon: 'PhList',
                  key: 'EDIT_COLUMNS',
                  label: 'Zeilen anpassen',
                },
                visible: () => this.hasRoles(ROLES.EDIT_VERSICHERUNGEN_FIELDS),
              },
            ],
          },
          {
            name: 'schadensmeldungen',
            title: this.isVorsorgeOrGesundheight ? 'Leistungsübersicht' : 'Schadensübersicht',
            component: () => import('@/views/versicherungen/cards/Schadensmeldungen.vue'),
            loading: () => !this.versicherungenDetails?.insurance?.id,
            isNavigable: this.isViewCustomerAsBypass,
            overrideDefaultAction: !this.isViewCustomerAsBypass,
          },
        ],
      };

      if (this.hasDepotPositions && !this.shouldHideWertpapiere) {
        data.widgets.push(

        {
            name: 'wertpapiere',
            title: 'Wertpapiere',
            component: () => import('@/views/versicherungen/cards/Wertpapiere.vue'),
            visible: () => true,
            loading: () => !this.versicherungenDetails?.insurance?.id,
            isNavigable: this.isViewCustomerAsBypass,
            overrideDefaultAction: !this.isViewCustomerAsBypass,
          },

        );
      }      

      if (this.eigebundeneWertpapiere?.length  || this.ruckkaufswerte?.rows?.length) {
        data.widgets.push(
  
        {
            name: 'sparteUbersicht',
            title: 'Fondswerte & Rückkaufswerte',
            component: () => import('@/views/versicherungen/cards/EingebundeneWertpapiere.vue'),
            loading: () => !this.versicherungenDetails?.insurance?.id,
            isNavigable: this.isViewCustomerAsBypass,
            overrideDefaultAction: !this.isViewCustomerAsBypass,
          },
  
        );
      }

      if (this.canViewCourtageStep) {
        data.widgets.push(
        {
          name: 'courtage',
          title: 'Courtage',
          component: () => import('@/views/versicherungen/cards/Courtage.vue'),
          loading: () => !this.versicherungenDetails?.insurance?.id,
          isNavigable: this.isViewCustomerAsBypass,
          overrideDefaultAction: !this.isViewCustomerAsBypass,
        } );
      }

      if (!this.shouldHideFahrzeugDatenStep) {
        data.widgets.push(
        {
          name: 'fahrzeugDaten',
          title: 'Fahrzeugdaten',
          component: () => import('@/views/versicherungen/cards/FahrzeugDaten.vue'),
          loading: () => !this.versicherungenDetails?.insurance?.id,
          isNavigable: this.isViewCustomerAsBypass,
          overrideDefaultAction: !this.isViewCustomerAsBypass,
          actions: [
              {
                legend: {
                  icon: 'PhList',
                  key: 'EDIT_COLUMNS',
                  label: 'Zeilen anpassen',
                },
                visible: () => this.hasRoles(ROLES.EDIT_VERSICHERUNGEN_FIELDS),
              },
            ],
        } );
      }

      return data;
    },
    versicherungId() {
      return this.$route.params.id;
    },    
    hasDepotPositions() {
      return this.depotPositions?.records?.length;
    },
    customOptionsMenu() {
      if (this.versicherungId) {
        const { isCustomerOnly } = this;
        const isDeeplinkAvailable = this.versicherungenDetails?.insurance?.isDeeplinkAvailable ?? false;

        return [
          TabMenu('/home/versicherungen/insurance-group/overview/'+this.versicherungId+'?fromOverview=true', 'Überblick'),
          !isCustomerOnly 
            ? TabMenu('/home/versicherungen/insurance-group/insurancePostfach/'+this.versicherungId+'?fromOverview=true', 'E-Mails & Aufgaben')
            : null,
          TabMenu('/home/versicherungen/insurance-group/dokumente/'+this.versicherungId+'?fromOverview=true', 'Dokumente'),
          TabMenu('/home/versicherungen/insurance-group/anmerkungen/'+this.versicherungId+'?fromOverview=true', 'Anmerkungen'),
          isDeeplinkAvailable 
            ? TabMenu(`/home/versicherungen/insurance-group/deeplinks/${this.versicherungId}?fromOverview=true`, 'Versicherungsportal')
            : null,
        ].filter(tab => !!tab);
      }
      return [];
    },
    isMasonry() {
      //return this.dashboardLayout === 'MASONRY';
      return false;
    },
    is3Columns() {
      return this.dashboardLayout === '3COLUMNS';
    }
 
  },
	mounted: async function () {
    await this.retrievetAllData().then(() => {} );
  },

  beforeRouteLeave (to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zum Überblick', 
      to,
      from,
    });

    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.NODE_ID, {nodeId: this.nodeId});

    next()
  },
  beforeDestroy() {
    this.$store.commit(VERSICHERUNG_TYPES.MUTATIONS.GET_FAHRZEUGDATEN_LIST_SUCCESS, [])
  },
  methods: {
    onRemoveCard(card) {
      this.columns.find(c => c.key == card.key);
      
    },
    getTitle(cardKey) {
      if (cardKey === 'weitere-vertragsdetails') {
        return this.getLabel;
      } else if (cardKey === 'schadensmeldungen') {
        return this.isVorsorgeOrGesundheight ? 'Leistungsübersicht' : 'Schadensübersicht';
      }
      return cardKey; 
    },    
    computeVisibility(cardKey) {
      switch (cardKey) {
        case "courtage":
          return this.canViewCourtageStep;       
        case "fahrzeugDaten":
          return !this.shouldHideFahrzeugDatenStep;
        case "wertpapiere":
          return this.hasDepotPositions && !this.shouldHideWertpapiere;                            
        default:
          return true;
      }

    },    
    async retrievetAllData() {

      this.$store.commit(FAHRZEUGDATEN_TYPES.MUTATIONS.CLEAN_FAHRZEUGDATEN_EDIT)

      return await Promise.all([
        this.retrieveInsuranceDetails(),
        this.retrieveDepotPositions(),
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_FAHRZEUGDATEN_LIST, {vertragId: this.versicherungId}),
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN, {vertragId: this.versicherungId}), 
        this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_CUSTOMER_DATA_CONFIG),
      ]);
    },
    retrieveInsuranceDetails() {
      return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.RETRIEVE_INSURANCE_DETAILS, {
        versVertrag: this.$route.params.id,
      });
    },
    retrieveDepotPositions() {
      return this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.GET_DEPOT_POSITIONS, {
        vertragsNr: this.$route.params.id,
      });
    },  
    openMainStep() {
      this.$router.push(`/home/versicherungen/insurance-group/details/${this.versicherungId}?stepKey=allgemeine`);
    },
    handleExecuteAction(actionData) {
        if (actionData.action?.legend?.key === "EDIT_COLUMNS") {
            actionData.component.$refs.table.openColumnsConfig();
        } else {

            if (actionData.action?.action === "BEARBEITEN_APP") {

              this.editComponent = actionData?.widget?.mainStep || actionData?.widget?.name;

              if (actionData?.widget?.mainStep) {
                this.substepProperty = actionData?.widget?.name;
              } else {
                this.substepProperty = null;
              }

              this.modalTitle = actionData?.widget?.title;
              this.selectedStepKey = this.editComponent = actionData?.widget?.mainStep || actionData?.widget?.name;
              this.setStepByKey(this.selectedStepKey);

              if (this.substepProperty) {
                this.setSubstepByKey(this.substepProperty);
              }

              this.$refs.editModal.open();              

            } else {
              this.$router.push(`/home/versicherungen/insurance-group/details/${this.versicherungId}?stepKey=${actionData?.widget?.name}`);
            }

        }
    },
    openDepot(){
      this.$router.push({name: 'vermogensubersicht-depot', params: {depotId: this.versicherungenDetails?.insurance?.depotId}})
      
    }   
  },

  filters: {
    currency(value, numberFixed = 2) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' € ';
    },
    number(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.'); // + '%';
    },
    percentage(value, numberFixed = 0) {
      if (isNaN(value)) {
        return value;
      }
      let val = (value/1).toFixed(numberFixed).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.') + ' % ';
    },
  },

  components: {
    PhCheck,
    PhWarning,
    CurrencyLabel,
    OptionMenu,
    DashboardPanel,
    NoData,
    EmailVersand,
    AufgabeBearbeitenModal,
    CopyInsuranceConfirmationModal,
    ShowOldPoliceNumbersModal,
    AddDocument,
    Pill,
    DragnDropArea,
    ComboBox,
    BaseModal,
    AllgemeineVertragsdaten,
    WeitereVertragsdetails,
    InsuranceDetailSchadensmeldungenTable,
    InsuranceAbrechnungDetails,
    InsuranceZubringercourtage,
    FahrzeugDatenEdit,
    SparteUbersicht,
    InsuranceDetailDepotPositionsTable,
    CardsOrganizer,
    CardOverview,
    ExterneZugangAskOptions,
    
  },
}
</script>

<style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>

<!-- <style src='./InsuranceOverview.scss' lang='scss' scoped></style> -->
