<template>
  <div class="cards__container">
    <div class="box__container cards__item">
      <div class="cards__items">
        <BaseCollapsable :showSection="showExpanded">
          <template v-slot:title>
            <div class="cards__item--header">
              <div class="box__title cards__item--header-title">
                <span><a>{{ position.fondsname }}</a></span>
                <span class="text-divider">|</span>
                <span>SRI {{ position.sri }}</span>
                <span class="text-divider">|</span>
                <span :class="getColorClass(position)">{{ position.art }}</span>
              </div>
              <div class="cards__item--header-actions">
                <a @click="openModalDeleteFond(position)" title="Position löschen">
                  <span class="cards__item--header-action--item-icon">
                    <PhTrash :size="16" />
                  </span>
                </a>
              </div>
            </div>
          </template>
          <template v-slot:content>
            <div class="row">
              <InputField class="ml-4 mt-2 mr-3" label="Betrag" isEmbedded type="currency" precision="2"
                :id="'betrag' + position.index" :value="position.sparplanBetrag
                  " @input="onChangeInputField($event, position, 'sparplanBetrag')" :disabled="disabled" />

              <ComboBox label="Abbuchung jeweils zum:" class="ml-3 mt-2 mr-3" isEmbedded
                :id="'beginnTag' + position.index" :value="position.planTag" :values="[
                  { label: '', value: '' },
                  { label: '1.', value: '1' },
                  { label: '15.', value: '15' },
                ]" @input="onChangeInputField($event, position, 'planTag')" :disabled="disabled" />
              <ComboBox v-if="categoryId == 'SPARPLAN'" label="Turnus" class="ml-3 mt-2 mr-3" isEmbedded
                :sortComboboxValues="false" :id="'frequenz' + position.index" :value="position.planFrequenz" :values="[
                  { label: '', value: '' },
                  { label: 'monatlich', value: 'MONATLICH' },
                  { label: 'vierteljährlich', value: 'VIERTELJ' },
                ]" @input="onChangeInputField($event, position, 'planFrequenz')" :disabled="disabled" />
            </div>

            <div class="row">
              <DatePickerField class="ml-2 mt-2 mr-2" monthPicker dateFormat="MM.YYYY" label="Abbuchungstermin Sparplan"
                v-if="categoryId == 'SPARPLAN'" isEmbedded :id="'beginnMonat' + position.index"
                :value="position.sparplanBeginnDatum" @input="
                  onChangeInputField($event, position, 'sparplanBeginnDatum')
                  " :disabled="disabled" />
              <DatePickerField class="ml-2 mt-2 mr-2" monthPicker dateFormat="MM.YYYY" label="Letzte Ausführung"
                v-if="categoryId == 'SPARPLAN'" isEmbedded :id="'endMonat' + position.index"
                :value="position.sparplanEndDatum" @input="
                  onChangeInputField($event, position, 'sparplanEndDatum')
                  " :disabled="disabled" />
              <ComboBox class="ml-2 mt-2 mr-2" :value="position.modus" :id="'modus' + position.index" :values="values('modus', position)"
                :firstEmpty="true"
                label="Tarif"
                @input="onChangeInputField($event, position, 'modus')" />
            </div>
          </template>
        </BaseCollapsable>
      </div>
    </div>
    <DeletePositionModal ref="deleteModal" :position="position" :positionLabel="position.art"
      @delete="removePosition()">
    </DeletePositionModal>
  </div>
</template>
<script>
import antragMixin from "@/mixins/antrag/antrag-mixin.js";
import mixin from "@/mixins/wertpapierorder/mixin.js";
import InputField from "@/components/core/forms/InputField.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import ANTRAG_TYPES from "@/store/antrag/types";
import BaseCollapsable from "@/components/core/BaseCollapsable.vue";
import FormLabel from "@/components/core/forms/FormLabel.vue";
import InputRadioBoxGroup from "@/components/core/forms/radiobox/InputRadioBoxGroup.vue";
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";
import DeletePositionModal from "@/components/core/forms/antraege/wertpapierAuswahl/DeletePositionModal.vue";
import { PhTrash } from "phosphor-vue";
export default {
  mixins: [mixin, antragMixin],
  data() {
    return {
      showExpanded: true,
      positionToDelete: null,
      dynamik: null,
      planFrequenz: 'MONATLICH',
      planTag: '1',
      modus:'1'
    };
  },
  components: {
    InputField,
    BaseCollapsable,
    FormLabel,
    InputRadioBoxGroup,
    DatePickerField,
    ComboBox,
    DeletePositionModal,
    PhTrash,
  },
  props: {
    position: {},
    antragId: {},
    positionDataAdditional: {},
    isWebservice: {
      type: Boolean,
      default: false
    },
    categoryId: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    config: {},
  },
  methods: {
    commitUpdatePositionsFromEvent(positionChange, isin, category) {
      const payload = {
        id: this.antragId,
        categoryId: category,
        fonds: [
          {
            ...positionChange,
            isin,
          },
        ],
      };
      this.$store.commit(ANTRAG_TYPES.MUTATIONS.UPDATE_POSITIONS, payload);
    },
    onChangeInputField($event, position, field) {
      const positionChange = { [field]: $event };
      this.commitUpdatePositionsFromEvent(
        positionChange,
        position.isin,
        position.art
      );
    },
    openModalDeleteFond(fond) {
      this.positionToDelete = fond;
      this.$refs.deleteModal.open();
    },
    removePosition() {
      this.deletePosition(this.antragId, this.position.art, this.position);
    },
    values(id, prop) {
      return id && this.config?.[id]?.values || [];
    }
  },
};
</script>

<style lang="scss" scoped>
.fixed-width {
  margin-left: 20px;
  margin-top: 10px;
  width: 300px;
}

.text-divider {
  font-weight: normal;
  margin: 0 0.25rem;
}

.plannummer-info {
  font-size: small;
  font-style: italic;
}

.cards__container {
  .cards__items {
    .cards__item {
      &.has-warnings {
        border: 1px solid var(--color-danger);
      }

      &.box__container>* {
        margin-bottom: 0.25rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .cards__item--header {
      display: flex;
      justify-content: space-between;

      &.cards__item--header-wrap {
        flex-wrap: wrap;
      }
    }

    .cards__item--header-title {
      display: flex;
      flex-wrap: wrap;
      margin: 0 0.75rem 0 0;
    }

    .cards__item--header-actions {
      display: flex;
      justify-content: flex-end;
      flex: 1 1 auto;
      text-align: right;

      .cards__item--header-action--item-text {
        display: none;
      }
    }

    .cards__item--inputs {
      display: flex;
      margin-left: -0.5rem;
      margin-right: -0.5rem;
      flex-wrap: wrap;

      >* {
        flex: 0 0 140px;
        margin: 0 0.5rem 0.25rem;
        padding: 0;
        width: auto;

        &.cards__item--input-240 {
          flex-basis: 240px;
        }
      }

      .cards__item--input-no-label {
        margin-top: 1.5rem;
      }
    }
  }
}

@media (max-width: 375px) {
  .cards__container {
    .cards__items {
      .cards__item--inputs {
        >* {
          width: calc(50% - 1rem);
          flex-basis: auto;
        }
      }

      .cards__item--header-actions {
        flex-flow: column;

        .text-divider {
          display: none;
        }

        .cards__item--header-action--item-text {
          display: initial;
        }
      }
    }
  }
}

@media (max-width: 335px) {
  .cards__container {
    .cards__items {
      .cards__item--inputs {
        >* {
          width: calc(100% - 1rem);
        }

        .cards__item--input-no-label {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
