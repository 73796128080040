<template>
  <div class="autocomplete">
    <form @submit.prevent="checkZIPAndUpdateValue(zip)" @keydown.tab="selectNext">
      <InputField v-model="zip" :label="label" :disabled="disabled" @input="checkZIPAndUpdateValue(zip)"
        @searchAction="onEnter" @blur="onBlur" :labelClass="labelClass" :validateUntouched="validateUntouched"
        :placeholder="placeholder" :suppressValidationMessage="suppressValidationMessage" :id="id"
        :isEmbedded="isEmbedded" :small="small" :precision="precision" :showConfirmationIcon="showConfirmationIcon"
        :isComponentHalfSize="isComponentHalfSize" :foreign_currency="foreign_currency" :isNotReactive="isNotReactive" />
    </form>
    <div v-show="isOpen" :class="{ 'results-container': true, 'half': isComponentHalfSize }">
      <div class="block">
        <div class="loading" v-if="isLoading">
          <AnimatedSpinner /> Bitte warten...
        </div>
        <template v-else>
          <div v-for="(result, i) in results"
              :key="'zip'+i"
              class="autocomplete-result">
              <a @click="setResult(result), indexSelected = i" :class="{'result-link': true, 'selected': indexSelected === i}">
                {{result.zip}}, {{result.city}}<span v-if="result.state">, {{result.state}}</span><span v-if="result.land">, {{result.land}}</span>
              </a>
          </div>
        </template>
      </div>
    </div>
    <template v-if="this.error.length > 0">
      <div class="input-forms__errors-container" :key="validation.updated">
        <div class="fc-form-danger" :key="error">
          {{ this.error }}
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import axios from 'axios';
import InputField from '@/components/core/forms/InputField.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import validator from '@/mixins/validator';
import ConfigPermissionModal from '@/views/core/configs/configPermission/ConfigPermissionModal.vue';

export default {
  mixins: [validator],
  validators: {
  },
  components: {
    InputField,
    AnimatedSpinner,
  },
  props: {
      value: {
        type: [String, Number, Boolean]
      },
      label: {
          type: String,
      },
      type: {
        type: String
      },
      componentData: {
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      land: {
        type: String,
        default: '',
      },
      labelClass: {
        type: String,
      },
      validateUntouched: {
        type: Boolean,
      },
      placeholder: {
        type: String
      },
      suppressValidationMessage: {
        type: Boolean,
      },
      id: {
        type: String,
      },
      isEmbedded: {
        type: Boolean,
      },
      small: {
        type: Boolean,
      },
      precision: {
        type: [String, Number]
      },
      showConfirmationIcon: {
        type: Boolean,
      },
      isComponentHalfSize: {
        type: Boolean,
      },
      foreign_currency: {
        type: String,
      },
      isNotReactive: {
        type: Boolean,
      },
  },
  data() {
    return {
      modifiedValue: undefined,
      results: [],
      isOpen: false,
      isLoading: false,
      timeoutRef: 0,
      indexSelected: -1,
      prevValue: '',
      error: '',
      knownZipFormat: false,
      isValidZip: true,
    }
  },
  watch: {
    componentData(value) {
      this.zip = value;
    },
    value() {
      this.modifiedValue = undefined;
    },
  },
  computed: {
    zip: {
      get: function() {
        return this.modifiedValue || this.value || '';
      },
      set: function(modifiedValue) {
        this.$emit('input', modifiedValue);
        this.$emit('change', modifiedValue);
        this.modifiedValue = modifiedValue;
        this.error = '';
      }
    },
  },
  methods: {
    checkZIPAndUpdateValue(value) {
      this.timeoutRef && clearTimeout(this.timeoutRef);
      if (!value) return;

      if (value.length < 2) return;

      const newPrevValue = value + "|" + this.land;
      if (this.prevValue == newPrevValue) {
        this.isOpen = true;
        return;
      }

      this.timeoutRef = setTimeout(() => {
        this.isLoading = true;
        axios.get(`${process.env.VUE_APP_API}/QuickSearch/getCity?zip=${value}&land=${this.land}`).then(response => {
          if (response.data) {
            this.results = response.data.map(result => ({
              ...result,
              zip: result.zip.split(",").filter(zip => zip.startsWith(value)).join(","),
            }));
            this.isOpen = true;

            // check valid zip (DE and AT)
             this.checkPLZ(this.land, this.zip);

            // nothing found to select; close dropdown to show the error message
            setTimeout(function () {
              if(response.data.length == 0) {
               this.isOpen = false;
                // unknown zip, but correct format for countries(DE, AT)
                if(this.knownZipFormat && this.isValidZip) {
                  this.error = 'Die Postleitzahl scheint in '+ this.land + ' nicht zu existieren.';
                }
              }
            }.bind(this), 1000)

            if (response.data.text) {
              this.error = response.data.text;
              return;
            }
          } 

          this.isLoading = false;
          this.timeoutRef && clearTimeout(this.timeoutRef);
          this.indexSelected = -1;
          this.prevValue = newPrevValue;
        });
      }, 1000);
    },
    onEnter() {
      if (this.indexSelected >= 0) {
        this.setResult(this.results[this.indexSelected]);
      } else {
        this.checkZIPAndUpdateValue(this.zip);
      }
    },
    selectNext(e) {
      e.preventDefault();
      e.stopPropagation();
      this.indexSelected = (this.indexSelected + 1) % this.results.length;
    },
    setResult(result) {
this.isOpen = false;
      const zipCodes = (result.zip || "").split(",");
      this.zip = zipCodes.find(zip => zip.startsWith(this.zip)) || (zipCodes.length ? zipCodes[0] : this.zip);
      //this.zip = result.zip
      this.$emit('change', this.zip)
      this.$emit('input', this.zip)
      this.$emit('city', result.city)
      this.$emit('country', result.land)
    },
    handleClickOutside(evt) {
      if (!this.$el.contains(evt.target)) {
        this.isOpen = false;
      }
    },
    onBlur() {
      this.$setTouched()
      this.$emit('change', this.zip);
      this.isOpen = false;
    },

    checkPLZ(land, value) {
      var valid = true;
      this.isValidZip = true;
      this.error = '';

      // DE
      if (land && land === 'Deutschland') {
        this.knownZipFormat = true;
        valid = new RegExp('^[0-9]{5}$').test(value);
        if (!valid) {
          this.error = 'Die Postleitzahl muss für '+ land + ' dem Format \'12345\' entsprechen.';
          this.isValidZip = false;
          this.isOpen = false;
        }
      }
      // AT
      else if (land && land === 'Österreich') {
        this.knownZipFormat = true;
        valid = new RegExp('^[0-9]{4}$').test(value);
        if (!valid) {
          this.error = 'Die Postleitzahl muss für '+ land + ' dem Format \'1234\' entsprechen.';
          this.isValidZip = false;
          this.isOpen = false;
        }
      }
      // Alle anderen Länder sind nicht genauer bestimmt 
      else {
        this.knownZipFormat = false;
        this.error = '';
        this.isValidZip = true;
      }
    },
    mounted() {
      document.addEventListener('click', this.handleClickOutside)
    },
    destroyed() {
      document.removeEventListener('click', this.handleClickOutside)
    },
  }
}
</script>
<style lang="scss" scoped>
.autocomplete {
  position: relative;
  width: 100%;
  padding-bottom: 16px;
}

.results-container {
  position: absolute;
  z-index: 99999;
  background: var(--color-box);
  border: rgba(0,0,0,.15);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, .5);
  width: 100%;
}

.results-container.half {
  width: calc(50% - 32px);
}

.results-container-no-results {
  padding-top: 16px;
}

.result-link.selected {
  text-decoration: underline;
}

.block {
  border-top: 1px solid #e5e5e5;
  padding: 4px 16px 16px 16px;
  margin-top: 16px;
}

.autocomplete-results {
  padding: 5px;
  margin: 0;
  border: 1px solid #eeeeee;
  max-height: 400px;
  overflow: auto;
  width: 100%;
  background-color: var(--color-top-nav-background);
  color: var(--color-top-nav-text);
  position: absolute;
  min-width: 420px;
  z-index: 1;
}

.autocomplete-result {
  list-style: none;
  text-align: left;

  /*&:hover {
    // background: #686767;
    // color: white !important;
  }*/
}
</style>