import PROCESS_TYPES from './types';
import axios from 'axios';
import { BASE_AXIOS_CONFIG as config } from '@/configs/http-request-interceptor';
import CORE_TYPES from '@/store/core/types';

export default {

  async [PROCESS_TYPES.ACTIONS.GET_PROCESS_LIST]({ commit, getters }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process`, config);

    if (response.data) {
      commit(PROCESS_TYPES.MUTATIONS.GET_PROCESS_LIST_SUCCESS, response.data);
    }
  },

  async [PROCESS_TYPES.ACTIONS.GET_PROCESS]({ commit, getters }, processId) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process/${processId}`);

    if (response.data) {
      commit(PROCESS_TYPES.MUTATIONS.GET_PROCESS_SUCCESS, response.data);
    }
  },

  async [PROCESS_TYPES.ACTIONS.GET_PROCESS_VORHABEN_OVERVIEW]({ commit, getters }, processId) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process/vorhaben_overview/${processId}`);

    if (response.data) {
      commit(PROCESS_TYPES.MUTATIONS.GET_PROCESS_VORHABEN_OVERVIEW_SUCCESS, response.data);
    }
  },

  async [PROCESS_TYPES.ACTIONS.DELETE_PROCESS]({ getters }, processId) {
    if (!processId) {
      return;
    }

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process/${processId}`, config);
  },

  async [PROCESS_TYPES.ACTIONS.DELETE_PROCESS_ITEM]({ getters }, processItemId) {
    if (!processItemId) {
      return;
    }

    await axios.delete(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process/processItem/${processItemId}`, config);
  },

  async [PROCESS_TYPES.ACTIONS.SAVE_PROCESS]({ getters, commit }, process) {
    if (!process) {
      return;
    }

    const response = await axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process`, process, config);

    if (response.status === 200) {
      commit(PROCESS_TYPES.MUTATIONS.GET_PROCESS_SUCCESS, { ...process, id: response.data });

      return response.data;
    }

    return null;
  },

  async [PROCESS_TYPES.ACTIONS.UPDATE_PROCESS]({ getters }, process) {
    if (!process?.id) {
      return;
    }

    await axios.put(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}/process/${process.id}`, process, config);
  }

}