import BRIDGE_TYPES from './types';
import handlers from './handlers.js'
import Vue from 'vue'
import LOG_TYPES from '@/store/log/types'
import CORE_TYPES from '@/store/core/types'
import WEBRTC_TYPES from '@/store/webrtc/types'
import MENU_TYPES from '@/store/menu/types';

import HTTP_REQ_TYPES from '@/store/http-requests/types';
import { MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT, MESSAGE_CANCEL_REQUEST_CLOSE_INSTANCE } from '@/store/http-requests/actions';

import { COLOR_THEME, MOBILE_NATIVE_CONTEXT } from './index';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { 
  getFilenameFromHeaders, 
  addFileExtension, 
  extractFileExtension, 
  extractMimeType, 
  extractExtensionFromMimeType, 
  decodeURIComponentIfNeeded, 
} from '@/helpers/utils-helper';
import { getRedirectionHtmlString } from '@/components/table/export/utils.js';
import { registerHandlerWeb } from '@/configs/vue-js-bridge';
import { getColorPropsUpdateSettings } from '@/configs/color-config.js'
import router from '@/router';
import store, { generateNewAppId } from '@/store';
import { ResetStateSource } from '@/store/core';
import { isLoginRoute, findLoginNextUrl, findUserId } from '@/router/features-routes/login';
import { isPathAllowed } from '@/router/guards';
import { isSameOrigin } from '@/helpers/utils-helper';
import menuManager from '@/menu/menu-manager';

const SIGNO_USER_AGENT = 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_6) AppleWebKit/605.1.15 (KHTML, like Gecko) Safari SoscompApp';
const SIGNO_SIGN_VIEWER_2_PATH = '/signoSignViewer2/webviewer/';
const SIGNO_CHECK_ERROR_JAVASCRIPT = "(()=>{return false;})()";
	//"(()=>{var title=document.querySelector('title');if(title==null){return true;}else if(title.textContent=='signoSign/mobile - Error'){return true;}else{var loginFailed=document.querySelector('[name=\"de.soscomp.signo.loginfailed\"]');if(loginFailed!=null&&loginFailed.getAttribute('content')=='true'){return true;}else{var webapplication=document.querySelector('[name=\"de.signotec.signosignmobile.webapplication\"]');if(webapplication!=null&&webapplication.getAttribute('content')=='true'){return false;}else{return true;}}}})()";
const REDIRECTION_WINDOW_PROGRESS_URL = new URL(getRedirectionHtmlString(), window.location.origin).href;

const CALL_FINISH_CONFIRM_MESSAGE = 'Ein Anruf ist aktiv. Er wird beendet. Sind Sie sicher, dass Sie fortfahren möchten?';

function assertException(responseData) {
  if (!responseData || responseData?.exception) {
    throw new Error(responseData?.exception)
  }
}

function toBase64(url) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    const config = {
      responseType: 'blob', 
      defaultSpinner: true, 
    };
    axios.get(url, config).then(response => {
      if (response.data) {
        reader.readAsDataURL(response.data);
        reader.onload = () => resolve({ 
          data: reader.result?.split(',')?.pop(), 
          contentType: response.data.type,
          filename: getFilenameFromHeaders(response.headers),
        });
        reader.onerror = error => reject(error);
      }
    }).catch(error => {
      reject(error)
    })
  })
}

function toURL(url) {
  return new Promise((resolve, reject) => {
    axios.get(url).then(response => {
      if (response.data) {
        resolve(response.data);
      }
    }).catch(error => {
      reject(error)
    })
  })
}

async function bridgeCallHandler(payload) {
  let responseData = await Vue.prototype.$bridge.callHandler(payload);
  return JSON.parse(responseData)
}

function logException(dispatch, error, action) {
  dispatch(LOG_TYPES.ACTIONS.ERROR, { message: `${action} action returned an exception`, error})
}

function rootLevelRouteList(list) {
  let result = new Set();

  for (let item of list) {
      if (item?.meta?.isCmsPage) {
        continue;
      }

      let fullPath = '';
      const path = item.redirect || item.path;
      if (path && !path.startsWith('/')) {
        fullPath += '/';
      }

      fullPath += path;
      result.add(fullPath);
  }

  // replace the path variables like :id or :broker_id(\\d+)?
  const variableDeclaration = /:\w+(\(.*\))?\??/g
  return result.map(path => path.replace(variableDeclaration, '.*?'));
}

async function waitManageLoginResponseBeFinished() {
  await new Promise(resolve => {
    if(store.getters[CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE]) {
      const unwatch = store.watch(() => store.getters[CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE], () => {
        if(store.getters[CORE_TYPES.GETTERS.IS_MANAGING_LOGIN_RESPONSE]) return;

        unwatch();
        resolve();
      });
    } else {
      resolve();
    }
  })
}

export default {
  async [BRIDGE_TYPES.ACTIONS.HANDLE_CALLS_FROM_NATIVE](context, { data, callback }) {
    let responseJson = null

    if (handlers[data.action]) {
      try {
        responseJson = await handlers[data.action](context, data)
      } catch (error) {
        responseJson = {
          exception: `ErrorName: '${error?.name}'. \nErrorMessage: '${error?.message}'. \nStackTrace: ${error?.stack}`
        }
      }
    } else {
      const message = `The call '${data.action}' from the native stack is not yet implemented. Please check HANDLE_CALLS_FROM_NATIVE`
      context.dispatch(LOG_TYPES.ACTIONS.ERROR, { message })

      responseJson = {
        exception: message
      }
    }

    callback(responseJson)
  },

  async [BRIDGE_TYPES.ACTIONS.UPDATE_TOKEN]({ dispatch, getters }, jwtToken) {
    const action = 'update-token'

    try {
      const isKundenzugang = getters[CORE_TYPES.GETTERS.IS_KUNDENZUGANG];
      const isMaklerzugang = getters[CORE_TYPES.GETTERS.IS_MAKLERZUGANG];
      const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];

      if (!isMobileNativeContext || isKundenzugang || isMaklerzugang) {
        return;
      }

      if (!jwtToken) {
        throw new Error('jwtToken param is required')
      }
      const param = { 
        action, 
        jwtToken 
      }

      let responseData = await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.SAVE_HASHED_PASSWORD]({ dispatch, state, getters }, { loginName, password }) {
    const action = 'save-hashed-password'
    try {
      if (state.hasPasswordPreviouslySaved || !getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
        return;
      }

      if (!loginName || !password) {
        throw new Error('loginName and password params are required')
      }

      const passwordToken = password//sha256(md5(password).toString()).toString()
      const params = { 
        action, 
        loginName,
        passwordToken
      }

      let responseData = await bridgeCallHandler(params)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.LOAD_LOGIN_DATA]({ dispatch, commit, getters }, { reload = false } = {}) {

    const action = 'load-login-data'
    try {
      const isKundenzugang = getters[CORE_TYPES.GETTERS.IS_KUNDENZUGANG];
      const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];
      const hasLoadedLoginData = getters[BRIDGE_TYPES.GETTERS.HAS_LOADED_LOGIN_DATA];

      if (!isMobileNativeContext || isKundenzugang || !reload && hasLoadedLoginData) {
        return;
      }

      let responseData = await bridgeCallHandler({ action })
      assertException(responseData)

      if (responseData.loginName) {
        commit(BRIDGE_TYPES.MUTATIONS.LOAD_LOGIN_DATA_SUCCESS, { 
          loginName: responseData.loginName, 
          authenticationType: responseData.authenticationType,
        })
        commit(BRIDGE_TYPES.MUTATIONS.HAS_LOADED_LOGIN_DATA);
      }
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.LOAD_HASHED_PASSWORD]({ dispatch, commit }, { loginName }) {
    const action = 'load-hashed-password'

    try {
      if (!loginName) {
        throw new Error('loginName param is required')
      }

      const params = { 
        action, 
        loginName,
      }

      let responseData = await bridgeCallHandler(params)
      assertException(responseData)

      if (!responseData?.passwordToken) {
        return null;
      }

      commit(BRIDGE_TYPES.MUTATIONS.HAS_PASSWORD_PREVIOUSLY_SAVED)
      return responseData.passwordToken
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.PREPARE_CREDENTIALS]({ dispatch, getters }) {
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];
    if (!isMobileNativeContext) return {};

    const action = 'prepare-credentials';
    const param = { action };

    try {
      const responseData =  await bridgeCallHandler(param);
      assertException(responseData);
      return responseData;
    } catch (error) {
      logException(dispatch, error, action);
    }
    return {};
  },

  async [BRIDGE_TYPES.ACTIONS.VIEW_DOCUMENT]({ dispatch, getters }, payload) {
    try {
      const convertToProxyURLIfNeeded = (url) => {
        const proxyContentFn = getters[CORE_TYPES.GETTERS.PROXY_CONTENT];
        return isSameOrigin(location.href, url) ? url : proxyContentFn(url);
      };

      const base64data = 'href' in payload ? await toBase64(convertToProxyURLIfNeeded(payload.href)) : null;
      let filename = base64data?.filename || payload.filename || 'file.pdf';
      filename = filename.replace(/[^a-z0-9.]/gi, '_')
      let extension = payload.fileExtension || extractFileExtension(filename)
      extension = extension !== filename ? extension : extractExtensionFromMimeType(base64data?.contentType || payload.contentType)
      const param = { 
        action: 'view-document', 
        data: base64data?.data || payload.data,
        filename: addFileExtension(filename, extension || 'pdf'),
        'media-type': base64data?.contentType || payload.contentType || extractMimeType(filename) || 'application/pdf',
      }

      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, 'view-document')
    }
  },

  async [BRIDGE_TYPES.ACTIONS.VIEW_PDF_IN_SIGNO]({ dispatch }, payload) {

    const url = payload?.requestURL ? await toURL(payload.href) : '';

    const param = { 
      action: 'view-pdf-in-signo', 
      url: url || payload.href,
      filename: addFileExtension(payload.filename, 'pdf'),
    }

    try {
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, param.action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.OPEN_EXTRA_WEB_INSTANCE]({ dispatch, getters }, { url, windowRef, queryParam }) {
    if (!getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT] && !queryParam?.skipNewTab) {

      if(windowRef?.location) {
        windowRef.location.href = url;
      } else {
        window.open(url);
      }

      return;
    }

    menuManager.close();
    dispatch(BRIDGE_TYPES.ACTIONS.OPEN_WITHOUT_NAVIGATE, queryParam)
  },

  async [BRIDGE_TYPES.ACTIONS.OPEN_WITHOUT_NAVIGATE]({ commit, dispatch, }, payload) {
    commit(CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN, true);

    await dispatch(HTTP_REQ_TYPES.ACTIONS.CANCEL_ALL_CURRENT_FRONTEND_REQUESTS, MESSAGE_CANCEL_REQUEST_DUE_TO_LOGOUT);
    generateNewAppId();

    const nextUrl = decodeURIComponentIfNeeded(payload?.nextUrl || payload?.path);

    if (payload.isKundenzugang || payload.isMaklerzugang) {
      await dispatch(CORE_TYPES.ACTIONS.LOGIN, payload);
      
    } else if (payload.kundennrSteckbrief) {
      await dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_STECKBRIEF, {
        customerId: payload.kundennrSteckbrief,
        nextUrl,
        tabsBroadcastId: payload.tabsBroadcastId,
        isMaklerzugang: payload.isMaklerzugang,
        maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
      })

    } else if (payload.kundennrVersicherungen) {
      await dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_INSURANCES, {
        customerId: payload.kundennrVersicherungen,
        nextUrl,
        tabsBroadcastId: payload.tabsBroadcastId,
        isMaklerzugang: payload.isMaklerzugang,
        maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
      })

    } else if (payload.maklernr) {
      await dispatch(CORE_TYPES.ACTIONS.OPEN_BROKER, {
        maklernr: payload.maklernr,
        tabsBroadcastId: payload.tabsBroadcastId,
        isMaklerzugang: payload.isMaklerzugang,
        maklerzugangTabsBroadcastId: payload.maklerzugangTabsBroadcastId,
      })

    }

    commit(CORE_TYPES.MUTATIONS.SET_LOADING_TOKEN, false);
  },

  async [BRIDGE_TYPES.ACTIONS.LOGGED_OFF]({ dispatch, getters }) {
    const isKundenzugang = getters[CORE_TYPES.GETTERS.IS_KUNDENZUGANG];
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];

    if (!isMobileNativeContext || isKundenzugang) {
      return;
    }

    const action = 'logged-off'

    try {
      let responseData =  await bridgeCallHandler({ action })
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.READY_TO_SCREENSHOT_START_PAGE]({ dispatch, getters }) {
    const isKundenzugang = getters[CORE_TYPES.GETTERS.IS_KUNDENZUGANG];
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];

    if (!isMobileNativeContext || isKundenzugang) {
      return;
    }

    const action = 'ready-to-screenshot-start-page'
    const param = { 
      action,
      'app-version-web': getters[CORE_TYPES.GETTERS.APP_VERSION],
      'fc-route-whitelist': [ ...rootLevelRouteList(router.options.routes) ]
    };

    try {
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE]({ dispatch, getters, commit }) {

    if (!getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
      return;
    }

    // end / reject WebRTC call
    dispatch(WEBRTC_TYPES.ACTIONS.HANG_UP);
    dispatch(WEBRTC_TYPES.ACTIONS.REJECT_CALL);

    // cancel requests
    await dispatch(HTTP_REQ_TYPES.ACTIONS.CANCEL_ALL_CURRENT_FRONTEND_REQUESTS, MESSAGE_CANCEL_REQUEST_CLOSE_INSTANCE);

    // track current menu
    dispatch(MENU_TYPES.ACTIONS.TRACK_RECENT_MENU_OPENED, router?.currentRoute || {});

    // proceed to recovery the original user session
    const originalUser = getters[CORE_TYPES.GETTERS.ORIGINAL_USER];
    const nextUrl = originalUser.originalUserRouteFullPath;

    delete originalUser.originalUserRouteFullPath;

    dispatch(CORE_TYPES.ACTIONS.RESET_STATE, ResetStateSource.CloseExtraWebInstance);
    commit(CORE_TYPES.MUTATIONS.SET_LOGGING_OUT, true);

    generateNewAppId();
    menuManager.close();
    return new Promise(resolve => {
      requestAnimationFrame(() => {
        dispatch(CORE_TYPES.ACTIONS.MANAGE_LOGIN_RESPONSE, { 
          data: { 
            ...originalUser,
          },
          nextUrl,
        });
  
        commit(MENU_TYPES.MUTATIONS.UPDATE_CURRENT_APP_NAVIGATION);
        commit(CORE_TYPES.MUTATIONS.SET_LOGGING_OUT, false);
        resolve();
      });
    });
  },

  async [BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE_WITH_CONFIRM]({ dispatch, getters }) {
    const isCallingActive = getters[WEBRTC_TYPES.GETTERS.IS_CALLING_ACTIVE];
    if (isCallingActive) {
      await dispatch(CORE_TYPES.ACTIONS.CONFIRM_MODAL, CALL_FINISH_CONFIRM_MESSAGE);
    }

    await dispatch(BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE);
  },

  async [BRIDGE_TYPES.ACTIONS.START_OUTGOING_CALL]({ dispatch, commit, getters }, { calleeChatBeteiligterId } = {}) {
    const action = 'start-outgoing-call'

    const beteiligterInfo = getters[WEBRTC_TYPES.GETTERS.BETEILIGTER_INFOS]?.[calleeChatBeteiligterId]

    const clientCallId = uuidv4()
    const param = {
      action,
      'client-call-id': clientCallId,
      'callee-display-name': beteiligterInfo?.displayName || '',
    }

    try {
      commit(BRIDGE_TYPES.MUTATIONS.SAVE_CLIENT_CALL_ID, clientCallId)
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)

      commit(BRIDGE_TYPES.MUTATIONS.SAVE_SHOW_BUTTON_SWITCH_AUDIO_OUTPUT, responseData?.['show-button-switch-audio-output']);
      return responseData?.['allowed-to-proceed']
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.STOP_CALL]({ dispatch, getters, commit }, payload) {

    if (!getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
      return;
    }

    const clientCallId = getters[BRIDGE_TYPES.GETTERS.CLIENT_CALL_ID];

    if (!clientCallId) {
      dispatch(LOG_TYPES.ACTIONS.WARN, 'Calling "stop-call" without a "client-call-id" previously saved.')
    }

    const action = 'stop-call'
    const param = {
      action,
      'client-call-id': clientCallId || uuidv4(),
      cause: payload?.stopCallCause,
    }

    try {
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
      commit(BRIDGE_TYPES.MUTATIONS.SAVE_CLIENT_CALL_ID, null)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.TRIGGER_OUTGOING_CALL]({ dispatch, commit, getters }, { calleeId, calleeIdType, callerSignalingUUID, calleeSignalingUUID, video }) {
    const action = 'trigger-outgoing-call'
    const clientCallId = uuidv4()

    const beteiligterInfo = getters[WEBRTC_TYPES.GETTERS.BETEILIGTER_INFOS]?.[calleeId]

    const param = {
      action,
      'client-call-id': clientCallId,
      'callee-id': calleeId,
      'callee-id-type': calleeIdType,
      'caller-signaling-uuid': callerSignalingUUID,
      'callee-signaling-uuid': calleeSignalingUUID,
      'callee-display-name': beteiligterInfo?.displayName || '',
      video,
    }

    try {
      commit(BRIDGE_TYPES.MUTATIONS.SAVE_CLIENT_CALL_ID, clientCallId)
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.DELETE_HASHED_PASSWORD]({ getters, dispatch, commit, }, loginError) {
    if (!getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
      return;
    }

    if (loginError?.response?.status) {
      const action = 'delete-hashed-password'

      try {
        let responseData =  await bridgeCallHandler({ action })
        assertException(responseData)
        commit(BRIDGE_TYPES.MUTATIONS.RESET_HAS_PASSWORD_PREVIOUSLY_SAVED)
      } catch (error) {
        logException(dispatch, error, action)
      }
    }
  },

  async [BRIDGE_TYPES.ACTIONS.COLOR_THEME]({ commit, dispatch }, colorTheme) {
    if (!colorTheme) {
      return
    }

    if (Object.values(COLOR_THEME).some(value => value === colorTheme)) {
      commit(CORE_TYPES.MUTATIONS.SET_PREFERRED_COLOR_SCHEMA, colorTheme === COLOR_THEME.DARK ? 'dark' : 'light')
    } else {
      dispatch(LOG_TYPES.ACTIONS.ERROR, `the query parameter 'colorTheme="${colorTheme}"' has a wrong value`)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_CONTEXT]({ commit, dispatch }, mobileNativeContext) {
    if (!mobileNativeContext) {
      return
    }

    if (Object.values(MOBILE_NATIVE_CONTEXT).some(value => value === mobileNativeContext)) {
      commit(BRIDGE_TYPES.MUTATIONS.MOBILE_NATIVE_CONTEXT, mobileNativeContext)
      registerHandlerWeb()
    } else {
      dispatch(LOG_TYPES.ACTIONS.ERROR, `the query parameter 'mobileNativeContext="${mobileNativeContext}"' has a wrong value`)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.MOBILE_NATIVE_SPEC]({ getters, commit, dispatch }, mobileNativeSpec) {
    if (!getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
      return;
    }

    const requiredAttributes = [
      "appInstallationId",
      "appId",
      "appVersion",
      "nativeOsVersion",
      "nativeDeviceModel",
    ]
    const hasAllRequiredAttributes = requiredAttributes.every(attr => mobileNativeSpec?.[attr]);

    if (hasAllRequiredAttributes) {
      commit(BRIDGE_TYPES.MUTATIONS.MOBILE_NATIVE_SPEC, mobileNativeSpec);
    } else {
      const required = `Required: ${JSON.stringify(requiredAttributes)}`;
      const current = `Current: ${JSON.stringify(mobileNativeSpec || {})}`;
      const message = `The current startUrl doesn't have all the required attributes.\n${required}\n${current}`;
      dispatch(LOG_TYPES.ACTIONS.ERROR, { message });
    }
  },

  async [BRIDGE_TYPES.ACTIONS.UPDATE_SETTINGS]({ getters, dispatch }, { allowPushNotification, layoutParamsFC }) {
    const isKundenzugang = getters[CORE_TYPES.GETTERS.IS_KUNDENZUGANG];
    const isMaklerzugang = getters[CORE_TYPES.GETTERS.IS_MAKLERZUGANG];
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];

    if (!isMobileNativeContext || isKundenzugang || isMaklerzugang) {
      return;
    }

    const action = 'update-settings'
    const param = {
      action,
      'allow-push-notification': allowPushNotification,
      'signo-user-agent': SIGNO_USER_AGENT,
      'signo-redirect-paths': [
        SIGNO_SIGN_VIEWER_2_PATH,
      ],
      'signo-check-error-javascript': SIGNO_CHECK_ERROR_JAVASCRIPT,
      'redirection-window-progress-url': REDIRECTION_WINDOW_PROGRESS_URL,
      'disabled-video-buttons': false,
      ...getColorPropsUpdateSettings(layoutParamsFC),
    }

    try {
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },
  async [BRIDGE_TYPES.ACTIONS.TOGGLE_AUDIO_OUTPUT]({ getters, commit, dispatch }) {
    const clientCallId = getters[BRIDGE_TYPES.GETTERS.CLIENT_CALL_ID];

    if (!clientCallId || !getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT]) {
      return;
    }

    const audioOutput = getters[BRIDGE_TYPES.GETTERS.AUDIO_OUTPUT];
    const newAudioOutput = audioOutput === 'RECEIVER' ? 'SPEAKER' : 'RECEIVER';

    const action = 'switch-audio-output'
      const params = {
        action,
        'client-call-id': clientCallId,
        output: newAudioOutput
      }

      try {
        let responseData =  await bridgeCallHandler(params)
        assertException(responseData)
        commit(BRIDGE_TYPES.MUTATIONS.SET_AUDIO_OUTPUT, newAudioOutput);
      } catch (error) {
        logException(dispatch, error, action)
      }
  },

  /**
   * 
   * @param {*} param0 
   * @param {string} loginName 
   * @returns 
   */
  async [BRIDGE_TYPES.ACTIONS.LOAD_BIOMETRIC_LOGIN_SETTINGS]({ dispatch, commit, getters }, loginName) {
    const isMobileNativeContext = getters[BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT];
    if (!loginName || !isMobileNativeContext) {
      return
    }

    const action = 'load-biometric-login-settings';
    const param = {
      action,
      loginName,
    }

    try {
      let responseData =  await bridgeCallHandler(param);
      assertException(responseData);

      if (responseData) {
        const { 
          isAvailable, 
          isPermissionGranted, 
          authenticationType, 
        } = responseData;
        commit(BRIDGE_TYPES.MUTATIONS.SET_BIOMETRIC_LOGIN_SETTINGS_RESPONSE, { 
          isAvailable, 
          isPermissionGranted, 
          authenticationType, 
          loginName, 
        });
      }
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  /**
   * @typedef UpdateBiometricLoginSettingsPayload
   * @type {object}
   * @property {string} loginName
   * @property {boolean} isPermissionGranted
   */

  /**
   * @param {*} param0 
   * @param {UpdateBiometricLoginSettingsPayload} payload 
   * @returns 
   */
  async [BRIDGE_TYPES.ACTIONS.UPDATE_BIOMETRIC_LOGIN_SETTINGS]({ dispatch, commit }, payload) {
    const { loginName, isPermissionGranted } = payload;
    
    if (!loginName) {
      return
    }

    const action = 'update-biometric-login-settings';
    const param = {
      action,
      loginName,
      isPermissionGranted
    }

    try {
      let responseData =  await bridgeCallHandler(param);
      assertException(responseData);

      commit(BRIDGE_TYPES.MUTATIONS.SET_BIOMETRIC_LOGIN_SETTINGS_RESPONSE, { isPermissionGranted });
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.OUTGOING_CALL_ACCEPTED]({ dispatch, getters }) {
    const clientCallId = getters[BRIDGE_TYPES.GETTERS.CLIENT_CALL_ID];
    if (!clientCallId) {
      return
    }

    const action = 'outgoing-call-accepted';
    const param = {
      action,
      'client-call-id': clientCallId,
    }

    try {
      let responseData =  await bridgeCallHandler(param)
      assertException(responseData)
    } catch (error) {
      logException(dispatch, error, action)
    }
  },

  async [BRIDGE_TYPES.ACTIONS.OPEN_DEEPLINK]({ commit, dispatch, getters }, path) {
    if (!path) return;

    const deferDeeplink = deferredPath => {
      dispatch(LOG_TYPES.ACTIONS.INFO, `[open-deeplink] path (${deferredPath}) is deferred`);
      commit(BRIDGE_TYPES.MUTATIONS.SET_DEFERRED_DEEPLINK, deferredPath);
    };

    const hasLoginInProgress = getters[CORE_TYPES.GETTERS.HAS_LOGIN_IN_PROGRESS];
    const isConfirming2FA = getters[CORE_TYPES.GETTERS.IS_CONFIRMING_2FA];
    const isDeeplinkReady = getters[BRIDGE_TYPES.GETTERS.IS_DEEPLINK_READY];
    if (hasLoginInProgress || isConfirming2FA || !isDeeplinkReady) {
      deferDeeplink(path);
      return;
    }

    const confirmUserLogout = async (nextUrl) => {
      dispatch(LOG_TYPES.ACTIONS.INFO, `[open-deeplink] confirm user logout for path (${path})`);

      await dispatch(CORE_TYPES.ACTIONS.CONFIRM_MODAL, {
        title: 'Link öffnen',
        message: 'Sie sollten sich abmelden, bevor Sie fortfahren',
        labelButtonConfirm: 'Abmelden',
      });

      if (getters[BRIDGE_TYPES.GETTERS.SHOW_CLOSE_EXTRA_WEB_INSTANCE]) {
        await dispatch(BRIDGE_TYPES.ACTIONS.CLOSE_EXTRA_WEB_INSTANCE_WITH_CONFIRM);
      }
      await waitManageLoginResponseBeFinished();

      await dispatch(CORE_TYPES.ACTIONS.LOGOUT, { nextUrl });
    };

    const findLoginValidNextUrl = (loginRelatedPath)  => {
      const { route:loginRelatedRoute } = router.resolve(loginRelatedPath);
      const userId = findUserId(loginRelatedRoute);
      const nextUrl = findLoginNextUrl(loginRelatedRoute);
      const isSameUserLoggedInFn = getters[CORE_TYPES.GETTERS.IS_SAME_USER_LOGGED_IN];
      return nextUrl && (!userId || isSameUserLoggedInFn(userId)) ? nextUrl : null;
    };

    const navigateToPath = innerPath => {
      dispatch(LOG_TYPES.ACTIONS.INFO, `[open-deeplink] navigating to "${innerPath}"`);
      router.push('/noop')
        .catch(() => {})
        .finally(() => router.push(innerPath));
    };

    // is login related page?
    const isLoginRelatedPath = isLoginRoute(path);
    const isLoggedIn = getters[CORE_TYPES.GETTERS.IS_LOGGED_IN];
    if (isLoginRelatedPath && isLoggedIn) {
      const validNextUrl = findLoginValidNextUrl(path);
      if (validNextUrl && isPathAllowed(validNextUrl)) {
        navigateToPath(validNextUrl);
      } else {
        confirmUserLogout(path);
      }
    } else {
      navigateToPath(path);
    }
  },

  async [BRIDGE_TYPES.ACTIONS.OPEN_DEFERRED_DEEPLINK]({ state, dispatch, commit }) {
    const { deferredPath:path } = state.deeplink ?? {};
    if (!path) return;

    commit(BRIDGE_TYPES.MUTATIONS.SET_DEFERRED_DEEPLINK, null);
    dispatch(BRIDGE_TYPES.ACTIONS.OPEN_DEEPLINK, path);
  },

}