<template>
  <div>
    <div class="row settings-row">
      <div class="col-4">
        <div>Jahre</div>
        <ComboBox
          :value="timeSpan"
          :values="AMOUNT_YEARS_OPTIONS"
          @change="onChangeTimeSpan"
        />
      </div>
      <div class="col-4">
        <InputField
          label="Startbetrag"
          v-model="initialInvestment"
          type="currency"
        />
      </div>
      <div class="col-4">
        <BaseButton
          @click="onClickSimulate()"
          style="max-height: 50px;"
          >Simulieren</BaseButton
        >
      </div>
    </div>
    <hr />
    <div>
      <LineChart
        ref="lineChart"
        :chartSeries="chartSeries"
        :isArea="false"
        :customTooltipFormatter="customTooltipFormatter"
        :height="height"
        :format="format"
        :isLegend="false"
        :gridLeft="75"
        :chartOptions="CHART_OPTIONS"
      />
      <slot name="beforLegend" />
      <ChartLegend
        v-if=" lineChart && chartSeries.length"
        :showAsTable="false"
        :chartSeries="chartSeries"
        :lineChart="lineChart"
      />
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex';
import ComboBox from '@/components/core/forms/ComboBox.vue';
import dayjs from 'dayjs';
import { formatNumber } from '@/helpers/number-formatter.js';
import LineChart from '@/components/charts/echarts/LineChart.vue';
import ChartLegend from "./ChartLegend.vue";
import InputField from "@/components/core/forms/InputField.vue";
import BaseButton from '../core/BaseButton.vue';
import FONDSINFO_TYPES from "@/store/fondsinfo/types";

const SIMULATIONS_AMOUNT = 100;

export default {
  components: {
    LineChart,
    ComboBox,
    ChartLegend,
    InputField,
    BaseButton,
  },
  props: {
    isin: {
      type: String,
      required: false,
    }
  },
  data: function () {
    return {
      height: '450px',
      format: 'currency',
      timeSpan: 10,
      initialInvestment: 10000,
      lineChart: null,
    }
  },
  computed: {
    ...mapGetters({
      chartData: FONDSINFO_TYPES.GETTERS.FONDSINFO_MONTECARLO_CHART,
    }),
    chartSeries: function () {
      const today = new Date();
      return this.chartData?.map((series) => {
        return {
          color: series.color,
          data: series?.data?.map((value, index) => {
            const date = new Date(today);
            date.setMonth(today.getMonth() + index);
            return [date, value];
          }),
          highlight: false,
          hidden: false,
          name: series.label,
        };
      }) || [];
    },
  },
  created() {
    this.CHART_OPTIONS = {
      yAxis: {
        show: true,
        type: 'value',
        boundaryGap: false,
        axisLabel: {
          formatter: (value) => {
            return new Intl.NumberFormat('de-DE', { style: 'currency', currency: 'EUR', maximumFractionDigits: 0 }).format(value);
          },
          fontSize: 9,
        },
      }
    };
    this.AMOUNT_YEARS_OPTIONS = [5, 10, 20, 50];
    this.getChart();
  },
  mounted() {
    if (this.$refs?.lineChart?.$refs?.chart) {
      this.lineChart = this.$refs.lineChart;
    }
  },
  methods: {
    onChangeTimeSpan(timeSpan) {
      this.timeSpan = timeSpan;
    },
    onClickSimulate() {
      this.getChart();
      //TODO: Indicate that generation is happening, maybe similar to kill session modal?
    },
    async getChart() {
      await this.$store.dispatch(
        FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_MONTECARLO_CHART,
        {
          isinList: [this.isin],
          timeSpan: this.timeSpan,
          amount: this.initialInvestment,
          quantitySimulation: SIMULATIONS_AMOUNT,
        });
    },
    customTooltipFormatter(params) {
      if (Array.isArray(params)) {
        const name = dayjs(params[0].value[0]).format('MMM YYYY') || '';
        const html = params.map(p => {
          const { marker, seriesName, value } = p;
          let seriesNameTool = seriesName;
          if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
            seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
          }
          let percentValue = value
          if (Array.isArray(value)) {
            percentValue = value[1]
          }
          if (!percentValue) {
            return ''
          }
          const formattedValue = formatNumber(percentValue || 0, 2) + (this.format === 'percent' ? ' %' : ' €');
          return `
            <div>
              <span>${marker}</span>
              <span style="white-space: normal;">${seriesNameTool}:</span> 
              <b>${formattedValue}</b>
            </div>`;
        });
        return `
          <div class="tooltip-series">
            <div><b>${name}</b></div>
            <div class="tooltip-series-table">${html.join('')}</div>
          </div>
        `;
      } else {
        const { marker, seriesName, name, value } = params;
        let seriesNameTool = seriesName;
        if (this.toolTipTruncName && -1 !== seriesNameTool.indexOf(this.toolTipTruncName)) {
          seriesNameTool = seriesNameTool.substr(0, seriesNameTool.indexOf(this.toolTipTruncName));
        }
        const formattedValue = formatNumber(value, 0) + (this.format === 'percent' ? ' %' : ' €');
        return `
          <div>
            <div><b>${seriesNameTool} test</b></div>
            <div><span>${marker}</span> <span>${name}:</span> <b>${formattedValue}</b></div>
          </div>`;
      }
    },
  },
}
</script>

<style scoped>
.years-container {
  display: flex;
  flex-direction: column;
  padding: 0 12px 16px 0;
}

.settings-row {
  align-items: flex-end;
}
</style>