<template>
  <div class="card box__container"
    
  >
    <div class="card-header" @dragstart="onDragStart" draggable="true" @dragover.prevent="onDragOver" @drop="onDrop">
      <div class="card-title">
        {{ card.title }}
      </div>
      <div class="card-buttons">
        <button
          type="button"
          :class="['btn-clear', 'clickable', 'd-lg-block']"
          data-sortable-item-handler
          @click="doEmitClick()"
          key="widget-order"
          v-if="!isSmallOrMediumScreen"
          :alt="ariaDescription"
        >
          <PhArrowsOutCardinal :size="20" :alt="ariaDescription"/>
        </button>

        <BaseContextMenu
          class="base-context-menu"
          :class="['d-none', 'd-lg-block']"
          ref="dropdownMenu"
          containerClass="dropdown-menu"
          key="widget-dropdown-menu"
          v-if="isLargeScreen"
        >
          <template>
            <template v-for="(action, index) in actions">
              <ContextMenuItem
                v-if="isActionVisible(action)"
                :key="index"
                @click="$emit('executeAction', {action, component: $refs['comp-' + card.key] ? $refs['comp-' + card.key] : card.key})"
              >
                <component
                  v-if="actionHasIcon(action)"
                  :is="actionIcon(action)"
                  :size="16"
                  class="mr-1"
                /><span>{{ action.legend.label }}</span>
              </ContextMenuItem>
            </template>
            <ContextMenuItem  @click="openRemoveCardtModal()"
              >löschen</ContextMenuItem>            
          </template>
        </BaseContextMenu>
      </div>
    </div>
    <component :ref="'comp-' + card.key" v-if="card.component" :is="card.component" v-bind="card.props"></component>
    <slot  v-else-if="card.key" :name="card.key"></slot>

    <BaseModal
        ref="removeCardModal"
        :modalTitle="`${card.title} löschen`"
        size="sm"
        @onConfirmButton="$emit('remove', card)"
      >
        <template #default>
          Soll die "{{ card.title }}" wirklich gelöscht werden?
        </template>
      </BaseModal>

  </div>
</template>

<script>
import {
  PhDotsThreeVertical,
  PhArrowsOutCardinal,
  PhFrameCorners,
  PhTrash,
  PhCaretRight,
} from "phosphor-vue";
import * as phosphor from "phosphor-vue";
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import BaseContextMenu from "@/components/core/BaseContextMenu.vue";
import BaseModal from "@/components/core/BaseModal.vue";

export default {
  name: "Card",
  props: {
    card: Object,
    columnIndex: Number,
    cardIndex: Number,
    actions: {
      type: Array,
      default: () => [],
    },
    ariaDescription: {
      type: String,
      default: 'Icon für das Verschieben eines Elements'
    },
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
    }),
    isMediumScreen() {
      return this.screenSize === "md";
    },
    isLargeScreen() {
      return this.screenSize === "lg" || this.screenSize === "xl";
    },
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },
  },
  methods: {
    openRemoveCardtModal() {
      this.$refs.removeCardModal.open();
    },    
    onDragStart(event) {
      this.$emit("dragstart", event, this.columnIndex, this.cardIndex);
    },
    onDragOver(event) {
      event.preventDefault();
    },
    onDrop(event) {
      this.$emit("drop", event, this.columnIndex, this.cardIndex);
    },
    isActionVisible(action) {
      return "visible" in action ? action.visible() : true;
    },
    actionHasIcon(action) {
      return !!action?.legend?.icon;
    },
    actionIcon(action) {
      const icon = action?.legend?.icon;
      if (!icon) return;
      if (typeof icon === "string") {
        return phosphor[icon];
      }
      return icon;
    },
  },
  components: {
    PhArrowsOutCardinal,
    ContextMenuItem,
    BaseContextMenu,
    BaseModal,
  },
};
</script>

<style  lang="scss" scoped>
.card {
  background-color: var(--color-box);
  margin: 10px 0;
  padding: 10px;
  border: 1px solid var(--color-background);
}
.card-title {
  padding: 5px;
  margin-bottom: 5px;
  font-weight: bold;
  flex: 1 1 auto;
  display: flex;
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin: 0 0 16px;
  cursor: grab;

  .card-buttons {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1 0 auto;
    margin: 0 0 0 4px;

    > * {
      margin: 0 0 0 4px;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}

.dropdown-menu {
  &.positioned-bottom-right {
    margin-left: 14px;
  }
}

.base-context-menu {
  display: flex;
  align-items: center;

  .dropdown-menu--hook-target {
    display: flex;
    align-items: center;
  }
}
</style>
