<template>
  <!-- // TODO remove v-if="!ignoreRender" after the migration to a global options menu is done -->
  <div v-if="!ignoreRender" class="option-menu__container box__container" :tid="_generateTidFromString($route.path+'box__container')" :class="optionMenuClasses">
    <div class="option-menu__header">
      <div class="option-menu__title box__title">
        <div class="menu-title">
          <span>{{ title }}</span>
        </div>
      </div>

      <div v-if="!isLoadingActive" class="option-menu__toolbar">
        <button 
          v-if="isToggleViewButtonVisible"
          type="button" class="btn btn-clear clickable option-menu__toolbar--toggle-view" 
          :class="{ 'active': collapsed, }"
          @click="toggleView" 
        >
          <PhCaretDown v-if="nextView(currentView) !== VIEW_GRID" :size="20" alt="Icon zum Öffnen des Menüs"/>
          <PhDotsNine v-else :size="iconSize" weight="bold" alt="Icon zum Öffnen der Kachelansicht"/>
        </button>

        <BaseContextMenu v-if="isEditAvailable" class="option-menu__context-menu">
          <ContextMenuItem @click="openEditModal()">Anpassen</ContextMenuItem>
        </BaseContextMenu>
      </div>
    </div>

    <div class="option-menu__content-wrap">
      <div class="option-menu__content" :key="id">
        <Carousel v-if="carouselValues.length" :id="id" class="option-menu__carousel" :values="carouselValues" 
          :options="carouselOptions" :asGrid="asGrid" @ready="onCarouselReady($event)" />
        <UnexpectedError v-else-if="isError" noIcon class="m-0" />
        <NoData v-else-if="isContentReady" noIcon class="m-0" />
      </div>
    </div>

    <OptionMenuEditModal 
      ref="optionMenuEditModal" 
      :configContext="MENU_CONFIG_CONTEXT" 
      :configId="id" 
      :defaultMenu="defaultMenu" 
      :optionMenu="optionMenu" 
      @onSaveConfig="onConfigChanged"
      @onRestoreDefault="onConfigChanged"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import FC_CONFIG from '@/configs/fcConfig.js';
import { PhCaretDown, PhDotsNine, } from 'phosphor-vue';

import MENU_TYPES from '@/store/menu/types';
import FC_CONFIG_TYPES from '@/store/fcConfig/types';
import CORE_TYPES from '@/store/core/types';

import UndrawFinance from '@/components/icons/undraw/UndrawFinance.vue'
import Carousel from '@/components/core/carousel/Carousel.vue';

import OptionMenuEditModal from './OptionMenuEditModal.vue';
import NoData from '@/components/core/NoData.vue';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import OptionMenuBackIcon from './OptionMenuBackIcon.vue';

import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";

import menuManager from '@/menu/menu-manager';

import { 
  sortByName, 
  findConfiguredMenuActives, 
  normalizeMenu,
} from './option-menu-utils';
import UnexpectedError from '@/components/core/UnexpectedError.vue';
import { VIEW_ROLES, BROKER_LEVEL_ROLES } from '@/router/roles';

const TITLE_DEFAULT = 'Optionen';
const VIEW_COLLAPSED = 'collapsed';
const VIEW_NORMAL = 'normal';
const VIEW_GRID = 'grid';
const INITIAL_ITEMS = [{},{},{}];
const SCROLL_INTO_VIEW_OFFSET = 8;
const MENU_CONFIG_CONTEXT = '__CURRENT_CONFIG__';

export default {
  name: 'OptionMenu',
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    // OptionMenu id must be different and unique. This "id" is being used to save the option menu config into backend
    // a UUID randomly generated is enough. https://www.uuidgenerator.net/version4 or any other generator.
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: TITLE_DEFAULT,
    },
    defaultMenu: {
      type: Array,
      default: () => [],
      required: true,
    },
    customOptionMenu: {
      type: Array,
    },
    showEditButton: {
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isError: {
      type: Boolean,
      default: false,
    },
    ariaDescription: {
      type: String,
      default: 'Icon zur Navigation'
    },
    ignoreRender: { // TODO remove it after the migration to a global options menu is done
      type: Boolean, 
      default: true, 
    },
  },
  emits: ['configuredMenu'],
  data() {
    return {
      multipleLoading: {},
      configuredMenuInternal: [],
      hasGrid: false,
      isContentReady: false,
      currentView: null,
      VIEW_COLLAPSED,
      VIEW_NORMAL,
      VIEW_GRID,
      MENU_CONFIG_CONTEXT,
    };
  },
  computed: {
    ...mapGetters({
      fcConfigOptionMenu: FC_CONFIG_TYPES.GETTERS.GET_FC_CONFIG_OPTION_MENU,
      parentsMenuBadgeCount: MENU_TYPES.GETTERS.PARENTS_MENU_BADGE_COUNT,
      optionsMenuBadgeCount: MENU_TYPES.GETTERS.OPTIONS_MENU_BADGE_COUNT,
      isConfiguringMenu: MENU_TYPES.GETTERS.IS_CONFIGURING_MENU,
      isFCConfigLoading: FC_CONFIG_TYPES.GETTERS.IS_FC_CONFIG_LOADING,
      isConfigurationReadOnly: FC_CONFIG_TYPES.GETTERS.IS_CONFIGURATION_READ_ONLY,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
    }),
    iconSize() {
      return this.$isSmallScreen ? 24 : 20;
    },
    isToggleViewButtonVisible() {
      return !(this.$isMobileNativeContext || this.$isSmallScreen);
    },
    isEditAvailable() {
      if (!this.hasRoles([VIEW_ROLES.VIEW_BROKER, VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_INTERN]) || !this.hasRoles([BROKER_LEVEL_ROLES.OPTIONS_MENU_EDIT])) {
        return false;
      }

      return this.id && this.showEditButton && !this.isConfigurationReadOnly;
    },
    viewOrder() {
      const viewOrder = [];
      viewOrder.push(VIEW_COLLAPSED);
      viewOrder.push(VIEW_NORMAL);
      if(this.hasGrid) { // view grid is only possible when has overflow to scroll/navigate
        viewOrder.push(VIEW_GRID);
      }
      return [ ...viewOrder, ];
    },
    hasGridView() {
      return this.viewOrder.indexOf(VIEW_GRID) >= 0;
    },
    asGrid() {
      return this.currentView === VIEW_GRID && this.hasGridView;
    },
    collapsed() {
      return this.currentView === VIEW_COLLAPSED;
    },
    isLoadingActive() {
      return this.multipleLoading?.[this.id] || this.isLoading || this.isConfiguringMenu || this.isFCConfigLoading(FC_CONFIG.OPTION_MENU_CONFIG, this.id);
    },
    optionMenuConfig() {
      return this.fcConfigOptionMenu?.[this.id] || {};
    },
    optionMenuClasses() {
      return {
        'option-menu__collapsed' : this.collapsed === true,
        'option-menu__to-collapse' : this.collapsed === false,
        'option-menu__as-grid': this.asGrid,
        'option-menu__as-carousel' : !this.asGrid,
        'option-menu__has-back-button' : this.hasBackButton,
      };
    },
    optionMenu() {
      if(this.customOptionMenu) {
        return [].concat(this.customOptionMenu);
      }

      const optionMenu = this.$store.getters[MENU_TYPES.GETTERS.OPTION_MENU];
      return [].concat([ ...optionMenu || [], ].sort(sortByName) || []);
    },
    configuredMenu() {
      if (!this.id) {
        return null;
      }

      const { optionMenuConfig } = this;
      if (!optionMenuConfig?.content) {
        return null;
      }

      const configuredMenu = JSON.parse(optionMenuConfig.content);
      return normalizeMenu(this.optionMenu, this.defaultMenu, configuredMenu);
    },
    configuredMenuActives() {
      if(this.configuredMenuInternal?.length) {
        return findConfiguredMenuActives(this.configuredMenuInternal);
      }

      return [...this.defaultMenu];
    },
    backButtonOptionValue() {
      if (!menuManager.showBackButton) return null;

      const backButtonOptionValue = this.toCarouselValue({
        label: 'Zurück',
        component: OptionMenuBackIcon,
        isBackItem: true,
        action: () => {
          menuManager.prev();
        },
      });

      return {
        ...backButtonOptionValue,
        loading: false,
      };
    },
    hasBackButton() {
      return !!this.backButtonOptionValue;
    },
    carouselValues() {
      const menuOptions = this.configuredMenuActives?.map((item) => this.toCarouselValue(item));

      return [ 
        ...(this.backButtonOptionValue ? [this.backButtonOptionValue] : []), 
        ...(this.isLoadingActive ? INITIAL_ITEMS.map(item => this.toCarouselValue(item)) : menuOptions), 
      ];
    },
    carouselOptions() {
      return {
        item: {
          width: 124,
          height: 72,
        },
        icon: {
          width: 60,
          height: 48,
        },
      };
    },
  },
  watch: {
    id: {
      handler() {
        this.loadConfigById(this.id);
      },
      immediate: true,
    },
    defaultMenu() {
      this.setConfiguredMenuInternal();
    },
    configuredMenu: {
      handler() {
        this.setConfiguredMenuInternal();
      },
      immediate: true,
    },
    configuredMenuActives: {
      handler() {
        this.$store.dispatch(MENU_TYPES.ACTIONS.CONFIG_OPTIONS_MENU_BADGE, { 
          optionsMenu: [ ...this.configuredMenuActives || [], ], 
        });
        this.$store.dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_BADGE);
      },
      immediate: true,
    },
  },
  methods: {
    toggleView() {
      const { currentView, } = this;
      this.setCurrentView(this.nextView(currentView));
    },
    setCurrentView(view) {
      const previousView = this.currentView;
      this.currentView = view;

      if (previousView) { // only dispatches when it has a previous view selected
        this.$nextTick(() => window.dispatchEvent(new Event('resize')));
      }
    },
    nextView(current) {
      const { viewOrder, } = this;
      const currentIndex = viewOrder.indexOf(current);
      const nextIndex = currentIndex + 1;
      if(currentIndex < 0 || nextIndex >= viewOrder.length) {
        return viewOrder[0];
      } else {
        return viewOrder[nextIndex];
      }
    },
    openEditModal() {
      this.$refs.optionMenuEditModal.open(this.optionMenuConfig?.userLevel);
    },
    setConfiguredMenuInternal() {
      const configuredMenu = [ ...this.configuredMenu || [] ];
      this.configuredMenuInternal = [...configuredMenu];
      this.$emit('configuredMenu', [...configuredMenu]);
    },
    async defaultCarouselAction(item) {
      if (item.disabled) {
        return;
      }

      const isRouteAlreadyActive = menuManager.isActive(item);
      if (!item?.group || !isRouteAlreadyActive) {
        menuManager.openMenu(item);
      } else {
        menuManager.next();
      }
    },
    toCarouselValue(item) {
      const isActive = menuManager.isActive(item);

      return {
        label: item.label, 
        disabled: item.disabled, 
        component: item.component || UndrawFinance, 
        componentProps: item.componentProps,
        action: item.action && !item.disabled ? item.action : () => this.defaultCarouselAction(item), 
        badgeCount: (item.group ? this.parentsMenuBadgeCount[item.path] : this.optionsMenuBadgeCount[item.path]),
        hasTestUserRole: item.hasTestUserRole,
        textBold: isActive || item?.textBold,
        isActive,
        classes: item?.isBackItem ? 'option-menu__back-item' : '',
        loading: this.isLoadingActive,
      };
    },
    async loadConfigById(id, forceReload = false) {
      try {
        if (!id || id in this.multipleLoading) {
          return ;
        }

        this.$set(this.multipleLoading, id, true);

        const payload = {
          configIds: [id],
          forceReload,
        };
        await this.$store.dispatch(MENU_TYPES.ACTIONS.LOAD_OPTIONS_MENU_CONFIGS, payload);
      } finally {
        this.$delete(this.multipleLoading, id);
        requestAnimationFrame(() => this.isContentReady = true);
      }
    },
    onCarouselReady(event) {
      this.hasGrid = event.hasOverflow;
    },
    prepareOptionMenuView() {
      const { currentView } = this;
      const view = menuManager.asGrid ? VIEW_GRID : VIEW_NORMAL;
      if(view !== currentView) {
        this.setCurrentView(view);
      }
      this.scrollIntoView();
    },
    scrollIntoView() {
      const rootEl = this.$el;
      if(!rootEl) return;

      const top = this.$isSmallScreen ? 0 : rootEl.offsetTop - SCROLL_INTO_VIEW_OFFSET;
      if(window.scrollY <= top) return;

      requestAnimationFrame(() => window.scrollTo({
        top: Math.max(0, top),
        left: 0,
        behavior: 'smooth',
      }));
    },
    onConfigChanged() {
      menuManager.checkMenuVisibility();
    },
    onViewChanged() {
      if (menuManager.isBackingActive && !this.isToggleViewButtonVisible) {
        return;
      }

      this.$nextTick(() => requestAnimationFrame(() => this.prepareOptionMenuView()));
    },
  },
  created() {
    if (this.ignoreRender) { // TODO remove it after the migration to a global options menu is done
      this.$destroy();
      return;
    }

    menuManager.$on('viewChanged', this.onViewChanged);
  },
  beforeDestroy() {
    menuManager.$off('viewChanged', this.onViewChanged);
  },
  components: {
    PhCaretDown,
    PhDotsNine,
    Carousel,
    OptionMenuEditModal,
    NoData,
    UnexpectedError,
    BaseContextMenu,
    ContextMenuItem,
  }
}
</script>

<style lang="scss" scoped>
.option-menu__header {
  display: flex;
  justify-content: flex-end;
  margin: 0;
}

.option-menu__title {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1 auto;
  margin: 0;
}

.option-menu__toolbar {
  display: flex;
  flex: 0 0 auto;

  > * {
    display: flex;
    align-items: center;
    margin: 0 0 0 6px;
    position: relative;
    width: 20px;
    height: 20px;

    &:first-child {
      margin-left: 0;
    }
  }
}

.option-menu__toolbar--toggle-view {
  svg {
    transform: rotate(180deg);
    transition: transform .5s ease;
    will-change: transform;
  }

  &.active {
    svg {
      transform: rotate(0deg);
    }
  }
}

/deep/ .option-menu__context-menu .base-content-menu--btn {
  display: flex;
  align-items: center;
}

.option-menu__content-wrap {
  overflow: hidden;
  position: relative;
}

.option-menu__content {
  min-width: 100%;
  min-height: 72px;
}

.option-menu__as-carousel {
  .option-menu__content {
    margin-top: 16px;
  }
}

.option-menu__as-grid {
  .option-menu__content {
    margin-top: 4px;
  }
}

.option-menu__button--open-edit {
  flex: 0 0 auto;
}

.menu-title {
  margin-right: 0.5rem;
  flex: 1 0 auto;

  > span {
    margin: 0 4px 0 0;
  }
}

.option-menu__collapsed {
  .option-menu__title {
    align-items: center;
  }

  .option-menu__content-wrap {
    max-height: 0;
  }
}

/** Responsive */
@media screen and (max-width: 767px) {
  .option-menu__container {
    .option-menu__header {
      margin-bottom: 0;

      .option-menu__title {
        margin: 0;
      }
    }

    .option-menu__toolbar {
      > * {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
