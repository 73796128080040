var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box__container-wrap" },
    [
      _c(
        "div",
        { staticClass: "box__container-rows" },
        [
          _vm.isLoading
            ? _c("GhostLoading", {
                attrs: {
                  type: "table",
                  config: { table: { rows: _vm.rowsPerPage } }
                }
              })
            : _vm.rows && _vm.rows.length
            ? _c("Table", {
                attrs: {
                  rowId: "dokId",
                  hidePagination: "",
                  headers: _vm.headers,
                  rows: _vm.rows,
                  rowsPerPage: _vm.rowsPerPage,
                  mobileConfig: _vm.mobileConfig
                },
                on: {
                  "click-kundenLinkName": _vm.openCustomerNewTab,
                  "action-REVERT": _vm.openVorlageModal,
                  "action-CLOSE": _vm.dokumentAbschliessen,
                  "action-DEACTIVATE":
                    _vm.openOffeneUnterschriftenDisableWarnung,
                  "action-ADDITIONAL-DOCUMENTS": _vm.selectAdditionalDocuments
                },
                scopedSlots: _vm._u([
                  {
                    key: "name",
                    fn: function(row) {
                      return [
                        _vm.canGenerateSignoViewerLink(row)
                          ? _c("DownloadLink", {
                              attrs: {
                                title: row.name || "Dokument",
                                downloadServicePath:
                                  "/get_signo_document_preview",
                                filename: row.name,
                                queryParams: {
                                  nodeId: encodeURIComponent(row.nodeId),
                                  viewFileId: encodeURIComponent(row.viewFileId)
                                }
                              }
                            })
                          : _c("span", [
                              _vm._v(" " + _vm._s(row.name || "Dokument") + " ")
                            ])
                      ]
                    }
                  },
                  {
                    key: "status",
                    fn: function(row) {
                      return [
                        _c(
                          "span",
                          {
                            staticClass: "text-smaller",
                            class: _vm.getStatusClass(row)
                          },
                          [_vm._v(_vm._s(row.status))]
                        )
                      ]
                    }
                  }
                ])
              })
            : _c("NoData", { attrs: { noIcon: "" } })
        ],
        1
      ),
      !_vm.isLoading && !_vm.$isSmallScreen
        ? _c(
            "div",
            { staticClass: "bottom-row text-right mt-3" },
            [
              _c(
                "router-link",
                { attrs: { to: "/communication/offene-unterschriften" } },
                [
                  _vm.rows.length
                    ? _c("div", [_vm._v("weitere...")])
                    : _c("div", [_vm._v("Übersicht anzeigen")])
                ]
              )
            ],
            1
          )
        : _vm._e(),
      _c("RemoveVorlageModal", {
        ref: "removeVorlageModal",
        attrs: { selectedElement: _vm.selectedElement, isBroker: true },
        on: {
          error: function($event) {
            _vm.errorStr = $event
            _vm.$refs.fehlerVorlage.open()
          },
          close: function($event) {
            return _vm.loadOpenSigns(_vm.rowsPerPage)
          }
        }
      }),
      _c("OpenSignatureAdditionalDocuments", {
        ref: "zusaetzlicheDokumente",
        attrs: {
          selectedAdditionalDocuments: _vm.selectedAdditionalDocuments,
          title: _vm.getTitle(
            null,
            _vm.selectedOpenSignatureForAdditionalDocuments
          ),
          statusClass: _vm.getStatusClass(
            _vm.selectedOpenSignatureForAdditionalDocuments
          ),
          statusStr: _vm.getStatusStr(
            _vm.selectedOpenSignatureForAdditionalDocuments
          ),
          missingSignatureDescription:
            _vm.selectedOpenSignatureForAdditionalDocuments
              .missingSignatureDescription
        },
        on: {
          close: function($event) {
            _vm.selectedAdditionalDocuments = null
          }
        }
      }),
      _vm.showOffeneUnterschriftenDisableWarnung
        ? _c("OffeneUnterschriftenDisableWarnung", {
            attrs: { selectedElement: _vm.selectedElement },
            on: {
              close: function($event) {
                return _vm.loadOpenSigns(_vm.rowsPerPage)
              }
            }
          })
        : _vm._e(),
      _c("BaseModal", {
        ref: "fehlerVorlage",
        attrs: { showConfirmButton: false, labelButtonCancel: "Ok" },
        scopedSlots: _vm._u([
          {
            key: "modalTitle",
            fn: function() {
              return [
                _c("PhWarning", {
                  staticClass: "mr-2",
                  staticStyle: { color: "orangered" },
                  attrs: { size: 16 }
                }),
                _vm._v(" " + _vm._s(_vm.errorStr) + " ")
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }