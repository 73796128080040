<template>
    <div>
        <InputField
            label="aktueller Bestand gem. Depotpositionen:"
            v-model="form.depotbestand"
            :disabled="true"
            isComponentHalfSize
            type="currency"/>
        <div v-if="showBondDepotRelevant">
            <InputField
                label="beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
                v-model="form.bondDepot"
                type="currency"
                isComponentHalfSize
                :disabled="disabled"
                @input="updateStore('bondDepot', $event || null)" />
            <InputField
                label="nicht beratungsrelevante Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
                v-model="form.bondDepotNotRelevant"
                type="currency"
                isComponentHalfSize
                :disabled="disabled"
                @input="updateStore('bondDepotNotRelevant', $event || null)" />
            </div>
            <div v-else>
            <InputField class="font-bold"
                label="Wertpapierdepots (Gesamtbetrag lt. aktueller Depotauszüge)"
                v-model="form.bondDepot"
                type="currency"
                isComponentHalfSize
                :disabled="disabled"
                @input="updateStore('bondDepot', $event || null)" />
        </div>
    
        <InputField
            label="gebundene Spareinlagen (z.B. Sparbuch, Festgeld)"
            v-model="form.boundBankDeposit"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('boundBankDeposit', $event || null)" />
    
        <InputField
            label="Alternative Investments"
            v-model="form.alternativeInvestment"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('alternativeInvestment', $event || null)" />
        <InputField
            label="Kunstgegenstände, Antiquitäten"
            v-model="form.art"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('art', $event || null)" />
        <InputField
            label="Bausparverträge (aktuelle Werte)"
            v-model="form.buildingSaving"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('buildingSaving', $event || null)" />
        <InputField
            label="Lebensversicherungen, Kapitalversicherungen (aktuelle Rückkaufswerte)"
            v-model="form.insurance"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('insurance', $event || null)" />
        <InputField class="font-bold"
            label="Sonstiges"
            v-model="form.otherInvestment"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('otherInvestment', $event || null)" />

    </div>
  </template>

<script>

import InputField from '@/components/core/forms/InputField.vue';

export default {
    props: {
        disabled: {
            default: false,
        },
        finance: {
            default: () => {},
        },
        showBondDepotRelevant: {
            default: false,
        }
    },
    data() {
        return {
            form: {}
        }
    },
    components: {
        InputField,
    },
    mounted() {
        this.form = Object.assign({}, this.finance);
    },
    methods: {
        updateStore(compId, value) {
            this.$emit('changed', {compId, value});
        },
    },

}
</script>