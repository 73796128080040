var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu,
          tid: "3c886523-0f75-4a62-bb04-2bd422c441b8"
        }
      }),
      _vm.gesamt
        ? _c("div", {}, [
            !_vm.$isSmallScreen
              ? _c(
                  "div",
                  { staticClass: "overview__container" },
                  _vm._l(_vm.gesamt.summen, function(sum, index) {
                    return _c("div", { key: "sum" + index }, [
                      sum && sum[1] !== ""
                        ? _c("div", { staticClass: "box__container" }, [
                            _c(
                              "div",
                              { staticClass: "text-small text-centered mp-0" },
                              [_vm._v(_vm._s(sum[0]))]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "text-centered mp-0",
                                class: _vm.getBadgesClassCategory(sum)
                              },
                              [
                                _c(
                                  "span",
                                  [
                                    _c("CurrencyLabel", {
                                      attrs: { value: sum[1], symb: sum[2] }
                                    })
                                  ],
                                  1
                                )
                              ]
                            )
                          ])
                        : _vm._e()
                    ])
                  }),
                  0
                )
              : _c(
                  "div",
                  { staticClass: "box__container" },
                  [
                    _vm._l(_vm.gesamt.summen, function(sum, index) {
                      return [
                        _c("MobileInfoRow", {
                          key: "sum" + index,
                          attrs: { label: sum[0] },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "value",
                                fn: function() {
                                  return [
                                    _c("span", [
                                      _c(
                                        "span",
                                        [
                                          _c("CurrencyLabel", {
                                            attrs: {
                                              value: sum[1],
                                              symb: sum[2]
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ]
                    })
                  ],
                  2
                )
          ])
        : _vm._e(),
      _vm._l(_vm.depots, function(kunde, index) {
        return _c("div", { key: index }, [
          kunde.kname
            ? _c("div", { staticClass: "box__title box__container mt-4" }, [
                _vm._v(_vm._s(kunde.kname))
              ])
            : _c("div", { staticClass: "mt-4" }),
          _c(
            "div",
            { staticClass: "kacheln" },
            [
              _vm._l(kunde.data, function(item) {
                return [
                  _c(
                    "div",
                    { key: item.key, staticClass: "box__container kachel" },
                    [
                      _c("div", { staticClass: "box__title" }, [
                        _vm._v(_vm._s(item.depotname))
                      ]),
                      _vm._l(item.summen, function(sum, index) {
                        return [
                          _c("MobileInfoRow", {
                            key: "sum" + index,
                            attrs: { label: sum[0] },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "value",
                                  fn: function() {
                                    return [
                                      _c("span", [
                                        _c(
                                          "span",
                                          [
                                            _c("CurrencyLabel", {
                                              attrs: {
                                                value: sum[1],
                                                symb: sum[2]
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ])
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              true
                            )
                          })
                        ]
                      })
                    ],
                    2
                  )
                ]
              })
            ],
            2
          )
        ])
      }),
      !_vm.depots.length
        ? _c(
            "div",
            [
              _c(
                "GhostLoading",
                { attrs: { repeat: 3, useBoxContainer: "", inline: "" } },
                [_c("Block"), _c("Block")],
                1
              ),
              _c(
                "div",
                { staticClass: "kacheln" },
                [
                  _c(
                    "GhostLoading",
                    { staticClass: "kachel" },
                    [
                      _c("Block", { attrs: { type: "title" } }),
                      _c("Block", { attrs: { height: 175 } })
                    ],
                    1
                  ),
                  _c(
                    "GhostLoading",
                    { staticClass: "kachel" },
                    [
                      _c("Block", { attrs: { type: "title" } }),
                      _c("Block", { attrs: { height: 175 } })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }