var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("InputField", {
        attrs: {
          label: "Miete",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("rent", $event || null)
          }
        },
        model: {
          value: _vm.form.rent,
          callback: function($$v) {
            _vm.$set(_vm.form, "rent", $$v)
          },
          expression: "form.rent"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Leasing",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("leasing", $event || null)
          }
        },
        model: {
          value: _vm.form.leasing,
          callback: function($$v) {
            _vm.$set(_vm.form, "leasing", $$v)
          },
          expression: "form.leasing"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Kreditrückzahlung",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("creditPayment", $event || null)
          }
        },
        model: {
          value: _vm.form.creditPayment,
          callback: function($$v) {
            _vm.$set(_vm.form, "creditPayment", $$v)
          },
          expression: "form.creditPayment"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Haushalt",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("household", $event || null)
          }
        },
        model: {
          value: _vm.form.household,
          callback: function($$v) {
            _vm.$set(_vm.form, "household", $$v)
          },
          expression: "form.household"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Unterhalt",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("maintainance", $event || null)
          }
        },
        model: {
          value: _vm.form.maintainance,
          callback: function($$v) {
            _vm.$set(_vm.form, "maintainance", $$v)
          },
          expression: "form.maintainance"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Versicherungsbeiträge",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("insurancePayment", $event || null)
          }
        },
        model: {
          value: _vm.form.insurancePayment,
          callback: function($$v) {
            _vm.$set(_vm.form, "insurancePayment", $$v)
          },
          expression: "form.insurancePayment"
        }
      }),
      _c("InputField", {
        attrs: {
          label: "Sonstiges",
          type: "currency",
          isComponentHalfSize: "",
          disabled: _vm.disabled
        },
        on: {
          input: function($event) {
            return _vm.updateStore("otherPayment", $event || null)
          }
        },
        model: {
          value: _vm.form.otherPayment,
          callback: function($$v) {
            _vm.$set(_vm.form, "otherPayment", $$v)
          },
          expression: "form.otherPayment"
        }
      }),
      _vm.showHinweisBetragNiedrig
        ? _c("InputTextArea", {
            attrs: {
              label: _vm.labelHinweisBetragNiedrig,
              disabled: _vm.disabled
            },
            on: {
              change: function($event) {
                return _vm.updateStore("textHinweisBetragNiedrig", $event)
              }
            },
            model: {
              value: _vm.form.textHinweisBetragNiedrig,
              callback: function($$v) {
                _vm.$set(_vm.form, "textHinweisBetragNiedrig", $$v)
              },
              expression: "form.textHinweisBetragNiedrig"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }