var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "input-forms__container",
      class: { "input-forms__container--embedded": _vm.isEmbedded }
    },
    [
      _c(
        "label",
        { staticClass: "input-forms__label-container" },
        [
          _c("div", { staticClass: "input-field__label" }, [
            _vm._v("Eingabe zur Suche")
          ]),
          _vm.label
            ? _c("div", {
                staticClass: "input-forms__label-content",
                class: _vm.labelClass,
                domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) }
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "input-forms__input-container",
              class: { "forms__input--half-size": this.isComponentHalfSize }
            },
            [
              _vm.verifiedInputType === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalValue,
                        expression: "internalValue"
                      }
                    ],
                    ref: "inputField",
                    staticClass: "fc-input input-forms__input-field",
                    class: _vm.getInputClass,
                    attrs: {
                      id: _vm.id,
                      name: _vm.id,
                      inputmode: _vm.computedInputmode,
                      placeholder: _vm.placeholder,
                      disabled: _vm.disabled,
                      autocomplete: _vm.autocomplete,
                      maxlength: _vm.maxLength,
                      passwordrules: _vm.passwordRules,
                      enterkeyhint: _vm.enterkeyhint,
                      type: "checkbox"
                    },
                    domProps: {
                      checked: Array.isArray(_vm.internalValue)
                        ? _vm._i(_vm.internalValue, null) > -1
                        : _vm.internalValue
                    },
                    on: {
                      blur: _vm.onBlur,
                      focus: _vm.onFocus,
                      change: [
                        function($event) {
                          var $$a = _vm.internalValue,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.internalValue = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.internalValue = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.internalValue = $$c
                          }
                        },
                        _vm.onChange
                      ],
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onKeyboardEnter.apply(null, arguments)
                        },
                        _vm.onKeyUp
                      ],
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        ) {
                          return null
                        }
                        return _vm.onKeyboardTab.apply(null, arguments)
                      },
                      click: _vm.onClick
                    }
                  })
                : _vm.verifiedInputType === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalValue,
                        expression: "internalValue"
                      }
                    ],
                    ref: "inputField",
                    staticClass: "fc-input input-forms__input-field",
                    class: _vm.getInputClass,
                    attrs: {
                      id: _vm.id,
                      name: _vm.id,
                      inputmode: _vm.computedInputmode,
                      placeholder: _vm.placeholder,
                      disabled: _vm.disabled,
                      autocomplete: _vm.autocomplete,
                      maxlength: _vm.maxLength,
                      passwordrules: _vm.passwordRules,
                      enterkeyhint: _vm.enterkeyhint,
                      type: "radio"
                    },
                    domProps: { checked: _vm._q(_vm.internalValue, null) },
                    on: {
                      blur: _vm.onBlur,
                      focus: _vm.onFocus,
                      change: [
                        function($event) {
                          _vm.internalValue = null
                        },
                        _vm.onChange
                      ],
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onKeyboardEnter.apply(null, arguments)
                        },
                        _vm.onKeyUp
                      ],
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        ) {
                          return null
                        }
                        return _vm.onKeyboardTab.apply(null, arguments)
                      },
                      click: _vm.onClick
                    }
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.internalValue,
                        expression: "internalValue"
                      }
                    ],
                    ref: "inputField",
                    staticClass: "fc-input input-forms__input-field",
                    class: _vm.getInputClass,
                    attrs: {
                      id: _vm.id,
                      name: _vm.id,
                      inputmode: _vm.computedInputmode,
                      placeholder: _vm.placeholder,
                      disabled: _vm.disabled,
                      autocomplete: _vm.autocomplete,
                      maxlength: _vm.maxLength,
                      passwordrules: _vm.passwordRules,
                      enterkeyhint: _vm.enterkeyhint,
                      type: _vm.verifiedInputType
                    },
                    domProps: { value: _vm.internalValue },
                    on: {
                      blur: _vm.onBlur,
                      focus: _vm.onFocus,
                      change: _vm.onChange,
                      keyup: [
                        function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.onKeyboardEnter.apply(null, arguments)
                        },
                        _vm.onKeyUp
                      ],
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
                        ) {
                          return null
                        }
                        return _vm.onKeyboardTab.apply(null, arguments)
                      },
                      click: _vm.onClick,
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.internalValue = $event.target.value
                      }
                    }
                  }),
              _vm.type === "percent"
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
                    },
                    [_c("ph-percent", { attrs: { size: 16 } })],
                    1
                  )
                : _vm.type === "formpercent"
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
                    },
                    [_c("ph-percent", { attrs: { size: 16 } })],
                    1
                  )
                : _vm.type === "currency"
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon inputfield__input-icon--text-right"
                    },
                    [_vm._v(" " + _vm._s(this.currency) + " ")]
                  )
                : _vm.type === "calendar"
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--left-side"
                    },
                    [_c("ph-calendar", { attrs: { size: 16 } })],
                    1
                  )
                : _vm.type === "search"
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
                    },
                    [_c("ph-magnifying-glass", { attrs: { size: 16 } })],
                    1
                  )
                : _vm.isCapslookEnabled && this.type === "password"
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
                    },
                    [_c("ph-arrow-square-up", { attrs: { size: 16 } })],
                    1
                  )
                : _vm.showCleanButtonIcon
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
                    },
                    [
                      _c("ph-x-circle", {
                        staticClass: "clickable",
                        attrs: { size: 16, tabindex: "-1" },
                        on: { click: _vm.clearButton }
                      }),
                      _vm.showConfirmationIcon
                        ? _c("ph-check-circle", {
                            staticClass: "color-success",
                            attrs: { size: 16 },
                            on: {
                              click: function($event) {
                                return _vm.confirmationButton($event)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : !_vm.showCleanButtonIcon && _vm.showConfirmationIcon
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side"
                    },
                    [
                      _c("ph-check-circle", {
                        staticClass: "color-success",
                        attrs: { size: 16 },
                        on: {
                          click: function($event) {
                            return _vm.confirmationButton($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.canTogglePasswordView
                ? _c(
                    "button",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side inputfield__input-icon--right-side-2 btn-clear clickable",
                      attrs: { type: "button", tabindex: "-1" },
                      on: {
                        click: function($event) {
                          _vm.isPlainPassword = !_vm.isPlainPassword
                        }
                      }
                    },
                    [
                      _vm.isPlainPassword
                        ? _c("PhEye", {
                            attrs: { size: 16, alt: "Icon für sichtbaren Text" }
                          })
                        : _c("PhEyeSlash", {
                            attrs: {
                              size: 16,
                              alt: "Icon für verborgenen Text"
                            }
                          })
                    ],
                    1
                  )
                : _vm.hasIconSlot()
                ? _c(
                    "span",
                    {
                      staticClass:
                        "inputfield__input-icon forms__input-icon inputfield__input-icon--right-side inputfield__input-icon--right-side-2"
                    },
                    [_vm._t("icon")],
                    2
                  )
                : _vm._e()
            ]
          ),
          _vm.isValidationConfigured()
            ? [
                !_vm.suppressValidationMessage && _vm.validation
                  ? _c(
                      "div",
                      {
                        key: _vm.validation.updated,
                        staticClass: "input-forms__errors-container"
                      },
                      _vm._l(
                        _vm.validation.getErrors(
                          this.validationPath,
                          this.validateUntouched
                        ),
                        function(error) {
                          return _c(
                            "div",
                            { key: error, staticClass: "fc-form-danger" },
                            [_vm._v(" " + _vm._s(error) + " ")]
                          )
                        }
                      ),
                      0
                    )
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }