<template>
  <div>
    <FinanzenLiquiditaet :disabled="isDisabled || isRefusedPageFinance" :finance="finance" :isDialogFVV="isDialogFVV" 
      @changed="valueChangedFinance"/>
    
    <FinanceLineSum  :summe="summeVermoegen" />
  </div>
</template>

<script>

import FinanzenLiquiditaet from '@/components/anlegerprofil/FinanzenLiquiditaet.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanzenLiquiditaet,
    FinanceLineSum,
  },
  mounted() {
    this.updateWarnings();
  },
}
</script>

<style scoped>
</style>