<template>
    <div>
        <InputField
            label="Einnahmen aus unselbständiger Arbeit (Gehaltsabrechnung / netto pro Monat)"
            v-model="form.salary"
            type="currency"
            :disabled="disabled"
            isComponentHalfSize
            @input="updateStore('salary', $event || null)" />
        <InputField
            label="Einnahmen aus selbständiger Arbeit (laut Einkommensteuerbescheid / netto pro Monat)"
            v-model="form.freelancerRevenue"
            type="currency"
            :disabled="disabled"
            isComponentHalfSize
            @input="updateStore('freelancerRevenue', $event || null)" />
        <InputField
            label="Einnahmen aus Kapitalvermögen (z.B. Zinsen, Dividenden, Ausschüttungen pro Monat)"
            v-model="form.capitalRevenue"
            type="currency"
            :disabled="disabled"
            isComponentHalfSize
            @input="updateStore('capitalRevenue', $event || null)" />
        <InputField
            label="Sonstiges (z.B. Unterhaltsbeträge, Mieteinnahmen usw. pro Monat)"
            v-model="form.otherRevenue"
            type="currency"
            isComponentHalfSize
            :disabled="disabled"
            @input="updateStore('otherRevenue', $event || null)" />
    
        <DatePickerField v-if="!isAnlegerprofil"
            label='Letzte Aktualisierung'
            isComponentHalfSize
            v-model="form.lastUpdate"
            @input="updateStore('lastUpdate', $event)" />

    </div>
  </template>

<script>

import InputField from '@/components/core/forms/InputField.vue';
import DatePickerField from "@/components/core/forms/date-picker2/DatePickerField.vue";

export default {
    props: {
        disabled: {
            default: false,
        },
        finance: {
            default: () => {},
        },
        isAnlegerprofil: {
            default: false,
        }
    },
    data() {
        return {
            form: {}
        }
    },
    components: {
        InputField,
        DatePickerField,
    },
    mounted() {
        this.form = Object.assign({}, this.finance);
    },
    methods: {
        updateStore(compId, value) {
            this.$emit('changed', {compId, value});
        },
    },

}
</script>