import EXTERNE_ZUGANG_TYPES from './types';
import CORE_TYPES from '@/store/core/types'
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import { buildMessage } from '@/helpers/log-message-helper';

import { MenuItem, } from '@/menu/menu-utils';
import UndrawLogin from '@/components/icons/undraw/UndrawLogin.vue';

import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';

const config = {
  defaultSpinner: true
};

const SERVICE_PATH = '/externe_zugang'

export default {
  async [EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_LIST]({ commit, getters }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/list_externe_zugang_vergleich`, config );
    commit(EXTERNE_ZUGANG_TYPES.MUTATIONS.GET_EXTERNE_ZUGANG_LIST_VERGLEICH_SUCCESS, [ ...response?.data || [] ]);
  },

  async [EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_ABSCHLUSS_LIST]({ commit, getters }) {
    const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/list_externe_zugang_abschluss`, config );
    
    if (response.data) {
      commit(EXTERNE_ZUGANG_TYPES.MUTATIONS.GET_EXTERNE_ZUGANG_LIST_ABSCHLUSS_SUCCESS, response.data);
    }
  },

  async [EXTERNE_ZUGANG_TYPES.ACTIONS.OPEN_EXTERNE_ZUGANG](ctx, { path, options, idList }) {
    const params = {
      ...(options ? options : [{}, {}]),
    }

    if (idList?.length) {
      path = `${path}?`
      idList.forEach(item => {
        path += `idList=${item.vertragId}&`;
      });

      path = path.substr(0,path.length-1);
    } 

    openLink(ResolverId.ExterneZugang, { 
      path, 
      params, 
    });
  },
  async [EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_OPTION_MENU]({ dispatch, getters, }) {
    await dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_LIST);

    const externeZugangVergleichList = getters[EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_LIST] || [];

    return externeZugangVergleichList
      .map(externeZugang => {
        let path = '';
        switch (externeZugang?.itemLabel) {
          case "Nafi-KFZ":
          case "Finanzportal24":
          case "Franke & Bornberg fb-tools":
          case "Tarifrechner Nürnberger BT4All":
          case "Innosystems":
            path = `/beratung/vergleichsrechner/${encodeURIComponent(externeZugang.itemLabel)}?path=${externeZugang.path}`
            break;
          case "Chegg.net":  
            path = `/beratung/vergleichsrechner/open-externe-zugang?path=${externeZugang.path}`
            break;
          case "Chegg.net Dokumente abholen":
            path = '/beratung/vergleichsrechner/dokumente-abholen';
            break;
          default:
            if (externeZugang.askOptions) {
              path = `/beratung/vergleichsrechner/ask-options/${encodeURIComponent(externeZugang.itemLabel)}?path=${externeZugang.path}`;
            } else {
              path = `/beratung/vergleichsrechner/open-externe-zugang?path=${externeZugang.path}`;
            }
            break;
        }
        return MenuItem(path, externeZugang?.labelHtml || externeZugang?.itemLabel, UndrawLogin)
      });
  },


  async [EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS]({ dispatch, getters, commit }) {
    await dispatch(EXTERNE_ZUGANG_TYPES.ACTIONS.GET_EXTERNE_ZUGANG_VERGLEICH_LIST);

    const externeZugangVergleichList = getters[EXTERNE_ZUGANG_TYPES.GETTERS.EXTERNE_ZUGANG_VERGLEICH_LIST] || [];

    const result = externeZugangVergleichList
      .map(externeZugang => {
        let path = '';
        switch (externeZugang?.itemLabel) {
          case "Nafi-KFZ":
          case "Finanzportal24":
          case "Franke & Bornberg fb-tools":
          case "Tarifrechner Nürnberger BT4All":
          case "Innosystems":
            path = `/beratung/vergleichsrechner/${encodeURIComponent(externeZugang.itemLabel)}?path=${externeZugang.path}`
            break;
          case "Chegg.net":  
            path = `/beratung/vergleichsrechner/open-externe-zugang?path=${externeZugang.path}`
            break;
          case "Chegg.net Dokumente abholen":
            path = '/beratung/vergleichsrechner/dokumente-abholen';
            break;
          default:
            if (externeZugang.askOptions) {
              path = `/beratung/vergleichsrechner/ask-options/${encodeURIComponent(externeZugang.itemLabel)}?path=${externeZugang.path}`;
            } else {
              path = `/beratung/vergleichsrechner/open-externe-zugang?path=${externeZugang.path}`;
            }
            break;
        }
        return PageHeaderSimpleAction(externeZugang?.key, externeZugang?.itemLabel).withValue({askOptions: externeZugang?.askOptions, data: externeZugang?.data, path, companyLabel: externeZugang?.companyLabel, isExterneZugangAction: true })
      });

      commit(EXTERNE_ZUGANG_TYPES.MUTATIONS.GET_EXTERNE_ZUGANG_VERGLEICH_PAGE_HEADER_ACTIONS_SUCCESS, result);
  },  

  
  async [EXTERNE_ZUGANG_TYPES.ACTIONS.GET_FFP_PLUS_LOGIN_DATA]({ getters }, {type}) {
    if (type) {
      const response = await axios.get(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/get_ffp_plus_login_data?type=${type}`, config );
      return response?.data;
    }
  },

  [EXTERNE_ZUGANG_TYPES.ACTIONS.GET_FFP_PLUS_URL]({ getters, dispatch }, {type, data, name}) {
    if (type) {
      axios.post(`${getters[CORE_TYPES.GETTERS.API_ADDRESS]}${SERVICE_PATH}/get_ffp_plus_login_url/${type}`, data, config ).then(response => {
        if (response.data?.url) {
          window.open(response.data.url, '_blank')
        } else {
          dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage((name || 'Die Seite') + ' kann nicht geöffnet werden.', 'danger'));
        }
      })
    }
  },

}