<template>
  <div>
    <GhostLoading v-if="filterLoading" useBoxContainer>
      <Block height="200" />
    </GhostLoading>
    <div v-else>
      <GenericPersonFilter  ref="filter"
        v-if="!filterLoading"
        filterId="7b8ff9e5-10b1-4cf4-8f94-2d54d19d56fd"
        :saveKey="saveKeyFilter" 
        title="Wertpapierfilter"
        :metadata="filter" 
        :configFilter="configFilter"
        :predefinedFilter="predefinedFilter" 
        :searchFromParam="searchFromParam"
        :extraButton="extraButton"
        :defaultOptions="defaultOptions"
        :showSearchButton="showSearchButton"
        :hasSmartSearch="!isFA || !isCourtage"
        @search="handleSearch"
        @extraButtonClick="$emit('extraButtonClick')"
      />
    </div>

    <template v-if="!emitFilters">
      <div v-if="rows.length" class="box__container">
        <slot name="beforeTable" ></slot>

        <WertpapierTable
          showMobileSelectionAlways
          :tableId="tableId"
          :rows="rows"
          :headers="headers"
          :loading="loading"
          :exportConfig="exportConfig"
          :singleSelection="singleSelection"
          :selected="isRowSelectable ? selectedRows : undefined"
          :headerActions="tableHeaderActions"
          :canAddToFavoritenliste="canAddToFavoritenliste"
          :canAddToMyDepot="canAddToMyDepot"
          :canAddToFondsvergleich="canAddToFondsvergleich"
          :type="type"
          :scrollHorizontally="scrollHorizontally"
          @selected="onSelect"
          @action="handleTableAction"
          @headerAction="$emit('headerAction', $event)"
        />

        <slot name="afterTable" ></slot>

        <BaseButton v-if="responseType === 1" class="mt-2" @click="emitSelected()" :disabled="!selectedRows.length">
            Markierung übernehmen
        </BaseButton>
      </div>
      <GhostLoading v-else-if="loading" type="table" :title="TABLE_TITLE" useBoxContainer />
      <NoData v-else-if="outcome" :title="TABLE_TITLE" content="Es wurden keine Einträge gefunden" useBoxContainer />
    </template>
  </div>
</template>

<script>
import WERTPAPIERINFO_TYPES from "@/store/wertpapierinfo/types";
import LOG_TYPES from '@/store/log/types';
import { mapGetters } from 'vuex';
import BaseButton from '@/components/core/BaseButton.vue';
import GenericPersonFilter from '@/components/core/GenericPersonFilter.vue';
import {wpFilterMixin, wpListeMixin} from '@/mixins/wp-suche.js';
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import dayjs from 'dayjs';
import WertpapierTable from '@/views/brokerData/WertpapierTable.vue'

const TABLE_TITLE = 'Wertpapierliste';

export default {
  name: 'WertpapierFilter',
  components: {
    BaseButton,
    GenericPersonFilter,
    NoData, GhostLoading, Block,
    WertpapierTable,
  },
  mixins: [wpFilterMixin, wpListeMixin],
  props: {
    name: {
      type: String,
      default: 'search',
    },
    type: { // 'I', 'Z', 'B'
      type: String,
      default: 'I',
    },
    withZ: { // Investment with Zertifikat
      type: Boolean,
      default: false,
    },
    gesellschaft: {
      type: String,
      default: '',
    },
    lagerstelle: {
      type: String,
      default: '',
    },
    respond: {
      type: Number,
      default: 0,
    },
    emitFilters: {
      type: Boolean,
      default: false
    },
    isCourtage: {
      type: Boolean,
      default: false
    },
    predefinedFilters:{
      type: Array,
      default: () => []
    },
    extraButton: {
      type: Object,
      default: () => (null)
    },
    initSelected:{
      type: Array,
      default: () => []
    },
    defaultOptions: {
      type: Object,
      default: () => ({})
    },
    showSearchButton: {
      type: Boolean,
      default: true,
    },
    isSelectable: {
      type: Boolean,
      default: false,
    },
    singleSelection: {
      type: Boolean,
      default: false,
    },
    tableHeaderActions: {
      type: Array,
      default: () => [],
    },
    canAddToFavoritenliste: {
      type: Boolean,
      default: false,
    },
    canAddToMyDepot: {
      type: Boolean,
      default: false,
    },
    canAddToFondsvergleich: {
      type: Boolean,
      default: false,
    },
    scrollHorizontally: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      TABLE_TITLE,
      loading: false,
      doRender: 1, // force rendering
      busy: false,
      tableId: '',
      searchFromParam: '',
      isMounted: false,
      selectedRows: [],
    }
  },
  created() {
    this.act = this.name +'.'+ this.gesellschaft +'.'+ this.lagerstelle;
    this.actFilter = this.act;
    this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.ADD_ACT, this.act);
    this.initFilter();
  },
  mounted() {
    this.isMounted = true;
  },
  computed: {
    ...mapGetters({
      getWPIFilter: WERTPAPIERINFO_TYPES.GETTERS.FILTER,
      getWPIAct: WERTPAPIERINFO_TYPES.GETTERS.ACT,
    }),
    isRowSelectable() {
      return this.isSelectable || this.$route.meta?.dispatchSelected
    },
    responseType() {
      if(this.$route.meta?.dispatchSelected) {
        return 1;
      }
      return this.respond
    },
    outcome() {
      return this.getWPIAct(this.act)?.outcome
    },  
    config() {
      return !this.doRender ? null : this.getWPIFilter;
    },
    instData() {
      const result = this.getWPIAct(this.act);
      return !this.doRender ? null : result;
    },
    headers() {
      return this.outcome?.headers || []
    },
    rows() {
      return this.outcome?.records || []
    },
    exportConfig() {
      const zeit = dayjs(new Date()).format('DD-MMM-YYYY HH mm');
      return {
        pdf: {
          show: true,
          name: 'Wertpapierliste ' + zeit,
          title: 'Wertpapierliste - FULLNAME',
          keys: this.keys
        },
        xls: {
          show: true,
          name: 'Wertpapierliste ' + zeit,
          title: 'Wertpapierliste - FULLNAME',
          keys: this.keys
        }
      }
    }
  },
  methods: {
    async getFilter() {
      try {
        if (!this.getWPIFilter){
          await this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.CONFIG, { isCourtage: this.isCourtage });
        }
        if ( this.type ) {
          switch (this.type.charAt(0).toUpperCase()) {
            case 'Z': // Zertifikate
              this.setWPS('2')
              break;
            case 'B': // Beteiligung
              this.setWPS('3')
              break;
            default: // Investment
              this.setWPS('1')
              break;
          }

          if (this.$route.query.searchParam) {
            this.addFilter({filter: this.searchFilter, key: '', name: ''}, true);
            this.defValue = this.$route.query.searchParam;
            this.searchFromParam = this.$route.query.searchParam;
            this.handleSearch({defaultSearchInput: this.$route.query.searchParam});
          }           
        }
      } catch (error) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, {message: "getFilter", error});
      }
    },
    setWPS(type) {

      this.setTableId(type);

      if ( type !== this.phase.wp_type ) {
        const f_state = {item: type + '.1.01'};
        this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {
          act: this.act, 
          phase: {wp_type: type, f_state: f_state}, 
          filters: [],
          outcome: null
        });
        this.defValue = '';
        if(!this.predefinedFilters?.length) {
          this.predefinedFilter = {};
        }
        const bf = this.getBaseFilter();
        if (bf) {
          bf.filterZurucksetzen();
        }
        this.doRender++;
        this.configFilter.defaultSearchInputKeys = [type + '.1.02', type + '.1.03', type + '.1.04']; // 'ISIN', 'WKN', 'Fondsname' search keys
      }
      this.configFilter.programmaticSearchParameter = this.apTerms;
    },
    setTableId(type) {
      const idx = parseInt(type) - 1;
      // this.saveKeyFilter = ['searchInvestmentfonds','searchZertifikate','searchBeteiligungen'][idx];
      this.tableId = [
        'bbaf1df0-bef3-49d5-b88d-c03890a8db0e',
        'dbda6ba9-fc17-4ea2-a9c4-563c8b367fe0',
        'da0559f3-b777-4019-aade-e36ed91c03f3',
        '4e0e84db-bc3a-4e4a-87fa-eea4d638359f',
        '934a4c92-187b-47ad-b8f7-6400e6704ed4',
        '1a5a37ee-2b56-4237-b490-b6d4e03203fc',
      ][idx + (this.responseType ? 3 : 0)];
    },
    setFilter(filters) {
      if ( this.isMounted ){
        this.$store.commit(WERTPAPIERINFO_TYPES.MUTATIONS.SET_ACT, {act: this.act, filters: filters, outcome: null} );
        this.$emit('reset-filter');
      }
    },
    filterOk() {
      if ( this.isMounted ){
        this.getOutcome();
      }
    },
    async getOutcome() {
      try {
        this.loading = true;
        await this.$store.dispatch(WERTPAPIERINFO_TYPES.ACTIONS.OUTCOME, {
            act: this.act,
            gesellschaft: this.gesellschaft,
            lagerstelle: this.lagerstelle,
            isCourtage: this.isCourtage,
            zusatz: {withZertifikat: this.type === 'I' ? this.withZ : null}
          });

        this.selectedRows = this.outcome?.records?.filter(r => r.selected) || [];

        this.busy = false;
      } finally {
        this.loading = false;
      }
    },
    onSelect(rows) {
      this.selectedRows = rows
      this.$emit('selected', this.selectedRows);
    },
    emitSelected() {
      if (this.$route.meta?.dispatchSelected) {
        if (typeof this.$route.meta?.dispatchSelected == 'function') {
          this.$store.dispatch(this.$route.meta?.dispatchSelected(), this.selectedRows)
        } else {
          this.$store.dispatch(this.$route.meta?.dispatchSelected, this.selectedRows)
        }
        // if (this.$breadcrumbs?.length) {
        //   const path = this.$breadcrumbs[this.$breadcrumbs.length - 1].fullPath;
        //   this.$router.push({ path, query: { backAction: true}})
        // }
        if (this.$refs.filter?.$refs?.baseFilter?.filterZurucksetzen) {
          this.$refs.filter.$refs.baseFilter.filterZurucksetzen()
        }
      } else {
        this.$emit('confirm-selected', this.selectedRows);
      }
    },
  },
}
</script>