<template>
  <div>
      <div class="row">
        <div class="col-12">
          <BoxContainer title="">
            <div class="d-flex">
              <div class="box__title" style="flex-grow: 1;">Wertentwicklung</div>
              <ComboBox v-if="zoom" :value="years" :values="zoom" @change="onChangeYear" />
            </div>
            <ChartFunds type="Fondsinfo" :chartData="chartPerf"/>
            <!-- <ChartPerformance :chartData="perfData" :zoom="perfZoom" :isin="isin"></ChartPerformance> -->
          </BoxContainer>
        </div>
        <div class="col-12">
          <BoxContainer title="Jährliche Rendite">
            <BarChart format="percent" height="400px" :chartOptions="chartRendite" gridLeft="0%"/>
          </BoxContainer>
        </div>
        <div class="col-12">
          <BoxContainer title="Rendite / Risiko">
            <ChartYieldRisk :chartParam="riskChartParams"></ChartYieldRisk>
            <div class="info">Das Rot dargestellte <span class="color-danger">ISIN</span> bezeichnet nicht handelbare Wertpapiere.</div>
            <template v-if="isBrokerOrBypass">
              <Table class="mt-4" 
                title="Fonds merken mit klick auf Punkten in Rendite / Risiko Grafik"
                :headers="headers"
                :rows="rows"
                rowId="isin"
                :rowsPerPage="15"
                :tableRowsPerPage="[]"
                :headerActions="headerActions"
                v-model="selectedRows"
                @click-fondsname="gotoFondsinfo"
                @action-ADD_FAVORITENLISTE="$refs.addFondsToListModal.open(fonds, 'Favoritenlisten')"
                @action-ADD_MYDEPOT="$refs.addFondsToListModal.open(fonds, 'MyDepot')"
                @action-ADD_FONDSVERGLEICH="$refs.addFondsToListModal.open(fonds, 'Fondsvergleich')"
                @headerAction="headerAction"
              />
              
              <AddFondsToListModal ref="addFondsToListModal" />
            </template>
          </BoxContainer>
        </div>
        <div class="col-12">
          <BoxContainer title="Monte-Carlo-Simulation">
            <ChartMontecarlo :isin="isin"></ChartMontecarlo>
          </BoxContainer>
        </div>
      </div>
  </div>
</template>

<script>
import FONDSINFO_TYPES from "@/store/fondsinfo/types";
import CORE_TYPES from "@/store/core/types";
import BoxContainer from "@/components/core/BoxContainer.vue";
import { mapGetters } from "vuex";
import ChartPerformance from "@/components/charts/ChartPerformance.vue";
import ChartFunds from '@/components/charts/ChartFunds.vue';
import ChartMontecarlo from "../charts/ChartMontecarlo.vue";
import {utils} from '@/components/charts/chartFondsUtil.js';
import ChartYieldRisk from "@/components/charts/ChartYieldRisk.vue";
import BarChart from "@/components/charts/echarts/BarChart.vue";
import ComboBox from '@/components/core/forms/ComboBox.vue';
import Table from "@/components/table2/Table.vue";
import { TextColumn, NumberColumn, SimpleAction, ActionColumn} from "@/components/table2/table_util.js";
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import AddFondsToListModal from '@/components/fondsinfo/AddFondsToListModal.vue';

export default {
  components: {
    BoxContainer,
    ChartPerformance, 
    ChartFunds,
    ChartYieldRisk,
    ChartMontecarlo,
    BarChart,
    ComboBox,
    Table,
    AddFondsToListModal,
  },
  name: "TabCharts",
  props: {
    selectedTabLabel: {
      type: String,
    },
  },
  data(){
    return {
      riskChartParams: {
        isin: this.$route.params?.isin,
        year: 5,
        chartComp: this.addOn,
      },
      chartPerf: {},
      perfData: [],
      perfZoom: [],
      isin: this.$route.params?.isin,
      years: 5,
      zoom: null,
      headers: {
        lockedLeft: [
          TextColumn("isin", "ISIN").addCellProps({displayWithClass: this.displayIsin}),
          TextColumn("fondsname", "Fondsname").makeLink(),
          NumberColumn("perf", "Performance",2),
          NumberColumn("vola", "Volatilität",2),
        ],
        center: [],
        lockedRight: [
          ActionColumn('actions', '')
        ]
      },
      rows: [],
      selectedRows: [],
      headerActions: [
        PageHeaderSimpleAction('ADD_FAVORITENLISTE', 'Markierung zur Favoritenliste hinzufügen')
          .withDisabled(() => !this.selectedRows.length),
        PageHeaderSimpleAction('ADD_MYDEPOT', 'Markierung zum Musterdepot hinzufügen')
          .withDisabled(() => !this.selectedRows.length),
        PageHeaderSimpleAction('ADD_FONDSVERGLEICH', 'Markierung zum Fondsvergleich hinzufügen')
          .withDisabled(() => !this.selectedRows.length),
      ],
      rowActions: [
        SimpleAction('ADD_FAVORITENLISTE', 'PhPlusCircle', 'Zur Favoritenliste hinzufügen'),
        SimpleAction('ADD_MYDEPOT', 'PhPlusCircle', 'Zum Musterdepot hinzufügen'),
        SimpleAction('ADD_FONDSVERGLEICH', 'PhPlusCircle', 'Zum Fondsvergleich hinzufügen'),
      ],
    }
  },
  computed: {
    ...mapGetters({
      performanceChart: FONDSINFO_TYPES.GETTERS.FONDSINFO_PERFORMANCE_CHART,
      rendite: FONDSINFO_TYPES.GETTERS.FONDSINFO_RENDITE_CHART,
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
    }),
    chartRendite() {
      if (this.rendite) {
        const chartOptions = utils.jaehrlicheTendite(this.rendite, this.isSmallScreen);
        return chartOptions;
      }
      return {};
    }
  },
  mounted() {
    this.loadPerformance();
  },
  watch: {
    performanceChart: 'receiveData',
  },
  methods: {
    loadPerformance() {
      this.$store.dispatch(
        FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_PERFORMANCE_CHART,
        {
          isin: this.isin,
          years: this.years
        }
      );
      this.chartPerf = {};
      this.$store.dispatch(FONDSINFO_TYPES.ACTIONS.GET_FONDSINFO_RENDITE_CHART, {isin: this.isin, years: this.years} );
    },
    receiveData() {
      if(this.performanceChart){
        this.perfData = this.performanceChart.records;
        this.chartPerf = utils.convSerieData([{'name': 'Wertentwicklung', 'data': this.performanceChart.records}])[0];
     
        const series = utils.addGD200Series(this.chartPerf);
        if (this.performanceChart.rows.length) {
          this.perfZoom = this.performanceChart.rows[0].zoom;
        }
        //
        if (this.zoom == null && this.chartPerf?.data?.length){
          let test = new Date();
          test = new Date(test.getFullYear() - 5, test.getMonth(), test.getDate() + 3);
          if (test.getTime() > this.chartPerf.data[0][0]) {
            this.zoom = [{label: '5Jahre', value: 5}, {label: 'Seit Beginn', value: 100}]
          }
        }
      }
    },
    onChangeYear(years) {
      this.years = years;
      this.loadPerformance();
    },
    addOn(chart, series) {
      chart.on( 'click', {seriesName: series[1].name}, this.onClickScatter)
    },
    onClickScatter({value}) {
      console.log(value)
      const row = {
        isin: value[2][1],
        fondsname: value[2][0],
        vola: value[0],
        perf: value[1],
        actions: this.rowActions,
        isNichtHandelbar: value[2].length > 2 && value[2][2] == '1'
      }
      if (!this.rows.find(r => r.isin == row.isin)) {
        this.rows.push(row);
        this.selectedRows.push(row);
      }
    },
    headerAction({key}) {
      let liste = '';
      if (key === 'ADD_FAVORITENLISTE') {
        liste = 'Favoritenlisten'
      } else if (key === 'ADD_MYDEPOT') {
        liste = 'MyDepot'
      } else if (key == 'ADD_FONDSVERGLEICH') {
        liste = 'Fondsvergleich'
      }
      if (liste) {
        this.$refs.addFondsToListModal.open(this.selectedRows, liste)
      }
    },  
    gotoFondsinfo({isin}) {
      this.$addBreadcrumb({
        breadcrumb: 'Fondsinfo',
        fullPath: this.$route.fullPath,
      });
      this.$router.push({ path: `/shared/vermogensubersicht/fondsinfo/${isin}` });
    },
    displayIsin(cell, row) {
      if (row.isNichtHandelbar) {
        return [{class: 'color-danger', text: cell}];
      } else {
        return [{class: '', text: cell}]
      }
    }
  }
};
</script>

<style scoped>
.info {
  font-size: 80%;
  margin: 8px 0;
  font-family: "sans";
}
</style>