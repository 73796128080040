<template>
  <div class="label-value--list">
    <div class="label-value--item" v-if="!isMehrfachagent && !versAdminA && zugriffVersicherungen">
      <div class="label-value--item-label">
        <template v-if="zugriffVersicherungen">
          <DownloadLink 
            v-if="steckBriefZusatz.maklervollmacht && steckBriefZusatz.maklervollmachtFile && !isFPPlus"
            :title="maklervollmachtLabel"
            :filename="steckBriefZusatz.maklervollmachtFile.name"
            downloadServicePath="/docId"
            :queryParams="{
              docId: steckBriefZusatz.maklervollmachtFile.dokId
            }"
          />

          <DownloadLink 
            v-else-if="steckBriefZusatz.maklervollmacht && isFA && !isFPPlus"
            :title="maklervollmachtLabel"
            filename="Antrag.pdf"
            downloadServicePath="/getAktuelleMaklervollmacht"
          />

          <a v-else-if="!isFPPlus || steckBriefZusatz.maklervollmachtDynamicFormId" @click.stop="redirectToMaklervollmacht()">{{ maklervollmachtLabel }}</a>

          <span v-else>{{ maklervollmachtLabel }}</span>
        </template>
        <template v-else>
          <span>{{ maklervollmachtLabel }}</span>
        </template>
      </div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.maklervollmacht">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div class="label-value--item" v-if="isMehrfachagent && steckBriefZusatz.maklervollmacht && zugriffVersicherungen">
      <div class="label-value--item-label">
        <DownloadLink 
          v-if="steckBriefZusatz.maklervollmacht && steckBriefZusatz.maklervollmachtFile && !isFPPlus"
          title="Mehrfachagentenvollmacht"
          :filename="steckBriefZusatz.maklervollmachtFile.name"
          downloadServicePath="/docId"
          :queryParams="{
            docId: steckBriefZusatz.maklervollmachtFile.dokId
          }"
        />

        <a v-else-if="!isFPPlus || steckBriefZusatz.maklervertragDynamicFormId" @click.stop="redirectToMaklerVertrag()">Mehrfachagentenvollmacht</a>
        <span v-else>Mehrfachagentenvollmacht</span>
      </div>
      <div class="label-value--item-value">
        <template>
          <div class="color-success"><PhCheck /></div>
        </template>
      </div>
    </div>
    <div class="label-value--item" v-if="!isMehrfachagent  && !versAdminA && zugriffVersicherungen">
      <div v-if="!isMehrfachagent" class="label-value--item-label">
        <template v-if="zugriffVersicherungen">
          <DownloadLink 
            v-if="steckBriefZusatz.maklervertrag && steckBriefZusatz.maklervertragFile && !isFPPlus"
            :title="maklervertragLabel"
            :filename="steckBriefZusatz.maklervertragFile.name"
            downloadServicePath="/docId"
            :queryParams="{
              docId: steckBriefZusatz.maklervertragFile.dokId
            }"
          />

          <DownloadLink 
            v-else-if="steckBriefZusatz.maklervertrag && isFA && !isFPPlus"
            :title="maklervertragLabel"
            filename="Antrag.pdf"
            downloadServicePath="/getAktuellerMaklervertrag"
          />

          <a v-else-if="!isFPPlus || steckBriefZusatz.maklervertragDynamicFormId" @click.stop="redirectToMaklerVertrag()">{{ maklervertragLabel }}</a>
          <span v-else>{{ maklervertragLabel }}</span>
        </template>
        <template v-else>
          <span>{{ maklervertragLabel }}</span>
        </template>
      </div>
      <div v-else class="label-value--item-label">Mehrfachagent</div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.maklervertrag">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    
    <div v-if="isFK" class="label-value--item">
      <div class="label-value--item-label">Datenschutz</div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.datenSchutz">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div v-else-if="isFA" class="label-value--item">
      <div class="label-value--item-label">Informationsblatt</div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.informationsblatt">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div v-if="zugriffInvestmentFonds" class="label-value--item">
      <div class="label-value--item-label">Rahmenvereinbarung{{standRahmenvereinbarung}}</div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.rahmenvereinbarung">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div class="label-value--item">
      <div class="label-value--item-label"><a @click.stop="redirectToLegitimationsdokumente">Legitimationsdokumente</a>&nbsp;(gültig bis {{legiGueltigBis}})</div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.legitiation">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div class="label-value--item">
      <div class="label-value--item-label">Zugang {{appNameDefault}}<span v-if="steckBriefZusatz.lastKSCLogin"> ({{steckBriefZusatz.lastKSCLogin}})</span></div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.lastKSCLogin">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div class="label-value--item">
      <div class="label-value--item-label">Zugang App<span v-if="steckBriefZusatz.lastAppLogin"> ({{steckBriefZusatz.lastAppLogin}})</span></div>
      <div class="label-value--item-value">
        <template v-if="steckBriefZusatz.lastAppLogin">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
    <div v-if="zugriffInvestmentFonds" class="label-value--item">
      <div class="label-value--item-label"><a @click.stop="redirectToAnlegerprofil()">Anlegerprofil</a></div>
      <div class="label-value--item-value">
        <template v-if="info.haveSignedAP">
          <div class="color-success"><PhCheck /></div>
        </template>
        <template v-else>
          <div class="color-danger"><PhWarning /></div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import STECKBRIEF_TYPES from '../store/types';
import CORE_TYPES from '@/store/core/types';
import { PhCheck, PhWarning  } from 'phosphor-vue';
import { BROKER_STRUCTURE_ROLES } from '@/router/roles';
import DownloadLink from '@/components/core/download/DownloadLink.vue';

export default {
  computed: {
    ...mapGetters({
      info: STECKBRIEF_TYPES.GETTERS.STECKBRIEF_CUSTOMER_INFO,
      isMehrfachagent: CORE_TYPES.GETTERS.IS_MEHRFACHAGENT,
      isFK: CORE_TYPES.GETTERS.IS_FK,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFPPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      versAdminA: CORE_TYPES.GETTERS.VERSADMIN_A,
      apiAddress: CORE_TYPES.GETTERS.API_ADDRESS,
      token: CORE_TYPES.GETTERS.GET_TOKEN,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      appNameDefault: CORE_TYPES.GETTERS.APP_NAME_DEFAULT,
    }),
    zugriffVersicherungen() {
      return this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_VERSICHERUNGEN])
    },
    zugriffInvestmentFonds() {
      return this.hasRoles([BROKER_STRUCTURE_ROLES.ZUGRIFF_INVESTMENT_FONDS]);
    },
    steckBriefZusatz() {
      return this.info?.steckBreifZusatz || {}
    },
    maklervollmachtLabel() {
      return this.steckBriefZusatz.maklervollmachtLabel || 'Maklervollmacht'
    },
    maklervertragLabel() {
      return this.steckBriefZusatz.maklervertragLabel || 'Maklervertrag'
    },
    legiGueltigBis() {
      const datum = this.info?.person?.legitimation?.validTo;
      return datum ? datum : 'nicht erfasst';
    },
    standRahmenvereinbarung() {
      const datum = this.info?.steckBreifZusatz?.rahmenvereinbarungDatum;
      return datum ? ` (erstellt am ${datum})` : '';
    },
  },
  components: {
    PhCheck, 
    PhWarning,
    DownloadLink,
  },
  methods: {
    redirectToAnlegerprofil() {
      this.$router.push({ path: '/beratung/anlegerprofil/person1/auswahl/personenauswahl?schiene=INVESTMENT' });
    },
    redirectToMaklerVertrag() {
      if (this.steckBriefZusatz.maklervertragDynamicFormId) {
        this.$router.push({
          path: `/beratung/formulare/antrag/VERSICHERUNG/${this.steckBriefZusatz.maklervertragDynamicPath}/`,
          query: { hideBeratungsmappepopup: true, lagerstelleLabel: 'Formulare Versicherung', dynamicFormId:  this.steckBriefZusatz.maklervertragDynamicFormId },
        });  
        return
      }

      let formPath = '/beratung/formulare/antrag/VERSICHERUNG/maklerauftrag/';
      let labelParameter = 'Formulare Versicherung';

      if (this.isFA) {
        const baseURL = 'VERSADMIN_M';
        labelParameter = 'VersAdminM';
        formPath = `/beratung/formulare/antrag/${baseURL}/vertragdaten/versicherungsmaklervertrag`;         
      }   
      
      this.$router.push({
        path: formPath,
        query: { hideBeratungsmappepopup: true, lagerstelleLabel: labelParameter },
      });  
    },
    redirectToLegitimationsdokumente() {
      this.$router.push({ path: '/persoenlichedaten/customer-data/steps/stammdaten?substep=legitimationsurkunde' });
    },    
    redirectToMaklervollmacht() {
      if (this.steckBriefZusatz.maklervollmachtDynamicFormId) {
        this.$router.push({
          path: `/beratung/formulare/antrag/VERSICHERUNG/${this.steckBriefZusatz.maklervollmachtDynamicPath}/`,
          query: { hideBeratungsmappepopup: true, lagerstelleLabel: 'Formulare Versicherung', dynamicFormId:  this.steckBriefZusatz.maklervollmachtDynamicFormId},
        });  
        return
      }

      let formPath = '/beratung/formulare/antrag/VERSICHERUNG/maklerauftrag/';
      let labelParameter = 'Formulare Versicherung';

      if (this.isFA) {
        const baseURL = 'VERSADMIN_M';
        labelParameter = 'VersAdminM';
        formPath = `/beratung/formulare/antrag/${baseURL}/maklervollmacht/maklervollmacht`;           
      }

      this.$router.push({
        path: formPath,
        query: { hideBeratungsmappepopup: true, lagerstelleLabel: labelParameter },
      });
    },  
  }
}
</script>

<style src='../steckbrief.scss' lang='scss' scoped></style>
