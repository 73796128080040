<template>
<div>
  <DepotpositionsNavigation 
    :title="depotTitle"
    :subtitle="hasDepotpositions && depotpositions[depotid].guvDate ? depotpositions[depotid].guvDate : ''" 
    :headerActions="headerActions">
    <template v-slot:subtitle v-if="hasVertrag">
      <span v-if="hasDepotpositions && depotpositions[depotid].guvDate">{{depotpositions[depotid].guvDate}} | </span>
      <a target="_blank" @click="openVertragdOverview()"> {{depotpositions[depotid].versVertrag.versNr}}</a>
    </template>
  </DepotpositionsNavigation>

  <HinweiseUndFehler :hints="hints"  />

  <div v-if="hasDepotpositions">
    <div v-if="!$isSmallScreen" class="depotpositions-categories__container">
      <div v-for="(sum, index) of getDataFromLabels(depotpositions[depotid].resultSums)" :key="'sum'+index">
        <div v-if="sum && sum[1] !== ''" class="box__container"  :tid="_generateTidFromString('box__container'+'sum'+index)">
          <div class="text-small text-center mp-0">{{sum[0]}}</div>
          <div class="text-center mp-0" :class="getBadgesClassCategory(sum)">
            <span v-if="sum[2] === 'LABEL_DEPOT_WERTENTWICKLUNG'">
              <template v-if="sum[3]">{{convertStringToNumber(sum[1])}} %</template>
              <template v-else><CurrencyLabel :value="convertStringToNumber(sum[1])"/></template>
            </span>
            <span v-else-if="isNumber(sum[1])">
              <CurrencyLabel :value="convertStringToNumber(sum[1])"/>
            </span>
            <span v-else>
              {{sum[1]}}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="box__container">
      <template v-for="(sum, index) of getDataFromLabels(depotpositions[depotid].resultSums)">
        <MobileInfoRow
          :key="'sum'+index"
          :label="sum[0]"
        >

          <template #value >
            <span :class="getBadgesClassCategory(sum)">
              <span v-if="sum[2] === 'LABEL_DEPOT_WERTENTWICKLUNG'">
                <template v-if="sum[3]">{{convertStringToNumber(sum[1])}} %</template>
                <template v-else>
                  <CurrencyLabel :value="convertStringToNumber(sum[1])"/>
                </template>
              </span>
              <span v-else-if="isNumber(sum[1])"><CurrencyLabel :value="convertStringToNumber(sum[1])"/></span>
              <span v-else>{{sum[1]}}</span>
            </span>
          </template>
        </MobileInfoRow>
      </template>
    </div>
      
    <div v-if="depotpositionsCategories && depotpositionsCategories.length">
      <div v-for="(group,idx) in treeGroup" :key="'group'+idx" class="box__container">
        <div class="box__title" v-if="showGroupTitle && group.title">{{group.title}}</div>
          <Table
                tableId="b6d61b0f-3dd5-4940-b387-fe4390ba26da"
                :headers="headers"  
                :rows="depotpositionsCategories[group.index].rows"
                rowId="rowId"
                hidePagination
                :title="group.title4Tbl"
                showColumnsConfigEvenNoTitle
                :enableToggleHorizontalScrollingConfig="mustEnableHorizontalScrolling"
                :mobileConfig="{disableClickRow: false}"
                @action-OEFFNEN="navigateToFondsinfo"
                @action-ALT_KAUF="addDepotToWertpapierorder($event, 'ALT_KAUF')"
                @action-ALT_VERKAUF="addDepotToWertpapierorder($event, 'ALT_VERKAUF')"
                @action-ALT_TAUSCH="addDepotToWertpapierorder($event, 'ALT_TAUSCH')"
                @action-ALT_SPARPLAN="addDepotToWertpapierorder($event, 'ALT_SPARPLAN')"
                @action-ALT_ENTNAHMEPLAN="addDepotToWertpapierorder($event, 'ALT_ENTNAHMEPLAN')"
                @action-LIMITS="actionLimit"
                @action-COURTAGE_BGS="handleCourtagehistorie"
                @action-TRANSAKTIONEN="navigateToTransactions"
                @action-FONDSINFO="actionFondsinfo"
                @action-DEPOT_DETAILS="getDepotDetails"
                @action-NEW_APPOIMENT="handleAddActivity"
                @action-FACTSHEET="openFactsheetFile($event, 'FACTSHEET')"
                @action-KAG="openFactsheetFile($event, 'MR')"
                @action-PRIIP-BIB="openFactsheetFile($event, 'KIID')"
                @click-depotnummer="openVertragdOverview"
                @click-stand_anteile="openVertragDetails($event.depotnummer)"
            >

            
                <template v-slot:customTableTitle v-if="zeigeDepotnummerGruppierung && group.titleMetadata.title">
                  {{ group.titleMetadata.title}} 
                </template>

                <template v-slot:historyChart="row">
                    <div class="history-chart depotpositions-container" v-if="investmentBestand && investmentBestand.chart">
                        <LineChart xAxisType="category" :chartData="historyChartData(row)" :showDataZoom="false" :showYaxis="false" :showXaxis="false" :isTooltip="false" isSmallChart :gridHeight="35" style="height: 35px;" />
                    </div>
                </template>
                <template v-slot:wertpapiername="row">
                    <div class="depotpositions--wertpapiername-column" v-if="!row.mobileTableContext">
                      <a @click="navigateToFondsinfo(row)">{{row.wertpapiername}}<PhCircle class="ml-2 mb-1" :size="16" :color="row.fondsampel" weight="fill" v-if="row.fondsampel"/></a>
                    </div>
                    <div v-else>{{row.wertpapiername}}<PhCircle class="ml-2 mb-1" :size="16" :color="row.fondsampel" weight="fill" v-if="row.fondsampel"/></div>
                </template>
                <template v-slot:info="row">
                  <div class="d-flex" v-if="row.info">
                    <div v-for="(line, index) in row.info" :key="index">
                      <a class="text-color" @click="openInfo(line, row)"  v-if="line.isLink && !row.mobileTableContext"
                      ><Pill v-if="line.tooltip" :label="line.label" :type="line.type"
                          v-fc-tooltip="{content: line.tooltip, className: 'info-tooltip'}"  />
                        <Pill v-else :label="line.label" :type="line.type" />
                      </a>
                      <Pill :label="line.label" :type="line.type" v-else></Pill>
                    </div>
                  </div>
                </template>
            </Table>
      </div>
    </div>
    <NoData v-else class="box__container" />

    <BalanceSheetInsuranceTable 
      v-if="isVersicherungVermoegen" 
      tableConfigId="eecf48ac-bf47-452d-81f0-16611bf14b5f"
      title="Rückkaufswerte"
      showOnlyRueckkaufswert
      @totalAmount="setRueckkaufswertTotalAmount($event)"/>
    
  </div>
  <div v-else-if="loadingDepotpositions">
    <GhostLoading class="hide-on-small-screen" :repeat="5" useBoxContainer inline>
      <Block />
      <Block />
    </GhostLoading>

    <GhostLoading class="hide-on-large-screen" :repeat="2" useBoxContainer inline>
      <Block />
      <Block />
    </GhostLoading>

    <GhostLoading :repeat="5" useBoxContainer>
      <Block type="title" />
      <Block type="table-row" />
    </GhostLoading>
  </div>
 
  <BaseModal
    ref="notImplemented"
    modalTitle="Nicht implementiert"
    labelButtonConfirm="Ok"
    :showCancelButton="false"
    >
    Formular noch nicht implementiert
  </BaseModal>
  
  <BaseModal
    ref="modalDepotDetails"
    :modalTitle="modalArg.title"
    :showConfirmButton="modalArg.showConfirmButton"
    :labelButtonConfirm="modalArg.labelButtonConfirm"
    :labelButtonCancel="modalArg.labelButtonCancel"
    @onConfirmButton="modalArg.onConfirm" > 
      <div v-html="modalArg.body"></div>
  </BaseModal>
  
</div>
</template>

<script>
import BaseModal from "@/components/core/BaseModal.vue";
import { mapGetters } from "vuex";
import DEPOTPOSITIONS_TYPES from "@/store/depotpositions/types";
import DEPOTPOSITIONENEDIT_TYPES from "@/store/depotpositionenedit/types";
import Table from "@/components/table2/Table.vue";
import MobileInfoRow from "@/components/core/MobileInfoRow.vue";
import {TextColumn, CurrencyColumn, NumberColumn, PercentageColumn, SlotColumn, ActionColumn, SimpleAction, LinkAction, DateColumn } from "@/components/table2/table_util.js";
import FinancialCalculator from '@/components/retirementScenario/financialCalculator';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import STECKBRIEF_TYPES from '@/components/steckbrief/store/types';
import MULTIBANKING_TYPES from '@/store/multiBanking/types'
import CALENDAR_TYPES from '@/store/calendar/types';
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import CORE_TYPES from '@/store/core/types'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import BalanceSheetInsuranceTable from '@/components/versicherungen/BalanceSheetInsuranceTable.vue'
import LineChart from '@/components/charts/echarts/LineChart.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue'
import Block from '@/components/core/loading/ghost-loading/Block.vue'
import NoData from '@/components/core/NoData.vue'
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import DepotpositionsNavigation from '@/components/depotpositions/DepotpositionsNavigation.vue';

import { PageHeaderPortfolioSelectorAction } from '@/components/core/header-title-navigation/page-header-utils';
import Pill from '@/components/core/Pill.vue'
import { v4 as uuidv4 } from 'uuid';
import { VIEW_ROLES, ROLES, EMPLOYEE_ROLES } from '@/router/roles'
import HinweiseUndFehler from "@/components/core/HinweiseUndFehler.vue";
import { parse } from '@/helpers/number-formatter';
import BALANCE_SHEET_TYPES from '@/store/balance-sheet/types';

import { openLink } from '@/link-resolvers';
import { ResolverId } from '@/link-resolvers/types';

import {
  PhArrowsLeftRight,
  PhPlusMinus,
  PhPlus,
  PhMinus,
  PhGauge,
  PhUsersThree,
  PhPencilLine,
  PhEye,
  PhListDashes,
  PhFileText,
  PhFile,
  PhCircle,
  PhLink,
} from "phosphor-vue";
import { ALL_DEPOTS } from './types';
import dayjs from 'dayjs';


export default {
  props: {
  },
  data() {
    return {
      rueckkaufswertTotalAmount: 0,
      tableDataNew: null,
      kidDisabled: false,
      factsheetDisabled: false,
      kagDisabled: false,
      downloadPdf: false,
      selectedFund: null,
      loadingDepotpositions: false,
      listDetails: [],
      noInitAppoiment: false,
      modalArg: {
        title: '',
        showConfirmButton: false,
        labelButtonConfirm: '',
        labelButtonCancel: '',
        body: '',
        onConfirm: () => {}
      },
      isVersicherungVermoegen: false,
      // showAnteileZero: -1,
    };
  },
  mixins: [InteractiveHelpCommonsMixin],
  watch: {
    'depotid': 'retrieveDepotpositions',
    '$route.meta.depotId': {
      handler(depotId) {
        // as computed isVersicherungVermoegen() not responsive if this.$route.meta.depotId is undefined
        this.isVersicherungVermoegen = depotId === 'WERTPAPIER_VERSICHERUNG'
        this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, depotId)
      },
      immediate: true,
    },
    '$route.params.depotId': {
      handler(depotId) {
        this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, depotId)
      },
      immediate: true,
    },

    // depotpositions() {      
    //   if(this.depotpositions[this.depotid]) {
    //     this.showAnteileZero = this.depotpositions[this.depotid].zeigeWith0 ? 1 : 0;
    //   }
    // }
  },
  computed: {
    ...mapGetters({
      depotpositions: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS,
      charts: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CHARTS,
      config: DEPOTPOSITIONS_TYPES.GETTERS.DEPOTPOSITIONS_CONFIG,
      investmentBestand: STECKBRIEF_TYPES.GETTERS.GET_INVESTMENT_BESTAND,
      multibankingAccounts: MULTIBANKING_TYPES.GETTERS.ACCOUNTS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      depotid: DEPOTPOSITIONS_TYPES.GETTERS.DEPOT_ID,
      depotTitle: DEPOTPOSITIONS_TYPES.GETTERS.SELECTED_DEPOT_TITLE,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      screenWidth: CORE_TYPES.GETTERS.SCREEN_WIDTH,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFa: CORE_TYPES.GETTERS.IS_FA,
      insuranceTable: BALANCE_SHEET_TYPES.GETTERS.INSURANCE_TABLE,
    }),
    zeigeDepotnummerGruppierung() {
      return this.depotpositions?.WERTPAPIER_VERSICHERUNG?.zeigeDepotnummerGruppierung === true;
    },
    headerActions() {
      const result = [
        PageHeaderPortfolioSelectorAction('SELECT-DEPOT'),
      ];
      // if (this.showAnteileZero != -1) {
      //   result.push(PageHeaderSimpleAction('ANTEILE-NULL', 'Depotpositionen ohne Anteile ' + (this.showAnteileZero ? 'ausblenden' : 'anzeigen')))
      // }
      return result;
    },
    hints() {
      return this.depotpositions[this.depotid]?.hints
    },
    suffixGesamt() {
      return this.screenWidth == 'xs' ? '(ges.)' : '(gesamt)';
    },
    headers() {
      const displayDepotnummer = (cell, row) => {
        if (cell.indexOf('(F)') != -1) {
          return [{class: '', text: cell.slice(0, cell.indexOf('(F)'))}, {class: 'color-danger', text: '(F)'}]
        } else if (cell.indexOf('(MP 1)') != -1) {
          return [{class: '', text: cell.slice(0, cell.indexOf('(MP 1)'))}, {class: 'color-info', text: '(MP 1)'}]
        } else {
          return [{class: '', text: cell}]
        }
      }
        const headers = {
            lockedLeft: [
                SlotColumn("wertpapiername", "Wertpapiername", 500).makeAlwaysVisible(),
                SlotColumn("info", "Info", 300).makeAlwaysVisible(),
            ],
            center: [
              ...(!this.zeigeDepotnummerGruppierung
                      ? [
                            TextColumn("depotnummer", "Depotnummer").addCellProps({displayWithClass: displayDepotnummer}),
                            TextColumn("lagerstelle", "Lagerstelle"),
                        ]
                      : []),
                TextColumn("gesellschaft", "Gesellschaft"),
                TextColumn("waehrung", "Währung"),
                NumberColumn("wechselkurs", "Kurs", 5),
                TextColumn("anteile", "Anteile/Nominale"),
                NumberColumn("wert_fw", "Wert (Wp.whrg.)", 2),
                NumberColumn("preis", "Preis", 4),
                TextColumn("kategorie", "Wertpapierkategorie"),
                DateColumn("stand_bew", "Stand Bewertung"),
                DateColumn("stand_anteile", "Stand Anteile").makeLink(),
                TextColumn("rk", "SRI"),
                PercentageColumn("rabatt", "Rabatt"),
                TextColumn("wkn", "WKN"),
                TextColumn("rating", "Rating"),
                TextColumn("isin", "ISIN"),
                SlotColumn("historyChart", "Verlauf (30 Tage)", 200, 0),
                PercentageColumn("proz_anteil", "proz. Anteil Depot"),
                CurrencyColumn("anlage", "Anlage (Euro)"),
            ],
            lockedRight: [
                CurrencyColumn("euro", "Wert").withSumFooter(),
            ],
        };
        if(!this.isVersicherungVermoegen) {
            headers.lockedRight.unshift(CurrencyColumn("guv_sum", `GuV € ${this.suffixGesamt}`)
              .setColorFn(row => this.depotauszugGuvFarbe && this.computedGuvSumColor(row)));
          if (this.isGuvPercentPAVisible) {
              headers.lockedRight.unshift(PercentageColumn("guv_procent_pa", "GuV % p.a.")
                .setColorFn(row => this.depotauszugGuvFarbe && this.computedGuvSumColor(row))); 
          }
          if (this.isGuvPercentVisible) {
              headers.lockedRight.unshift(PercentageColumn("guv_procent", `GuV % ${this.suffixGesamt}`)
                .setColorFn(row => this.depotauszugGuvFarbe && this.computedGuvSumColor(row)));
          }

          headers.lockedRight.push(ActionColumn("actions", "Aktionen"));
        }
        return headers;
    },
    isGuvPercentVisible() {
      const guv = this.depotpositions[this.depotid].headers['guv'];
      return guv && guv['procent']?.visible;
    },
    isGuvPercentPAVisible() {
      const guv = this.depotpositions[this.depotid].headers['guv'];
      return guv && guv['procentpa']?.visible;
    },
    depotpositionsCategories() {
        if(!this.depotpositions[this.depotid]) {
            return [];
        }
        let values = JSON.parse(JSON.stringify(this.depotpositions[this.depotid].values));
        // if( this.showAnteileZero != 1) {
        //   values = values
        //     .map(item => {
        //       item.records = item.records?.filter(rec => rec?.anteile?.replace(/[0,]/g, '') );
        //       return item;
        //     })
        //     .filter(item => item.records.length);
        // }
        const results = {};
        values.forEach(value => {
            const id = value.groupHeaders && value.groupHeaders[0] || '';
            results[id] = [
                ...(results[id] || []),
                value,
            ];
        });
        function title(group) {
          if (group?.length == 2 ){
            const pos = group[0].lastIndexOf(group[1]);
            if (pos == -1 || pos + group[1].length !== group[0].length ) {
              return group;
            }
            return [group[0].substring(0, pos), group[1]];
          }
          return group;
        }
       
        const depots = Object.keys(results).map((key) => ({
            key,
            title: title(results[key][0].groupHeaders),
            rows: results[key].flatMap((group) => this.buildRows(group.records, group))
        }));
        return depots;
    },
    treeGroup() {
      return this.depotpositionsCategories.reduce((acc, curr, index) => {
        const title4Tbl = curr?.title?.length == 2 ? curr?.title?.[1] || '' : curr?.title?.[0] || '';
        const titlePart = title4Tbl.split(' | '); // Split the string by " | " and take the first part

        acc.push({
          title: curr?.title?.length == 2 ? curr?.title?.[0] || '' : '', //in case curr.title array has more than 1 element
          titleMetadata: {
            title:  titlePart[0], // Use the first part of the title4Tbl
            /* the depotnummer could have value different of the request returned, breaking the redirection to the insurance details page */
            key: titlePart[1] || curr?.rows?.[0]?.depotnummer // try to extract from the title before the modification of the depotnummer
          },
          title4Tbl,
          index,
        });
        return acc;
      }, []);
    },
    getDataFromLabels() {
      return function(data) {
        if (data === undefined) {
          return [];
        }
        const filteredKeys = JSON.parse(JSON.stringify(Object.keys(data)
          .filter(key => key !== 'LABEL_DEPOT_WERTENTWICKLUNG' && key !== 'LABEL_SALDO_BESTAND' && key !== 'LABEL_DEPOTAUSZUG_SUMME')));
        const result = filteredKeys
          .map(key => [...data[key], key]);
        const summe = [...data['LABEL_DEPOTAUSZUG_SUMME']]
        const saldoBestand = data['LABEL_SALDO_BESTAND'] && [...data['LABEL_SALDO_BESTAND']]

        if (saldoBestand) {
          saldoBestand[1] = this.convertStringToNumber(saldoBestand[1]) + (this.isVersicherungVermoegen && !isNaN(this.rueckkaufswertTotalAmount) && this.rueckkaufswertTotalAmount || 0)
          result.push(saldoBestand)
        } else if (summe) {
          summe[1] = this.convertStringToNumber(summe[1]) + (this.isVersicherungVermoegen && !isNaN(this.rueckkaufswertTotalAmount) && this.rueckkaufswertTotalAmount || 0)
          result.push(summe)
        }

        const dwe = data['LABEL_DEPOT_WERTENTWICKLUNG'];
        if (dwe && dwe.length > 1 && dwe[0] ) {
          const val = dwe[1];
          let values = [val]
          if (val.includes('/')) {
            values = val.split(/\//);
          } else {
            values = val.replace(')', '').split(/\(/);
          }
          result.push([`Wertentwicklung ${this.suffixGesamt}:`, values[0], 'LABEL_DEPOT_WERTENTWICKLUNG', values[0].includes('%')]);
          if (values.length > 1) {
            result.push(['Wertentwicklung p.a.:', values[1], 'LABEL_DEPOT_WERTENTWICKLUNG', values[1].includes('%')]);
          }
        }
        return result;
      }
    },
    hasDepotpositions() {
      return !!this.depotpositions?.[this.depotid];
    },
    mustEnableHorizontalScrolling(){
      return true;
    },
    zeigeWertpapiergruppenGruppierung() {
      return this.depotpositions[this.depotid].values.some(row => row.groupHeaders?.length)
    },
    gruppierungNachLagerstelle() {
      return this.depotpositions[this.depotid].values.some(row => row.groupHeaders?.length > 1)
    },
    depotauszugGuvFarbe() {
      return this.hasRoles(ROLES.DEPOTAUSZUG_GUV_FARBE);
    },
    showGroupTitle() {
      return (this.gruppierungNachLagerstelle || this.zeigeWertpapiergruppenGruppierung) && !this.isVersicherungVermoegen;
    },
    hasVertrag(){
      return !!this.depotpositions[this.depotid]?.versVertrag
    }
  },
  mounted: function() {
    if (!this.$route.meta.depotId) {
      this.retrieveDepotpositions();
    } else if (this.$route.meta.depotId !== this.depotid) {
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, this.$route.meta.depotId)
    }
    this.$store.dispatch(STECKBRIEF_TYPES.ACTIONS.GET_INVESTMENT_BESTAND, {});
  },
  methods: {
    getVertragId(depotnummer){
      const vertrag = this.insuranceTable?.insuranceTable?.records?.find(i => i[3]==depotnummer);
      if(vertrag){
        return vertrag[16]
      }
      else if(this.hasVertrag){
        return this.depotpositions[this.depotid]?.versVertrag?.id
      }
      return null
    },
    openVertragdOverview(depotnummer) {
      const vertragId = this.getVertragId(depotnummer)
      if (vertragId) {
        this.$router.push(`/home/versicherungen/insurance-group/overview/${vertragId}`);
      }
    },
    openVertragDetails(depotnummer) {
      const vertragId = this.getVertragId(depotnummer)
      if (vertragId) {
        this.$router.push(`/home/versicherungen/insurance-group/details/${vertragId}?stepKey=sparteUbersicht`);
      }
    },   
    setRueckkaufswertTotalAmount(totalAmount) {
      this.rueckkaufswertTotalAmount = totalAmount
    },
    historyChartData(val) {
      if (this.investmentBestand && !this.investmentBestand.chart) {
        return [];
      }
      
      let data = [];
      const foundSerie = this.investmentBestand.chart[0].seriesGoup.find(v => v.title == val.wertpapiername);
      if (!foundSerie) {
        return [];
      }
      const serieIndex = foundSerie && this.investmentBestand.chart[0].dataset[0].findIndex(v => v === ('S' + foundSerie.cntSerie)) || 0;
      data = this.investmentBestand.chart[0].dataset?.slice?.(-30).map((v) => v[serieIndex]) || []
      return data;
    },
    async retrieveDepotpositions() {
      await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RESET_RETRIEVED_DEPOTPOSITIONS_IF_NEEDED);
      if (this.depotid && !this.depotpositions[this.depotid]) {
        this.loadingDepotpositions = true;
        // this.showAnteileZero = -1;
        await this.$store.dispatch(DEPOTPOSITIONS_TYPES.ACTIONS.RETRIEVE_DEPOTPOSITIONS, {
          depotid: this.depotid
        });
        this.loadingDepotpositions = false;
      }
    },
    buildRows(records, actionData) {
      return records.map(record => {

        const actions = [SimpleAction("OEFFNEN", PhLink, "Öffnen"),];
        const depotActions = actionData?.actions && Object.keys(actionData.actions) || [];
        if (this.hasRoles([VIEW_ROLES.VIEW_CUSTOMER_AS_BYPASS]) && !this.isVersicherungVermoegen) {
          const wertpapierorderActions = [
            SimpleAction("ALT_KAUF", PhPlus, "Nachkaufen"),
            SimpleAction("ALT_VERKAUF", PhMinus, "Verkaufen"),
            SimpleAction("ALT_TAUSCH", PhPlusMinus, "Tausch"),
            SimpleAction("ALT_SPARPLAN", PhPencilLine, "Sparplan verwalten"),
            SimpleAction("ALT_ENTNAHMEPLAN", PhPencilLine, "Entnahmeplan verwalten"),
          ];
          wertpapierorderActions.forEach(action => {
            if (depotActions.includes(action.actionKey))
              actions.push(action);
          });
          if (this.hasRoles([[EMPLOYEE_ROLES.ZUGRIFF_COURTAGEHISTORIE]])) {
            actions.push(SimpleAction("COURTAGE_BGS", PhFileText, "Courtagehistorie (PDF)"));
          }
        }
        actions.push(SimpleAction("LIMITS", PhGauge, "Limits"));

        actionData.transaktionActions?.forEach(act => {
          if (act?.href && act?.label) {
            actions.push(LinkAction("TRANSAKTIONEN_PDF", PhFile, act.label, act.href));
          } else if (act?.label) {
            actions.push(SimpleAction("TRANSAKTIONEN", PhArrowsLeftRight, act.label));
          }
        });

        if (actionData.infoDepot && actionData.infoSparplan) {
          actions.push(SimpleAction("DEPOT_DETAILS", PhListDashes, 'Details anzeigen'));
        }
        if (this.isBrokerOrBypass) {
          actions.push(SimpleAction("NEW_APPOIMENT", PhUsersThree, "Neue Aufgabe"));
        }

        if (actionData.urlFactsheet) {
          actions.push(SimpleAction("FACTSHEET", PhEye, "Factsheet"));
        }

        if (actionData.urlKag || actionData.kag === 'pdf') {
          actions.push(SimpleAction('KAG', 'PhEye', 'KAG'));
        }

        if (actionData.urlKid || actionData.kid === 'pdf') {
          actions.push(SimpleAction('PRIIP-BIB', 'PhEye', 'PRIIP-BIB'));
        }

        const infoArr = actionData.transaktionActions ? actionData.transaktionActions.filter(act => act?.info)
          .map(act => {return { label: act.info, type: act.color || '', isLink: act.isLink, tooltip: act.tooltip || ''}}) : [];

        if (actionData.limit?.exist) {
          infoArr.push({ label: 'L', type: actionData.limit.exceed ? 'danger' : '', isLink: 1});
        }
        if (actionData.planTyp) {
          infoArr.push({ label: actionData.planTyp, type: 'info', isLink: 1, tooltip: actionData.infoSparplan});
        }
        
        return {
            ...record,
            guv_sum: record.guv?.sum || '',
            guv_procent: record.guv?.procent,
            guv_procent_pa: record.guv?.procentpa,
            actions,
            actionData,
            info: infoArr.length ? infoArr : null,
            rowId: `${actionData.begleitscheinNr}_${record.isin}_${uuidv4()}` // isin alone is not unique
        }
      });
    },
    navigateToTransactions(record) {
      let paramsObj = {};
      if (record.isin) {
        paramsObj.isin = record.isin;
      }
      if(record.wkn) {
        paramsObj.wkn = record.wkn;
      }     
      if(record.actionData.begleitscheinNr) {
        paramsObj.bgsNr = record.actionData.begleitscheinNr;
      }      
      const bodyParams = JSON.stringify(paramsObj);
      const params = encodeURIComponent(bodyParams);
      this.navigateTo(`/home/berichte/transaktionen?params=${params}&title=${encodeURIComponent(record.wertpapiername)}&depoid=${this.depotid}`);
    },
    getDepotDetails(record) {
      if (record.actionData.infoDepot || record.actionData.infoSparplan) {
        this.modalArg.showConfirmButton =false;
        this.modalArg.labelButtonConfirm = '';
        this.modalArg.labelButtonCancel = 'Schließen';
        this.modalArg.body = `${record.actionData.infoDepot || ''}${record.actionData.infoSparplan || ''}`;
        this.modalArg.onConfirm = () => {};
        this.$refs.modalDepotDetails.open();
      }
    },
    navigateTo(path) {
      this.$router.push({ path })
    },
    isNumber(val) {
      return (`${val}`)[val.length - 1] === '€' || !isNaN(FinancialCalculator.convertStringToNumber(val));
    },
    convertStringToNumber(val) {
      return this.isNumber(val) ? FinancialCalculator.convertStringToNumber(String(val).replace(' €', '')) : val;
    },
    actionFondsinfo(row) {
        this.selectedFund =  row.wertpapiername;
        this.navigateTo(`/shared/vermogensubersicht/fondsinfo/${row.isin}/${this.depotid}`);
    },
    actionLimit(row) {
        this.navigateTo(`/home/vermogensubersicht/wertpapiere/limits/${row.isin}/${row.actionData.begleitscheinNr}`)
    },
    addDepotToWertpapierorder(row, category) {
        const specificAction = row.actionData.actions[category];
        const begleitscheinNr = row.actionData.begleitscheinNr;
        if (!specificAction) {
            this.$refs.notImplemented.open();
        } else {
            this.$router.push(`/beratung/formulare/antrag/${specificAction}initData=true&bgsNr=${begleitscheinNr}&category=${category}`).catch(() => {});
        }
    },
    navigateToFondsinfo(row) {
      if(row) {
        this.selectedFund = row.wertpapiername;
        this.navigateTo(`/shared/vermogensubersicht/fondsinfo/${row.isin}/${this.depotid}`);
      }
    },
    handleAddActivity(row) {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_NEW_ACTIVITY);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_BETEILIGTE_FOR_UPDATE, []);
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_SELECTED_APPOINTMENT_DATA, {
        appointment: {
          appointment: {
            label: row ? row.wertpapiername : '',
            activity: true,
            bereich: 'Kunde',
            bereichDisplay: 'Kunde ' + this.loginData.fullname + ' (' + this.loginData.userid + ')',
            bereichId: this.loginData.userid,
          }
        }
      });
      this.noInitAppoiment = true;
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
        noInitialise: this.noInitAppoiment,
        attachCustomer: false,
        discardChanges: this.resetAppointment,
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    resetAppointment() {
      this.noInitAppoiment = false;
    },
    getDate(dt) {
      return typeof dt == 'number' ? dayjs(dt).format("DD.MM.YYYY") : dt;
    },
    handleCourtagehistorie(row) {
      this.$store.dispatch(DEPOTPOSITIONENEDIT_TYPES.ACTIONS.COURTAGE_BGS, {bgsnr: row.bgsNr?.toString()})
        .then(data => {
          if (data?.pdf) {
            viewDocument({
              data: data.pdf,
              filename: 'Depotposition.pdf',
              contentType: 'application/pdf',
            }, false);
          }
        });
    },
    getBadgesClassCategory(sum) {
      if (!this.depotauszugGuvFarbe) {
        return '';
      }

      if (!sum[2] || sum[2] === 'LABEL_SALDO_EINAUS') {
        return '';
      }

      return this.getColorClassBasedOnValue(sum[1]);
    },
    computedGuvSumColor(row) {
      return this.getColorClassBasedOnValue(row?.guv_sum);
    },
    getColorClassBasedOnValue(value) {
      const doubleValue = parse(value);
      if (doubleValue > 0) {
        return "color-success";
      } else if (doubleValue < 0) {
        return "color-danger"
      }
      return "text-color";
    },
    openInfo(elem, row) {
      if (elem?.label === 'T') {
        this.navigateToTransactions(row);
      } else if (elem?.label === 'Sparplan' || elem?.label === 'Entnahmeplan') {
        this.navigateTo('/home/berichte/spar_entnahmeplane');
      } else if (elem?.label === 'L') {
        this.actionLimit(row);
      }
    },
    openFactsheetFile(row, type) {
      openLink(ResolverId.Factsheet, {
        type,
        isin: row.isin,
      });
    },
  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      label: 'zurück zu den Depotpositionen', 
      to,
      from,
      breadcrumb: this.selectedFund || ''
    });

    this.selectedFund = null;

    if (this.$route.meta.depotId) {
      this.$store.commit(DEPOTPOSITIONS_TYPES.MUTATIONS.CHANGE_ID, ALL_DEPOTS)
    }

    next()
  },
  components: {
    Table,
    BaseModal,
    CurrencyLabel,
    PhPencilLine,
    PhCircle,
    AppointmentEditCreate,
    BalanceSheetInsuranceTable,
    LineChart,
    GhostLoading,
    Block,
    NoData,
    DepotpositionsNavigation,
    Pill,
    HinweiseUndFehler,
    MobileInfoRow,
  },
};
</script>

<style scoped>
.text-color {
  color: var(--color-text);
}
.text-success {
  color: #28a745;
}
.text-red {
  color: var(--color-danger);
}
.text-muted {
  color: #aaaaaa;
}
.col-6 {
  text-align: center;
}
.card h5 {
  margin-top: 0;
}
.links {
  text-align: left;
}
.limits-info-green {
  color: var(--color-success);
}
.limits-info-red {
  color: var(--color-danger);
}
.limits-info-grey {
  color: grey;
}
.status-circles {
  display: flex;
}
.badge-with-text {
  width: 16px;
  height: 16px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-weight: 700;
  color: var(--color-box);
  font-style: normal;
  margin-right: 5px;
}
.badge-with-text.limits-info-green {
  background: var(--color-success);
}
.badge-with-text.limits-info-red {
  background: var(--color-danger);
}
.badge-with-text.limits-info-grey {
  background: grey;
}
.badge-sparplan {
  background-color: #2660a4;
}
.badge-entnahmeplane {
  background-color: #4abce2;
}
.space-between {
  justify-content: space-between;
}
.chart-container {
  max-width: 150px;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.box__container .text-center.mp-0 {
  margin: 0;
  padding: 0;
}
/* .history-chart .chart-container {
} */
.nowrap {
  white-space: nowrap;
}
.depotpositions-categories__container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px 16px;
}
.depotpositions-categories__container div {
  margin: 0;
}
.depotpositions-categories__container > div {
  flex-grow: 1;
  padding: 0 12px;
  margin: 0 0 8px;
}
.antrag-action-button__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.depot-details {
  display: grid;
  gap: 20px;
  grid-template-columns: 40% auto;
}

.depotauszugGuv__container {
  text-align: right;
}

.clickable__table__title {
    display: inline;
    text-decoration: none;
    cursor: default;   
}

.clickable__table__title:hover {
    text-decoration: underline;
    cursor: pointer; 
}


@media screen and (max-width: 800px) {
  .depotpositions--wertpapiername-column {
    max-width: 350px;
  }
}

@media screen and (max-width: 767px) {

  .depotpositions-categories__container {
    margin-bottom: 4px;
  }

  .depotpositions--wertpapiername-column {
    max-width: 300px;
  }
}

@media screen and (max-width: 600px) {
  .depotpositions--wertpapiername-column {
    max-width: 250px;
  }
}

@media screen and (max-width: 500px) {
  .depotpositions--wertpapiername-column {
    max-width: 180px;
  }
}

@media screen and (max-width: 400px) {
  .depotpositions--wertpapiername-column {
    max-width: 150px;
  }
}
</style>
