<template>
  <fieldset class="fieldset_input_radio_box_group">
    <legend class="legend_input_radio_box_group">InputRadioBoxGroup</legend>
  <div class="input-forms__container" :class="{ 'input-forms__label-value--inline': isInlineLabelValues}">
    <div class="input-forms__label-container">
      <div class="input-forms__label-content" :class="labelClass" v-if="title" v-html="sanitize(title)"></div>
      <div v-if="config && config.subtitle" v-html="sanitize(config.subtitle)" class="text-smaller"></div>
    </div>
    <div :class="{
        'input-forms__input-container radiobox-input__container': vertical,
        'input-forms__input-container__horizontal': !vertical,
        'input-forms__inline-values': isInlineValues,
      }">
      <div v-for="val in values" :key="val.value">
        <InputRadioBoxItem 
          :class="!vertical && 'mr-3'"
          :name="groupName"
          :value="val.value" 
          :label="val.label" 
          :checked="val.value === value"
          :disabled="val.disabled || disabled"
          :config="config"
          :itemStyle="val.style"
          @change="onChangeSelected($event)"/>

        <div class="my-2 ml-4" 
          v-if="$slots['after-'+val.value]">
          <slot :name="'after-'+val.value"/>
        </div>
      </div>
      <template v-if="isValidationConfigured()">
        <div :key="validation.updated" v-if="!suppressValidationMessage && validation">
          <div class="input-forms__error-message" 
            v-for="error in validation.getErrors(this.validationPath, this.validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
        </div>
      </template>
    </div>
  </div>
</fieldset>	
</template>

<script>
import InputRadioBoxItem from '@/components/core/forms/radiobox/InputRadioBoxItem.vue'
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { sanitize } from '@/helpers/string-helper.js';

const LABEL_JA = 'Ja';
const LABEL_NEIN = 'Nein';
const LABEL_STD = 'Std.*';
const LABEL_BALKENDIAGRAMM = 'Balkendiagramm';

export default {
  mixins: [validatorComponentUtils],
  data: function() {
    return {
      groupName: this.name || Math.random().toString(36).substr(2, 10),
    }
  },
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      default: ''
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    values: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    config: {
    },
    labelClass: {
      type: String,
    },
    vertical: {
      type: Boolean,
      default: true,
    },
    inlineLabelValues: {
      type: Boolean,
      default: true,
    }
  },
  computed: {
    isInlineValues() {
      return this.values
        .every(val => [LABEL_JA, LABEL_NEIN, LABEL_STD, LABEL_BALKENDIAGRAMM].map(v => v.toLowerCase()).indexOf(val?.label?.toLowerCase()) >= 0)
    },
    isInlineLabelValues() {
      return this.inlineLabelValues && this.isInlineValues
    }
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    onChangeSelected(event) {
      this.$emit('input', event)
      this.$runValidator(this.value)
    },
  },
  mounted() {
    this.$runValidator(this.value, true)
  },
  watch: {
    values: function(newValues){
      for (const nv of newValues) {
        if (!nv.hasOwnProperty('label')) {
          throw new Error('InputRadioBoxGroup: A "label" is required for each "values" list object')
        }
        if (!nv.hasOwnProperty('value')) {
          throw new Error('InputRadioBoxGroup: A "value" is required for each "values" list object')
        }
      }
    },
    value: function(newValue) {
      this.$runValidator(newValue)
    },
  },
  components: {
    InputRadioBoxItem
  }
}
</script>

<style scoped lang="scss">
.fieldset_input_radio_box_group {
  border: 0;
}

.legend_input_radio_box_group{
  display: none;
}

.input-forms__inline-values {
  flex-direction: row;

  > div {
    margin: 0 16px 0 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.input-forms__label-value--inline{ 
  display: flex;
  justify-content: space-between;
}

.input-forms__label-container {
  margin-bottom: 4px;
}
</style>
