var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("OptionMenu", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          defaultMenu: _vm.$appNavigation.currentOptionMenu
        }
      }),
      _c("PageHeaderTitleNavigation", {
        attrs: {
          id: _vm.$appNavigation.currentOptionMenuId,
          title: "Aufgaben",
          actions: _vm.headerActions
        },
        on: { "action-NEW_AUFGABE": _vm.newTask }
      }),
      _vm.defaultOptions
        ? _c("BaseFilter", {
            attrs: {
              title: "Aufgaben Filter",
              filterId: "PostfachTaskFilter",
              isCache: _vm.hasFilterCache,
              configFilter: _vm.filterConfig,
              metadata: _vm.metadata,
              defaultOptions: _vm.defaultOptions,
              showSaveButton: "",
              hasSmartSearch: "",
              immidiateSearch: ""
            },
            on: { onFilter: _vm.onFilter }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "box__container" },
        [
          _vm.loading && !_vm.scrollLoading
            ? _c("GhostLoading", {
                attrs: { type: "table", config: { table: { rows: 10 } } }
              })
            : _c("PaginatedTable", {
                attrs: {
                  title: "Aufgaben",
                  tableId: "015eb9cf-6e4f-4dca-b9e3-52f0b49736bd",
                  rowId: "rowid",
                  headers: _vm.headers,
                  page: _vm.selectedPage,
                  pages: _vm.pages,
                  pageCount: _vm.pageCount,
                  rowCount: _vm.rowCount,
                  headerActions: _vm.tableHeaderActions,
                  exportConfig: _vm.exportConfig,
                  mobileConfig: _vm.mobileConfig,
                  sorted: _vm.searchSort,
                  selectionAsBlacklist: _vm.invertSelection
                },
                on: {
                  page: function($event) {
                    _vm.selectedPage = $event
                  },
                  requestPage: _vm.loadPage,
                  sort: _vm.onSearchSort,
                  onScroll: _vm.onScroll,
                  scrollLoading: function($event) {
                    _vm.scrollLoading = $event
                  },
                  selectionAsBlacklist: function($event) {
                    _vm.invertSelection = $event
                  },
                  "click-subject": _vm.clickBetreff,
                  "click-bereich": _vm.clickBereich,
                  "action-DONE": _vm.doneRow,
                  "action-DELETE": _vm.deleteRow,
                  "headerAction-BULK_DELETE": _vm.deleteSelected,
                  "headerAction-BULK_DONE": _vm.doneSelected
                },
                scopedSlots: _vm._u([
                  {
                    key: "participants",
                    fn: function(row) {
                      return [
                        _c(
                          "ul",
                          { staticStyle: { padding: "0", margin: "0" } },
                          _vm._l(row.participants, function(
                            participant,
                            index
                          ) {
                            return _c(
                              "li",
                              {
                                key: participant.id,
                                staticStyle: { display: "inline-block" }
                              },
                              [
                                participant.isKunde &&
                                _vm.canOpenCustomer(participant) &&
                                !row.mobileTableContext
                                  ? _c(
                                      "a",
                                      {
                                        on: {
                                          click: function($event) {
                                            return _vm.openCustomerNewTab(
                                              participant.nummer
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(_vm._s(participant.name))]
                                    )
                                  : _c("span", [
                                      _vm._v(_vm._s(participant.name))
                                    ]),
                                index < row.participants.length - 1
                                  ? _c("span", [_vm._v("; ")])
                                  : _vm._e()
                              ]
                            )
                          }),
                          0
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedRows,
                  callback: function($$v) {
                    _vm.selectedRows = $$v
                  },
                  expression: "selectedRows"
                }
              })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modalStatus",
          attrs: {
            modalTitle: "Aufgabe wiederholen?",
            showConfirmButton: true,
            labelButtonConfirm: "Ja, wiederholen",
            labelButtonCancel: "Nein, Aufgabe bestätigen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.doRepeate()
            },
            onCancelButton: function($event) {
              return _vm.dontRepeate()
            }
          }
        },
        [
          _c("div", [
            _vm._v(
              " Diese Aufgabe soll wiederholt werden. Alle Wiederholungen bestätigen? "
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }