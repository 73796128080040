var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.hasPageHeader
        ? [
            _c("OptionMenu", {
              attrs: {
                id: _vm.$appNavigation.currentOptionMenuId,
                defaultMenu: _vm.$appNavigation.currentOptionMenu
              }
            }),
            _c("PageHeaderTitleNavigation", {
              attrs: {
                id: _vm.$appNavigation.currentOptionMenuId,
                title: _vm.pageTitle,
                actions: _vm.headerActions
              },
              on: {
                "action-SEND-MAIL-POSTFACH": function($event) {
                  return _vm.sendMail({}, {}, true)
                },
                "action-SEND-MAIL-VERSENDEN": function($event) {
                  return _vm.sendMail({}, {}, false)
                },
                "action-SEND-MAIL-UNENCRYPTED": function($event) {
                  return _vm.sendMail({}, {}, false)
                },
                "action-SEND-MAIL": function($event) {
                  return _vm.sendMail({}, {}, false)
                },
                "action-SEND-TEST-MAIL": _vm.sendTestMailModal,
                "action-PREVIEW": _vm.getFaxPreview,
                "action-SEND-FAX": _vm.sendFax,
                "action-SWITCH-TO-EMAIL": _vm.switchBriefModus,
                "action-SWITCH-TO-FAX": function($event) {
                  return _vm.switchBriefModus("FAX")
                },
                "action-SEND-BRIEF": _vm.sendBrief,
                "action-ADD-ACTIVITY": _vm.handleAddActivity,
                "action-RESET-EMAIL": function($event) {
                  return _vm.resetEmail()
                },
                "action-GENERATE-PDF": function($event) {
                  return _vm.handlePreview(true, true)
                }
              }
            })
          ]
        : _vm._e(),
      _vm.hasPageHeader &&
      (_vm.$route.meta.type === "BRIEF" ||
        (_vm.$route.meta.type !== "FAX" && _vm.showExtendedActionButtons))
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: {
                tid: _vm._generateTidFromString("box__container__header")
              }
            },
            [
              _c(
                "div",
                { staticClass: "row" },
                [
                  _vm.$route.meta.type === "BRIEF"
                    ? [
                        _c(
                          "div",
                          {
                            staticClass: "col-12 col-md-6 col-lg-6 mb-3 mb-md-0"
                          },
                          [
                            _c("ComboBox", {
                              attrs: {
                                values: _vm.briefTypeOptions,
                                firstEmpty: false
                              },
                              model: {
                                value: _vm.briefType,
                                callback: function($$v) {
                                  _vm.briefType = $$v
                                },
                                expression: "briefType"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.$route.meta.type !== "FAX" &&
                  _vm.showExtendedActionButtons
                    ? [
                        _c(
                          "div",
                          { staticClass: "col-6 col-md-6 col-lg-6" },
                          [
                            _c("ComboBox", {
                              attrs: {
                                values: _vm.templatesOptions,
                                firstEmpty: false,
                                disabled:
                                  _vm.isAlreadySent || _vm.vorlageDisabled
                              },
                              on: {
                                change: function($event) {
                                  return _vm.changeTemplate($event)
                                }
                              },
                              model: {
                                value: _vm.template,
                                callback: function($$v) {
                                  _vm.template = $$v
                                },
                                expression: "template"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.pdfPreviewLink
                          ? _c(
                              "div",
                              { staticClass: "col-6 col-md-6 col-lg-6" },
                              [
                                _vm._v(" Vorschau: "),
                                _c("DownloadLink", {
                                  attrs: {
                                    title: _vm.pdfPreviewLink.name || "PDF",
                                    filename: _vm.pdfPreviewLink.name || "PDF",
                                    downloadServicePath: "/docId",
                                    queryParams: {
                                      attId: _vm.pdfPreviewLink.copyFileId,
                                      tempFileId: _vm.pdfPreviewLink.id
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    : _vm._e()
                ],
                2
              )
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "box__container",
          attrs: { tid: _vm._generateTidFromString("absender-data") }
        },
        [
          [
            _c("ComboBox", {
              attrs: {
                label: "Absender",
                firstEmpty: true,
                values: _vm.sendersOptions,
                disabled: _vm.isAlreadySent || _vm.absenderDisabled
              },
              model: {
                value: _vm.sender,
                callback: function($$v) {
                  _vm.sender = $$v
                },
                expression: "sender"
              }
            }),
            _vm.$route.query &&
            _vm.$route.query.singleUser &&
            _vm.$route.meta.type !== "BRIEF_MAIL"
              ? _c("div", [_vm._v(" Empfänger ")])
              : _c(
                  "div",
                  { staticClass: "participants-container" },
                  [
                    _c("ParticipantInput", {
                      attrs: {
                        label: "Empfänger",
                        maxItemsVisible: _vm.maxItemsVisible,
                        warning: _vm.warningMsg,
                        disabled: _vm.isAlreadySent || this.reciverDisable,
                        multipleReceivers: _vm.multipleReceivers,
                        isReceiveUserClickable: _vm.isReceiveUserClickable,
                        isUserSelect: _vm.isUserSelect && _vm.hasPageHeader,
                        availableUsers: _vm.availableUsers,
                        isFax: _vm.$route.meta.type === "FAX"
                      },
                      on: {
                        input: _vm.inputReceiver,
                        searchReceiverUsers: function($event) {
                          return _vm.searchReceiverUsers($event)
                        },
                        clickReceiveUsers: function($event) {
                          return _vm.openReceiveUser($event)
                        },
                        deleteReceiveUsers: function($event) {
                          return _vm.deleteReceiveUsers($event)
                        },
                        deleteAllReceiveUsers: function($event) {
                          return _vm.deleteAllReceiveUsers()
                        },
                        createManualReceiver: function($event) {
                          return _vm.createManualReceiver($event)
                        },
                        handleAddUsers: function($event) {
                          return _vm.handleAddUsers("TO")
                        },
                        userPreview: function($event) {
                          _vm.isUserPreviewActivated = $event
                        }
                      },
                      model: {
                        value: _vm.receiver,
                        callback: function($$v) {
                          _vm.receiver = $$v
                        },
                        expression: "receiver"
                      }
                    }),
                    _vm.$route.meta.type !== "FAX" &&
                    _vm.$route.meta.type !== "BRIEF" &&
                    _vm.emailModus !== "MANY_MAIL"
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex participant-copy-container",
                            class: {
                              "align-self-start": _vm.isUserPreviewActivated
                            }
                          },
                          [
                            _c("div", { staticClass: "p-1" }, [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function($event) {
                                      _vm.isCC = !_vm.isCC
                                    }
                                  }
                                },
                                [_vm._v("CC")]
                              )
                            ]),
                            _vm.hasPageHeader
                              ? _c("div", { staticClass: "p-1" }, [
                                  _c(
                                    "a",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.isBCC = !_vm.isBCC
                                        }
                                      }
                                    },
                                    [_vm._v("BCC")]
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _vm._e()
                  ],
                  1
                ),
            _vm.$route.meta.type !== "FAX" &&
            _vm.$route.meta.type !== "BRIEF" &&
            _vm.emailModus !== "MANY_MAIL"
              ? [
                  _vm.isCC
                    ? _c(
                        "div",
                        { staticClass: "participants-container" },
                        [
                          _c("ParticipantInput", {
                            attrs: {
                              label: "CC",
                              availableUsers: _vm.availableUsers,
                              isUserSelect: _vm.isUserSelect,
                              multipleReceivers: _vm.multipleReceivers,
                              isReceiveUserClickable: _vm.isReceiveUserClickable
                            },
                            on: {
                              searchReceiverUsers: function($event) {
                                return _vm.searchReceiverUsers($event)
                              },
                              deleteReceiveUsers: function($event) {
                                return _vm.deleteReceiveUsers($event)
                              },
                              handleAddUsers: function($event) {
                                return _vm.handleAddUsers("CC")
                              },
                              createManualReceiver: function($event) {
                                return _vm.createManualReceiver($event, "CC")
                              }
                            },
                            model: {
                              value: _vm.receiverCC,
                              callback: function($$v) {
                                _vm.receiverCC = $$v
                              },
                              expression: "receiverCC"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isBCC
                    ? _c(
                        "div",
                        { staticClass: "participants-container" },
                        [
                          _c("ParticipantInput", {
                            attrs: {
                              label: "BCC",
                              availableUsers: _vm.availableUsers,
                              isUserSelect: _vm.isUserSelect,
                              isReceiveUserClickable: _vm.isReceiveUserClickable
                            },
                            on: {
                              searchReceiverUsers: function($event) {
                                return _vm.searchReceiverUsers($event)
                              },
                              deleteReceiveUsers: function($event) {
                                return _vm.deleteReceiveUsers($event)
                              },
                              handleAddUsers: function($event) {
                                return _vm.handleAddUsers("BCC")
                              },
                              createManualReceiver: function($event) {
                                return _vm.createManualReceiver($event, "BCC")
                              }
                            },
                            model: {
                              value: _vm.receiverBCC,
                              callback: function($$v) {
                                _vm.receiverBCC = $$v
                              },
                              expression: "receiverBCC"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ]
              : _vm._e()
          ],
          _vm.$route.meta.type !== "FAX"
            ? [
                !_vm.isPreview
                  ? _c("ShortcutInputField", {
                      attrs: { disabled: _vm.isAlreadySent, label: "Betreff" },
                      on: { input: _vm.updateSubject },
                      model: {
                        value: _vm.subject,
                        callback: function($$v) {
                          _vm.subject = $$v
                        },
                        expression: "subject"
                      }
                    })
                  : _vm._e(),
                _vm.isPreview
                  ? _c("InputField", {
                      attrs: { disabled: true, label: "Betreff" },
                      model: {
                        value: _vm.previewHtml.subject,
                        callback: function($$v) {
                          _vm.$set(_vm.previewHtml, "subject", $$v)
                        },
                        expression: "previewHtml.subject"
                      }
                    })
                  : _vm._e(),
                _vm.hasPasswort
                  ? _c("InputField", {
                      attrs: { label: "Passwort" },
                      model: {
                        value: _vm.passwort,
                        callback: function($$v) {
                          _vm.passwort = $$v
                        },
                        expression: "passwort"
                      }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.$route.meta.type === "BRIEF"
            ? [
                _c("InputField", {
                  attrs: { label: "Dateiname" },
                  model: {
                    value: _vm.dateiname,
                    callback: function($$v) {
                      _vm.dateiname = $$v
                    },
                    expression: "dateiname"
                  }
                })
              ]
            : _vm._e(),
          _vm.$route.meta.type === "APP"
            ? [
                _vm.allowedToSendPin
                  ? _c("div", { staticClass: "d-flex" }, [
                      _vm.canSms
                        ? _c(
                            "div",
                            { staticClass: "mr-3" },
                            [
                              _c("InputToggleSwitch", {
                                attrs: {
                                  label: "PIN per SMS versenden",
                                  inLineLabel: true
                                },
                                model: {
                                  value: _vm.isSMS,
                                  callback: function($$v) {
                                    _vm.isSMS = $$v
                                  },
                                  expression: "isSMS"
                                }
                              })
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("div", { staticClass: "text-color-info" }, [
                        _vm._v(" " + _vm._s(_vm.textSms) + " ")
                      ])
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _vm.$route.meta.type === "BRIEF"
            ? [
                _vm.briefType !== "WORD"
                  ? _c("div", { staticClass: "d-flex brief-switches" }, [
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("InputToggleSwitch", {
                            attrs: { label: "Hintergrund-Vorlage benutzen" },
                            model: {
                              value: _vm.briefBackground,
                              callback: function($$v) {
                                _vm.briefBackground = $$v
                              },
                              expression: "briefBackground"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("InputToggleSwitch", {
                            attrs: { label: "Querformat" },
                            model: {
                              value: _vm.briefLandscape,
                              callback: function($$v) {
                                _vm.briefLandscape = $$v
                              },
                              expression: "briefLandscape"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "mr-3" },
                        [
                          _c("InputToggleSwitch", {
                            attrs: {
                              label: "Brief zusätzlich beim Empfänger speichern"
                            },
                            model: {
                              value: _vm.briefSaveDocArchive,
                              callback: function($$v) {
                                _vm.briefSaveDocArchive = $$v
                              },
                              expression: "briefSaveDocArchive"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "space-row" },
            [
              _vm.$route.meta.type !== "FAX" && _vm.$route.meta.type !== "BRIEF"
                ? [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label:
                          "E-Mail / App-Nachricht als reine Textmail ohne HTML versenden",
                        inLineLabel: true
                      },
                      on: {
                        input: function($event) {
                          return _vm.changeNotHtml($event)
                        }
                      },
                      model: {
                        value: _vm.isNotHTML,
                        callback: function($$v) {
                          _vm.isNotHTML = $$v
                        },
                        expression: "isNotHTML"
                      }
                    }),
                    _vm.$route.meta.type === "MANY_MAIL" &&
                    _vm.$route.meta.mailMode === "RUND_MAIL"
                      ? _c(
                          "div",
                          { staticClass: "rundMail-switches" },
                          [
                            _c("InputToggleSwitch", {
                              attrs: {
                                label: "Als E-Mail versenden",
                                inLineLabel: true
                              },
                              model: {
                                value: _vm.sendEmail,
                                callback: function($$v) {
                                  _vm.sendEmail = $$v
                                },
                                expression: "sendEmail"
                              }
                            }),
                            _c("InputToggleSwitch", {
                              attrs: {
                                label: "Als Nachricht versenden",
                                inLineLabel: true
                              },
                              model: {
                                value: _vm.sendNachricht,
                                callback: function($$v) {
                                  _vm.sendNachricht = $$v
                                },
                                expression: "sendNachricht"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                : _vm._e(),
              _vm.$route.meta.type === "ONE_MAIL"
                ? [
                    _c("InputToggleSwitch", {
                      attrs: {
                        label: "E-Mail direkt senden",
                        inLineLabel: true
                      },
                      model: {
                        value: _vm.isDirect,
                        callback: function($$v) {
                          _vm.isDirect = $$v
                        },
                        expression: "isDirect"
                      }
                    })
                  ]
                : _vm._e()
            ],
            2
          ),
          _vm.$route.meta.type === "FAX"
            ? [
                _c("ComboBox", {
                  attrs: {
                    label: "Benachrichtigung E-Mail",
                    values: _vm.faxNotifyTypeOptions,
                    disabled: _vm.isAlreadySent,
                    firstEmpty: false
                  },
                  model: {
                    value: _vm.faxNotifyType,
                    callback: function($$v) {
                      _vm.faxNotifyType = $$v
                    },
                    expression: "faxNotifyType"
                  }
                })
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.$route.meta.type !== "FAX" &&
      (_vm.$route.meta.type !== "BRIEF" || _vm.briefType !== "WORD")
        ? _c(
            "div",
            {
              staticClass: "box__container",
              attrs: { tid: _vm._generateTidFromString("meta-type") }
            },
            [
              _c(
                "DragnDropArea",
                {
                  attrs: {
                    hoverText: "Datei hier ablegen",
                    tid: _vm._generateTidFromString("Datei hier ablegen")
                  },
                  on: {
                    files: function($event) {
                      return _vm.addDocument($event)
                    }
                  }
                },
                [
                  _c("HtmlEditor", {
                    attrs: {
                      disabled: _vm.isAlreadySent,
                      textPlaceholderOptions: _vm.placeholdersOptions,
                      isPreviewButtonDisabled:
                        !_vm.receiver || (_vm.receiver && !_vm.receiver.length),
                      previewHTML: _vm.convertPreview(
                        _vm.previewHtml.text,
                        _vm.previewHtml.plainText
                      ),
                      isFontParams: "",
                      applySavedSettings: "",
                      isPreviewButton: ""
                    },
                    on: { onTogglePreviewEnabled: _vm.handlePreview },
                    model: {
                      value: _vm.html,
                      callback: function($$v) {
                        _vm.html = $$v
                      },
                      expression: "html"
                    }
                  })
                ],
                1
              ),
              _vm.allowedSendLater
                ? _c(
                    "div",
                    { staticClass: "space-row" },
                    [
                      _vm._v(" Übermittlung verzögern bis "),
                      _c("DatePickerField", {
                        attrs: { dateFormat: "DD.MM.YYYY", label: "" },
                        model: {
                          value: _vm.dateSend,
                          callback: function($$v) {
                            _vm.dateSend = $$v
                          },
                          expression: "dateSend"
                        }
                      }),
                      _vm.dateSend
                        ? _c("TimePicker", {
                            attrs: { label: "" },
                            model: {
                              value: _vm.timeSend,
                              callback: function($$v) {
                                _vm.timeSend = $$v
                              },
                              expression: "timeSend"
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "DragnDropArea",
        {
          attrs: {
            hoverText: "Datei hier ablegen",
            tid: _vm._generateTidFromString("Datei hier ablegen")
          },
          on: {
            files: function($event) {
              return _vm.addDocument($event)
            }
          }
        },
        [
          _vm.$route.meta.type !== "BRIEF"
            ? _c("div", { staticClass: "box__container" }, [
                _vm.isLoaded
                  ? _c(
                      "div",
                      { staticClass: "d-block justify-content-between" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between hide-on-large-screen"
                          },
                          [
                            _c("div", { staticClass: "box__title" }, [
                              _vm._v("Anhänge")
                            ]),
                            _c(
                              "BaseContextMenu",
                              [
                                _c(
                                  "ContextMenuItem",
                                  {
                                    staticClass: "upload-btn",
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        "Datei hochladen"
                                      )
                                    }
                                  },
                                  [
                                    _vm._t("default", function() {
                                      return [_vm._v("Datei hochladen")]
                                    }),
                                    _c("input", {
                                      attrs: {
                                        type: "file",
                                        accept: _vm.acceptFileTypes,
                                        multiple: "",
                                        "aria-label": "Datei hochladen"
                                      },
                                      on: { change: _vm.onAddFiles }
                                    })
                                  ],
                                  2
                                ),
                                this.showDokArchiv
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        attrs: {
                                          tid: _vm._generateTidFromString(
                                            "Dokumentenarchiv"
                                          )
                                        },
                                        on: { click: _vm.handleAddDocuments }
                                      },
                                      [_vm._v(" Dokumentenarchiv ")]
                                    )
                                  : _vm._e(),
                                _vm.legitimation &&
                                _vm.legitimation.hasLegitimation
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        attrs: {
                                          tid: _vm._generateTidFromString(
                                            "Legitimationsurkunde"
                                          ),
                                          disabled:
                                            _vm.legitimation &&
                                            _vm.legitimation.disabled
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddDocuments(
                                              "LEGITIMATION"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Legitimationsurkunde ")]
                                    )
                                  : _vm._e(),
                                _vm.vollmacht && _vm.vollmacht.hasVollmacht
                                  ? _c(
                                      "ContextMenuItem",
                                      {
                                        attrs: {
                                          tid: _vm._generateTidFromString(
                                            "Vollmacht"
                                          ),
                                          disabled:
                                            _vm.vollmacht &&
                                            _vm.vollmacht.disabled
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.handleAddDocuments(
                                              "VOLLMACHT"
                                            )
                                          }
                                        }
                                      },
                                      [_vm._v(" Vollmacht ")]
                                    )
                                  : _vm._e(),
                                !!_vm.$slots.maklervollmacht
                                  ? _c(
                                      "ContextMenuItem",
                                      [_vm._t("maklervollmacht")],
                                      2
                                    )
                                  : _vm._e(),
                                _c(
                                  "ContextMenuItem",
                                  {
                                    staticClass: "upload-btn",
                                    attrs: {
                                      tid: _vm._generateTidFromString(
                                        "Markierte löschen"
                                      )
                                    },
                                    on: { click: _vm.removeAllMarked }
                                  },
                                  [_vm._v(" Markierte löschen ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c("Table", {
                          attrs: {
                            headers: _vm.headers,
                            rows: _vm.rows,
                            hidePagination: ""
                          },
                          on: { "action-DELETE": _vm.deleteFile },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "name",
                                fn: function(row) {
                                  return [
                                    !row.noPreview
                                      ? _c("DownloadLink", {
                                          attrs: {
                                            title: row.dateiname || row.name,
                                            filename: row.fileName || row.name,
                                            downloadServicePath:
                                              "/get_email_attachment",
                                            queryParams: {
                                              attId: row.copyFileId,
                                              tempFileId: row.id
                                            }
                                          }
                                        })
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(row.dateiname || row.name)
                                          )
                                        ])
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1082709902
                          ),
                          model: {
                            value: _vm.selectedAttachmentDocumentsRow,
                            callback: function($$v) {
                              _vm.selectedAttachmentDocumentsRow = $$v
                            },
                            expression: "selectedAttachmentDocumentsRow"
                          }
                        }),
                        _c("div", { staticClass: "hide-on-small-screen" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex d-md-block mt-1 layout__negative-margin--8 antrag-action-button__container justify-content-center"
                            },
                            [
                              _c(
                                "BaseButton",
                                {
                                  staticClass: "upload-btn",
                                  attrs: {
                                    isSecondary: "",
                                    tid: _vm._generateTidFromString(
                                      "Datei hochladen"
                                    )
                                  }
                                },
                                [
                                  _vm._t("default", function() {
                                    return [_vm._v("Datei hochladen")]
                                  }),
                                  _c("input", {
                                    attrs: {
                                      type: "file",
                                      accept: _vm.acceptFileTypes,
                                      multiple: "",
                                      "aria-label": "Datei hochladen"
                                    },
                                    on: { change: _vm.onAddFiles }
                                  })
                                ],
                                2
                              ),
                              this.showDokArchiv
                                ? _c(
                                    "BaseButton",
                                    {
                                      attrs: {
                                        isPrimary: "",
                                        tid: _vm._generateTidFromString(
                                          "Dokumentenarchiv"
                                        )
                                      },
                                      on: { click: _vm.handleAddDocuments }
                                    },
                                    [_vm._v(" Dokumentenarchiv ")]
                                  )
                                : _vm._e(),
                              _vm.legitimation &&
                              _vm.legitimation.hasLegitimation
                                ? _c(
                                    "BaseButton",
                                    {
                                      attrs: {
                                        isSecondary: "",
                                        tid: _vm._generateTidFromString(
                                          "Legitimationsurkunde"
                                        ),
                                        disabled:
                                          _vm.legitimation &&
                                          _vm.legitimation.disabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAddDocuments(
                                            "LEGITIMATION"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Legitimationsurkunde ")]
                                  )
                                : _vm._e(),
                              _vm.vollmacht && _vm.vollmacht.hasVollmacht
                                ? _c(
                                    "BaseButton",
                                    {
                                      attrs: {
                                        isSecondary: "",
                                        tid: _vm._generateTidFromString(
                                          "Vollmacht"
                                        ),
                                        disabled:
                                          _vm.vollmacht &&
                                          _vm.vollmacht.disabled
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.handleAddDocuments(
                                            "VOLLMACHT"
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v(" Vollmacht ")]
                                  )
                                : _vm._e(),
                              this.rows
                                ? _c(
                                    "BaseButton",
                                    {
                                      attrs: {
                                        tid: _vm._generateTidFromString(
                                          "Markierte löschen"
                                        ),
                                        disabled:
                                          this.selectedAttachmentDocumentsRow &&
                                          !this.selectedAttachmentDocumentsRow
                                            .length,
                                        isPrimary: ""
                                      },
                                      on: { click: _vm.removeAllMarked }
                                    },
                                    [_vm._v(" Markierte löschen ")]
                                  )
                                : _vm._e(),
                              _vm._t("maklervollmacht")
                            ],
                            2
                          )
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _c("AddDocument", {
            ref: "addDocumentModal",
            attrs: { vtgId: _vm.$route.params.id, nodeId: _vm.nodeId }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modalTestMail",
          attrs: {
            modalTitle: "Testmail senden an",
            labelButtonConfirm: "Testmail versenden"
          },
          on: { onConfirmButton: _vm.sendTestMail }
        },
        [
          _c("ComboBox", {
            attrs: { values: _vm.testMailPreselectOptions, firstEmpty: false },
            model: {
              value: _vm.testMailPreselect,
              callback: function($$v) {
                _vm.testMailPreselect = $$v
              },
              expression: "testMailPreselect"
            }
          }),
          _vm.testMailPreselect === "-1" || _vm.testMailPreselect === ""
            ? _c("InputField", {
                attrs: { placeholder: "E-Mail" },
                model: {
                  value: _vm.testMailPreselectEmail,
                  callback: function($$v) {
                    _vm.testMailPreselectEmail = $$v
                  },
                  expression: "testMailPreselectEmail"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "modalError",
          attrs: {
            modalTitle: "Fehlerliste für Verfassen",
            labelButtonConfirm: "Trotzdem fortfahren",
            showConfirmButton: !_vm.isCriticalSendingError
          },
          on: { onConfirmButton: _vm.sendIgnoreError }
        },
        _vm._l(_vm.errorMessage, function(e, i) {
          return _c("div", { key: i }, [_vm._v(" " + _vm._s(e) + " ")])
        }),
        0
      ),
      _c(
        "BaseModal",
        {
          ref: "modalNoContact",
          attrs: {
            modalTitle: "Fehler",
            labelButtonConfirm: "Zum Profil wecheln"
          },
          on: { onConfirmButton: _vm.goToProfile }
        },
        [
          _vm.$route.meta.type === "FAX"
            ? _c("div", [
                _vm._v(
                  " Der Kunde hat keine Fax-Nummer. Bitte fügen Sie diese zuerst ein. "
                )
              ])
            : _c("div", [
                _vm._v(
                  " Der Kunde hat keine E-Mail-Adresse. Bitte fügen Sie diese zuerst ein. "
                )
              ])
        ]
      ),
      _c(
        "BaseModal",
        {
          ref: "manualReceiverModal",
          attrs: {
            modalTitle: "Empfänger hinzufügen",
            labelButtonConfirm: "Speichern"
          },
          on: {
            onConfirmButton: _vm.setManualReceiver,
            close: _vm.resetManualReceiver
          }
        },
        [
          _c("InputField", {
            attrs: { label: "Name" },
            model: {
              value: _vm.manualReceiver.name,
              callback: function($$v) {
                _vm.$set(_vm.manualReceiver, "name", $$v)
              },
              expression: "manualReceiver.name"
            }
          }),
          _c("InputField", {
            attrs: { label: "Vorname" },
            model: {
              value: _vm.manualReceiver.vorname,
              callback: function($$v) {
                _vm.$set(_vm.manualReceiver, "vorname", $$v)
              },
              expression: "manualReceiver.vorname"
            }
          }),
          _vm.$route.meta.type !== "FAX"
            ? _c("ComboBox", {
                attrs: { label: "Anrede", values: _vm.anredeValues },
                model: {
                  value: _vm.manualReceiver.anrede,
                  callback: function($$v) {
                    _vm.$set(_vm.manualReceiver, "anrede", $$v)
                  },
                  expression: "manualReceiver.anrede"
                }
              })
            : _vm._e(),
          _vm.$route.meta.type !== "FAX"
            ? _c("InputField", {
                attrs: { label: "Briefanrede" },
                model: {
                  value: _vm.manualReceiver.briefanrede,
                  callback: function($$v) {
                    _vm.$set(_vm.manualReceiver, "briefanrede", $$v)
                  },
                  expression: "manualReceiver.briefanrede"
                }
              })
            : _vm._e(),
          _vm.$route.meta.type !== "FAX"
            ? _c("InputField", {
                attrs: { label: "Ort" },
                model: {
                  value: _vm.manualReceiver.ort,
                  callback: function($$v) {
                    _vm.$set(_vm.manualReceiver, "ort", $$v)
                  },
                  expression: "manualReceiver.ort"
                }
              })
            : _vm._e(),
          _vm.$route.meta.type !== "FAX"
            ? _c("InputField", {
                attrs: { label: "Straße" },
                model: {
                  value: _vm.manualReceiver.strasse,
                  callback: function($$v) {
                    _vm.$set(_vm.manualReceiver, "strasse", $$v)
                  },
                  expression: "manualReceiver.strasse"
                }
              })
            : _vm._e(),
          _vm.$route.meta.type !== "FAX"
            ? _c("InputZIP", {
                attrs: { label: "PLZ" },
                on: {
                  city: function($event) {
                    _vm.manualReceiver.ort = $event
                  }
                },
                model: {
                  value: _vm.manualReceiver.plz,
                  callback: function($$v) {
                    _vm.$set(_vm.manualReceiver, "plz", $$v)
                  },
                  expression: "manualReceiver.plz"
                }
              })
            : _vm._e(),
          _c("InputField", {
            attrs: { label: "Fax", validateUntouched: false, inputmode: "tel" },
            model: {
              value: _vm.manualReceiver.fax,
              callback: function($$v) {
                _vm.$set(_vm.manualReceiver, "fax", $$v)
              },
              expression: "manualReceiver.fax"
            }
          }),
          _vm.$route.meta.type !== "FAX"
            ? _c("InputField", {
                attrs: { label: "E-Mail" },
                model: {
                  value: _vm.manualReceiver.email,
                  callback: function($$v) {
                    _vm.$set(_vm.manualReceiver, "email", $$v)
                  },
                  expression: "manualReceiver.email"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }