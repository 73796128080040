<template>
  <div v-if="nodeId" class="documents-table__container">
    <GhostLoading v-if="isLoading && !scrollLoading" type="table" :title="title" :config="{ table: { rows: 5, } }" />
    <template v-else>
        <PaginatedTable
            :key="nodeId"
            :title="title"
            :tableId="tableId"
            :headers="headers"
            :pages="pages"
            :pageCount="pageCount"
            :rowCount="resultCount"
            :hidePagination="pageCount <= 1"
            :page="currentPage"
            :selectionAsBlacklist="isSelectedAllInFolder"
            @selectionAsBlacklist="setInvertedList($event)"
            :selected="showCheckboxes() ? selectedRows : null"
            :noDataIcon="showNoDataMessageWithIcon"
            :noDataContent="NO_DATA_MESSAGE"
            :mobileConfig="{title: 'bezeichnung', headers: mobileHeaders, selectionActive: true}"
            :headerActions="tableHeaderActions"
            @headerAction="executeHeaderAction"
            @selected="setRowSelection"
            @requestPage="onRequestPage"
            @page="setPageIndex"
            @click-sichtbar="handleClickVisible"
            @click-vertragsnr="openVertrag"
            @click-schadensnr="openSchaden"
            @action="handleAction"
            @sort="onSort"
            :sorted="sorted"
            @scrollLoading="scrollLoading = $event"
            @onScroll="onScroll"
        >
            <template v-slot:bezeichnung="row"> 
              <span v-if="row.mobileTableContext" style="vertical-align: middle">
                <ph-trash :size="16" class="color-danger mr-1 icon" v-if="!row.exists"/>{{row.bezeichnung || row.fileName || 'unbenannt'}}
              </span>
              <span v-else-if="!row.data.fileWithSignedAttachments && listHasAction(row.data.actions, 'DOKUMENT_ANZEIGEN')">
                <ph-trash :size="16" class="color-danger mr-1 icon" v-if="!row.exists"/>
                <DownloadLink v-if="$isSmallScreen || !canPreviewFile(row)" target="_blank" rel="noopener noreferer" 
                    :title="row.bezeichnung"
                    :filename="row.data.fileName || row.data.bezeichnung"
                    downloadServicePath="/docInfo"
                    :queryParams="{
                      nodeId: row.nodeId, 
                      idFile: encodeURIComponent(row.id)
                    }"
                />

                <template v-else>
                    <DownloadLink target="_blank" rel="noopener noreferer" 
                      title="In neuem Tab öffnen"
                      :filename="row.data.fileName || row.data.bezeichnung"
                      downloadServicePath="/docInfo"
                      :queryParams="{
                        nodeId: row.nodeId, 
                        idFile: encodeURIComponent(row.id)
                      }"
                  >
                    <PhFilePdf v-if="looksLikePDFFile(row)" :size="16" alt="Icon zum Generieren eines PDFs"/>
                    <PhFile v-else :size="16" />&nbsp;
                  </DownloadLink>
                  
                  &nbsp;<a  @click="fetchObject(row)">{{ row.bezeichnung }}</a>
                </template>


              </span>

              <span v-else-if="listHasAction(row.data.actions, row.data.actionOnClick)">
                <span v-bind:key="action.legend.label + uuidv4()" v-for="action in row.data.actions">  
                  <a v-if="action.legend.key === row.data.actionOnClick" @click="handleExecuteAction({action: action, value: row})">
                    <ph-trash :size="16" class="color-danger mr-1 icon" v-if="!row.exists"/> {{row.bezeichnung || row.fileName || 'unbenannt'}}
                  </a>
                </span>
              </span>

              <span v-else style="vertical-align: middle">
                <ph-trash :size="16" class="color-danger mr-1 icon" v-if="!row.exists"/>{{row.bezeichnung || row.fileName || 'unbenannt'}}
              </span>
            </template>
            <template v-slot:vorlagestatus="row"> 
                <WorkflowPill :currentStatusIcons="row.data.statusActions" :statusType="'VORLAGE'"></WorkflowPill>
            </template>
            <template v-slot:signaturstatus="row"> 
                <WorkflowPill :currentStatusIcons="row.data.statusActions" :statusType="'SIGNATUR'"></WorkflowPill>
            </template>
        </PaginatedTable>
        <span v-if="showCheckboxes() && (selectedRows.length || isSelectedAllInFolder)">
            Insgesamt {{ isSelectedAllInFolder ? resultCount - selectedRows.length : selectedRows.length}} ausgewählt
        </span>
      <!-- </template> -->

    </template>

    <BaseModal
      ref="signoFilesModal"
      labelButtonCancel="Zurück"
      :showCancelButton="false"
      :showConfirmButton="false">

      <template #modalTitle>
        <b v-if="signoFiles && signoFiles.length">Mehrere Dateien zu signieren</b>
        <b v-else>Keine Dokumente zur Unterschrift</b>
      </template>
      <template #default>
        <p v-if="signoFiles && signoFiles.length">
          <b>Es liegen folgende Dokumente zur Unterschrift bereit:</b>
        </p>
        <p v-else>Es liegen keine Dokumente zur Unterschrift bereit.</p>

        <div v-for="item in signoFiles" :key="item.url">
          <div class="grid">
            <div class="row">
              <div class="col">
                <AnimatedSpinner v-if="signoModalLoading"/>
                <div
                  v-if="item.signaturStatus && item.signaturStatus === 'KOMPLETT'">
                    {{ item.fileName }} (bereits eingereicht)
                </div>
                <a
                  v-else-if="item.isPictureAttach"
                  @click="signoModalLoading ? null : openZusatzDokModal(item)">
                    {{ item.fileName }}
                </a>

                <DownloadLink 
                  v-else
                  :href="item.url"
                  :title="item.fileName" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <BaseButton 
          label="Zurück"
          :disabled="signoModalLoading"
          :animated="signoModalLoading"
          @click="$refs.signoFilesModal.close()"
        />
      </template>
    </BaseModal>

    <BaseModal
      ref="noSignoFilesModal"
      labelButtonCancel="Zurück"
      :showConfirmButton="false">

      <template #modalTitle>
        <b>Das Dokument ist nicht zur e-Signatur geeignet</b>
      </template>
      <template #default>
        <p>Sie können jedoch das Dokument herunterladen</p>

        <div class="grid">
          <div class="row">
            <div class="col">
              <DownloadLink 
                v-if="currentActionData && currentActionData.document"
                :title="currentActionData.document.bezeichnung || currentActionData.document.fileName || 'unbenannt'"
                :filename="currentActionData.document.fileName || currentActionData.document.bezeichnung"
                downloadServicePath="/docInfo"
                :queryParams="{
                  nodeId: currentActionData.document.nodeId, 
                  idFile: encodeURIComponent(currentActionData.document.id), 
                }" />
            </div>
          </div>
        </div>
      </template>
    </BaseModal>

    <EditDocumentModal 
      ref="editDokumentModal"
      :additionalInputs="currentEditDocumentInputs"
      @confirm="saveDokument"
    />

    <PictureAttachmentsModal
      ref="pictureAttachmentsModal"
      :selectedDoc="selectedDoc"
      @updateLoadingStatus="updateLoadingStatus($event)">
    </PictureAttachmentsModal>

    <BaseModal
      ref="showCallRecordsModal"
      modalTitle="Aufgezeichnetes Beratungsgespräch"
      :showConfirmButton="false"
      labelButtonCancel="Zurück">

      <p>{{ additionalDocuments && additionalDocuments.tableTitle || '' }}</p>

      <p><b>Video</b></p>
      <div v-for="(cr, index) in callRecordList" v-bind:key="index">
        <DownloadLink 
          :href="makeCallRecordLink(cr)"
          :title="cr.bezeichnung" />
      </div> 
    </BaseModal>  

    <BaseModal
        ref="multipleFilesModal"
        :modalTitle="currentFilename"  :showCancelButton="false" :showConfirmButton="false"   
    >
      <FileAttachmentsModal :signedAttachments="signedAttachments" :unsignedAttachments="unsignedAttachments" @close="$refs.multipleFilesModal.close()" />

    </BaseModal>    

    <BaseModal
        ref="downloadErrorModal"
        modalTitle="Fehler"
        labelButtonConfirm="Ok"
        :showCancelButton="false">
        <label>{{downloadErrorMessage}}</label>
    </BaseModal> 

    <BaseModal
      ref="confirmationModal"
      modalTitle="Bestätigung"
      labelButtonConfirm="Ok"
      :showCancelButton="true"
      @onConfirmButton="onConfirmHeaderActionModal()"
    >
      <label>{{ confirmationMessage }}</label>
    </BaseModal>

    <BaseModal ref="sendEmailModal" modalTitle="Dokumente versenden" :showDefaultButtons="false">
      An wen möchten Sie die ausgewählten Dokumente versenden?
      <template #footer>
        <BaseButton isSecondary @click="closeSendEmailModal()">Abbrechen</BaseButton>
        <BaseButton @click="confirmSendEmail();">An Kunden</BaseButton>
        <BaseButton @click="confirmSendEmail(true);">An Gesellschaft</BaseButton>
      </template>
    </BaseModal> 

    <EmailVersand :unregisterEventsBeforeDestroy="unregisterEmailEventsBeforeDestroy"/>

    <DocumentsUploadModal
      v-if="files && currentAdditionalInputs"
      :files="files"
      @close="files=null"
      :additionalInputs="currentAdditionalInputs"
      :parentId="nodeId"
      :versicherungId="versicherungId"
      :schadenId="schadenId"
      :uploadType="filesFromDokumentenarchiv.length ? 5 : null"
      :showOnlySchadenDocs="showOnlySchadenDocs"
      :maxFileSizeMB="maxFileSizeMB"
    />

    <PreviewObject :fullClientHeight="true" :saveLastPosition="true" ref="resizablePopup" :modalTitle="modalTitle" :objectUrl="objectUrl" :signatureUrlMetadata="signatureUrlMetadata" id="d4fbef9a-7577-47f1-b0a4-38b111f7f426"></PreviewObject>
  </div>
</template>

<script>
import {
  PhEye,
  PhEyeSlash,
  PhCheck,
  PhChecks,
  PhWarning,
  PhPencil,
  PhTrash,
  PhFilePdf,
  PhFile,
} from "phosphor-vue";
import axios from 'axios';
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import ANTRAG_TYPES from "@/store/antrag/types";
import DOCUMENT_TYPES from "@/store/documents/types";
import LOG_TYPES from '@/store/log/types';

import PaginatedTable from "@/components/table2/PaginatedTable.vue";

import { IconColumn, PillColumn, SlotColumn, ActionColumn, Icon, SimpleAction, oldToNewColumn, TextColumn, textToSortable} from "@/components/table2/table_util.js";
import WorkflowPill from '@/components/core/WorkflowPill.vue';
import Pill from '@/components/core/Pill.vue';
import BaseModal from '@/components/core/BaseModal.vue';
import PictureAttachmentsModal from "@/views/documents/PictureAttachmentsModal.vue";
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue'
import GhostLoading from '@/components/core/loading/GhostLoading.vue'
import BaseButton from "@/components/core/BaseButton.vue";
import EmailVersand from "@/components/core/EmailVersand.vue";

import FormUtil from '@/components/beratung/formsMenu/forms-menu.js';
import FileAttachmentsModal from '@/components/documents/FileAttachmentsModal.vue'
import EditDocumentModal from '@/components/documents/EditDocumentModal.vue'
import DownloadLink from '@/components/core/download/DownloadLink.vue'
import { openSignoViewDocument } from '@/components/core/download/SignoViewerLink.vue';
import {sanitize} from '@/helpers/string-helper.js';
import { Draggable, } from '@/directives/v-draggable-directive';
import DocumentsUploadModal from '@/components/fileUpload/DocumentsUploadModal.vue';

import documentsHeaderActionsMixin from '@/components/documents/documents-header-actions-mixin';
import { PageHeaderSimpleAction, } from '@/components/core/header-title-navigation/page-header-utils';
import { downloadLinkMaker } from '@/helpers/utils-helper';
import PreviewObject from '@/components/core/PreviewObject.vue';
import { buildMessage } from "@/helpers/log-message-helper";

const BASE_URL = process.env.VUE_APP_API;
const config = {
  defaultSpinner: true
};
const NO_DATA_MESSAGE = 'Keine Dokumente vorhanden';

const sortFunc = (arrays) => {
  return arrays.slice().sort(function(a, b){
    return (a.index > b.index) ? 1 : -1;
  });
};

const ROOT_DOWNLOAD_SERVICE_PATH = '/download_service'

export default {
  mixins: [documentsHeaderActionsMixin],
  components: {
    PhEye,
    PhEyeSlash,
    PhCheck,
    PhWarning,
    PaginatedTable,
    WorkflowPill,
    Pill,
    BaseModal,
    FileAttachmentsModal,
    PictureAttachmentsModal,
    AnimatedSpinner,
    BaseButton,
    DownloadLink,
    GhostLoading,
    PhPencil,
    PhTrash,
    EditDocumentModal,
    DocumentsUploadModal,
    EmailVersand,
    PreviewObject,
    PhFilePdf,
    PhFile,
  },
  props: {
    title: {
      type: String,
    },
    tableId: {
      type: String,
    },
    nodeId: {
      type: [String, Number],
    },
    versicherungId: {
      type: [String, Number],
      default: null,
      required: false
    },
    schadenId: {
      type: [String, Number],
      default: null,
      required: false
    },
    showNoDataMessageWithIcon: { 
      type: Boolean,
      default: false
    },
    rowsPerPage: {
      default: 20,
    },
    showActionColumn: {
      type: Boolean,
      default: true
    },
    loadAntraege: {
      type: Boolean,
      default: false
    },
    linkVertrag: {
      type: Boolean,
      default: false
    },    
    enableDraggable: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: false
    },
    filterFunc: {
      type: Function,
      default: (v) => v
    },
    noHeaderActions: {
      type: Boolean,
      default: false
    },
    unregisterEmailEventsBeforeDestroy: { 
      type: Boolean,
      default: true
    },
    maxFileSizeMB: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      NO_DATA_MESSAGE,
      currentActionData: null,
      signoFiles: [],
      fileId: null,
      unsignedAttachments: [],
      signedAttachments: [],
      currentFilename: null,
      downloadErrorMessage: null,    
      downloadLink: {},
      selectedDoc: null,
      signoModalLoading: false,
      currentEditDocumentInputs: [],
      scrollLoading: false,
      objectUrl: null,
      modalTitle: 'Vorschau',
      signatureUrlMetadata: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedNode: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS,
      currentPage: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENT_PAGE,
      loginData: CORE_TYPES.GETTERS.GET_LOGIN_DATA,
      getCoreUserID: CORE_TYPES.GETTERS.GET_USER_ID,
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST,
      selectedRows: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
      simpleFileLinkGenerator: DOCUMENT_TYPES.GETTERS.GET_ANTRAG_SCANS_LINK_MAKER,
      isLoadingNode: DOKUMENTENARCHIV_TYPES.GETTERS.IS_LOADING_NODE,
      additionalDocuments: DOKUMENTENARCHIV_TYPES.GETTERS.ADDITIONAL_DOCUMENTS,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      showDeleted: DOKUMENTENARCHIV_TYPES.GETTERS.SHOW_DELETED_DOCUMENTS,
      documentsFilter: DOKUMENTENARCHIV_TYPES.GETTERS.DOCUMENTS_FILTER,
      isSelectedAllInFolder: DOKUMENTENARCHIV_TYPES.GETTERS.IS_SELECTED_ALL_IN_FOLDER,
      sorted: DOKUMENTENARCHIV_TYPES.GETTERS.SORTING,
      isAnlageBeispiel: CORE_TYPES.GETTERS.IS_ANLAGE_BEISPIEL,
      isDropActionActive: DOKUMENTENARCHIV_TYPES.GETTERS.IS_DROP_ACTION_ACTIVE,
      isCustomerLogin: CORE_TYPES.GETTERS.IS_CUSTOMER_ONLY,
    }),
    isLoading() {
      return this.isLoadingNode || this.isDropActionActive;
    },
    headers() {
        const headers = {
            lockedLeft: [
                SlotColumn('bezeichnung', this.selectedNode?.downloadColumnLabel || 'Bezeichnung', 250, 1)
                    .makeAlwaysVisible()
                    .makeSortable(bezeichnung => textToSortable(bezeichnung)),
            ],
            center: [],
            lockedRight: [],
        };

        if (this.selectedNode && this.selectedNode.headers) {
            this.selectedNode.headers.filter(h => h.visible)
            .forEach(header => {
              switch (header.lockedColumn) {
                case 'left':
                  headers.lockedLeft.push(oldToNewColumn(header.key, header.label, header.dataType, header.cellProps, null, header.clickable, header.sortable))
                  break;
                case 'right':
                  headers.lockedRight.push(oldToNewColumn(header.key, header.label, header.dataType, header.cellProps, null, header.clickable, header.sortable))
                  break;
                default:
                  headers.center.push(oldToNewColumn(header.key, header.label, header.dataType, header.cellProps, null, header.clickable, header.sortable).addCellProps({lineClamp: 4}))
                  break;
              }
              });
        }

        if (this.hasStatusAction('VORLAGE_STATUS') && !this.isCurrentRouteSchadensmeldung)
            headers.lockedRight.push(SlotColumn('vorlagestatus', 'Vorlage', 200, 0))
        if (this.hasAction('DOKUMENTE_HINZUFUGEN'))
            headers.lockedRight.push(PillColumn('dokumente', 'Dokumente', 120))
        if (this.hasStatusAction('FREIGABE_KUNDE'))
            headers.lockedRight.push(IconColumn('sichtbar', 'Sichtbar').makeConditionalLink('exists'))
        if (this.hasAction('GETEILT_MIT_KUNDE'))
            headers.lockedRight.push(PillColumn('geteilt', 'Geteilt'))

        if (!this.versicherungId || this.isBrokerOrBypass) {
          if (this.hasStatusAction('ANTRAG_STATUS'))
              headers.lockedRight.push(IconColumn('status', 'Status'))
          if (this.hasStatusAction('ANLEGERPROFIL_BESTATIGT'))
              headers.lockedRight.push(IconColumn('apstatus', 'Status'))
          if (this.hasStatusAction('SIGN_STATUS'))
              headers.lockedRight.push(SlotColumn('signaturstatus', 'e-Signatur', 200, 0))
              
          if (this.showActionColumn) {
            headers.lockedRight.push(ActionColumn('actions', 'Aktionen'))
          }
        } else {
          headers.center = headers.center.filter(h => h.key !== 'bemerkung')
        }

        return headers;
    },
    pageCount() {
        return this.selectedNode?.numberOfPages || 0;
    },
    pages() {
        if (!this.selectedNode?.documents)
            return {};
        return Object.fromEntries(Object.entries(this.selectedNode?.documents).map(([key, page]) => {
            return [key, page
                .map(document => this.mapDocument(document))];
            }));
    },
    resultCount() {
        return this.selectedNode.numberOfRecords || 0;
    },
    callRecordList() {
      return this.additionalDocuments && this.additionalDocuments.tableRows || []
    },
    mobileHeaders() {
      return this.selectedNode.mobileHeaders || []
    },
    tableHeaderActions() {
      if (this.noHeaderActions) {
        return [];
      }

      return sortFunc(this.filterFunc(this.selectedNode?.headerActions || []))
        .map(headerAction => {
          return PageHeaderSimpleAction(headerAction.key, headerAction.label)
            .withDisabled(() => this.buttonDisabled(headerAction.key))
            .withValue(headerAction)
            .withAsPrimary(() => headerAction.key === 'DATEI_HOCHLADEN' || headerAction.key === 'DATEI_HINZUFUGEN');
        });
    },
    isLoadingComplete() {
      return !this.isLoadingNode && !!this.tableHeaderActions?.length;
    },
    isCurrentRouteSchadensmeldung() {
      return this.$route.path.includes('schadensmeldung')
    }
  },
  watch: {
    nodeId: {
      handler(newNodeId) {
        if(newNodeId && !this.isModal) {
          this.getNextPageDocuments(newNodeId, 0);
        }
      },
      immediate: true,
    },
    documentsFilter: {
      handler() {
        if (!this.isModal) {
          this.getNextPageDocuments(this.nodeId, 0, false, true);
        }
      },
    }
  },
  methods: {
    canPreviewFile(row) {
      if (!row || !row.data || !row.data.originalFileName) {
        return false;
      }

      const fileName = row.data.originalFileName;
      const allowedExtensions = ['.pdf', '.txt', '.html', '.png', '.jpg', '.jpeg', '.wav', '.mp3', '.mp4', '.gif'];
      const fileExtension = fileName.substring(fileName.lastIndexOf('.')).toLowerCase();

      return allowedExtensions.includes(fileExtension);
    },
    looksLikePDFFile(whatRow) {
      const fileName = whatRow?.data?.originalFileName || '';
      const fileExtension = fileName.slice(fileName.lastIndexOf('.')).toLowerCase();
      return fileExtension === '.pdf';
    },
    fetchObject(fetchData) {
        const fileName = fetchData?.data?.fileName || fetchData?.data?.bezeichnung;

        const queryParams = {
          nodeId: fetchData?.nodeId, 
          idFile: encodeURIComponent(fetchData?.id)
        };

        const downloadServicePath="/docInfo";

        const url = downloadLinkMaker(this.$store.getters, `${ROOT_DOWNLOAD_SERVICE_PATH}${downloadServicePath}`, fileName, queryParams);
        this.objectUrl = url;

        this.signatureUrlMetadata = {
          fileName,
          queryParams,
          downloadServicePath,
          fetchSignatures: true
        }

        this.modalTitle = fileName;

        this.$refs.resizablePopup.showPopup();

    },       
    async onScroll(onScrollEnd) {
      try {
        await this.onRequestPage(this.currentPage + 1)
      } finally {
        onScrollEnd()
      }
    },
    mapDocument(document) {
      let row = {
          data: document,
          ...document,
      };
      if (this.selectedNode.headers && document.additionalColumns) {
          row = {
              ...row,
              ...document.additionalColumns,
          };
      }
      row.bezeichnung = row.data?.bezeichnung || row.bezeichnung || row.fileName || 'unbenannt';
      if (this.hasAction('DOKUMENTE_HINZUFUGEN')) {
          row.dokumente = !row.documentCount
                      ? {type: "info", label: "keine Dokumente"}
                      : {type: "info", label: row.documentCount + (row.documentCount == 1 ? " Dokument" : " Dokumente")};
      }
      if (this.hasStatusAction('FREIGABE_KUNDE')) {
          row.sichtbar = null;
          if (document.statusActions.find(a => a.legend.key == 'FREIGABE_KUNDE_VERWEIGERT' || a.legend.key == 'FREIGABE_MAKLER_VERWEIGERT'))
              row.sichtbar = [Icon(PhEyeSlash, "Freigabe verweigert", 16, "bold", "color-danger")];
          else if (document.statusActions.find(a => a.legend.key == 'FREIGABE_KUNDE_VORHANDEN' || a.legend.key == 'FREIGABE_MAKLER_VORHANDEN'))
              row.sichtbar = [Icon(PhEye, "freigegeben", 16, "bold", "color-text")];
      }
      if (this.hasAction('GETEILT_MIT_KUNDE')) {
          let action = document.actions.find(a => a.specificAction == 'ANTRAG_TEILEN')
          if (action) {
            row.geteilt = action.legend.key == 'NICHT_MIT_KUNDE_TEILEN'
                        ? {type: "success", label: action.additionalData.pillLabel}
                        : {type: "danger", label: action.additionalData.pillLabel}
          }
      }
      if (this.hasStatusAction('ANTRAG_STATUS')) {
          row.status = null;
          if (document.statusActions.find(a => a.legend.key == 'ANTRAG_ORDNUNG'))
              row.status = [Icon(PhCheck, "Gut", 16, "bold", "color-success")];
          else if (document.statusActions.find(a => a.legend.key == 'ANTRAG_FEHLER'))
              row.status = [Icon(PhWarning, "Fehler", 16, "bold", "color-danger")];
      }
      if (this.hasStatusAction('ANLEGERPROFIL_BESTATIGT')) {
          row.apstatus = null;
          if (document.statusActions.find(a => a.legend.key == 'ANLEGERPROFIL_FEHLERHAFT'))
              row.apstatus = [Icon(PhWarning, "fehlerhaft", 16, "bold", "color-danger")];
          else if (document.statusActions.find(a => a.legend.key == 'ANLEGERPROFIL_BESTATIGT'))
              row.apstatus = [Icon(PhCheck, "bestätigt", 16, "bold", "color-success")];
          else if (document.statusActions.find(a => a.legend.key == 'ANLEGERPROFIL_BESTATIGT_OK'))
              row.apstatus = [Icon(PhChecks, "bestätigt", 16, "bold", "color-success")];
          else if (document.statusActions.find(a => a.legend.key == 'ANLEGERPROFIL_BESTATIGT_PENDING'))
              row.apstatus = [Icon(PhCheck, "unbestätigt", 16, "bold", "color-warning")];
      }

      row.actions = [];
      if (document.actions) {
          document.actions.forEach(action => {
              if (action.legend.key !== document.actionOnClick && (this.isIntern && !row.exists ? action.showIfDeleted : true)) {
                row.actions.push(SimpleAction(action.legend.key, action.legend.icon, action.legend.label));
              }
          });
      }

      if(this.enableDraggable) {
        const draggableText = [row.bezeichnung,row.name]
          .filter(text => !!text) // filter only valid values
          .join(' | ')
        row.$draggable = row.draggable ? Draggable(row, draggableText) : null;
      }

      row.selectedHidden = !parseInt(row.documentCount); // zero documents must not be allowed to select

      return row;
    },
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    setInvertedList(event) {
      this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_SELECTED_ALL_IN_FOLDER, event)
    },
    makeCallRecordLink(callRecord) {
      let sendList = []

      for (const [key, value] of Object.entries(callRecord.sendData)) {
          sendList.push(key)
          sendList.push(value)
      }

      return this.simpleFileLinkGenerator(callRecord.bezeichnung, this.nodeId, sendList)
    },
    uuidv4() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function (c) {
          var r = (Math.random() * 16) | 0,
            v = c == "x" ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        }
      );
    },
    hasAction(whatAction) {
      if(!this.selectedNode?.availableActions) {
        return false;
      }
      return this.selectedNode.availableActions.some(r=> r.key == whatAction);
    },
    hasStatusAction(whatAction) {
      if(!this.selectedNode?.availableStatusIcons) {
        return false;
      }
      return this.selectedNode.availableStatusIcons.some(r=> r.key == whatAction);
    },
    setRowSelection(whatRows) {
      const selectedDocumentsWithoutFiles = whatRows.filter(document => document.documentCount == 0);

      if(selectedDocumentsWithoutFiles?.length) {
        let message = "Die folgenden ausgewählten Elemente haben keine zugehörigen Dokumentdateien und können nicht per E-Mail versandt werden: ";
        selectedDocumentsWithoutFiles.forEach(doc => message = message.concat(`\n- ${doc.fileName}`))
        this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(message, "danger"));
        // remove all documents without files from the selection
        whatRows = whatRows.filter(document => document.documentCount > 0)
      }

      const allRowsSelected = this.resultCount-this.selectedRows.length === 0;
      let selectedItems = this.isSelectedAllInFolder ? Object.values(this.pages).flat() : whatRows;
      this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_ROW_SELECTION, selectedItems);
      if(this.isSelectedAllInFolder) {
        // the selection value is inverted by the property 'selectionAsBlacklist', without it we cannot have the checkbox on the headers to mark all items
        this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_SELECTED_ALL_IN_FOLDER, false);
      }
    },
    getDownloadURL(document) {
      if(!document) {
        return ;
      }

      const token = this.loginData.token;
      return `${BASE_URL}/documents/docInfo?docInfo=${
        this.nodeId
      }&docInfo=${encodeURIComponent(document.id)}&token=${token}`;
    },
    onSort({key, sortDirection}) {
        this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.SORT_DOCUMENTS, {sortKey: key, ascending: sortDirection == 'asc'});
    },
    handleExecuteAction(event) {
      const actionData = {
        action: event.action,
        document: event.value,
        nodeId: this.nodeId,
        offset: this.currentPage,
        fileName: event.fileName,
        bezeichnung: event.bezeichnung,
      };

      this.currentActionData = actionData;

      this.executeAction(actionData);
    },
    handleClickVisible(row) {
        const action = row.data.statusActions.find(a => a.legend.key == 'FREIGABE_KUNDE_VERWEIGERT'
                                        || a.legend.key == 'FREIGABE_MAKLER_VERWEIGERT'
                                        || a.legend.key == 'FREIGABE_KUNDE_VORHANDEN'
                                        || a.legend.key == 'FREIGABE_MAKLER_VORHANDEN')
        if (action)
            this.handleAction({key: action.legend.key, row});
    },
    handleAction({key, row}) {
        const actionData = {
            action: row.data.actions.find(a => a.legend.key == key),
            document: row.data,
            nodeId: this.nodeId,
            offset: this.currentPage,
        };

        this.currentActionData = actionData;

        this.executeAction(actionData);
    },
    executeAction(actionData) {
      switch(actionData?.action?.legend?.key) {
        case 'DOKUMENT_ANZEIGEN':
          this.viewDocument(actionData.document);
          break;
        case "DOKUMENT_BEARBEITEN":
          this.editDocument(actionData);
          break;
        // Signoviewer
        case "DOKUMENT_UNTERSCHREIBEN":
          this.signDocument(actionData);
          break;
        case "KEINE_DOKUMENTE":
        case "DOKUMENTE_HINZUFUGEN":
        case "DOKUMENTE_HINZUFUGEN_KUNDENLOGIN":
        case "KREDIT_DARLEHENSVERTRAG_ANZEIGEN":
        case "DOKUMENT_ABSCHLIESSEN_SUBNODE":
          this.$router.push({
            path: `/communication/documents/zusaetzlichedokumente`, 
            query: { 
              nodeId: actionData.nodeId, 
              fileId: encodeURIComponent(actionData.document.id),
            },
          });
          break;
        //Kredite editieren
        case "KREDIT_DARLEHENSVERTRAG_OFFNEN":
            let property = actionData.action.sendData.find(sd => sd.key === "PARAM_KREDIT_ID")

            if (property) {
              const creditId = property.value;
              const link = `/home/verbindlichkeiten/credit/edit/${creditId}`;
              this.$router.push({path: link}).catch(() => {});
            }
          break;
        case "DOKUMENT_LOSCHEN":
        case "DOKUMENT_WIEDERHERSTELLEN":
        case "FREIGABE_MAKLER_VERWEIGERT":
        case "FREIGABE_MAKLER_VORHANDEN":
        case "FREIGABE_KUNDE_VORHANDEN":
        case "FREIGABE_KUNDE_VERWEIGERT":
        case "DOKUMENT_VORLEGEN":
        case "DOKUMENT_NICHT_VORLEGEN":  
        case "DOKUMENT_KUNDE_NICHT_VORLEGEN":
        case "DOKUMENT_VERMITTLER_NICHT_VORLEGEN":
        case "DOKUMENT_ABSCHLIESSEN":
        case "DOKUMENT_MULTIPLE_VORLAGE":
        case "DOKUMENT_REMOVE_MULTIPLE_REMOVE_VORLAGE":
        case "DOKUMENT_VORLEGEN_TEILWEISE":
        case "DOKUMENT_NICHT_VORLEGEN_TEILWEISE":
        case "MIT_KUNDE_TEILEN":
        case "NICHT_MIT_KUNDE_TEILEN":
          if (actionData.action.confirmationMessage) {
              this.$confirmModal({
                title: actionData.action.confirmationTitle  || 'Bestätigung',
                message: actionData.action.confirmationMessage,
                labelButtonConfirm: 'Ok',
              })
              .then(() => {
                // This is to not display an empty page when deleting the last item of a page
                if (actionData.action.legend?.key === "DOKUMENT_LOSCHEN" && !this.showDeleted) {
                  let documentsOnCurrentPage = this.pages[this.currentPage]?.length

                  if (documentsOnCurrentPage === 1 && actionData.offset > 0)
                    actionData.offset = actionData.offset - 1;
                }

                this.executeConfirmedAction(actionData)
              })
              .catch(() => {})
          } else {
            this.executeConfirmedAction(actionData)
          }
          break;
        case "CALL_RECORDS_ANZEIGEN":
          this.$store.dispatch(
            DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_ADDITIONAL_DOCUMENTS,
            {
              showDeleted: this.showDeleted,
              nodeId: actionData.nodeId,
              fileId: encodeURIComponent(actionData.document.id),
            }
          ).then(() => this.$refs.showCallRecordsModal.open());
          break;
        case "ANDERUNGSHISTORIE":
          this.$router.push(`/communication/documents/aenderungshistorie?docInfo=${encodeURIComponent(actionData.document.id)}`)
          break;
        case "ANLEGERPROFIL_BESTATIGT":
          axios.post(`${process.env.VUE_APP_API}/dokumentenarchiv/confirmAnlegerprofil&docInfo=${actionData.nodeId}&docInfo=${encodeURIComponent(actionData.document.id)}`)
          break;
        default:
          this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, {message: "action not yet implemented: " + actionData?.action?.legend?.key, actionData});
      }
    },
    executeConfirmedAction(actionData) {
      //Do not send any request if it has no specific action associated with it
      if(!actionData.action.specificAction ) {
        return;
      }
      this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_ACTION,
        {
          action: actionData.action,
          nodeId: actionData.nodeId,
          documentId: actionData.document.id,
          offset: actionData.offset,
          limit: this.rowsPerPage,
          schadenId: this.schadenId,
        }
      ).then(response => {
        if (response?.warningMessage) {
          this.$confirmModal({
            title: response.warningTitle,
            message: response.warningMessage,
            labelButtonConfirm: 'Ja',
            labelButtonCancel: 'Nein',
          }).then(() => {
            if (actionData.action.sendData) {
              actionData.action.sendData.push({ key: 'PARAM_IS_CONFIRMED_SUBMIT_MIT_BLANK_SIGNATURES', value: '1' });
              this.executeConfirmedAction(actionData);
            }
          }).catch(() => {})
        }
      });
    },
    saveDokument(editFileData) {
      this.currentActionData.action.additionalData = editFileData

      this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.EXECUTE_ACTION,
        {
          action: this.currentActionData.action,
          nodeId: this.currentActionData.nodeId,
          documentId: this.currentActionData.document.id,
          offset: this.currentActionData.offset,
          limit: this.rowsPerPage               
        })
    },
    viewDocument({nodeId, id}) {
      this.$store.dispatch(
        DOKUMENTENARCHIV_TYPES.ACTIONS.GET_FILE_DATA,
        {
          nodeId,
          id: encodeURIComponent(id),
        }
      ).then(response => {
          if (response?.length) {

            this.signedAttachments = response.filter(re => re.signed === true);
            this.unsignedAttachments = response.filter(re => re.unsigned === true);
            this.$refs.multipleFilesModal.open();
          }  else if (response?.errorMessage) {
            this.downloadErrorMessage = response?.errorMessage;
            this.$refs.downloadErrorModal.open();
          }
        });
    }, 
    editDocument(actionData) {
      const specificAction = actionData?.action?.specificAction || '';

      // Beratgunsmappe
      if (specificAction === "BERATUNGSMAPPE_OFFNEN") {
        this.openBeratungsmappe(actionData.action);
      }

      // Antrag
      if (specificAction === "ANTRAG_OFFNEN") {
        this.openAntrag(actionData.action);
      }

      // Antrag
      if (specificAction === "MAKLERANTRAG_OFFNEN") {
        this.openMaklerAntrag(actionData.action);
      }

        // Anlegerprofil
      if (specificAction === "ANLEGERPROFIL_OFFNEN") {
        this.openAnlegerprofil(actionData.action);
      }

      // Beratungsdokvers
      if (specificAction === "BERATUNGSDOK_OFFNEN") {
        this.openBeratungsdokVers(actionData.action);
      }

      // Dokument editieren
      if (specificAction === "DOKUMENT_BEARBEITEN") {
          let serviceUrl = `/dokumentenarchiv/getEditDocumentInputs?docInfo=${this.nodeId}&docInfo=${encodeURIComponent(this.currentActionData.document.id)}`;

          axios.get(`${process.env.VUE_APP_API}${serviceUrl}`, config).then(response => {
            if (response && response.data) {
              this.currentEditDocumentInputs = response.data
              this.$refs.editDokumentModal.open()
            }      
          })
      }

      // Anlageempfehlung
      if (specificAction === "ANLAGEEMPFEHLUNG_OFFNEN") {
        let property = actionData.action.sendData.find(sd => sd.key === "PARAM_ANLAGEEMPFEHLUNG_ID")

        if (property) {
          const adviceId = property.value;
          const path = this.isAnlageBeispiel ? 'anlagebeispiel' : 'anlageempfehlung';
          const link = `/beratung/${path}/details?adviceId=${adviceId}`;
          this.$router.push({path: link}).catch(() => {});
        }
      }
    },
    openBeratungsmappe(action) {
     let propertyIndex = action.sendData.findIndex(
        (sd) => sd.key === "PARAMETER_NAME_MAPPE_ID"
      );
      const beratunsmappeId = action.sendData[propertyIndex].value;

      propertyIndex = action.sendData.findIndex((sd) => sd.key === "art");
      const art = action.sendData[propertyIndex].value;

      propertyIndex = action.sendData.findIndex(
        (sd) => sd.key === "datum_erstellt"
      );
      const datumErstellt = action.sendData[propertyIndex].value;

      this.$router.push({path: '/beratung/beratung/bearbeiten', query: {
        id: beratunsmappeId, art: art, 
        datum: datumErstellt, 
        investementAdviceReferrer: this.investementAdviceReferrer && !this.$route.query.backAction}});

    },
    openVertrag(row) {
      this.$router.push({path: `/home/versicherungen/insurance-group/overview/${row.vertragId}`})
    },
    openSchaden(row) {
      this.$router.push(`/home/versicherungen/insurance-group/schadensmeldung/${this.versicherungId}/${row.schadenId}`)
    },
    openAntrag(action) {
      let propertyIndex = action.sendData.findIndex(
        (sd) => sd.key === "PARAMETER_NAME_ANTRAGDATEN_ID"
      );
      if(propertyIndex <= -1) {
        return ;
      }

      const self = this;

      axios
        .get(
          `${BASE_URL}/../mrskundenzugang?userType=KUNDE&userid=${this.getCoreUserID}`
        )
        .then((response) => {
          if (response.data.link) {
            const antrag = {
              beratungsMappeId: action.sendData.find(sd => sd.key === "PARAMETER_NAME_MAPPE_ID")?.value,
              bezeichnung: action.sendData.find(sd => sd.key === "bezeichnung")?.value,
              gesellschaft: action.sendData.find(sd => sd.key === "gesellschaft")?.value,
              antragsdatenId: action.sendData.find(sd => sd.key === "PARAMETER_NAME_ANTRAGDATEN_ID")?.value,
              antragModus: action.sendData.find(sd => sd.key === "antragModus")?.value,
              servicePath: action.sendData.find(sd => sd.key === "servicePath")?.value,
              hideBeratungsmappepopup: action.sendData.find(sd => sd.key === "hideBeratungsmappepopup")?.value
            }

            const link = FormUtil.getLoadURL(antrag, self.antragList.formGroups, self.isCustomerLogin);

            if (link) {
              this.$router.push({path: link}).catch(() => {});
            } else {
              this.$router.push({path: '/role-forbidden'});
            }
          }
        });
    },
    openMaklerAntrag(action) {
      const antragId = action.sendData.find(sd => sd.key === "PARAMETER_NAME_ANTRAGDATEN_ID")?.value
      const antragTyp = action.sendData.find(sd => sd.key === 'ANTRAG_TYP')?.value;
 
      if (antragTyp && antragId) {
        let link = null
        if (antragTyp === 'WK_KUNDENBESCHWERDE') {
          link = `/beratung/vv/beschwerde?antragsdatenId=${antragId}`;
        } else if (antragTyp === 'WK_FRAGEBOGEN') {
          link = `/beratung/vv/advisor-fragebogen/wealthkonzept/wk_advisor_fragebogen/fragebogen?bmOptChosen=true&antragsdatenId=${antragId}`;
        }else if(antragTyp == 'FA_CHECKLISTE'){
          link = `/intern/aufsichtsrechtliches/jahrescheckliste?antragsdatenId=${antragId}`;
        }

        if (link) {
          this.$router.push({path: link}).catch(() => {});
        } else {
          this.$router.push({path: '/role-forbidden'});
        }
      }
    },
    openAnlegerprofil(action) {
      let propertyIndex = action.sendData.findIndex(
        (sd) => sd.key === "PARAM_PRUEFNUMMER"
      );
      const pruefnummer = action.sendData[propertyIndex].value;

      axios
        .get(
          `${process.env.VUE_APP_API}/../mrskundenzugang?userType=KUNDE&userid=${this.getCoreUserID}`
        )
        .then((response) => {
          if (response.data.link) {
            const schiene = action.sendData?.find(elem => elem?.key === 'schiene')?.value === 'WEALTH_KONZEPT' ? 'WEALTH_KONZEPT' : '';
            const link = `/beratung/${schiene === 'WEALTH_KONZEPT' ? 'anlegerprofilWK' : 'anlegerprofil'}/person1/auswahl/personenauswahl?pruefnummer=${pruefnummer}`;
              this.$router.push({path: link, query: { schiene: schiene}}).catch(() => {});
          }
        });
    },
    openBeratungsdokVers(action) {
      let propertyIndex = action.sendData.findIndex(
        (sd) => sd.key === "PARAMETER_PROTOKOLL_ID"
      );
      const protokollId = action.sendData[propertyIndex].value;

      axios
        .get(
          `${process.env.VUE_APP_API}/../mrskundenzugang?userType=KUNDE&userid=${this.getCoreUserID}`
        )
        .then((response) => {
          if (response.data.link) {
            const link = `/beratung/formulare/antrag/VERSICHERUNG/beratungsdokumentationVersicherungen/?protokollId=${protokollId}&hideBeratungsmappepopup=true`;
              this.$router.push({path: link}).catch(() => {});
          }
        });
    },
    signDocument(actionData) {
      const specificAction = actionData?.action?.specificAction || '';
      if (specificAction === "SIGNOVIEWER_OFFNEN") {
        let fileId = null;

        let propertyIndex = actionData.action.sendData.findIndex(
          (sd) => sd.key === "fileId"
        );

        if (propertyIndex > -1) {
          fileId = actionData.action.sendData[propertyIndex];
        }

        if (actionData.nodeId && fileId) {
          let serviceUrl = `/dokumentenarchivSign/documentSignAction?docInfo=${actionData.nodeId}&docInfo=${encodeURIComponent(fileId.value)}`;

          axios.post(`${process.env.VUE_APP_API}${serviceUrl}`, actionData.action?.sendData, config).then(response => {
            if (response && response.data) {
              this.signoFiles = response.data.signatureOutputs
              this.handleSignature()
            }      
          })
        }
      }
    },
    handleSignature() {
      if (this.signoFiles && this.signoFiles.length === 1) {

        if (this.signoFiles[0].isPictureAttach) {
          // Dokument hat die Möglichkeit, weitere Dokumente beim Unterschreiben anzuhängen
          this.openZusatzDokModal(this.signoFiles[0])

        } else if (this.signoFiles[0].signaturStatus === 'KOMPLETT') {
           // Dokument wurde bereits eingereicht
            this.downloadErrorMessage = 'Das Dokument wurde bereits eingereicht';
            this.$refs.downloadErrorModal.open();

        } else if (this.signoFiles[0].url) {
          // Es gibt eine url
          openSignoViewDocument(this.signoFiles[0].url, this.signoFiles[0].fileName);

        } else {
          // Es gibt keine Dateien, im MSC wird hier nur die PDF geöffnet
          this.$refs.noSignoFilesModal.open()

        }
        // Es gibt mehrere Dateien -> antrag.isMultisignable
      } else if (this.signoFiles && this.signoFiles.length > 1) {
        this.$refs.signoFilesModal.open();

      } else {
        // Es gibt keine Dateien, im MSC wird hier nur die PDF geöffnet
        this.$refs.noSignoFilesModal?.open()
      }
    },
    setPageIndex(index) {
        this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_PAGE, index);
    },
    onRequestPage(newPageIndex) {
        return this.getNextPageDocuments(this.nodeId, newPageIndex, true);
    },
    getNextPageDocuments(nodeId, offset, keepOldPages = false, doAlwaysLoad = false) {
      this.setPageIndex(offset);
      return this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {
        nodeId: nodeId,
        offset: offset,
        limit: this.rowsPerPage,
        schadenId : this.schadenId,
        keepOldPages,
        linkVertrag: this.linkVertrag,
        doAlwaysLoad,
      });
    },
    retrieveAntragList() {
      this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST);
    },
    clearSelectedRows() {
      this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_SELECTED_ALL_IN_FOLDER, false);
      this.$store.commit(DOKUMENTENARCHIV_TYPES.MUTATIONS.SET_ROW_SELECTION, []);
    },
    listHasAction(actions, whatAction) {
      return actions && actions.some(r=> r.legend.key == whatAction);
    },  
    openZusatzDokModal(item) {
      if (item.signoElement) {
        this.selectedDoc = item.signoElement
        setTimeout(() => {
          this.$refs.pictureAttachmentsModal.open();
        }, 100);
      }
    },
    updateLoadingStatus(event) {
      this.signoModalLoading = event
    },
    showCheckboxes() {
      // some document archive folders don't have any header actions, this will prevent checkboxes shows up to select documents to send mails
      if(this.$route.path.includes("mailcomposer-dokumentenarchiv")) {
        return true;
      }
      if (this.selectedNode && this.selectedNode.headerActions) {
        return this.selectedNode.headerActions.some(x => x.key === 'MARKIERTE_MAIL_VERSCHICKEN' || x.key === 'MARKIERTE_LOSCHEN')
      }
      return false;
    },
  },
  mounted() {
    if (this.loadAntraege) {
      this.retrieveAntragList();
    }
  },
  beforeDestroy() {
    this.clearSelectedRows();
  },
}
</script>

<style scoped>
.container {
  margin: 0;
  padding: 0;
}

.cell {
  display: table-cell;
}

.icon {
  vertical-align: text-bottom
}
</style>
