<template>
  <div class="input-forms__container combo-box__container" :class="{'input-forms__container--embedded': isEmbedded}">
    <div class="input-forms__label-container" :class="{ 'inline-container': inlineContainer }">
      <div class="input-forms__label-content" v-if="label" :class="labelClass" v-html="sanitize(label)"></div>

      <div class="input-forms__input-container" :class="[inputContainerClasses]">
        <div class="combo-box--input-container" :class="{ 'has-no-option-selected': !selectedOption, }">
          <input ref="inputEl" 
            class="combo-box--input-field fc-select input-forms__input-field unselectable" 
            :class="inputClasses"
            type="text"
            :value="selectedLabel"
            :placeholder="placeholder"
            :disabled="disabled"
            inputmode="none"
            @click="toggle($event)"
            @focus="onFocus"
            @blur="onBlur"
            @keypress="onKeypress($event);"
            @keydown="onArrowVerticalMove($event);"
            @keydown.enter="onEnter"
            @keydown.space="onSpace"
            @keydown.tab="onTab"
            :aria-label="ariaLabel"
          />
          <div class="combo-box--input-label fc-select input-forms__input-field unselectable" :class="inputClasses">
            <slot v-if="$slots[selectedValue]" :name="selectedValue" />
            <span v-else v-html="sanitize(selectedOption ? selectedLabel : placeholder)" />
          </div>
        </div>
        <div v-if="isShowList" ref="dropListEl" class="combo-box--list__container unselectable" data-dropdown-not-close>
          <ul class="combo-box--list__scroll" @mousemove="onHover" @mouseleave="resetHoverValue">
            <li 
              v-for="(item, index) in allValues" 
              :key="index" 
              :tabindex="index+1" 
              class="combo-box--list-option"
              :class="{
                'hover': item.value === hoverValue,
                'selected': isSelected(item.value),
                'disabled': item.disabled,
                'bold': isBold(item.value),
              }" 
              :data-value="item.value" 
              :style="item.style" 
              @click.prevent="selectAndRefocusOnInput(item);"
            >
              <span class="option-icon"><PhCheck v-if="isSelected(item.value)" :size="16" /></span>
              <slot v-if="$slots[item.value]" :name="item.value" />
              <span v-else class="option-label" v-html="sanitize(item.label ? item.label : item.value)" />
            </li>
          </ul>
        </div>
      </div>

      <template v-if="isValidationConfigured()">
        <div :key="validation.updated" v-if="!suppressValidationMessage && validation" class="input-forms__errors-container">
          <div class="fc-form-danger" 
            v-for="error in validation.getErrors(validationPath, validateUntouched)" 
            :key="error">
              {{ error }}
          </div>
        </div>
      </template>
      <div class="input-forms__hint-content" v-if="hint">{{hint}}</div>
    </div>
  </div>
</template>

<script>
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types'
import { mapGetters } from 'vuex';

import { PhCheck, } from 'phosphor-vue';

import BrowserSupport from '@/browser-support';
import { sanitize } from '@/helpers/string-helper.js';
import validatorComponentUtils from '@/mixins/validator/validator-component-utils'
import { isObject, } from '@/helpers/commonfunctions';

const __PLACEHOLDER_VALUE__ = '__PLACEHOLDER_VALUE__';

const COMBO_BOX_SELECTOR = '.combo-box__container';
const COMBO_BOX_LIST_SELECTOR = '.combo-box--list__container';
const COMBO_BOX_LIST_SCROLL_SELECTOR = '.combo-box--list__scroll';
const COMBO_BOX_LIST_OPTION_SELECTOR = '.combo-box--list-option';

const MIN_DROP_LIST_WIDTH = 240;
const MAX_DROP_LIST_HEIGHT = 400;
const BOX_SHADOW_SIZE = 8;
const HORIZONTAL_PADDING_SIZE = 32;

const WHEEL_EVENT_NAME = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
const WHEEL_EVENT_OPTIONS = BrowserSupport.supportsPassive ? { passive: false, cancelable: false, } : false;
const WHEEL_EVENT_OPTIONS_PASSIVE = BrowserSupport.supportsPassive ? { passive: true, cancelable: false, } : false;

const IGNORE_MODAL_ESC_FOR_CHECKBOX_CLASS = 'ignore-modal-esc-for-combobox';

const ALLE_LABEL = 'Alle';

function getStringForSorting(comboItem) {
  let strValue = ''

  if (!comboItem) {
    return comboItem
  }

  if ('label' in comboItem) {
    strValue = comboItem.label
  } else if ('value' in comboItem) {
    strValue = comboItem.value
  } else {
    strValue = comboItem
  }

  return strValue?.toUpperCase?.()
}

function isAlleOption(item) {
  if (isObject(item) && 'label' in item) {
    return item.label?.toLowerCase?.() === ALLE_LABEL.toLowerCase();
  }
  return item?.toLowerCase?.() === ALLE_LABEL.toLowerCase();
}

function eventCoord(event, coord) {
  let eventCoords = event;

  if(event.targetTouches && event.targetTouches.length) {
    eventCoords = event.targetTouches[0];
  } else if(event.changedTouches && event.changedTouches.length) {
    eventCoords = event.changedTouches[0];
  }

  return eventCoords[coord];
}

export function buildValue(value, label = null) {
  return {
    label: label ?? value,
    value,
  };
}

export default {
  mixins: [validatorComponentUtils],
  props: {
    label: {
    },
    labelClass: {
      type: String,
    },
    placeholder: {
    },
    value: {
    },
    values: {
      type: [Array, String],
      default: () => [],
    },
    firstEmpty: {
      type: Boolean,
      default: false,
    },
    isEmbedded: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    validateUntouched: {
      type: Boolean,
      default: false
    },
    suppressValidationMessage: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false,
    },
    renderDanger: {
      type: Boolean,
      default: false
    },
    hint: {
      type: String,
      default: ''
    },
    isComponentHalfSize: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    boldValues: {
      type: Array,
      default: () => [],
    },
    // When true sort combobox values from A-Z
    // Set it to false to get natural order
    sortComboboxValues: {
      type: Boolean,
      default: true,
    },
    inlineContainer: {
      type: Boolean,
      default: false,
    },
    isEntitySelector: {
      type: Boolean,
      default: false
    },
    ariaLabel: {
      type: String,
      default: "Combobox mit Auswahlmöglichkeiten"
    },
  },
  emits: ['input', 'change', 'focus', 'blur'],
  data() {
    return {
      searchTimeoutRef: null,
      searchExpression: '',
      hoverValue: undefined, // it is to avoid select a value and emit 'change' and 'input' events
      internalValue: null,
      firstEmptyIntern: this.firstEmpty,
      isShowList: false,
      touchLastClientY: null,
    }
  },
  computed: {
    ...mapGetters({
      systemData: CORE_TYPES.GETTERS.SYSTEM_DATA
    }),
    inputContainerClasses() {
      return { 
        'forms__input--small': this.small,
        'forms__input--half-size': this.isComponentHalfSize,
      };
    },
    inputClasses() {
      return {
        'fc-form-danger': this.renderDanger || (this.isValidationConfigured() && this.validation.isInvalid(this.validationPath, this.validateUntouched)),
        'combobox__input--borderless': this.borderless,
        'bold': this.isBold(this.internalValue),
      };
    },
    hasPlaceholder() {
      return !!this.placeholder;
    },
    computedComboboxValues() {
      const values = []
      if (this.firstEmptyIntern) {
        values.push({ label: '', value: ''})
      }
      if (Array.isArray(this.values)) {
        if (this.values.every(option => option?.hasOwnProperty('value'))) {
          values.push(...JSON.parse(JSON.stringify(this.values)))
        } else {
          values.push(...this.values.map(curr => {
            return { label: curr, value: curr };
          }))
        }
      } else if (typeof(this.values) === 'string') {
        if (this.systemData && this.systemData.comboData && this.systemData.comboData[this.values]) {
          values.push(...this.systemData.comboData[this.values])
        }
      }

      // Don't sort if this is a combobox that comes from systemData (this.values is string). 
      // For example 'countries' comes with a preferred sort from the backend. It brings Deutschland at the first place
      if (this.sortComboboxValues && values && typeof(this.values) !== 'string') {
        values.sort((a, b) => {
          const strA = getStringForSorting(a);
          const strB = getStringForSorting(b);

          if (strA < strB) {
            return -1;
          }
          if (strA > strB) {
            return 1;
          }

          return 0;
        })
      }

      // keeps the "Alle" option at the first position
      const index = values.findIndex(v => isAlleOption(v));
      const alleItem = index >= 0 ? values.splice(index, 1) : [];

      return [ ...alleItem, ...values, ];
    },
    allValues() {
      if(this.hasPlaceholder) {
        return [
          { label: this.placeholder, value: __PLACEHOLDER_VALUE__, },
          ...this.computedComboboxValues,
        ];
      } else {
        return [ ...this.computedComboboxValues, ];
      }
    },
    selectedOption() {
      const { internalValue, } = this;
      return [...this.computedComboboxValues || []].find(item => item?.value == internalValue);
    },
    selectedLabel() {
      const option = this.selectedOption;
      return option?.label || option?.value;
    },
    selectedValue() {
      return this.selectedOption?.value;
    },
  },
  watch: {
    value(newValue) {
      this.internalValue = newValue;
      this.$runValidator(this.internalValue)
      this.checkSelectedValueIntegrity()
    },
    internalValue() {
      this.checkSelectedValueIntegrity()
    },
    disabled() {
      if (this.disabled) {
        this.$reset()
      } else { 
        this.$runValidator(this.internalValue)
      }
    },
    computedComboboxValues() {
      this.checkSelectedValueIntegrity()
    },
    firstEmpty: {
      handler() {
        this.$nextTick(() => this.firstEmptyIntern = this.firstEmpty);
      },
    },
    values: {
      handler(newValue) {
        if (newValue && typeof(newValue) === 'string') {
          this.$store.dispatch(CORE_TYPES.ACTIONS.GET_SYSTEM_DATA, { key: newValue});
        }
      },
      immediate: true
    },
    allValues: {
      handler() {
        if (!this.isEntitySelector) {
          this.$nextTick(this.calculateInputSize);
        }
      },
      immediate: true,
    },
  },
  methods: {
    sanitize(htmlString) {
      return sanitize(htmlString);
    },
    disableInputSelection() {
      const { inputEl, } = this.$refs;
      inputEl?.addEventListener('selectstart', (event) => {
        event.preventDefault();
        event.stopPropagation();
      });
    },
    calculateInputSize() {
      const { inputEl, } = this.$refs;
      const { allValues, } = this;
      if(!inputEl || !allValues?.length) return;

      const ghostContainerEl = document.createElement('div');
      ghostContainerEl.style.cssText = `position: absolute; top: -200%; left: -200%;`; // set position out of the screen

      allValues.forEach(value => {
        const ghostItemEl = document.createElement('div');
        ghostItemEl.innerHTML = this.sanitize(value.label);
        ghostContainerEl.appendChild(ghostItemEl); // append item to the container
      });

      document.body.appendChild(ghostContainerEl); // append container to body
      inputEl.style.width = `${ghostContainerEl.offsetWidth + HORIZONTAL_PADDING_SIZE}px`; // set input width
      document.body.removeChild(ghostContainerEl); // remove container from body
    },
    isBold(value) {
      return this.boldValues?.includes(value);
    },
    toggle(event) {
      event.preventDefault();
      event.stopPropagation();

      if(this.isShowList) this.hide();
      else this.show();
    },
    show() {
      if(this.disabled || this.isShowList) return; // ignore if it is disabled or already shown

      this.isShowList = true;
      this.$nextTick(() => {
        const { dropListEl, } = this.$refs;
        if(!dropListEl) return;

        this._moveListToBody();
        this._positionList();
        this.$nextTick(this.scrollToSelectedOption);

        // add global classes
        document.body.classList.add(IGNORE_MODAL_ESC_FOR_CHECKBOX_CLASS);

        // add events
        const dropListScrollEl = dropListEl.querySelector(COMBO_BOX_LIST_SCROLL_SELECTOR);
        dropListScrollEl.addEventListener('DOMMouseScroll', this.keepOnlyDropListScrollable, WHEEL_EVENT_OPTIONS);
        dropListScrollEl.addEventListener(WHEEL_EVENT_NAME, this.keepOnlyDropListScrollable, WHEEL_EVENT_OPTIONS);

        window.addEventListener('scroll', this.onScroll, true);
        window.addEventListener('DOMMouseScroll', this.onScroll);
        window.addEventListener(WHEEL_EVENT_NAME, this.onScroll, WHEEL_EVENT_OPTIONS_PASSIVE);
        window.addEventListener('touchstart', this.setTouchLastClientY, WHEEL_EVENT_OPTIONS_PASSIVE);
        window.addEventListener('touchmove', this.keepOnlyDropListScrollable, WHEEL_EVENT_OPTIONS);
        window.addEventListener('resize', this.onResize);

        document.addEventListener('keydown', this.onEscPress);
        document.addEventListener('click', this.onGlobalClick);
      });
    },
    _moveListToBody() {
      const { dropListEl, } = this.$refs;
      if(dropListEl.parentNode !== document.body) {
        document.body.appendChild(dropListEl);
      }
    },
    _positionList() {
      const { inputEl, dropListEl, } = this.$refs;

      const inputBounding = inputEl.getBoundingClientRect();

      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // set list element size
      const width = inputBounding.width >= MIN_DROP_LIST_WIDTH ? inputBounding.width : MIN_DROP_LIST_WIDTH;
      const maxHeight = (MAX_DROP_LIST_HEIGHT + BOX_SHADOW_SIZE) <= screenHeight ? MAX_DROP_LIST_HEIGHT : screenHeight - BOX_SHADOW_SIZE * 2;
      dropListEl.style.cssText = `
        width: ${width}px;
        max-height: ${maxHeight}px;
        top: -9999px;
        left: -9999px;
      `;

      // set list element x and y position
      const positionX = (() => {
        const width = dropListEl.offsetWidth;
        const remainingSizeOnRight = screenWidth - inputBounding.left;
        if(remainingSizeOnRight >= width) { // is there enough space remaining on left?
          return inputBounding.left;
        } else { // if no, place it on right
          return inputBounding.right - width;
        }
      })();

      const positionY = (() => {
        const height = dropListEl.offsetHeight;
        const remainingSizeOnBottom = screenHeight - inputBounding.bottom - BOX_SHADOW_SIZE;
        const remainingSizeOnTop = inputBounding.top - BOX_SHADOW_SIZE;
        if(remainingSizeOnBottom >= height) { // is there enough remaining space on bottom?
          return inputBounding.bottom;
        } else if(remainingSizeOnTop >= height) { // is there enough remaining space on top?
          return inputBounding.top - height;
        } else { // when there is not enough space (on the top and on the bottom) it puts the list over the input field
          return inputBounding.bottom - height + (screenHeight - inputBounding.bottom - BOX_SHADOW_SIZE);
        }
      })();

      dropListEl.style.cssText = `
        width: ${width}px;
        max-height: ${maxHeight}px;
        top: 0;
        left: 0;
        transform: translate(${positionX}px, ${positionY}px);
      `;
    },
    scrollToSelectedOption() {
      const { dropListEl, } = this.$refs;
      this._scrollToListOption(dropListEl?.querySelector('.selected'));
    },
    hide() {
      if(!this.isShowList) return; // ignore if it is already hidden

      // remove global classes
      document.body.classList.remove(IGNORE_MODAL_ESC_FOR_CHECKBOX_CLASS);

      // remove events
      const { dropListEl, } = this.$refs;
      const dropListScrollEl = dropListEl?.querySelector(COMBO_BOX_LIST_SCROLL_SELECTOR);
      dropListScrollEl?.removeEventListener('DOMMouseScroll', this.keepOnlyDropListScrollable, WHEEL_EVENT_OPTIONS);
      dropListScrollEl?.removeEventListener(WHEEL_EVENT_NAME, this.keepOnlyDropListScrollable, WHEEL_EVENT_OPTIONS);

      window.removeEventListener('scroll', this.onScroll, true);
      window.removeEventListener('DOMMouseScroll', this.onScroll);
      window.removeEventListener(WHEEL_EVENT_NAME, this.onScroll, WHEEL_EVENT_OPTIONS_PASSIVE);
      window.removeEventListener('touchstart', this.setTouchLastClientY, WHEEL_EVENT_OPTIONS_PASSIVE);
      window.removeEventListener('touchmove', this.keepOnlyDropListScrollable, WHEEL_EVENT_OPTIONS);
      window.removeEventListener('resize', this.onResize);

      document.removeEventListener('keydown', this.onEscPress);
      document.removeEventListener('click', this.onGlobalClick);

      // hide list
      this.isShowList = false;
      this.resetHoverValue();
    },
    onTab(event) {
      if(this.isShowList) {
        event.preventDefault();
        event.stopPropagation();
        this.selectHoverValue();
      }
      this.hide();
    },
    onEnter(event) {
      event.preventDefault();
      event.stopPropagation();
      this.selectHoverValue();
      this.hide();
    },
    onSpace(event) {
      event.preventDefault();
      event.stopPropagation();
      this.show();
    },
    onScroll(event) {
      if(!this._isElementOnListContext(event.target)) {
        this.hide();
      }
    },
    setTouchLastClientY(event) {
      this.touchLastClientY = eventCoord(event, 'clientY');
    },
    keepOnlyDropListScrollable(event) {
      if(this._isElementOnListContext(event.target)) {
        event.preventDefault();
        const { dropListEl, } = this.$refs;
        const dropListScrollEl = dropListEl?.querySelector(COMBO_BOX_LIST_SCROLL_SELECTOR);

        let scrollTop = null;
        if(event.type === 'touchmove') {
          const currentClientY = eventCoord(event, 'clientY');
          scrollTop = this.touchLastClientY - currentClientY;
          this.touchLastClientY = currentClientY;
        } else {
          scrollTop = event.wheelDeltaY * -1;
        }

        dropListScrollEl.scrollTop = dropListScrollEl.scrollTop + scrollTop;
      } else {
        this.hide();
      }
    },
    onResize() {
      this.hide();
    },
    onEscPress(event) {
      const key = event.keyCode || event.which;
      if(key === 27) {
        this.hide();
      }
    },
    onGlobalClick(event) {
      const { inputEl, } = this.$refs;
      const target = event?.target;
      if(inputEl && target !== inputEl) {
        this.hide();
      }
    },
    onKeypress(event) {
      event.preventDefault();
      event.stopPropagation();

      clearTimeout(this.searchTimeoutRef)
      this.searchForItem(event)
      this.searchTimeoutRef = setTimeout(() => this.searchExpression = '', 1000)
    },
    searchForItem(event) {
      if (event.which !== 0) {
        this.searchExpression += String.fromCharCode(event.which)?.toLowerCase()

        let listFound = this.computedComboboxValues?.filter(item => item?.label?.toLowerCase().startsWith(this.searchExpression))

        if (!listFound.length) {
          listFound = this.computedComboboxValues?.filter(item => item?.label?.toLowerCase().includes(this.searchExpression))
        }

        if (listFound.length) { // if there is at least 1 result, select the first
          this.setHoverValueAndScroll(listFound[0])
        }
      }
    },
    onArrowVerticalMove(event) {
      const selectDirection = (value, up) => {
        event.preventDefault();
        event.stopPropagation();

        const { allValues, } = this;
        if (!allValues?.length) return;

        const selectNextFn = currentIndex => (currentIndex === allValues.length - 1 ? -1 : currentIndex) + 1;
        const selectPrevFn = currentIndex => (currentIndex <= 0 ? allValues.length : currentIndex) - 1;

        const memorizeIndex = []; // avoid infinite loop

        let currentIndex = allValues.findIndex(item => item?.value == value);
        let currentValue = null;
        do { // find a next/prev not disabled item
          memorizeIndex.push(currentIndex);
          const index = up ? selectPrevFn(currentIndex) : selectNextFn(currentIndex);
          currentValue = allValues[index];
          currentIndex = index;
        } while(currentValue.disabled && memorizeIndex.indexOf(currentIndex) < 0);

        return currentValue;
      };

      const selectInner = (up) => {
        if(this.isShowList) { // when list is visible only make a hover without selection, it avoids emit 'change', 'input' events
          const value = this.hoverValue !== undefined ? this.hoverValue : this.internalValue;
          this.setHoverValueAndScroll(selectDirection(value, up), up);
        } else {
          const value = this.hasPlaceholder && this.internalValue === undefined ? __PLACEHOLDER_VALUE__ : this.internalValue;
          this.select(selectDirection(value, up));
        }
      };

      const keyPressed = event.key || event.keyCode;
      switch(keyPressed) {
        case 'ArrowUp':
        case 38:
          selectInner(true);
          break;
        case 'ArrowDown':
        case 40:
          selectInner(false);
          break;
      }
    },
    onHover(event) {
      const clientX = eventCoord(event, 'clientX');
      const clientY = eventCoord(event, 'clientY');
      const currentHoverEl = document.elementFromPoint(clientX, clientY);
      if(!this._isElementOnListContext(currentHoverEl)) return;

      const currentOptionEl = currentHoverEl.closest(COMBO_BOX_LIST_OPTION_SELECTOR) || currentHoverEl;
      if(!currentOptionEl) return;

      const currentOptionIndex = [ ...currentOptionEl.parentElement.children, ].indexOf(currentOptionEl);
      if(currentOptionIndex >= 0) {
        const option = this.allValues[currentOptionIndex];
        this.hoverValue = option?.value;
      }
    },
    setHoverValueAndScroll(item, up) {
      this.hoverValue = item.value;
      this.$nextTick(() => this.scrollToHoveredOption(up));
    },
    scrollToHoveredOption(up) {
      const { dropListEl, } = this.$refs;
      this._scrollToListOption(dropListEl?.querySelector('.hover'), up);
    },
    resetHoverValue() {
      this.hoverValue = undefined;
    },
    selectHoverValue() {
      const { allValues, } = this;
      if (!allValues?.length || !this.hoverValue) return;

      const option = allValues.find(item => item?.value === this.hoverValue);
      this.select(option);
    },
    selectAndRefocusOnInput(item) {
      this.$refs.inputEl?.focus(); // avoids to emit a blur event when selecting an item
      this.select(item);
    },
    isSelected(value) {
      return this.internalValue == value;
    },
    select(item) {
      if(!item || item.disabled) return;

      const value = item.value === __PLACEHOLDER_VALUE__ ? undefined : item.value;
      if(value !== this.internalValue) {
        this.$emit('input', value);
        this.$emit('change', value);
        this.$runValidator(value);
      }
    },
    checkSelectedValueIntegrity() {
      const found = this.computedComboboxValues?.find?.(item => item.value == this.internalValue)
      if (!found && this.internalValue && this.computedComboboxValues?.[0]?.value) {
        this.$store.dispatch(LOG_TYPES.ACTIONS.WARN, {message: `The combobox's current value is not present in the available combobox values: ${this.internValue}`});
      }
    },
    onBlur(event) {
      if(this.disabled) return;

      if(!this._isElementOnComponentContext(event.relatedTarget)) { // only emits the blur event if the source blur event is not from a related component element
        this.hide();

        this.$setTouched();
        this.$emit('blur', event);
      }
    },
    onFocus(event) {
      if(this.disabled) return;

      if(!this._isElementOnComponentContext(event.relatedTarget)) { // only emits the focus event if the source blur event is not from a related component element
        this.$emit('focus', event);
      }
    },
    _scrollToListOption(element, up) {
      const { dropListEl, } = this.$refs;
      const dropListScrollEl = dropListEl?.querySelector(COMBO_BOX_LIST_SCROLL_SELECTOR);
      if(!dropListScrollEl || !element) return;

      if(element.offsetTop <= dropListScrollEl.scrollTop 
        || (element.offsetTop + element.offsetHeight) >= (dropListScrollEl.scrollTop + dropListScrollEl.offsetHeight)) {
        if(up) { // scroll to the selected item keeping it at the top
          dropListScrollEl.scrollTop = element.offsetTop;
        } else { // scroll to the selected item keeping it at the bottom
          dropListScrollEl.scrollTop = (element.offsetTop - dropListScrollEl.offsetHeight) + element.offsetHeight;
        }
      }
    },
    _isElementOnListContext(element) {
      if(!element) return false;

      const { dropListEl, } = this.$refs;
      return element === dropListEl || element.closest?.(COMBO_BOX_LIST_SELECTOR) === dropListEl
    },
    _isElementOnComponentContext(element) {
      if(!element) return false;

      const rootEl = this.$el;
      return element === rootEl || element.closest?.(COMBO_BOX_SELECTOR) === rootEl || this._isElementOnListContext(element);
    },
  },
  mounted() {
    this.disableInputSelection();

    if (this.value !== null && this.value !== undefined) {
      this.internalValue = this.value
      this.$runValidator(this.internalValue, true)
      this.checkSelectedValueIntegrity()
    } else { // wait 1 second for watch
      this.internalValue = undefined;
      setTimeout(() => {
        if (this.internalValue === undefined) {
          this.$runValidator(this.internalValue, true)
          this.checkSelectedValueIntegrity()
        }
      }, 1000);
    }
  },
  beforeDestroy() {
    this.hide();
    if(this.$refs.dropListEl) {
      document.body.removeChild(this.$refs.dropListEl);
    }
  },
  components: {
    PhCheck,
  },
}
</script>

<style scoped lang="scss">
* {
  box-sizing: border-box;
}

.combo-box__container {
  max-width: 100%;
}

.combobox__input--borderless {
  border: none!important;
  background: transparent;
  padding-left: 0;
}

.combo-box--input-container {
  position: relative;

  .combo-box--input-field {
    caret-color: transparent;
    color: transparent;
    cursor: default;
    opacity: 0;
    min-width: 100%;
    max-width: 100%;
    text-shadow: 0 0 0 var(--color-text);
  }

  .combo-box--input-label {
    background: var(--color-box);
    line-height: 30px;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;

    &.bold {
      font-weight: bold;
    }

    &.fc-form-danger {
      color: var(--color-danger) !important;
      border-color: var(--color-danger) !important;
    }

    span {
      display: block;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  &.has-no-option-selected {
    .combo-box--input-label {
      color: #757575;
    }
  }

  .combo-box--input-field:focus + .combo-box--input-label {
    border-color: var(--color-primary);
    outline: none;
  }

  .combo-box--input-field:disabled + .combo-box--input-label {
    background-color: var(--color-box);
    color: var(--color-text);
    border-color:  var(--color-text);
    cursor: not-allowed;
    opacity: 0.4;

    span {
      opacity: 0.7;
    }
  }
}

.forms__input--small {
  * {
    max-height: 25px;
  }

  .combo-box--input-container {
    .combo-box--input-label {
      line-height: 23px;
    }
  }
}

.combo-box--list__container {
  background-color: var(--color-box);
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0,0,0,.5);
  overflow: hidden;
  position: fixed;
  top: -9999px;
  left: -9999px;
  z-index: 99999;

  ul {
    list-style: none;
    overflow: auto;
    margin: 0;
    padding: 0;
    min-height: 16px;
    max-height: inherit;

    li {
      color: var(--color-text);
      cursor: default;
      font-size: 14px;
      display: flex;
      align-items: center;
      line-height: 1.2em;
      min-height: 30px;
      padding: 6px 12px 6px 6px;
      word-break: break-word;

      &.selected {
        background: var(--color-active);
        color: var(--color-primary-text);
      }

      &.hover {
        background: var(--color-box-neutral);
        color: var(--color-text);
        transition: background 0.3s ease-in-out;
      }

      &.disabled {
        background: none;
        color: #515a6e;
        cursor: not-allowed;
        opacity: 0.7;

        &.selected {
          background: var(--color-active);
          color: var(--color-primary-text);
          cursor: default;
        }
      }

      &.bold {
        font-weight: bold;
      }

      .option-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 0 0 auto;
        margin: 0 6px 0 0;
        width: 16px;
        height: 16px;
      }
    }
  }
}
</style>
