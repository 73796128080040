<template>
  <TeleportToBody>
    <div v-if="isOpen" 
      ref="modalEl"
      class="base-modal__container is-modal-component " 
      :class="{ 
        [`modal-${size}`]: !!size,
        'is-open': isOpen,
        'is-modal-as-full-page': isModalAsFullPageIntern,
        'always-scrollable': alwaysScrollable,
      }">
      <div class="base-modal__backdrop" @click="handleCloseBackdrop($event)">
        <BaseTopProgressBar v-if="showItemsAsFullPage"/>
        <div class="base-modal__content">
          <div v-if="hasHeader" class="base-modal__header">
            <div class="base-modal__header--top">
              <div class="base-modal__header--close-large " v-if="showItemsAsModal">
                <button v-if="showCloseButton" type="button" class="base-modal__header--close clickable" @click="handleCloseButton()">
                  <PhXCircle size="20" alt="Icon zum Schließen des Fensters"/>
                </button>
              </div>
              <div class="base-modal__header--close-small" v-if="showItemsAsFullPage">
                <template v-if="replacementActionForCloseButtonSmallScreen">
                  <component
                    :key="replacementActionForCloseButtonSmallScreen.key"
                    v-bind="replacementActionForCloseButtonSmallScreen"
                    :is="replacementActionForCloseButtonSmallScreen.component"
                    :tid="_generateTidFromString(id + '-' + replacementActionForCloseButtonSmallScreen.key)"
                    @action="handlePageHeaderAction">
                    <template :slot="`action-${replacementActionForCloseButtonSmallScreen.actionKey}`">
                      <slot :name="`action-${replacementActionForCloseButtonSmallScreen.actionKey}`" />
                    </template>
                  </component>
                </template>
                <BaseButton v-else isLink @click="handleCloseButton()" class="m-0 p-0">
                  <PhCaretLeft size="22"/>
                </BaseButton>
              </div>

              <div class="base-modal__header--title" :class="{'ellipsis-all': $isSmallScreen}">
                <slot name="modalTitle"><span v-html="sanitize(titleSetFromChild || modalTitle)"></span></slot>
              </div>

              <div 
                v-if="showItemsAsFullPage"
                class="base-modal__header--actions" 
                :class="{
                  'has-remain-actions': hasRemainActions,
                }"
              >
                <slot name="modalActions">
                  <div v-if="primaryAction" class="base-modal__header--first-action">
                    <component
                      v-if="primaryAction.component"
                      isMainAction
                      v-bind="primaryAction"
                      :is="primaryAction.component"
                      :tid="_generateTidFromString(id + '-' + primaryAction.key)"
                      class="ellipsis"
                      @action="handlePageHeaderAction">
                    </component>
                  </div>
                  
                  <BaseContextMenu v-if="remainActions" :key="actionsKey" class="base-modal__header--multiple-action">
                    <ContextMenuItem v-for="action of remainActions" :key="action.key">
                      <component
                        v-if="action.component"
                        :showAsLink="isModalAsFullPage"
                        v-bind="action"
                        :is="action.component"
                        :tid="_generateTidFromString(id + '-' + action.key)"
                        @action="handlePageHeaderAction">
                        <template :slot="`action-${action.actionKey}`"><slot :name="`action-${action.actionKey}`" /></template>
                      </component>
                    </ContextMenuItem>
                  </BaseContextMenu>
                </slot>
              </div>
            </div>
            <div v-if="$slots.modalHeaderBottom" class="base-modal__header--bottom">
              <slot name="modalHeaderBottom"></slot>
            </div>
          </div>

          <div class="base-modal__body" data-scroll-container>
            <div class="base-modal__body--wrap">
              <slot></slot>
            </div>
          </div>

          <template v-if="showItemsAsModal">
            <div v-if="hasFooter" class="base-modal__footer">
              <div class="layout__negative-margin--8">
                <template v-if="hasActions">
                  <template v-for="action of visibleActions" >
                    <component
                      v-if="action.component"
                      :key="action.key"
                      v-bind="action"
                      :showAsLink="isModalAsFullPage"
                      :is="action.component"
                      :tid="_generateTidFromString(id + '-' + action.key)"
                      @action="handlePageHeaderAction">
                      <template :slot="`action-${action.actionKey}`"><slot :name="`action-${action.actionKey}`" /></template>
                    </component>
                  </template>
                </template>
                <slot name="footer"></slot>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </TeleportToBody>
</template>
<script>
import BaseButton from '@/components/core/BaseButton.vue';
import AnimatedSpinner from '@/components/core/AnimatedSpinner.vue';
import { PhXCircle, PhCaretLeft, } from 'phosphor-vue'
import { SMALL_SCREEN_WIDTH } from '@/helpers/responsive-helper';
import BaseContextMenu from '@/components/core/BaseContextMenu.vue';
import ContextMenuItem from '@/components/core/base-context-menu/ContextMenuItem.vue';
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import { mapGetters } from 'vuex';
import CORE_TYPES from '@/store/core/types';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';
import TeleportToBody from '@/components/core/teleport/TeleportToBody.vue';
import BaseTopProgressBar from "@/components/core/BaseTopProgressBar.vue";
import { sanitize } from '@/helpers/string-helper';

const PROP_SIZE_VALIDS = ['sm', 'md', 'lg', 'xl', 'full'];
const BACKDROP_CSS_CLASS = 'base-modal__backdrop';
const MODAL_OPEN_CLASS = 'modal-open';
const MODAL_OPEN_PAGE_HAS_SCROLL_CLASS = 'modal-open-page-has-scroll';
const MODAL_MIN_BODY_MAX_HEIGHT = 100;

const MODAL_Z_INDEX_INITIAL = 1010;

const AUTO_CONFIRM_BUTTON_KEY = 'AUTO_CONFIRM_BUTTON';
const AUTO_CANCEL_BUTTON_KEY = 'AUTO_CANCEL_BUTTON';

const BASE_MODAL_IS_OPEN_SELECTOR = '.base-modal__container.is-open:not(.is-closing)';
const IS_MODAL_COMPONENT_OPEN_SELECTOR = '.is-modal-component.is-open';
const MODAL_CONTENT_SELECTOR = '.base-modal__content';
const TABINDEX_ATTR = 'tabindex';
const TABINDEX_ELEMENTS_SELECTOR = [
  'fieldset'
  ,'input'
  ,'textarea'
  ,'select'
  ,'button'
  ,'a'
  ,'[tabindex]'
].join(',');
const MODAL_ADDED_TABINDEX_SELECTOR = '[data-modal-added-tabindex]';
const IGNORE_MODAL_ESC_FOR_CLASS = 'ignore-modal-esc-for-';

const NORMAL_MODAL_IN_ANIMATION_CLASSES = ['fade-in', 'is-opening', 'slide-in-top'];
const NORMAL_MODAL_OUT_ANIMATION_CLASSES = ['fade-out', 'is-closing', 'slide-out-top'];

const FULLSCREEN_MODAL_IN_ANIMATION_CLASSES = ['fade-in', 'is-opening', 'slide-in-right'];
const FULLSCREEN_MODAL_OUT_ANIMATION_CLASSES = ['fade-out', 'is-closing', 'slide-out-right'];

function animationInClass(isModalAsFullPage) {
  return isModalAsFullPage 
    ? FULLSCREEN_MODAL_IN_ANIMATION_CLASSES
    : NORMAL_MODAL_IN_ANIMATION_CLASSES;
}

function animationOutClass(isModalAsFullPage) {
  return isModalAsFullPage 
    ? FULLSCREEN_MODAL_OUT_ANIMATION_CLASSES
    : NORMAL_MODAL_OUT_ANIMATION_CLASSES;
}

const verticalMargin = (el) => {
  const style = window.getComputedStyle(el);
  const marginTop = parseFloat(style?.['marginTop'] || 0);
  const marginBottom = parseFloat(style?.['marginBottom'] || 0);
  return marginTop + marginBottom;
};

const verticalPadding = (el) => {
  const style = window.getComputedStyle(el);
  const paddingTop = parseFloat(style?.['paddingTop'] || 0);
  const paddingBottom = parseFloat(style?.['paddingBottom'] || 0);
  return paddingTop + paddingBottom;
};

const findActiveModalEl = () => {
  const modalIndexes = [ ...document.querySelectorAll(BASE_MODAL_IS_OPEN_SELECTOR) ].map(el => parseInt(el.dataset.index));
  const activeModalIndex = Math.max(...modalIndexes);
  if (activeModalIndex < 0) {
    return null;
  }

  return document.querySelector(`${BASE_MODAL_IS_OPEN_SELECTOR}[data-index="${activeModalIndex}"]`);
};

/**
 * Default modal component. See props comments to more detail about how to use it
 */
export default {
  mixins: [InteractiveHelpCommonsMixin],
  props: {
    id: {
      type: String,
      default: '',
    },
    // Change the modal title
    modalTitle: {
      default: 'Modal Title'
    },
    // Change the default cancel label button
    labelButtonCancel: {
      default: 'Abbrechen'
    },
    closeButtonLabel: {
      default: 'Schließen'
    },
    // Change the default confirm label button
    labelButtonConfirm: {
      default: 'Speichern'
    },
    // Show/hide the default buttons. Can be used to provide personalized list of button using slots
    showDefaultButtons: {
      type: Boolean,
      default: true
    },
    // Show/hide cancel default button
    showCancelButton: {
      type: Boolean,
      default: true
    },
    // Show/hide cancel default button on small screens
    showCancelButtonSmallScreen: {
      type: Boolean,
      default: true
    },
    // Show/hide confirm default button
    showConfirmButton: {
      type: Boolean,
      default: true
    },
    // Show/hide confirm default button  on small screens
    showConfirmButtonSmallScreen: {
      type: Boolean,
      default: true
    },
    // Show/hide close button on the top
    showCloseButton: {
      type: Boolean,
      default: true
    },
    confirmDisabled: {
      type: Boolean,
      default: false
    },
    confirmLoading: {
      type: Boolean,
      default: false
    },
    backdrop: {
      type: String,
      default: 'static'
    },
    esc: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      validator: (value) => {
        return PROP_SIZE_VALIDS.indexOf(value) >= 0;
      },
      default: 'md',
    },
    autoClose : {
      type: Boolean,
      default: true
    },
    actions: {
      type: Array,
      default: () => [],
    },
    noPrimaryAction: {
      type: Boolean,
      default: false,
    },
    isModalAsFullPage: { // on small screens.
      type: Boolean,
      default: true,
    },
    autoCloseOnRouteNavigation: {
      type: Boolean,
      default: true
    },
    alwaysScrollable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
      isClosing: false,
      deferredOpen: {
        promise: null,
        resolve: null,
        reject: null,
      },
      titleSetFromChild: '',
      scheduleOpenId: null,
      isCompletelyMounted: false,
    };
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN
    }),
    isModalAsFullPageIntern() {
      return this.$isSmallScreen && this.isModalAsFullPage; // only actives on small screen even if the 'isModalAsFullPage' is passed as true
    },
    showItemsAsModal() {
      return !this.$isSmallScreen || !this.isModalAsFullPageIntern;
    },
    showItemsAsFullPage() {
      return !this.showItemsAsModal
    },
    hasHeader() {
      return this.titleSetFromChild || this.modalTitle || this.$slots.modalTitle || this.showCloseButton || this.$slots.modalHeaderBottom;
    },
    hasFooter() {
      return this.showDefaultButtons || this.$slots.footer || this.hasActions;
    },
    visibleActions() {
      const actions = []

      if (this.showCancelButton && this.showDefaultButtons 
        && (!this.isSmallScreen || (this.isSmallScreen && this.showCancelButtonSmallScreen))) {
        actions.push(BaseModalSimpleAction(AUTO_CANCEL_BUTTON_KEY, this.labelButtonCancel)
          .withPrimary(() => false));
      }

      if (this.showConfirmButton && this.showDefaultButtons
        && (!this.isSmallScreen || (this.isSmallScreen && this.showConfirmButtonSmallScreen))) {
        actions.push(BaseModalSimpleAction(AUTO_CONFIRM_BUTTON_KEY, this.labelButtonConfirm)
          .withDisabled(() => this.confirmDisabled || this.confirmLoading)
          .withLoading(() => this.confirmLoading)
          .withPrimary(() => true));
      }

      const filterVisibility = (act) => {
        if (!act.visible || act.visible?.()) {
          if (this.isSmallScreen && (!act.visibleOnSmallScreen || act.visibleOnSmallScreen?.())) {
            return true
          } else if (!this.isSmallScreen && (!act.visibleOnLargeScreen || act.visibleOnLargeScreen?.())) {
            return true;
          }
          return false;
        }
      }

      const visibleDeclaredActions = this.actions?.filter(filterVisibility);
      return actions.concat(visibleDeclaredActions);
    },
    hasActions() {
      return !!this.visibleActions?.length;
    },
    primaryAction() {
      if(this.noPrimaryAction || !this.visibleActions || this.visibleActions.length === 0) return undefined;

      const primaryAction = this.visibleActions.find(action => action.primary?.());

      if (primaryAction) return primaryAction;

      const [firstElem] = this.visibleActions || [];
      return firstElem
    },
    replacementActionForCloseButtonSmallScreen() {
      if(this.noPrimaryAction || !this.visibleActions || this.visibleActions.length === 0) return undefined;
      
      const replacementActionForCloseOnSmallScreen = this.visibleActions.find(action => action.replacementActionForCloseOnSmallScreen?.());
      if (!replacementActionForCloseOnSmallScreen) return undefined;

      const actionCopy = Object.assign({}, replacementActionForCloseOnSmallScreen)
        .withLabel('') // overrides label
        .withIcon('PhCaretLeft', 22); // overrides icon

      return actionCopy;
    },
    remainActions() {
      if (!this.visibleActions || this.visibleActions.length <= 1) return undefined;

      if (this.noPrimaryAction || !this.primaryAction) return this.visibleActions;

      return this.visibleActions.filter(action => action !== this.primaryAction);
    },
    hasRemainActions() {
      return !!this.remainActions?.length;
    },
    actionsKey() {
      return this.actions
        .map(act => [!act.visible || act.visible?.(), !act.disabled || act.disabled?.()].join('-'))
        .join(':');
    },
  },
  watch: {
    '$route'() {
      if(this.isOpen && this.autoCloseOnRouteNavigation) {
        this.close();
      }
    },
    showItemsAsModal() {
      requestAnimationFrame(this.prepareContent);
    },
    isOpen() {
      requestAnimationFrame(() => this.checkRootCssClasses());
    },
  },
  methods: {
    open() {
      clearTimeout(this.scheduleOpenId);

      if(this.isOpen) return Promise.resolve(); // ignore when already opened

      this.deferOpen();

      if (!this.isCompletelyMounted) {
        this.scheduleOpenId = setTimeout(this.open, 100);
        return this.deferredOpen.promise;
      }

      this.$set(this, 'isOpen', true);
      this.finishDeferredOpen(true);

      requestAnimationFrame(this.bringToFront);
      this.preventBehindTabNavigation();

      document.addEventListener('keydown', this.handleEscPress);
      window.addEventListener('resize', this.handleResize);
      this.handleResize();

      requestAnimationFrame(() => {
        const { modalEl } = this.$refs;
        modalEl?.removeEventListener('animationend', this.onOpenAnimationEnd);
        modalEl?.addEventListener('animationend', this.onOpenAnimationEnd);
        modalEl?.classList?.add(...animationInClass(this.isModalAsFullPageIntern));
      });

      return this.deferredOpen.promise;
    },
    onOpenAnimationEnd(event) {
      const { modalEl } = this.$refs;
      if (event.target !== modalEl) return;

      modalEl?.removeEventListener('animationend', this.onOpenAnimationEnd);
      modalEl?.classList?.remove(...animationInClass(this.isModalAsFullPageIntern));

      this.handleOpened();
    },
    handleOpened() {
      this.$emit('opened');
    },
    close() {
      clearTimeout(this.scheduleOpenId);
      this.finishDeferredOpen(false);
      if (this.isOpen && !this.isClosing) {
        this.$set(this, 'isClosing', true); // prevents to call twice when the animation is executing

        const { modalEl } = this.$refs;
        modalEl?.removeEventListener('animationend', this.onCloseAnimationEnd);
        modalEl?.addEventListener('animationend', this.onCloseAnimationEnd);
        modalEl?.classList?.add(...animationOutClass(this.isModalAsFullPageIntern));

        this.activeBehindTabNavigation();

        document.removeEventListener('keydown', this.handleEscPress);
        window.removeEventListener('resize', this.handleResize);

        this.$emit('close');
      }
    },
    onCloseAnimationEnd(event) {
      const { modalEl } = this.$refs;
      if (event.target !== modalEl) return;

      modalEl?.removeEventListener('animationend', this.onCloseAnimationEnd);
      modalEl?.classList?.remove(...animationOutClass(this.isModalAsFullPageIntern));

      this.$set(this, 'isOpen', false);
      this.$set(this, 'isClosing', false);
      this.checkRootCssClasses();
      this.handleClosed();
    },
    deferOpen() {
      if (!this.deferredOpen.promise) {
        this.deferredOpen.promise = new Promise((resolve, reject) => {
          this.deferredOpen.resolve = resolve;
          this.deferredOpen.reject = reject;
        });
      }
    },
    finishDeferredOpen(resolve) {
      if (resolve) {
        this.deferredOpen.resolve?.();
      } else {
        this.deferredOpen.reject?.();
      }

      this.$set(this.deferredOpen, 'promise', null);
      this.$set(this.deferredOpen, 'resolve', null);
      this.$set(this.deferredOpen, 'reject', null);
    },
    checkRootCssClasses() {
      const baseModalOpened = document.querySelectorAll(BASE_MODAL_IS_OPEN_SELECTOR);
      const hasRemainingModalOpen = baseModalOpened.length > 0;

      document.querySelector('html').classList.toggle(MODAL_OPEN_CLASS, hasRemainingModalOpen);
      document.querySelector('html').classList.toggle(MODAL_OPEN_PAGE_HAS_SCROLL_CLASS, hasRemainingModalOpen);
    },
    handleClosed() {
      this.$emit('closed');
    },
    preventBehindTabNavigation() {
      const tabindexEls = document.body.querySelectorAll(TABINDEX_ELEMENTS_SELECTOR) || [];
      tabindexEls.forEach(el => {
        const tabindex = el.getAttribute(TABINDEX_ATTR);
        if(!tabindex || tabindex >= 0) {
          el.setAttribute(TABINDEX_ATTR, -1);
          el.dataset.modalAddedTabindex = true;
          el.dataset.modalOriginalTabindex = tabindex;
        }
      });

      this.focusOnModalContent();
    },
    bringToFront() {
      const { modalEl } = this.$refs;
      if (!modalEl) return;

      const modalOpenedEls = modalEl.parentNode.querySelectorAll(IS_MODAL_COMPONENT_OPEN_SELECTOR);
      const zIndexes = [ ...modalOpenedEls, ]?.map(el => el.style.zIndex || MODAL_Z_INDEX_INITIAL);
      const maxZIndex = Math.max(...zIndexes);
      const zIndex = maxZIndex + 1;
      modalEl.style.zIndex = zIndex;
      modalEl.dataset.index = zIndex; // helps to find which modal is active
    },
    activeBehindTabNavigation() {
      document.querySelectorAll(MODAL_ADDED_TABINDEX_SELECTOR).forEach(el => {
        const tabindex = el.dataset.modalOriginalTabindex;
        if(tabindex >= 0) {
          el.setAttribute(TABINDEX_ATTR, tabindex);
        } else {
          el.removeAttribute(TABINDEX_ATTR);
        }
        delete el.dataset.modalAddedTabindex;
        delete el.dataset.modalOriginalTabindex;
      });
    },
    focusOnModalContent() {
      requestAnimationFrame(() => {
        const { modalEl } = this?.$refs || {};
        if (!modalEl) return;

        const modalContent = modalEl.querySelector(MODAL_CONTENT_SELECTOR);
        modalContent?.setAttribute(TABINDEX_ATTR, 0);
        modalContent?.focus();
        modalContent?.removeAttribute(TABINDEX_ATTR);
      });
    },
    handleResize() {
      this.$nextTick(() => requestAnimationFrame(this.prepareContent));
    },
    prepareContent() {
      const { modalEl } = this.$refs;
      if(!modalEl || !modalEl.querySelector) {
        return ;
      }

      const { innerHeight } = window;

      const backdropEl = modalEl.querySelector('.base-modal__backdrop');
      const contentEl = modalEl.querySelector('.base-modal__content');
      const headerEl = modalEl.querySelector('.base-modal__header');
      const footerEl = modalEl.querySelector('.base-modal__footer');

      const headerTotalHeight = this.hasHeader && headerEl ? headerEl.getBoundingClientRect().height + verticalMargin(headerEl) : 0;
      const footerTotalHeight = this.hasFooter && footerEl ? footerEl.getBoundingClientRect().height + verticalMargin(footerEl) : 0;

      const maxBodyHeight = innerHeight - verticalPadding(backdropEl) - verticalPadding(contentEl) - verticalMargin(contentEl) - headerTotalHeight - footerTotalHeight;

      const maxModalBodyHeight = maxBodyHeight > MODAL_MIN_BODY_MAX_HEIGHT ? maxBodyHeight : MODAL_MIN_BODY_MAX_HEIGHT;
      modalEl.style.setProperty('--maxModalBodyHeight', `${maxModalBodyHeight}px`);
    },
    handleEscPress(event) {
      // esc action is ignored whether there is a class starting with 'IGNORE_MODAL_ESC_FOR_CLASS' at the body element
      const hasIgnoreModalEscClass = [ ...document.body?.classList || [], ].some(c => c.startsWith(IGNORE_MODAL_ESC_FOR_CLASS));
      if(!this.esc || hasIgnoreModalEscClass) return ;

      const { modalEl } = this.$refs;
      const activeModalEl = findActiveModalEl();
      if (modalEl !== activeModalEl) {
        return;
      }

      const key = event.keyCode || event.which;
      if(key === 27) {
        this.$emit('onEscButton');
        this.close();
      }
    },
    handleCloseBackdrop(event) {
      if(!event || !event.target.classList.contains(BACKDROP_CSS_CLASS)) return;

      const isBackdropStatic = this.backdrop === 'static';
      const forceBackdropClose = this.showCloseButton && (this.$isMobileNativeContext || window.innerWidth <= SMALL_SCREEN_WIDTH); // is on mobile native context or a small screen width?
      if(isBackdropStatic && !forceBackdropClose) return;

      this.close();
    },
    handleCloseButton() {
      this.$emit('onCloseButton');
      this.close();
    },
    handleCancel() {
      this.$emit('onCancelButton');
      this.close();
    },
    handleConfirm() {
      this.$emit('onConfirmButton');
      if(this.autoClose) {
        this.close();
      }
    },
    handlePageHeaderAction({ key, value, }) {
      this.$emit('action', { key, value, });
      this.$emit(`action-${key}`, value);

      if (AUTO_CONFIRM_BUTTON_KEY === key) {
        this.handleConfirm();
      }

      if (AUTO_CANCEL_BUTTON_KEY === key) {
        this.handleCancel();
      }
    },
    sanitize(html) {
      return sanitize(html);
    },
  },
  mounted() {
    requestAnimationFrame(() => this.isCompletelyMounted = true);
  },
  beforeDestroy() {
    this.close();
  },
  components: {
    BaseButton,
    AnimatedSpinner,
    PhXCircle,
    PhCaretLeft,
    BaseContextMenu,
    ContextMenuItem,
    TeleportToBody,
    BaseTopProgressBar,
  },
  destroyed() {
    this.checkRootCssClasses();
  }
}
</script>

<style lang="scss" scoped>
* {
  box-sizing: border-box;
}

.base-modal__header--multiple-action {
  align-self: center;
  height: unset;
  margin-left: 10px;
}

.base-modal__container {
  --modalAnimationSize: 1000px;
  --modalAnimationDuration: .5s;

  color: var(--color-text);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1010;
  padding-bottom: 8px;
}
.base-modal__backdrop {
  background-color: rgba(0,0,0,0.5);
  box-sizing: border-box;
  overflow: auto;
  padding: 8px 15px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
@media (min-width: 640px) {
  .base-modal__backdrop {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (min-width: 960px) {
  .base-modal__backdrop {
    padding-left: 40px;
    padding-right: 40px;
  }
}
.base-modal__content {
  background-color: var(--color-box);
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 40, 87, 0.1);
  margin: 0 auto;
  padding: 15px;
  outline: none !important;
}
.modal-sm .base-modal__content {
  max-width: 352px;
}
.modal-md .base-modal__content {
  max-width: 552px;
}
.modal-lg .base-modal__content {
  max-width: 852px;
}
.modal-xl .base-modal__content {
  max-width: 1052px;
}
.modal-full .base-modal__content {
  max-width: 100%;
}
.base-modal__header {
  flex: 0 0 auto;
  margin: 0 0 12px;
  min-height: 16px;
}
.base-modal__header--top {
  display: flex;
  flex-direction: row-reverse;
}
.base-modal__header--close {
  background: none;
  border: none;
  display: flex;
  flex: 0 0 auto;
  margin: 0 0 0 6px;
  padding: 0;
  width: 20px;
  height: 20px;
}
.base-modal__header--title {
  flex: 1 1 auto;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}
.base-modal__header--top + .base-modal__header--bottom {
  margin: 6px 0 0;
}
.base-modal__header--bottom {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4em;
}
.base-modal__body {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px 15px;
  margin: -8px -15px;
  max-height: calc(var(--maxModalBodyHeight) + 16px);
}
.always-scrollable .base-modal__body {
  overflow-y: scroll;
}
.base-modal__footer {
  display: flex;
  margin: 16px 0 0;
}
.base-modal__footer--button {
  margin: 0 16px 0 0;
}
.base-modal__footer--button:last-child {
  margin-right: 0;
}

.base-modal__header--first-action {
  text-align: right;
  max-width: 100%;

  > button {
    line-height: 1.2em;
    max-width: 100%;
  }
}

.has-remain-actions .base-modal__header--first-action {
  max-width: calc(100% - 34px);
}

.base-modal__header--close-small {
  display: flex;
  flex: 0 0 auto;
  text-align: left;
  margin-right: 10px;
  min-width: fit-content;
}

.base-modal__header--actions {
  flex: 0 0 auto;
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 10px;
  max-width: calc(100% - 42px);
}

@media screen and (max-width: 768px) {

  .is-modal-as-full-page {
    --maxModalBodyMarginTop: calc(1em - 8px);

    .base-modal__backdrop {
      padding: 0;
    }
    .base-modal__header {
      margin-bottom: 0;
      min-height: 40px;
    }
    .base-modal__header + .base-modal__body {
      margin-top: var(--maxModalBodyMarginTop);
    }
    .base-modal__body {
      overflow-y: auto;
      flex-grow: 1;
      max-height: calc(var(--maxModalBodyHeight) - var(--maxModalBodyMarginTop));
    }
    .base-modal__content {
      border-radius: 0px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      margin: 0;
      padding-top: 0;
      padding-bottom: 0;
      width: 100vw;
      height: 100%;
      max-width: none;
      max-height: none;
    }
    .base-modal__header--top {
      flex-direction: row;
      align-items: center;
      line-height: var(--topNavbar-min-height);
      min-height: var(--topNavbar-min-height);
    }
    .base-modal__header--close {
      margin: 0 0 0 -6px;
      width: 22px;
      height: 22px;
    }
    .base-modal__header--title {
      text-align: center;
    }
  }

  .app--is-mobile-native-context {
    .is-modal-as-full-page {
      .base-modal__header--top {
        line-height: var(--topNavbar-min-height);
        min-height: var(--topNavbar-min-height);
      }
    }
  }
}

@media (min-width: 640px) {
  .is-modal-as-full-page {
    .base-modal__content,
    .base-modal__body {
      padding-left: 30px;
      padding-right: 30px;
    }

    .base-modal__body {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
}

@media (min-width: 960px) {
  .is-modal-as-full-page {
    .base-modal__content,
    .base-modal__body {
      padding-left: 40px;
      padding-right: 40px;
    }

    .base-modal__body {
      margin-left: -40px;
      margin-right: -40px;
    }
  }
}

@media screen and (max-width: 420px) {
  .app--is-mobile-native-context {
    .base-modal__header--close-small {
      margin-left: -12px;
    }
  }
}

.fade-in,
.fade-out,
.fade-in .base-modal__backdrop,
.fade-out .base-modal__backdrop {
  overflow: hidden !important;
}

.fade-in {
  animation: fade var(--modalAnimationDuration) cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.fade-out {
  animation: fade var(--modalAnimationDuration) cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slide-in-top .base-modal__content {
  animation: slide-top var(--modalAnimationDuration) cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.slide-out-top .base-modal__content {
  animation: slide-top var(--modalAnimationDuration) cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse;
}

@keyframes slide-top {
  0% {
    transform: translateY(calc(var(--modalAnimationSize) * -1));
  }
  100% {
    transform: translateY(0);
  }
}

.slide-in-right,
.slide-out-right,
.slide-in-right .base-modal__backdrop,
.slide-out-right .base-modal__backdrop {
  min-width: calc(var(--modalAnimationSize) * 10);
}

.slide-in-right .base-modal__content {
  animation: slide-right var(--modalAnimationDuration) cubic-bezier(0.250, 0.460, 0.450, 0.940);
}

.slide-out-right .base-modal__content {
  animation: slide-right var(--modalAnimationDuration) cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse;
}

@keyframes slide-right {
  0% {
    transform: translateX(var(--modalAnimationSize));
  }
  100% {
    transform: translateX(0);
  }
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.is-modal-as-full-page {
  .base-modal__header--multiple-action {
    .dropdown-menu--hook-target {
      display: flex;
    }
  }
}
</style>
