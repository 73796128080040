import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';
import axios from 'axios';
import HTTP_REQ_TYPES from '@/store/http-requests/types'
import { MESSAGE_CANCEL_REPEATED_REQUESTS, MESSAGE_REPLACE_SAME_URL, MESSAGE_SESSION_TIMEOUT } from '@/store/http-requests/actions';
import { escapeRegex } from '@/helpers/commonfunctions';

/**
 * This interceptor responsible for ignore repeated requests 
 * Requests with the same url cannot run together at the same time
 * 
 */

const validatesURLConfig = (configList, configName) => {
  configList.forEach(url => {
    if (!url.startsWith('/')) {
      store.dispatch(LOG_TYPES.ACTIONS.WARN, `The URL "${url}" on "${configName}" should start with a "/". It might be matched with an unrelated URL.`);
    }
    if (url.startsWith('//')) {
      store.dispatch(LOG_TYPES.ACTIONS.WARN, `The URL "${url}" on "${configName}" should start with only one "/". It might be never matched.`);
    }
    if (url.endsWith('/')) {
      store.dispatch(LOG_TYPES.ACTIONS.WARN, `The URL "${url}" on "${configName}" should not end with a "/". It might be never matched.`);
    }
  });
};

const urlMatches = (configuredURL, requestURL) => {
  const configuredURLEscaped = escapeRegex(configuredURL);
  return new RegExp(`${configuredURLEscaped}(?=[\\/#?&]|$)`).test(requestURL);
};

const isURLConfigured = (configList, url) => {
  return configList.some(configuredURL => urlMatches(configuredURL, url));
};

const findConfiguredURL = (configList, url) => {
  return configList.find(configuredURL => urlMatches(configuredURL, url));
};

/**
 * list of urls to ignore and allow it to run multiple times
 * > try to be as specific as possible to not affect unrelated paths
 */
const IGNORE_URL = [
  '/mrsputexception',
  '/cms/cmsGetBrokerLayout',
  '/cms/cmsGetBrokerInfo',
  '/upload_chunk',
  '/dokumentenarchiv/uploadDocument',
  '/dokumentenarchiv/getDokTree',
  '/dokumentenarchiv/getDocuments',
  '/investmentadvice/positions/add',
  '/mrsbanktrasactionaccount',
  '/mrsbanktrasactionaccountregister',
  '/mrsbanktrasactiondepotextload',
  '/mrsmygoals',
  '/mailComposer',
  '/mrschatupload',
  '/mrsinvestmentprofilecheck',
  '/mrsdepotpdf',
  '/insurances/updateInsurance',
  '/insurances/updateInsuranceCourtage',
  '/insurances/getInsuranceById',
  '/process',
  '/insurances/getAnbindungValues',
  '/mrscustomerdata',
  '/virtualsubdepot',
  '/fcconfig',
  '/mrsinsurance',
  '/mrsnews',
  '/mrsdepotpositionsum',
  '/mrsfundschartdata',
  '/depotpositions/list',
  '/mrsretirementscenario',
  '/customerData/birthdays',
  '/kill_session',
  '/setBeteiligte',
  '/getInsuranceGesellschaftList',
  '/ticketsnachrichten/closeTicket',
  '/calendarService/getCalendar',
  '/antragforms/list',
  '/postfachOverview/list',
  '/insurances/policeNummerValidation',
  '/config_definition/configs_definition',
  '/brokerPermissions/additionalPermissions',
  '/shortcut/createOrUpdate',
  '/shortcut/remove',
  '/mrschataddfile',
  '/mrschat',
  '/mrschatattachmentviewer',
  '/mrsbankaccount',
  '/list_externe_zugang_vergleich',
];
validatesURLConfig(IGNORE_URL, 'IGNORE_URL');

/**
 * list of urls to be canceled and replaced with a newer request
 * > try to be as specific as possible to not affect unrelated paths
 */
const REPLACEABLE_SAME_URL = [
  '/postfach/list',
  '/postfach/listForInsurance',
  '/customerFilters/getCustomersFiltered',
  '/ticketsnachrichten/getTickets',
  '/ticketsnachrichten/ticketOptions',
  '/ticketsnachrichten/getTicket',
  '/ticketsnachrichten/getBewertung',
  '/brokerSearch/getBrokers',
  '/consultation/wertpapierinfo?ask=get_wp',
  '/consultation/wertpapierinfo?ask=get_opt',
  '/TemplatesSetup/getTemplates',
  '/mrsprogressdetailsdata',
  '/depotpositions/search',
  '/fee/calcKundeHonorare',
  '/postfach/unread',
  '/signandvideoident/countVideoIdent',
  '/offene_unterschriften/getOpenSignsCountOnly',
  '/insurances/policeNummerRunValidation',
  '/mscnews/get_msc_news_count',
];
validatesURLConfig(REPLACEABLE_SAME_URL, 'REPLACEABLE_SAME_URL');

axios.interceptors.request.use(
  config => {
    const httpRequestUrl = store.getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL]

    if (isURLConfigured(REPLACEABLE_SAME_URL, config?.url)) {
      const configuredURL = findConfiguredURL(REPLACEABLE_SAME_URL, config?.url);
      store.getters[HTTP_REQ_TYPES.GETTERS.HTTP_REQUEST_URL_LIST].map(request => {
        if (urlMatches(configuredURL, request.url)) {
          store.dispatch(LOG_TYPES.ACTIONS.WARN, `Cancel same request: '${config?.url}'`);
          if (request.config?.defaultSpinner) {
            store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
          }
          request.config?.abort?.(MESSAGE_REPLACE_SAME_URL);
          store.commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, request);
        }
      })
    } else if (!isURLConfigured(IGNORE_URL, config?.url) && httpRequestUrl[config?.url]) {
      if (config?.defaultSpinner) {
        store.dispatch(CORE_TYPES.ACTIONS.GLOBAL_LOADING_STATE_STOP);
      }

      config.abort?.(MESSAGE_CANCEL_REPEATED_REQUESTS);
      return config;
    } else if (store.getters[HTTP_REQ_TYPES.GETTERS.IS_TOKEN_EXPIRED](config?.headers?.token)) {
      config.abort?.(MESSAGE_SESSION_TIMEOUT);
      return config;
    }
    const payload = {
      config: config,
      url: config?.url,
      requestId: config?.headers?.UID,
      cancelableRequest: config?.cancelableRequest,
    }

    store.commit(HTTP_REQ_TYPES.MUTATIONS.ADD_HTTP_REQUEST_URL, payload)

    return config;
  },
  error => {
    return Promise.reject(error)
  }
);

axios.interceptors.response.use(
  response => {
    store.commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, { requestId: response?.config?.headers?.UID, url: response.config?.url })
    return response;
  },
  error => {
    if (error?.config?.url) {
      store.commit(HTTP_REQ_TYPES.MUTATIONS.REMOVE_HTTP_REQUEST_URL, { requestId: error?.config?.headers?.UID, url: error.config?.url })
    }
    return Promise.reject(error);
  }
);
