<template>
  <div>
    <PageHeaderTitleNavigation
      :title="bezeichnung || 'Beratungstitel'"
      :actions="actions"
      @action-OPEN_ERZEUGEN="openErzeugen()"
      @action-OPEN_SIGNIEREN="openSignieren()"
      @action-OPEN_MAIL="openMail()"
      @action-ADD_ACTIVITY="handleAddActivity()"
      @action-FORTSETZEN="beratungsmappeFortsetzen()"
      @action-ABSCHLIESSEN="beratungsmappeAbschliessen()"
      @action-CHANGE_DESCRIPTION="openEditBeratungsmappe()"
      @action-START_INVESTEMENT_ADVICE="startInvestementAdvice()"
    >
      <template #subtitle>
        <span>{{pageHeaderSubtitle}}</span>
        <span v-if="statusText">&nbsp;|&nbsp;</span>
        <Pill v-if="statusText" :label="statusText" type="danger"></Pill>
      </template>
    </PageHeaderTitleNavigation>

    <BaseModal
      ref="deleteAntraegeConfirmation"
      modalTitle="Antrag löschen"
      :showDefaultButtons="false"
      :actions="deleteAntraegeBaseModalActions"
      @action-CANCEL="closeDeleteAntraegeConfirmation()"
      @action-REMOVE="deleteAntraege()"
      @action-DELETE="deleteAntraege(true)"
    >
      {{ deleteAntraegMessage }}
    </BaseModal> 

    <EditBeratungsmappeModal 
      @confirm="saveBeratungsmappe" 
      ref="editBeratungsmappeModal"
      isEdit
      :isBeratungsmappeClosed="isBeratungsmappeClosed"
    />

    <PictureAttachmentsModal
      ref="pictureAttachmentsModal"
      :selectedDoc="selectedDoc">
    </PictureAttachmentsModal>
    
    <!-- PDF ERZEUGEN -->
    <div class="box__container" v-if="erzeugen && !loading.getPdf">
      <p>{{actionsTitle}}</p>
      <div v-if="!beratungsmappeActions.signedAttachments || !beratungsmappeActions.signedAttachments.length">
        <div class="row mt-2">
          <div class="col-lg-6 col-md-12 mb-2">
            <BaseButton label="Beratungsmappe erzeugen" @click="beratungsmappeErzeugen()" :disabled="isSomeButtonLoading" :animated="loading.pdf"/>
          </div>
        </div>

        <div v-if="pdfErzeugenCheckboxesGroups && pdfErzeugenCheckboxesGroups.length">
          <div class="mt-2" v-for="(group, index) in pdfErzeugenCheckboxesGroups" :key="index">
            <InputCheckBoxGroup v-if="group"
              :title="group.label"
              :values="group.values" 
              :value="checkboxValues"
            />
          </div>
          <p class="font-bold">{{ beratungsmappeActions.nachricht }}</p>
        </div>
      </div>
    </div>

    <!-- SIGNIEREN -->
    <div class="box__container" v-if="signieren && !loading.getSign">
      <p>{{actionsTitle}}</p>
      <div v-for="(signableDocument, index) in beratungsmappeActions.signatures" v-bind:key="index">
        <SignoViewerLink v-if="signableDocument.url" target="_blank" :href="signableDocument.url" :title="signableDocument.fileName" />
        <a v-else-if="signableDocument.signaturStatus !== 'KOMPLETT' && signableDocument.isPictureAttach" target="_blank" @click="openPictureAttach(signableDocument)">{{signableDocument.fileName}}</a>   
        <span class="grayed-out" v-else>{{signableDocument.fileName}}</span>     

      </div>
      <p class="font-bold">{{beratungsmappeActions.nachricht}}</p>
    </div>

    <!-- BERATUNGSMAPPEN VERSENDEN -->
    <div class="box__container" v-if="versenden && !loading.getSend">
      <p>{{actionsTitle}}</p>
      <div v-if="!beratungsmappeActions.signedAttachments || !beratungsmappeActions.signedAttachments.length">
        <div class="row mt-2">
          <div class="col-lg-6 col-md-12 mb-2">
            <BaseButton class="btn btn-primary " @click="beratungsmappeVersenden()" label="Beratungsmappe versenden" :disabled="isSomeButtonLoading" :animated="loading.send"/>
          </div>
        </div>

        <div v-if="pdfErzeugenCheckboxesGroups && pdfErzeugenCheckboxesGroups.length">
          <div class="mt-2" v-for="(group, index) in pdfErzeugenCheckboxesGroups" :key="index">
            <InputCheckBoxGroup v-if="group"
              :title="group.label"
              :values="group.values" 
              :value="checkboxValues"
            />
          </div>
          <p class="font-bold">{{ beratungsmappeActions.nachricht }}</p>
        </div>
      </div>

    </div>

    <div v-if="beratungsmappeCards && cardsData && cardsData.length" class="beratung-edit__main">
      <div class="beratung-edit__sidebar">
        <div v-if="loading.init">
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <GhostLoading useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
        </div>

        <BoxList :items="cardsData" @clickItem="handleCardItemClick" v-else>
          <template #icon="{ item }">          
            <ph-info v-if="item.key == 'unterschriften_info_text'" :size="24" class="color-info" />
            <ph-info v-else-if="item.key == 'erfassung_position_papierantrag'" :size="24" class="color-info" />
            <ph-info v-else-if="item.key == 'bemerkung_text'" :size="24" class="color-info" />
            <ph-question v-else-if="item.key == 'unterschriften_question_mark_text'" :size="24" class="color-info" />
            <ph-check-circle v-else-if="item.status == 'success'" :size="24" class="color-success" />
            <ph-warning-circle v-else-if="item.status == 'warning'" :size="24" class="color-warning" />
            <ph-warning-circle v-else-if="item.status == 'danger'" :size="24" class="color-danger" />
            <ph-info v-else-if="item.status == 'info'" :size="24" class="color-info" />
            <ph-circle v-else :size="24" class="color-primary" />
          </template>

          <template #default="{ item }">
            <h3 class="mt-0 mb-1" v-if="item.key !== 'unterschriften_info_text' && item.key !== 'unterschriften_question_mark_text'">{{ item.label }}</h3>
            <p class="mt-0 mb-0" v-if="item.key !== 'unterschriften_info_text'"  :tid="item.description && item.description.length ? _generateTidFromString(item.description + item.key) : null">
              <small>{{ item.description }}</small>
            </p>
            <p class="mt-0 mb-0" v-for="(warning, index) in item.warnings" :key="index">
              <small :class="getStatusClass(warning.status)"> {{ warning.message }}</small>
            </p>
            <p class="mt-0 mb-0" v-if="item.key == 'unterschriften_info_text'">
              <small v-html="sanitize(unterschriftenModalMsg)"></small>
            </p>    
          </template>

          <template #link="{ item }">
            <a v-if="item.clickable">{{ item.linkText }}</a>
          </template>
        </BoxList>
      </div>
      <div class="beratung-edit__content">

        <OptionMenu v-if="showForms" :id="FORMULARE_OPTIONS_MENU_ID" :showEditButton="false" 
          :defaultMenu="customOptionMenu" :customOptionMenu="customOptionMenu" :ignoreRender="false" />

        <div v-if="showForms && selectedLagerstelle && selectedLagerstelle.path">
          <div class="box__container" :tid="_generateTidFromString('box__container')">
            <div class="box__title">{{selectedLagerstelle.label}}</div>
              <InputField 
                isComponentHalfSize
                placeholder="Suchen nach Bezeichnung"
                v-model="descriptionSearch"/>
          </div>
          <div class="box__container" v-for="(value, propName) in groupedForms" :key="propName">
            <Table 
              rowId="index_plus_category"
              :headers="antragListeHeaders(propName)"
              :rows="antragListeRows(value, propName)"
              hidePagination
              @click-label="handleLabelClick"
            />
          </div>
        </div>      

        <GhostLoading v-if="loading.init" useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
        <div v-else class="box__container">
          <Table
            v-if="antragRows.length"
            rowId="index"
            title="Anträge"
            :headers="antragHeaders"
            :rows="antragRows"
            hidePagination
            @click-label="editAntraeg"
          >  
            <template v-slot:delete="row">
              <ph-trash class="ml-3" :size="18" @click="deleteAntraegeConfirmation(row)"/>
            </template>
          </Table>
          <NoData v-else noIcon content="Keine Anträge vorhanden"/>
        </div>

        <div v-if="!!referredFromInvestmentAdvice === false">
          <GhostLoading v-if="loading.init" useBoxContainer type="block" :config="{ block: { height: 100, }, }" />
          <div v-else class="box__container">           
            <Table
              v-if="positionRows.length"
              tableId="bd07a11c-2664-413b-99ba-0bc846a0d90c"
              rowId="index"
              title="Orderpositionen"
              :headers="positionHeaders"
              :rows="positionRows"
              hidePagination
              @click-wertpapier="openOrderPosition"
            >       
              <template v-slot:delete="row">
                <ph-trash v-if="row.isManuellePosition" class="ml-3" :size="18" @click="deletePosition(row)"/>
              </template>
            </Table>
            <NoData v-else noIcon content="Keine Positionen vorhanden"/>
          </div>

          <!-- Sums -->
          <div v-if="positionRows.length && !loading.init" class="box__container">
            <table style="width:100%">
              <tr>
                <td>
                  <div class="header-cell">Einmalanlagen</div>
                  <CurrencyLabel class="table-cell" :value="positionSums.summeEinmal"/>
                </td>
                <td>
                  <div class="header-cell">Verkäufe</div>
                  <CurrencyLabel class="table-cell" :value="positionSums.summeVerkauf"/>
                </td>
                <td>
                  <div class="header-cell">Gesamt</div>
                  <CurrencyLabel class="table-cell" :value="positionSums.summeGesamtEinmal"/>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="header-cell">monatl. Anlage</div>
                  <CurrencyLabel class="table-cell" :value="positionSums.summeSpar"/>
                </td>
                <td>
                  <div class="header-cell">monatl. Entnahme</div>
                  <CurrencyLabel class="table-cell" :value="positionSums.summeEntnahme"/>
                </td>
                <td>
                  <div class="header-cell">Gesamt</div>
                  <CurrencyLabel class="table-cell" :value="positionSums.summeGesamtSpar"/>
                </td>
              </tr>
            </table>
          </div>
        </div>
      
        <div v-if="!!referredFromInvestmentAdvice"> 
          <div v-if="investmentAdviceOrderList.length > 0">
              <div>Wertpapierorder*</div>
              <BoxList :items="investmentAdviceOrderList" @clickItem="handleInvestmentAdviceOrderListClick">
                  <template #icon="{ item }">
                      <ph-check-circle v-if="item.checked" :size="24" class="color-success" />
                      <ph-warning-circle v-else :size="24" class="color-warning" />
                  </template>

                  <template #default="{ item }">
                      <h3 class="mt-0 mb-1" :tid="item.label && item.label.length ? _generateTidFromString(item.label + item.key) : null">{{ item.label }}</h3>
                      <p class="mt-0 mb-0">
                      <small v-if="!item.checked" > Bitte überprüfen Sie die Antragsdaten </small>
                      </p>
                  </template>

                  <template #link="{ item }">
                      <a v-if="!item.checked" href="javascript:;"> Öffnen </a>
                  </template>
              </BoxList>
          </div>
          <div v-if="investmentAdviceAntraege.length > 0">
              <div>Anträge*</div>
              <BoxList :items="investmentAdviceAntraege" @clickItem="handleInvestmentAdviceAntraegeClick">
                  <template #icon="{ item }">
                      <ph-check-circle v-if="item.checked" :size="24" class="color-success" />
                      <ph-warning-circle v-else :size="24" class="color-warning" />
                  </template>

                  <template #default="{ item }">
                      <h3 class="mt-0 mb-1"   :tid="item.label && item.label.length ? _generateTidFromString(item.label + item.key) : null">{{ item.label }}</h3>
                      <p class="mt-0 mb-0">
                      <small v-if="!item.checked" > Bitte überprüfen Sie die Antragsdaten </small>
                      </p>
                  </template>

                  <template #link="{ item }">
                      <a v-if="!item.checked" href="javascript:;"> Öffnen </a>
                  </template>
              </BoxList>
          </div>
        </div>
      </div>
    </div>
  
    <BaseModal
      ref="multipleFilesModal"
      :modalTitle="bezeichnung + '.pdf' || 'Beratungstitel'"  :showCancelButton="false" :showConfirmButton="false"   
    >
      <FileAttachmentsModal :signedAttachments="executeActionResult.signedAttachments" :unsignedAttachments="executeActionResult.unsignedAttachments || []" @close="$refs.multipleFilesModal.close()" />

    </BaseModal>  
    
    <BaseModal
      ref="investementAdvice"
      modalTitle="Antragspositionen werden gelöscht"
      labelButtonCancel="Nein"
      labelButtonConfirm="Ja"
      @onConfirmButton="startInvestementAdvice2"
    >
    <p>Durch die Anlageempfehlung werden alle bestehenden Antragspositionen, die noch nicht beantragt wurden, gelöscht.</p>
    Möchten Sie fortfahren?
    </BaseModal>

    <BaseModal
      ref="grundlagenwissenModal"
      modalTitle="Grundlagenwissen Wertpapiere"
      labelButtonConfirm="Speichern"
      :confirmDisabled="isBeratungsmappeClosed"
      @onConfirmButton="confirmGrundlagenwissenModal"
    >
      <InputRadioBoxGroup 
        title="Soll dem Kunden die Broschüre Grundlagenwissen Wertpapiere kostenpflichtig (1,28€) zur Verfügung gestellt werden?"
        v-model="grundlagenwissenData.SHOW_GRUNDLAGENWISSEN" 
        :disabled="isBeratungsmappeClosed"
        :values="grundlagenwissenValues"
      />

      <InputRadioBoxGroup 
        title="Kunde wird / wurde die Broschüre Grundlagenwissen Wertpapiere in gedruckter / CD Form ausgehändigt."
        v-model="grundlagenwissenData.IGNORE_GRUNDLAGENWISSEN" 
        :disabled="isBeratungsmappeClosed"
        :values="grundlagenwissenValues"
      />

    </BaseModal>
  </div>
</template>

<script>
import { PhCheckCircle, PhWarningCircle, PhCircle, PhTrash, PhInfo, PhQuestion } from 'phosphor-vue';

import BERATUNGSMAPPE_TYPES from "@/store/beratungsmappe/types";
import INVESTMENT_ADVICE from "@/store/investmentAdvice/types";
import WERTPAPIERORDER from "@/store/wertpapierorder/types";
import { mapGetters } from "vuex";
import InputCheckBoxGroup from '@/components/core/forms/checkbox/InputCheckBoxGroup.vue';
import FormUtil from '@/components/beratung/formsMenu/forms-menu.js';
import BaseButton from "@/components/core/BaseButton.vue";
import BaseModal from '@/components/core/BaseModal.vue';
import InputField from '@/components/core/forms/InputField.vue'
import BoxList from '@/components/core/BoxList.vue';
import OptionMenu from '@/components/core/option-menu/OptionMenu.vue';
import CORE_TYPES from '@/store/core/types'
import ANTRAG_TYPES from "../../store/antrag/types";
import AppointmentEditCreate from '@/components/calendar/appointmentFiles/AppointmentEditCreate.vue';
import { componentByLagerstelle } from '@/helpers/utils-helper'
import InteractiveHelpCommonsMixin from "@/assets/mixins/interactivehelpcommonsmixins.js";
import MAILCOMPOSER_TYPES from "@/store/mailcomposer/types";
import FileAttachmentsModal from '@/components/documents/FileAttachmentsModal.vue'
import {sanitize} from '@/helpers/string-helper.js';
import { viewDocument } from '@/components/core/download/DownloadLink.vue';
import SignoViewerLink from '@/components/core/download/SignoViewerLink.vue';
import PictureAttachmentsModal from "@/views/documents/PictureAttachmentsModal.vue";
import EditBeratungsmappeModal from "@/components/beratungsmappe/EditBeratungsmappeModal.vue";
import PageHeaderTitleNavigation from '@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue'
import { PageHeaderSimpleAction, PageHeaderConfirmedAction, } from '@/components/core/header-title-navigation/page-header-utils'
import LOG_TYPES from '@/store/log/types';
import { buildMessage } from "@/helpers/log-message-helper";
import Table from "@/components/table2/Table.vue";
import {TextColumn, SlotColumn, PillColumn} from "@/components/table2/table_util.js";
import NoData from '@/components/core/NoData.vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import Block from '@/components/core/loading/ghost-loading/Block.vue';
import axios from 'axios';
import { makeQueryParam } from '@/helpers/utils-helper';
import Pill from '@/components/core/Pill.vue';
import CALENDAR_TYPES from '@/store/calendar/types';
import InputRadioBoxGroup from '@/components/core/forms/radiobox/InputRadioBoxGroup.vue';
import CurrencyLabel from '@/components/core/CurrencyLabel.vue';
import { BaseModalSimpleAction } from '@/components/core/base-modal-actions/base-modal-actions-utils';

const DEFAULT_OTHER_CATEGORY = 'Sonstiges'

const FORMULARE_OPTIONS_MENU_ID = '/beratung/formulare';

const config = {
  defaultSpinner: true
};

export default {
  mixins: [InteractiveHelpCommonsMixin],
  emits: ['configuredMenu'],
  data: function () {
    return {
      FORMULARE_OPTIONS_MENU_ID,
      descriptionSearch: '',
      cards: {},
      liste: null,
      actionsTitle: "Beratungsmappe Erzeugen",
      erzeugen: false,
      versenden: false,
      signieren: false,
      bemerkung: null,
      bezeichnung: null,
      verwende: null,
      verwendeApSchiene: null,
      lagerstelle: null,
      erlaubnisPara34: null,
      // menu: {...this.antragList.formGroups.filter(f => f.showInBeratungsmappe == true)},
      showForms: false,
      antraegeToDelete : null,
      selectedBeratungsType: null, 
      selectedAntrag: null,  
      lagerstelleList: [],
      selectedLagerstelle: {},
      loading: {
        getSend: false,
        getPdf: false,
        getSign: false,
        close: false,
        open: false,
        send: false,
        pdf: false,
        init: false,
        action: false,
      },
      grundlagenwissenData: {
        SHOW_GRUNDLAGENWISSEN: "Nein",
        IGNORE_GRUNDLAGENWISSEN: "Nein",
      },
      grundlagenwissenValues: [{ value: 'Ja', label: 'Ja' }, { value: 'Nein', label: 'Nein' }],
      selectedDoc: null,
      unterschriftenModalMsg: null,
      actionsData: null,
    };
  },
  components: {
    InputCheckBoxGroup,
    BaseButton,
    Table,
    BaseModal,
    InputField,
    CurrencyLabel,
    BoxList,
    PhCheckCircle,
    PhWarningCircle,
    PhCircle,
    PhTrash,
    AppointmentEditCreate,
    OptionMenu,
    PhInfo,
    PhQuestion,
    FileAttachmentsModal,
    SignoViewerLink,
    PictureAttachmentsModal,
    PageHeaderTitleNavigation,
    NoData,
    GhostLoading,
    Block,
    Pill,
    EditBeratungsmappeModal,
    InputRadioBoxGroup,
  },
  computed: {
    ...mapGetters({
      beratungsmappeCards: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPE_CARDS,
      beratungsmappeActions: BERATUNGSMAPPE_TYPES.GETTERS.BERATUNGSMAPPE_ACTIONS,
      executeActionResult: BERATUNGSMAPPE_TYPES.GETTERS.EXECUTE_ACTION_RESULT,
      antragList: ANTRAG_TYPES.GETTERS.ANTRAG_LIST,
      investmentAdviceOrder: INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_ORDER,
      investmentAdviceDepoteroeffnung: INVESTMENT_ADVICE.GETTERS.INVESTMENT_ADVICE_DEPOTEROEFFNUNG,
      addressLegacy: CORE_TYPES.GETTERS.API_ADDRESS_LEGACY,
      isBrokerOrBypass: CORE_TYPES.GETTERS.IS_BROKER_OR_BYPASS,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isAnlageBeispiel: CORE_TYPES.GETTERS.IS_ANLAGE_BEISPIEL,
    }),
    textEmpehlung() {
      return this.isAnlageBeispiel ? 'beispiel' : 'empfehlung' ;
    },
    antragCardLabel() {
      return this.showForms ? 'Anträge ausblenden' : 'Anträge anzeigen';
    },
    checkboxValues() {
      return this.beratungsmappeActions?.data?.reduce((acc, curr) => ({...acc, [curr.label]: curr.value}), {}) || {};
    },
    pdfErzeugenCheckboxesGroups() {
      // sort checkbox groups so, that the 1.st one is the main group (without label), then come groups of eFonds (with label)
      const checkboxes = (this.beratungsmappeActions?.checkboxes?.filter(elem => !elem.label) || [])
        .concat(this.beratungsmappeActions?.checkboxes?.filter(elem => elem.label) || []);
      return checkboxes.map(group => ({
        ...group,
        values: group.values?.map(entry => ({label: entry?.label, path: entry.value, disabled: entry.disabled})),
      }))
    },

    cardsData() {
      const cards = [
        {...this.beratungsmappeCards.erstinformation, key: 'erstinformation', link: this.getFormLink(this.beratungsmappeCards.erstinformation?.formUrl, this.beratungsmappeCards.erstinformation?.id), linkText: 'öffnen', clickable: true},
        {...this.beratungsmappeCards.dialogGrundlagenwissen, key: 'dialog_grundlagenwissen', linkText: 'öffnen'},
        {...this.beratungsmappeCards.rahmenvereinbarung, key: 'rahmenvereinbarung', link: this.getFormLink(this.beratungsmappeCards.rahmenvereinbarung?.formUrl, this.beratungsmappeCards.rahmenvereinbarung?.id) , linkText: 'öffnen', clickable: true},
        {...this.beratungsmappeCards.anlegerprofil, key: 'anlegerprofil', link: this.getAnlegerprofilLink(), linkText: 'öffnen', clickable: true},
        {...this.beratungsmappeCards.beratungsdokumentation_grundlage, key: 'beratungsdokumentation_grundlage', link: this.getFormLink(`dokumentationgrundlage`), linkText: 'öffnen', clickable: true},
        {...this.beratungsmappeCards.vvwVertrag, key: 'vvwVertrag', link: this.getFormLink(`vvwvertrag`, this.beratungsmappeCards.vvwVertrag?.id) , linkText: 'öffnen', clickable: this.beratungsmappeCards.vvwVertrag?.status !== 'info'},
        ... !this.isBeratungsmappeClosed ? [{ label: "Anträge", key: 'antrage_box', clickable: !this.isAntraegeLocked, status: this.beratungsmappeCards.antraege?.status, linkText: this.antragCardLabel }] : [], 
        ... !this.isWealthkonzept && !this.isBeratungsmappeClosed ? [{ label: "Erfassung Position Papierantrag", key: 'erfassung_position_papierantrag', link: `/beratung/beratung/erfassungpositionpapierantrag?beratungsMappeId=${this.$route.query.id}`, 
            linkText: 'öffnen', clickable: true,  description: "Erlaubt die Eingabe einer Orderposition, die außerhalb des Systems erfasst wurde (beispielsweise mit einem Papierantrag). Diese Position wird in der Risikoprüfung berücksichtigt, es erfolgt jedoch kein Ausdruck in der PDF."}] : [],        
        {...this.beratungsmappeCards.beratungsdokumentation_ergebnis, key: 'beratungsdokumentation_ergebnis', link: this.getFormLink(`dokumentationergebnis`), linkText: 'öffnen', clickable: true},
        {...this.beratungsmappeCards.risikoprufung, key: 'risikoprufung', link: this.getFormLink(`risikoprufung`) + `&isWk=${this.verwendeApSchiene === 'WEALTH_KONZEPT'}`, linkText: 'öffnen', clickable: true},
        {...this.beratungsmappeCards.unterschriften, key: 'unterschriften', linkText: this.buttonVorlegenAktiv ? 'Zur e-Signatur vorlegen' : 'Zur e-Signatur vorgelegt', clickable: this.buttonVorlegenAktiv },
        {...this.beratungsmappeCards.unterschriften, key: 'unterschriften_question_mark_text',  description: "Bitte beachten Sie, dass die Dokumente nach erfolgter Unterschrift nicht mehr bearbeitet werden können und die Beratungsmappe automatisch gesichert wird."},
        {...this.beratungsmappeCards.unterschriften, key: 'unterschriften_info_text',  description: this.unterschriftenModalMsg},
        ... this.bemerkung ? [{label: 'Bemerkung', description: this.bemerkung, key: 'bemerkung_text', clickable: !this.isBeratungsmappeClosed, linkText: 'öffnen'}] : [],
      ];
      if (this.beratungsmappeCards) {
        return cards.filter(card => card.label);
      }
      return [];
    },  
    isSomeButtonLoading() {
      return Object.values(this.loading).some(valueLoading => valueLoading)
    },
    isWealthkonzept() {
      return this.verwende && this.verwende === 'WEALTH_KONZEPT_2018' 
    },
    antragHeaders() {
      return {
        center: [
          TextColumn("label", "Bezeichnung").makeLink().makeAlwaysVisible(),
          TextColumn("lagerstelle", "Gesellschaft"),
          PillColumn("status", "Status").makeAlwaysVisible(),
          ... this.isBeratungsmappeClosed ? [] : [SlotColumn('delete', '').makeAlwaysVisible()],
        ],
      }
    },
    antragRows() {
      if (!this.beratungsmappeCards.antraege || !this.beratungsmappeCards.antraege.antraegeList)
        return []

      return this.beratungsmappeCards.antraege.antraegeList.map((row, index) => {
        let status = null;

        if (row.Status === 'success') {
          status = {label: "fehlerfrei", type: row.Status};
        } else {
          status = {label: "fehlerhaft", type: row.Status};
        }
        return {...row, index, status, label: row.Antrag, lagerstelle: row.Gesellschaft};
      });
    }, 
    positionHeaders() {
      return {
        lockedLeft: [
          TextColumn("art", "Art"),
          TextColumn("wertpapier", "Wertpapier").makeConditionalLink('isManuellePosition').addCellProps({lineClamp: 4}),
        ],
        center: [
          TextColumn("betrag", "Betrag"),
          TextColumn("SRI", "SRI"),
          TextColumn("antrag", "Antrag").addCellProps({lineClamp: 4}),
          TextColumn("isin", "ISIN"),
          TextColumn("zusatz", "Zusatz"),
        ],
        lockedRight: [
          ... this.isBeratungsmappeClosed ? [] : [SlotColumn('delete', '').makeAlwaysVisible()],
        ]
      }
    },
    positionRows() {
      if (!this.beratungsmappeCards.antraege || !this.beratungsmappeCards.antraege.orderPositionen)
        return []

      return this.beratungsmappeCards.antraege.orderPositionen.map((row, index) => {
        return {...row, index};
      });
    },   
    positionSums() {
      if (!this.beratungsmappeCards.antraege || !this.beratungsmappeCards.antraege.orderPositionen)
        return {}

      return this.beratungsmappeCards.antraege.orderPositionenSums || {};
    },   
    deleteAntraegMessage() {
      const antragName = this.antraegeToDelete && this.antraegeToDelete.Antrag;

      return antragName && `Soll der Antrag "${antragName}" wirklich aus der Beratungsmappe entfernt (Formular bleibt im Dokumentenarchiv erhalten) oder vollständig gelöscht werden?`;
    },
    erlaubnisPara34Description() {
      if (Array.isArray(this.beratungsmappeCards?.erlaubnisPara34Options)) {
        const found = this.beratungsmappeCards.erlaubnisPara34Options.find(v => v.value === this.erlaubnisPara34)
        return found?.label ? found.label : ''
      }
      return ''
    },
    statusText() {
      return this.isBeratungsmappeGesichert ? 'gesichert' : this.isBeratungsmappeClosed ? 'geschlossen' : null;
    },   
    buttonVorlegenAktiv() {
      return this.beratungsmappeCards.unterschriften?.additionalData?.buttonVorlegenAktiv
    },
    isBeratungsmappeGesichert() {
      return this.beratungsmappeCards.kann_bearbaitet_werden?.status === 'IstGesperrtAutomatisch'
    }, 
    isAntraegeLocked(){
      return this.beratungsmappeCards.antraegeLocked;
    },
    isBeratungsmappeAbgeschlossen() {
      return this.beratungsmappeCards.kann_bearbaitet_werden?.status === 'IstGesperrt'
    }, 
    isBeratungsmappeClosed() {
      return this.isBeratungsmappeGesichert || this.isBeratungsmappeAbgeschlossen;
    }, 
    pageHeaderSubtitle() {
      if (this.isFA || !this.erlaubnisPara34Description) {
        return this.beratungsmappeCards?.verwendeLabel || ''
      }
      return this.beratungsmappeCards?.verwendeLabel ? `${this.beratungsmappeCards.verwendeLabel} | Erlaubnis §34 f/h: ${this.erlaubnisPara34Description}` : ''
    },
    customOptionMenu() {
      return this.lagerstelleList.filter(menuItem => (!menuItem.isHidden)).map((lagerstelle) => ({
        ...lagerstelle,
        id: `/beratung/formulare/antrag/${lagerstelle.path}`,
        path: `/beratung/formulare/antrag/${lagerstelle.path}`,
        textBold: this.selectedLagerstelle === lagerstelle,
        component: componentByLagerstelle(lagerstelle),
        componentProps: {
          src: lagerstelle.id?.toUpperCase() ? `${this.addressLegacy}/mrscompanylogo?company=${lagerstelle.id?.toUpperCase()}` : null,
          fit: 'contain',
          placeholder: {
            width: '78px',
            height: '48px',
          }
        },
        action: () => {
          switch (lagerstelle?.id) {
            case 'WERTPAPIERORDER':      
              this.handleLabelClick(FormUtil.getForms(lagerstelle, { beratungsMappeId: this.$route.query.id })[0]);
              break;
            default:
              this.selectLagerstelle(lagerstelle);
          }
        },
      }));
    },      
    currentFormPath() {
      return this.$route?.query?.formPath;
    },
    forms() {
      return FormUtil.getForms(this.selectedLagerstelle, { beratungsMappeId: this.$route.query.id });
    },   
    referredFromInvestmentAdvice() {
      const result = (this.$route.query && this.$route.query.investementAdviceReferrer && this.$route.query.investementAdviceReferrer === 'true') 
        || this.beratungsmappeCards?.referredFromInvestmentAdvice === true 
        || this.beratungsmappeCards?.referredFromInvestmentAdvice === 'true';
      return result;
    },
    investmentAdviceOrderList() {
      if (this.referredFromInvestmentAdvice) {        
        let result = this.investmentAdviceOrder?.orderList || [];
        if (process.env.NODE_ENV === 'development')
          console.log('investmentAdviceOrderList ' + result.length);
        return result;
      }
      return [];
    },
    investmentAdviceAntraege() {
      if (this.referredFromInvestmentAdvice) {        
        return this.investmentAdviceDepoteroeffnung?.antragList || [];
      }
      return [];
    },
    filteredForms() {
      const forms = this.forms?.filter?.(f => !f.hideForm)
      if (!forms || !this.descriptionSearch) {
        return forms
      }

      return forms.filter(f => f?.friendlyName?.toLowerCase?.().includes(this.descriptionSearch.toLowerCase()))
    },
    groupedForms() {
      if (!this.filteredForms) {
        return this.filteredForms
      }

      const groupedForm = this.filteredForms.reduce((accu, curr) => {
        const childGroup = curr?.childGroup || DEFAULT_OTHER_CATEGORY

        if (!accu[childGroup]) {
          accu[childGroup] = []
        }
        accu[childGroup].push(curr)
        return accu
      }, {})

      return groupedForm
    },
    actions() {
      return [
        PageHeaderSimpleAction('FORTSETZEN', 'Fortsetzen')
          .withVisible(() => this.isBeratungsmappeGesichert)
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('OPEN_ERZEUGEN', 'PDF')
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('OPEN_SIGNIEREN', 'PDF signieren')
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('OPEN_MAIL', 'Versenden')
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('ADD_ACTIVITY', 'Aufgabe hinzufügen')
          .withVisible(() => this.isBrokerOrBypass)
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('CHANGE_DESCRIPTION', !this.isBeratungsmappeClosed ? 'Bearbeiten' : 'Bemerkung')
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderSimpleAction('START_INVESTEMENT_ADVICE', `Anlage${this.textEmpehlung}`)
          .withVisible(() => !this.isBeratungsmappeClosed && !this.isWealthkonzept)
          .withDisabled(() => this.isSomeButtonLoading),
        PageHeaderConfirmedAction('ABSCHLIESSEN', 'Abschließen', 'Beratungsmappe abschließen? Wenn die Beratungsmappe abgeschlossen wird, sind keine weiteren Änderungen möglich.', 'Beratungsmappe Abschließen', 'Abschließen')
          .withVisible(() => !this.isBeratungsmappeAbgeschlossen)
          .withDisabled(() => this.isSomeButtonLoading),
      ]
    },
    deleteAntraegeBaseModalActions() {
      return  [
        BaseModalSimpleAction('REMOVE', 'Entfernen'),
        BaseModalSimpleAction('DELETE', 'Löschen'),
        BaseModalSimpleAction('CANCEL', 'Abbrechen')
        .withVisibleOnSmallScreen(() => false)
        .withPrimary(() => false),
      ];
    },
  },
  watch: {
    beratungsmappeActions: function (newValue, oldValue) {
      this.resetLoadingState()
      this.actionsData = newValue.data;
    },
    beratungsmappeCards: function (newValue, oldValue) {      
      if (newValue && Object.keys(newValue).length) {
        this.bezeichnung = newValue.bezeichnung
        this.verwende = newValue.verwende
        this.bemerkung = newValue.bemerkung
        this.lagerstelle = newValue.lagerstelle
        this.verwendeApSchiene = newValue.verwendeApSchiene

        if (!this.bezeichnung || this.bezeichnung.length==0) {
          this.openEditBeratungsmappe();     
        }

        this.erzeugen = false
        this.versenden = false
        this.signieren = false

        this.erlaubnisPara34 = newValue.erlaubnisPara34;
        this.unterschriftenModalMsg = newValue.unterschriften && newValue.unterschriften.additionalData && newValue.unterschriften.additionalData.textTooltip; //.replace(/<br>+/g, "\\n");
      }
    },
    executeActionResult: function (newValue, oldValue) {
      if (newValue.pdfData && newValue.pdfData.bytes) {
        this.showFileContent(newValue.pdfData.bytes, newValue.title);
      }

      if ((newValue.signedAttachments && newValue.signedAttachments.length) ||
      (newValue.unsignedAttachments && newValue.unsignedAttachments.length )) {
        this.$refs.multipleFilesModal.open();
      }

    },
    antragList: {
      handler(newValue) {
        this.lagerstelleList = newValue && newValue.formGroups && [...newValue.formGroups].sort(this.sortByLabel) || [];
      },
      immediate: false,
    },
  },
  methods: {
    openGrundlagenwissenModal() {
      const grundlagenCardData = this.beratungsmappeCards.dialogGrundlagenwissen?.additionalData || {}

      this.grundlagenwissenData = {
        SHOW_GRUNDLAGENWISSEN : grundlagenCardData.SHOW_GRUNDLAGENWISSEN || "Nein",
        IGNORE_GRUNDLAGENWISSEN : grundlagenCardData.IGNORE_GRUNDLAGENWISSEN || "Nein",
      }

      this.$refs.grundlagenwissenModal.open()
    },
    confirmGrundlagenwissenModal() {
      if (this.isBeratungsmappeClosed) {
        return;
      }
      this.loading.action = true

      axios.post(`${process.env.VUE_APP_API}/beratungsmappe/saveGrundlagenwissen?beratungsmappeId=${this.$route.query.id}`, this.grundlagenwissenData, config)
        .then(() => this.retrieveBeratungsmappeCards())
        .finally(() => this.loading.action = false)
    },
    antragListeRows(rows, category) {
      if (!this.forms)
        return []

      return rows.map((row, index) => {
        return {...row, index_plus_category: index + category};
      });
    },
    antragListeHeaders(category) {
      return {
        lockedLeft: [
          TextColumn("label", category).makeLink(),
        ],
      }
    }, 
    sanitize(htmlString) {
        return sanitize(htmlString);
    },
    openEditBeratungsmappe() {

      const payload = {
        bezeichnung: this.bezeichnung,
        mappeTyp: this.verwende,
        lagerstelle: this.lagerstelle,
        bemerkung: this.bemerkung,
      }
      this.$refs.editBeratungsmappeModal.open(payload)
    },
    retrieveBeratungsmappeCards() {
      let payload = {
        beratungsMappeID: this.$route.query.id,
      }
      if (this.$route.query && this.$route.query.investementAdviceReferrer && !this.$route.query.backAction && this.investmentAdviceOrder) {        
        payload.posBesitzerId = this.investmentAdviceOrder.posBesitzerId;
        payload.antragType = this.investmentAdviceOrder.type;        
      } else if (this.$route.query && this.$route.query.investementAdviceReferrer && !this.$route.query.backAction && this.investmentAdviceDepoteroeffnung) {        
        payload.posBesitzerId = this.investmentAdviceDepoteroeffnung.posBesitzerId;
        payload.posBesitzerIdVerkauf = this.investmentAdviceDepoteroeffnung.posBesitzerIdVerkauf;
        payload.antragType = this.investmentAdviceDepoteroeffnung.type;        
      }
     
      return this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.EDIT_BERATUNGSMAPPE, payload)
      .finally(() => this.resetLoadingState());
    },

    retrieveBeratungsmappeActions(parameterNameModus) {
      this.$store.dispatch(
        BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_BERATUNGSMAPPEACTIONS,
        {
          beratungsMappeID: this.$route.query.id,
          PARAMETER_NAME_MODUS: parameterNameModus,
        }
      );
    },
    beratungsmappeErzeugen() {
      if (!Object.values(this.checkboxValues).some(value => !!value)) {
        this.$confirmModal({
          title: 'Bitte wählen Sie ein Dokument', 
          labelButtonCancel: 'Ok',
          showConfirmButton: false,
        })
      } else {

        this.loading.pdf = true

        let parameters =         {
            beratungsMappeID: this.$route.query.id,
            action: 'ACTION_PDF'
          };

          Object.assign(parameters, this.checkboxValues);

        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.EXECUTE_BERATUNGSMAPPEACTION,
          parameters
        ).finally(() => this.loading.pdf = false)
      }
    },
    beratungsmappeVersenden() {
      this.loading.send = true

      if (this.actionsData) {
        // let result = {};
        // let ids=  this.beratungsmappeCards.antraege.antraegeList.map(r=>r.Id);
        // Object.assign(result, this.checkboxValues);
        // Object.assign(result, this.PDFErzeugenCheckboxesModel);
        // result.idsArray = ids;
        this.$store.dispatch(
          BERATUNGSMAPPE_TYPES.ACTIONS.RETRIEVE_VERSENDEN_FILES,
          {
            beratungsMappeID: this.$route.query.id,
            data: this.checkboxValues,
          }
          
        ).then(response => {
          if (response && response.data) {
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_MAILCOMPOSER_CONFIG, {
              type: 'mailcomposer-beratungsmappen',
              action: 'clear',
            });
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, {
              type: 'mailcomposer-beratungsmappen',
              action: 'clear',
            });
            const responseData = response.data
            this.$store.commit(MAILCOMPOSER_TYPES.MUTATIONS.SET_ATTACHMENTS, responseData);
            this.$router.push({path: `/communication/mailcomposer-beratungsmappen/${this.$route.query.id}`, query: {replaceContent: true, toCompany: true}});
          }
        }).finally(() => this.loading.send = false)
      }
    },
    saveBeratungsmappe(mappeData) {
      const payload = {
        ...mappeData,
        id: this.$route.query.id,
      }
      this.save(payload)
    },
    // bei der geschlossene BM kann man nur die Bemerkung speichern
    async save(payload = {}) {
      if (!this.isBeratungsmappeClosed) {
        this.loading.action = true

        await axios.post(`${process.env.VUE_APP_API}/beratungsmappe/save`, payload, config)
          .catch(() => this.loading.action = false)
        
        await this.retrieveBeratungsmappeCards()

        const lagerstelleChanged = payload.lagerstelle && this.lagerstelle !== payload.lagerstelle
        if (lagerstelleChanged) {
          this.retrieveAntragList()
        }

      } else {
        await axios.post(`${process.env.VUE_APP_API}/beratungsmappe/saveBemerkung`, payload, config)
          .catch(() => this.loading.action = false)
        this.loading.action = false;
        this.bemerkung = payload?.bemerkung;
      }
    },
    handleAddActivity(){
      this.openAppointment();
    },
    openAppointment() {
      this.$store.commit(CALENDAR_TYPES.MUTATIONS.SET_APPOINTMENT_CONFIG, {
        title: 'Aufgabe bearbeiten',
  
        back: this.$router.currentRoute.fullPath,
      })
      this.$router.push({ path: `/communication/appointment` });
    },
    openErzeugen() {
      if (!this.erzeugen) {
        this.loading.getPdf = true;
        this.retrieveBeratungsmappeActions("PDF");
        this.actionsTitle = "Beratungsmappe erzeugen";
      }
      this.showErzeugen();
    },
    openSignieren() {
      if (!this.signieren) {
        this.loading.getSign = true;
        this.retrieveBeratungsmappeActions("SIGN");
        this.actionsTitle = "Beratungsmappe Signieren";
      }
      this.showSignieren();
    },
    openMail() {
      if (!this.versenden) {
        this.loading.getSend = true;
        this.retrieveBeratungsmappeActions("MAIL");
        this.actionsTitle = "Beratungsmappe versenden";
      }
      this.showVersenden();
    },
    showVersenden() {
      this.erzeugen = false;
      this.versenden = this.versenden ? false : true;
      this.signieren = false;
    },
    showErzeugen() {
      this.erzeugen = this.erzeugen ? false : true;
      this.versenden = false;
      this.signieren = false;
     },
    showSignieren() {
      this.erzeugen = false;
      this.versenden = false;
      this.signieren = this.signieren ? false : true;
    },
    showFileContent(bytes, title) {
      if (!bytes) {
        //this.openModal(this.noDataModal, false);
      } else {
        viewDocument({
          data: bytes,
          filename: title,
          contentType: 'application/pdf',
        })
      }
    },   
    editAntraeg (row) {
      this.selectedAntrag = row.Antrag;

      if (this.isWealthkonzept && row.Antrag === "Erweiterte Geldwäscheprüfung") {
        this.$router.push({path: `/beratung/formulare/antrag/vv/vvgeldwaesche`, query: {beratungsMappeId: this.$route.query.id, antragsdatenId: row.antragsDatenId, antragsnr: row.Id }});
        return
      } else if (this.isFA && row.Antrag === "Erweiterte Geldwäscheprüfung") {
        this.$router.push({path: `/beratung/formulare/antrag/FA/allgemeingeldwaeschepruefung`, query: {beratungsMappeId: this.$route.query.id, antragsdatenId: row.antragsDatenId, antragsnr: row.Id }});
        return
      }

      const antrag = {
        beratungsMappeId: this.$route.query.id,
        bezeichnung: row.Antrag,
        gesellschaft: row.GesellschaftShort,
        antragsdatenId: row.antragsDatenId,
        antragModus: row.AntragModus,
        servicePath: row.servicePath,
      }

      const link = FormUtil.getLoadURL(antrag, this.lagerstelleList);

      if (link) {
        this.$router.push({path: link}).catch(() => {});
      } else {
        this.$router.push({path: '/role-forbidden'});
      }
    },
    startInvestementAdvice() {
      if ( this.positionRows.filter(row => !row.antragBezeichnung).length ) {
        this.$refs.investementAdvice.open();
      } else {
        this.startInvestementAdvice2()
      }
    },
    startInvestementAdvice2() {
      this.$router.push({path:  `/beratung/anlage${this.textEmpehlung}/details?adviceId=-1&mappeId=${this.$route.query.id}` })
    },
    handleInvestmentAdviceAntraegeClick(item) {
      if (item.link ) { 
        item.checked = true;         
        this.$router.push({path: `${item.link}&bmOptChosen=true&beratungsMappeId=${this.$route.query.id}`});
      }
    },
    handleInvestmentAdviceOrderListClick(item) {
      if (item.link/* && !item.checked*/) {
        this.$store.dispatch(INVESTMENT_ADVICE.ACTIONS.SET_INVESTMENT_ADVICE_ORDER, {...this.investmentAdviceOrder,                    
          lagerstelle: item.value                    
        }); 
        item.checked = true;
        let found = this.investmentAdviceOrderList.find(order => order.value === item.value);
        if (found) {
          found.checked = true;
        }
        this.$store.commit(WERTPAPIERORDER.MUTATIONS.RESET_STATE);
        this.$router.push({path: `${item.link}&bmOptChosen=true&beratungsMappeId=${this.$route.query.id}`});
      }
    },
    deleteAntraegeConfirmation(row) {
      this.antraegeToDelete = row;
      this.$refs.deleteAntraegeConfirmation.open();
    },
    closeDeleteAntraegeConfirmation() {
      this.$refs.deleteAntraegeConfirmation.close();
    },
    deleteAntraege(deleteCompletely = false) {
      this.loading.action = true

      const params = {
        antragId: this.antraegeToDelete.Id,
        beratungsMappeID: this.$route.query.id, 
        deleteCompletely: deleteCompletely
      };

      this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_ANTRAG, params)
      .then(() => this.retrieveBeratungsmappeCards())
      .catch(() => this.loading.action = false)

      this.antraegeToDelete = null;
      this.closeDeleteAntraegeConfirmation()
    },
    deletePosition(row) {
      this.$confirmModal({
        title: 'Position löschen',
        message: `Soll die folgende Position wirklich gelöscht werden? "${row.wertpapier}"`,
        labelButtonCancel: 'Abbrechen',
        labelButtonConfirm: 'Löschen',
      }).then(() => {
        const params = {
          positionId: row.id,
          beratungsmappeId: this.$route.query.id
        };

        this.$store.dispatch(BERATUNGSMAPPE_TYPES.ACTIONS.DELETE_POSITION, params)
        .then(() => this.retrieveBeratungsmappeCards());
      })
    },    
    openOrderPosition(row) {
      this.$router.push({path: '/beratung/beratung/erfassungpositionpapierantrag', query: {beratungsMappeId: this.$route.query.id, positionId: row.id, isin: row.isin }});
    },
    handleCardItemClick(card) {
      if(!card) {
        return ;
      }

      if(card.link){
        this.selectedBeratungsType = card.label;
        this.$router.push({path: card.link});
      } else if (card.key) {
        switch(card.key) {
          case 'antrage_box':
            this.showForms = !this.showForms
          break;
          case 'bemerkung_text':
            this.openEditBeratungsmappe()
            break;
          case 'unterschriften':
            this.executeAction('BUTTON_UNTERSCHRIFTEN_ANFORDERN')
            break;
          case 'dialog_grundlagenwissen':
            this.openGrundlagenwissenModal()
            break;
          default: 
            break;
        }
      }
    },
    beratungsmappeAbschliessen() {
      this.loading.close = true
      this.executeAction('BUTTON_SPERREN')
    },   
    beratungsmappeFortsetzen() {
      this.loading.open = true
      this.executeAction('BUTTON_OEFFNEN')
    }, 
    executeAction(action) {
      this.loading.action = true

      const params = makeQueryParam({ beratungsmappeId: this.$route.query.id, action })
        
      let serviceUrl = `/beratungsmappe/executeAction?${params}`;

      axios.post(`${process.env.VUE_APP_API}` + serviceUrl, {}, config)
        .then(response => {
          if (response && response.data) {
            this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(response.data, 'danger'));
          }
          this.retrieveBeratungsmappeCards()
        })
        .catch(() => this.resetLoadingState())
    }, 
    resetLoadingState() {
      Object.keys(this.loading).forEach(k => this.loading[k] = false)
    },
    selectLagerstelle(lagerstelle) {
      if(this.selectedLagerstelle === lagerstelle) return;

      this.selectedLagerstelle = lagerstelle;
    },   
    handleLabelClick(row) {
      if(row.link || row.linkExtraAntrag) {
        this.$router.push(row.link || row.linkExtraAntrag);
      }
    },  
    retrieveAntragList() {
      return this.$store.dispatch(ANTRAG_TYPES.ACTIONS.RETRIEVE_ANTRAG_LIST, {
        showInBeratungsmappe: true,
        beratungsmappeType: this.verwende,
        lagerstelle: this.beratungsmappeCards.lagerstelle,
      });
    },
    openPictureAttach(signoDoc) {
      // For beratungsmappe signing there is only 2 options:
      // 1. There's an url -> gets opened directly
      // 2. There can be additional files attached (pictureAttach)
      // There is no multisign because antraege with subdocuments get listed each
      if (signoDoc.isPictureAttach) {
        this.selectedDoc = signoDoc.signoElement
        setTimeout(() => {
          this.$refs.pictureAttachmentsModal.open();
        }, 100);
      }
    },        
    /**
      * For new customers, the "öffnen" button should open the form in "create mode"
      */
    getFormLink(path, antragsnrIntern) {
        let result = `/beratung/formulare/antrag/${ path === 'vvwvertrag' ? 'vv' : (this.isFA ? 'FA' : 'FK') }/${path}?beratungsMappeId=${this.$route.query.id}`
        if(antragsnrIntern) {
          if(this.beratungsmappeCards.vvwVertrag?.id === antragsnrIntern){
            result = `${result}&antragsdatenId=${antragsnrIntern}`;
          }
          else {
            result = `${result}&antragsnrIntern=${antragsnrIntern}`;
          }
        }
        return result;
    }, 
    getAnlegerprofilLink() {
      if (this.verwendeApSchiene === 'WEALTH_KONZEPT')
        return `/beratung/anlegerprofilWK/person1/auswahl/personenauswahl?beratungsMappeId=${this.$route.query.id}&schiene=WEALTH_KONZEPT`;
      else
        return `/beratung/anlegerprofil/person1/auswahl/personenauswahl?beratungsMappeId=${this.$route.query.id}&schiene=INVESTMENT`;
    },
    getStatusClass(status) {
      switch(status) {
        case 'HINWEIS':
          return { 'color-text': true, };
        case 'WARNUNG':
          return { 'color-warning': true, };
        case 'FEHLER':
          return { 'color-danger': true, };
      }
    },
  },
  mounted() {
  },
  async beforeMount() {
    this.loading.init = true
    await this.retrieveBeratungsmappeCards()
    this.loading.init = true
    await this.retrieveAntragList()
    this.loading.init = false

    if (this.beratungsmappeCards?.expirationMessage) {
      this.$store.dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, 
        buildMessage(this.beratungsmappeCards.expirationMessage, "danger")
      );
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.commit(ANTRAG_TYPES.MUTATIONS.RETRIEVE_ANTRAG_LIST_SUCCESS, {})

    this.$addBreadcrumb({
      label: 'zurück zur Beratungsmappe', 
      to,
      from,
    });

    next()
  },
}
</script>

<style scoped>
.header-cell {
    font-weight: 500;
    padding: 1px 1px;
}
.table-cell {
    box-sizing: border-box;
    padding: 8px 4px;
    border-top: 1px solid #C4C4C4;
    overflow: hidden;
}
div.action-buttons > i {
  font-size: 1.8em;
}
.beratung-edit__main {
  display: flex;
}
.beratung-edit__sidebar {
  flex: 1 0 auto;
  width: 480px;
}
.beratung-edit__content {
  width: calc(92% - 480px);
  margin-left: 28px;
}

.grayed-out {
  color: grey;
}

@media screen and (max-width: 1100px) {
  .beratung-edit__sidebar {
    width: 380px;
  }
  .beratung-edit__content {
    width: calc(92% - 380px);
  }
}

@media screen and (max-width: 870px) {
  .beratung-edit__sidebar {
    width: 300px;
  }
  .beratung-edit__content {
    width: calc(92% - 300px);
  }
}

@media screen and (max-width: 767px) {
  .beratung-edit__main {
    flex-direction: column;
  }

  .beratung-edit__sidebar {
    width: 100%;
  }
  .beratung-edit__content {
    margin-left: 0;
    width: 100%;
  }
}
</style>