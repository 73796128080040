<template>
  <div :class="getClasses" @click="setMinHeight()">
    <div class="dashboard-panel-widget--wrap">
      <div :class="['dashboard-panel-widget__header']">
        <div class="dashboard-panel-widget__title box__title" @click="doEmitClick('titleClick')">
          <component v-if="titleComponent" :is="titleComponent" :name="widget && widget.name" :title="title" />
          <template v-else-if="title">
            {{ title }}
          </template>
        </div>
        <div v-if="showToolbar" class="dashboard-panel-widget__toolbar">
          <button type="button" :class="[
            'dashboard-panel-widget__toolbar--order',
            'btn-clear',
            'clickable',
            'd-lg-block',
          ]" data-sortable-item-handler @click="doEmitClick()" key="widget-order" v-if="!isSmallOrMediumScreen" alt="Icon für das Verschieben eines Elements">
            <PhArrowsOutCardinal :size="20" alt="Icon für das Verschieben eines Elements"/>
          </button>

          <button type="button" :class="[
            'dashboard-panel-widget__toolbar--order',
            'btn-clear',
            'd-block',
            { 'd-lg-none': isLargeScreen },
          ]" @click="doEmitClick('titleClick')" key="widget-navigate" v-if="isNavigable" alt="Icon zur Navigation zum Feature">
            <PhCaretRight :size="20" alt="Icon zur Navigation zum Feature"/>
          </button>

          <BaseContextMenu class="dashboard-panel-widget__base-context-menu" :class="[
            {
              'has-only-change-size-action':
                !widgetActions.length && !isRemovable,
            },
            'd-none',
            'd-lg-block',
          ]" ref="dropdownMenu" containerClass="dashboard-panel-widget__dropdown-menu" key="widget-dropdown-menu"
            v-if="isLargeScreen">
            <template v-if="widgetActions.length">
              <template v-for="(action, index) in widgetActions">
                <ContextMenuItem v-if="isActionVisible(action)" :key="index" @click="$emit('executeAction', action)">
                  <component v-if="actionHasIcon(action)" :is="actionIcon(action)" :size="16" class="mr-1" /><span>{{
                    action.legend.label }}</span>
                </ContextMenuItem>
              </template>
            </template>

            <ContextMenuItem v-if="!isMasonry && !is3Columns"
              class="dashboard-panel-widget__dropdown-menu--change-size">
              <div class="d-flex justify-content-between align-items-center">
                <span>Größe anpassen:</span>
                <ComboBox data-dropdown-not-close :value="size" :values="sizes" @change="changeSize($event)" ariaLabel="Größe anpassen" />
              </div>
            </ContextMenuItem>

            <ContextMenuItem v-if="overrideCanEdit && isLargeScreen" @click="doEmitClick('bearbeitenClick')">Bearbeiten
            </ContextMenuItem>
            <ContextMenuItem v-if="isRemovable" @click="openRemoveWidgetModal()">löschen</ContextMenuItem>
          </BaseContextMenu>
        </div>
      </div>
      <div class="dashboard-panel-widget__content" @click="doEmitClick()">
        <slot></slot>
      </div>

      <BaseModal ref="removeWidgetModal" :modalTitle="`${title} löschen`" size="sm" @onConfirmButton="$emit('remove')">
        <template #default>
          Soll die "{{ title }}" wirklich gelöscht werden?
        </template>
      </BaseModal>
    </div>
  </div>
</template>

<script>
import {
  PhDotsThreeVertical,
  PhArrowsOutCardinal,
  PhFrameCorners,
  PhTrash,
  PhCaretRight,
} from "phosphor-vue";
import * as phosphor from "phosphor-vue";
import BaseDropdownMenu from "@/components/core/BaseDropdownMenu.vue";
import BaseModal from "@/components/core/BaseModal.vue";
import ComboBox from "@/components/core/forms/ComboBox.vue";
import BaseContextMenu from "@/components/core/BaseContextMenu.vue";
import ContextMenuItem from "@/components/core/base-context-menu/ContextMenuItem.vue";
import BaseButton from "@/components/core/BaseButton.vue";
import { mapGetters } from "vuex";
import CORE_TYPES from "@/store/core/types";
import BRIDGE_TYPES from "@/store/bridge/types";

const SIZES = [50, 100];
const SIZE_DEFAULT = 50;

export default {
  name: "DashboardPanelWidget",
  props: {
    canEdit: {
      type: Boolean,
      default: false,
    },
    isCardWithFlexibleList: {
      type: Boolean,
      default: false,
    },
    isMasonry: {
      type: Boolean,
      default: false,
    },
    is3Columns: {
      type: Boolean,
      default: false,
    },
    widget: {
      type: Object,
      default: () => ({}),
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
    actions: {
      type: Array,
      default: () => [],
    },
    ariaDescription: {
      type: String,
      default: 'Icon für das Verschieben eines Elements'
    },
  },
  data() {
    return {
      sizes: SIZES,
    };
  },
  computed: {
    ...mapGetters({
      isSmallScreen: CORE_TYPES.GETTERS.IS_SMALL_SCREEN,
      screenSize: CORE_TYPES.GETTERS.SCREEN_WIDTH,
      isMobileNativeContext: BRIDGE_TYPES.GETTERS.IS_MOBILE_NATIVE_CONTEXT,
    }),
    isMediumScreen() {
      return this.screenSize === "md";
    },
    isSmallOrMediumScreen() {
      return this.isSmallScreen || this.isMediumScreen;
    },
    isLargeScreen() {
      return this.screenSize === "lg" || this.screenSize === "xl";
    },
    title() {
      return this.widget?.title || "";
    },
    isNavigable() {
      return (
        this.widget?.isNavigable === undefined ||
        this.widget?.isNavigable === true
      );
    },
    overrideCanEdit() {
      return this.widget?.alwaysEditable;
    },
    overrideDefaultAction() {
      return this.widget?.overrideDefaultAction;
    },
    titleComponent() {
      return this.widget?.titleComponent;
    },
    size() {
      return this.widget?.size || SIZE_DEFAULT;
    },
    sizeClass() {
      return `dashboard-panel-widget__size-${this.size}`;
    },
    widgetActions() {
      const widgetActions = this.widget?.actions || [];
      return [...widgetActions, ...this.actions];
    },
    isRemovable() {
      return "removable" in this.widget ? this.widget.removable() : true;
    },
    getClasses() {
      const classes = [this.sizeClass, "box__container"];
      //this parameter doesn't exist as a predefined template, so here we need to explicitlly check for 'false' value because the default behavior is add the class 'box__container'
      if (this.widget?.displayBoxContainer === false) {
        classes.pop();
      }
      if (this.isMasonry) {
        classes.push("dashboard-masonry-panel-widget");
      } else if (this.is3Columns) {
        classes.push("dashboard-three-columns-panel-widget");
      } else {
        classes.push("dashboard-panel-widget");
      }
      return classes;
    },
  },
  methods: {
    /**
     * It is to avoid scroll to top, if the widget content change
     */
    setMinHeight() {
      const widgetContainer = this.$el;
      if (!widgetContainer) return;

      const { clientHeight } = widgetContainer;
      widgetContainer.style.minHeight = `${clientHeight}px`;
      this.$nextTick(() => (widgetContainer.style.minHeight = null));
    },
    isActionVisible(action) {
      return "visible" in action ? action.visible() : true;
    },
    openRemoveWidgetModal() {
      this.$refs.removeWidgetModal.open();
    },
    changeSize(size) {
      this.$refs.dropdownMenu.close();
      this.$emit("changeSize", size);
    },
    actionHasIcon(action) {
      return !!action?.legend?.icon;
    },
    actionIcon(action) {
      const icon = action?.legend?.icon;
      if (!icon) return;
      if (typeof icon === "string") {
        return phosphor[icon];
      }
      return icon;
    },
    doEmitClick(event) {
      if (this.isSmallScreen) {
        if (
          (event === "titleClick" && this.isNavigable) ||
          !this.overrideDefaultAction
        ) {
          this.$emit("executeAction", {
            action: "BEARBEITEN_APP",
            widget: this.widget,
          });
        }
      }
      if (event === "bearbeitenClick") {
        this.$emit("executeAction", {
          action: "BEARBEITEN",
          widget: this.widget,
        });
      }
    },
  },
  components: {
    PhDotsThreeVertical,
    PhArrowsOutCardinal,
    PhFrameCorners,
    PhTrash,
    PhCaretRight,
    BaseDropdownMenu,
    BaseModal,
    ComboBox,
    BaseContextMenu,
    ContextMenuItem,
    BaseButton,
  },
};
</script>

<style scoped lang="scss">
$sizes: 50 100;

.dashboard-panel-widget__title {
  flex: 1 1 auto;
  margin: 0;
  word-break: break-word;
  font-size: 1rem;
}

.clickable-mobile-only {
  pointer-events: none;

  @media all and (max-width: 467px) {
    pointer-events: all;
  }
}

.dashboard-panel-widget {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;

  @each $size in $sizes {
    &.dashboard-panel-widget__size-#{floor($size)} {
      width: calc(#{$size}% - 24px);
    }
  }

  .dashboard-panel-widget__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 16px;

    .dashboard-panel-widget__toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 0 auto;
      margin: 0 0 0 4px;

      >* {
        margin: 0 0 0 4px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .dashboard-panel-widget__content {
    flex: 1 0 auto;
    align-items: stretch;
  }
}


.dashboard-panel-widget--wrap {
  height: 100%;
  display: flex;
  flex-direction: column;
}


.dashboard-masonry-panel-widget {
  margin: 0 0 1em;
  width: 100%;

  &.sortable-list__item-placeholder {
    display: inline-block;
  }

  .dashboard-panel-widget--wrap {
    display: inline-block;
    width: 100%;
  }

  .dashboard-panel-widget__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 16px;

    .dashboard-panel-widget__toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 0 auto;
      margin: 0 0 0 4px;

      >* {
        margin: 0 0 0 4px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .dashboard-panel-widget__content {
    flex: 1 0 auto;
    align-items: stretch;
  }
}

.dashboard-three-columns-panel-widget {
  margin: 0 0 1em;
  width: 100%;

  &.sortable-list__item-placeholder {
    display: inline-block;
  }

  .dashboard-panel-widget--wrap {
    display: inline-block;
    width: 100%;
  }

  .dashboard-panel-widget__header {
    display: flex;
    justify-content: space-between;
    margin: 0 0 16px;

    .dashboard-panel-widget__toolbar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex: 1 0 auto;
      margin: 0 0 0 4px;

      >* {
        margin: 0 0 0 4px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .dashboard-panel-widget__content {
    flex: 1 0 auto;
    align-items: stretch;
  }
}

/** Responsive */
@media screen and (max-width: 767px) {
  .dashboard-panel-widget__title {
    margin: 0 0 8px;
  }
}

/**
 * Extra Large Screen (XXL)
 */
@media screen and (min-width: 3840px) {
  .app--is-test-user-context {
    .dashboard-panel-widget {
      &.dashboard-panel-widget__size-50 {
        width: calc(25% - 24px);
      }

      &.dashboard-panel-widget__size-100 {
        width: calc(50% - 24px);
      }
    }
  }
}
</style>

<!-- GLOBAL STYLE -->
<style lang="scss">
.dashboard-panel-widget {
  margin: 0 12px 24px;
  width: calc(50% - 24px);

  &.box__container {
    .dashboard-panel-widget__content {

      .box__container,
      .box__shadow {
        background: none;
        box-shadow: none;
        padding: 0;
        margin: 0;
        border-radius: 0;
      }
    }
  }
}

.dashboard-panel-widget__base-context-menu {
  display: flex;
  align-items: center;

  .dropdown-menu--hook-target {
    display: flex;
    align-items: center;
  }
}

.dashboard-panel-widget__dropdown-menu {
  &.positioned-bottom-right {
    margin-left: 14px;
  }
}

.button--link {
  color: var(--color-link);
  transition: color 0.3s ease;

  &:hover {
    color: var(--color-primary);
  }
}

@media screen and (max-width: 768px) {
  .dashboard-panel-widget__base-context-menu {
    &.has-only-change-size-action {
      display: none !important;
    }
  }

  .dashboard-panel-widget__dropdown-menu--change-size {
    display: none !important;
  }
}

@media screen and (max-width: 1200px) {
  .dashboard-panel-widget {
    width: 100% !important;

    .dashboard-panel-widget__toolbar {
      .dashboard-panel-widget__toolbar--order {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .dashboard-panel-widget {
    margin-bottom: 12px;
  }

  .dashboard-panel-widget__header {
    margin: 0 0 8px !important;
  }
}

/**
 * Extra Large Screen (XXL)
 */
@media screen and (min-width: 3840px) {
  .app--is-test-user-context {
    .dashboard-panel-widget {
      width: calc(25% - 24px);
    }
  }
}
</style>
