var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row settings-row" }, [
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c("div", [_vm._v("Jahre")]),
          _c("ComboBox", {
            attrs: { value: _vm.timeSpan, values: _vm.AMOUNT_YEARS_OPTIONS },
            on: { change: _vm.onChangeTimeSpan }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c("InputField", {
            attrs: { label: "Startbetrag", type: "currency" },
            model: {
              value: _vm.initialInvestment,
              callback: function($$v) {
                _vm.initialInvestment = $$v
              },
              expression: "initialInvestment"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "col-4" },
        [
          _c(
            "BaseButton",
            {
              staticStyle: { "max-height": "50px" },
              on: {
                click: function($event) {
                  return _vm.onClickSimulate()
                }
              }
            },
            [_vm._v("Simulieren")]
          )
        ],
        1
      )
    ]),
    _c("hr"),
    _c(
      "div",
      [
        _c("LineChart", {
          ref: "lineChart",
          attrs: {
            chartSeries: _vm.chartSeries,
            isArea: false,
            customTooltipFormatter: _vm.customTooltipFormatter,
            height: _vm.height,
            format: _vm.format,
            isLegend: false,
            gridLeft: 75,
            chartOptions: _vm.CHART_OPTIONS
          }
        }),
        _vm._t("beforLegend"),
        _vm.lineChart && _vm.chartSeries.length
          ? _c("ChartLegend", {
              attrs: {
                showAsTable: false,
                chartSeries: _vm.chartSeries,
                lineChart: _vm.lineChart
              }
            })
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }