import FONDSINFO_TYPES from './types';

export default {
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_DETAILS](state) {
    return state.fundsDetail;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_ALGEMEIN](state) {
    return state.allgemeinDescription;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_AUSSCHUTTUNG](state) {
    return state.ausschuttung;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_STEUERLICHES_ERGEBNIS](state) {
    return state.steuerlichesErgebnis;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_ERBSCHAFTSSTEUERLICHE_WERTE](state) {
    return state.erbschaftssteuerlicheWerte;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_EINZAHLUNGEN](state) {
    return state.einzahlungen;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_MITTELHERKUNFT](state) {
    return state.mittelherkunft;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_INVESTITIONSKOSTEN](state) {
    return state.investitionskosten;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_DOKUMENTE](state) {
    return state.dokumente;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_PERFORMANCE](state) {
    return state.performance;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_RISIKO](state) {
    return state.risiko;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_UNTERLAGEN](state) {
    return state.unterlagen;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_UNTERLAGEN_LINKS](state) {
    return state.unterlagenLinks;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_RISK_CHART](state) {
    return state.riskChart;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_PERFORMANCE_CHART](state) {
    return state.performanceChart;
  },  
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_MONTECARLO_CHART](state) {
    return state.montecarloChart;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_PURCHASE](state) {
    return state.purchase;
  },
  [FONDSINFO_TYPES.GETTERS.FONDSINFO_RENDITE_CHART](state) {
    return state.renditeChart;
  },
}