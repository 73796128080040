<template>
  <div>
    <div class="box__container">
      <div class="row">
        <div class="col-12" v-if="canViewCourtageStep">
          <InputField
            id="courtageBemerkung"
            :label="isIntern && !isMaklerzugang ? 'Bemerkung zur Courtage (Sichtbar für Vermittler)' : 'Bemerkung zur Courtage'"
            :disabled="cannotEditAPBemerkung"
            v-model="versicherungenDetails.insurance.courtageBemerkung"
            @input="dataChanged('courtageBemerkung', $event)"
          />
        </div>
      </div>    
      <div class="row">
        <div class="col-12">
          <InputField
            id="produktbezeichnung"
            label="Produktbezeichnung"
            v-model="versicherungenDetails.produktbez"
            disabled
          />
        </div>
      </div>
      <div class="row" v-if="isIntern">
        <div class="col-6">
          <InputCheckBoxItem
            label="ThinkSurance Vergleichsrechner"
            v-model="form.thinksuranceVergleich"
          />
        </div>
      </div>
      <div class="row" v-if="isIntern">
        <div class="col-6">
          <InputCheckBoxItem
            label="ThinkSurance Angebotserstellung"
            v-model="form.thinksuranceAngebot"
          />
        </div>
      </div>
      <div class="row" v-if="editable">
        <div class="col 12">
          <BaseButton @click="openNewBuchung()">Neue</BaseButton>
        </div>
      </div>
    </div>

    <div class="box__container">
        <Table
          v-if="!loading && rows.length"
          tableId="a97811c0-a7f2-4ac8-abb1-7dde57ab6810"
          title="Abrechnungen"
          :headers="headers"
          :rows="rows"
          :rowsPerPage="10"
          rowId="buchungsnr"
          v-model="selectedRowsAbrechnungTable"
          :exportConfig="{roottext: 'Abrechnungen'}"
          @click-buchungsnr="openVerteilungAufDieStruktur($event)"
          @action-BEARBEITEN="openEditBuchung"
          @action-STRUKTUR="addNewStruktur"
          @action-DELETE="deleteAbrechnung($event)"
          @action-COPY="copyVersicherungBuchung($event)"
          @action-STORNO="stornierenVersicherungBuchung($event)"
        />
        <GhostLoading v-else-if="loading" type="table" />
        <NoData v-else content="Es wurden keine Einträge gefunden" />
    </div>

    <div class="box__container" v-if="selectedMasterItem">
      <Table
        v-if="!loadingDetails && detailRows.length"
        :title="'Buchungsnr ' + selectedMasterItem.buchungsnr + ' - Verteilung der Abrechnung auf die Struktur'"
        :headers="detailHeaders"
        :rows="detailRows"
        :rowsPerPage="10"
        v-model="detailSelectedRows"
        @click-lfdNr="openEditStruktur"
        @action-DELETE="deleteStruktur($event)"
      />

      <GhostLoading v-else-if="loadingDetails" type="table" />
      <NoData v-else content="Es wurden keine Einträge gefunden" />  
    </div>    

    <BaseModal
      ref="buchungDeleteModal"
      size="sm"
      modalTitle="Buchung löschen"
      labelButtonConfirm="Löschen"
      @onConfirmButton="deleteBuchungItem()"
    >
      <template #default>
        Soll die Buchung {{ abrechnungItemToDelete.buchungsnr }} wirklich gelöscht werden?
      </template>
    </BaseModal>

    <BaseModal
      ref="buchungCopyModal"
      size="sm"
      modalTitle="Buchung kopieren"
      labelButtonConfirm="kopieren"
      @onConfirmButton="copyBuchungItem()"
    >
      <template #default>
        <h3 class="color-danger">ACHTUNG!</h3>
        <p>Die Courtagesätze werden gemäß der Verteilung des Originals ebenfalls kopiert!</p>
        <p>Dies gilt allerdings nur solange Sie die Verteilung nicht neu berechnen lassen!</p>
        <p>Möchten Sie mit dem Kopieren fortfahren?</p>
      </template>
    </BaseModal>

    <BaseModal
      ref="buchungStornierenModal"
      size="sm"
      modalTitle="Buchung Stornieren"
      labelButtonConfirm="Stornieren"
      @onConfirmButton="stornierenBuchungItem()"
    >
      <template #default>
        <h3 class="color-danger">ACHTUNG!</h3>
        <p>Die Courtagesätze werden gemäß der Verteilung des Originals ebenfalls kopiert und ins Minus gesetzt!</p>
        <p>Dies gilt allerdings nur solange Sie die Verteilung nicht neu berechnen lassen!</p>
        <p>Möchten Sie mit dem Stornieren fortfahren?</p>
      </template>
    </BaseModal>

    <BaseModal
      ref="verteilungStrukturModal"
      size="sm"
      modalTitle="Verteilung auf die Struktur"
      :showDefaultButtons="false"
    >
      <template #default>
        <div class="row" v-if="isNewStruktur">
          <div class="col-12">
            <ComboBox
              id="previousLfdNr"
              label="Lfd. Nummer"
              v-model="detailForm.previousLfdNr"
              :values="comboboxValues"
              :validateUntouched="true"
            />
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-12">
            <InputField
              id="lfdNr"
              label="Lfd. Nummer"
              v-model="detailForm.lfdNr"
              disabled
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputField
              label="Vermittlernummer"
              v-model="detailForm.maklernr"
              validateUntouched
              @input="findMaklerDataDebounce($event)"
              @onBlur="checkIfMaklerDataIsValid()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <InputField
              id="provSatz"
              label="Courtagesatz"
              type="percent"
              v-model="detailForm.provSatz"
              :precision="2"
              :validateUntouched="true"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <BaseButton isSecondary @click="closeEditStruktur()" class="mr-3">Abbrechen</BaseButton>
        <BaseButton :disabled="validation.isInvalid('detailForm') || isMaklernrInvalid" @click="saveForm(); closeEditStruktur();">Speichern</BaseButton>
      </template>
    </BaseModal>

    <BaseModal
      ref="deleteModal"
      size="sm"
      modalTitle="Provisionssatz löschen"
      labelButtonConfirm="Löschen"
      @onConfirmButton="deleteStrukturItem()"
    >
      <template #default>
        Soll der Datensatz wirklich gelöscht werden?
      </template>
    </BaseModal>    
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import INSURANCE_BUCHUNG_TYPES from "@/store/insuranceBuchung/types";
import CORE_TYPES from '@/store/core/types';
import CUSTOMERDATA_TYPES from '@/store/customerData/types';
import BaseModal from '@/components/core/BaseModal.vue';
import BaseButton from "@/components/core/BaseButton.vue";
import Table from '@/components/table2/Table.vue';
import {TextColumn, PercentageColumn, ActionColumn, LoadingAction, SimpleAction, NumberColumn } from "@/components/table2/table_util.js";
import {PhTrash, PhArrowsLeftRight, PhCopy, PhArrowBendDoubleUpRight, PhPencilLine } from 'phosphor-vue';
import GhostLoading from '@/components/core/loading/GhostLoading.vue';
import InputCheckBoxItem from '@/components/core/forms/checkbox/InputCheckBoxItem.vue';
import InputField from "@/components/core/forms/InputField.vue";
import { VIEW_ROLES, ROLES } from "@/router/roles";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js';
import NoData from '@/components/core/NoData.vue';
import validator from '@/mixins/validator';
import { required } from '@/mixins/validator/rules';
import { debounce } from '@/helpers/commonfunctions.js';
import ComboBox from '@/components/core/forms/ComboBox.vue';

const PRECISION_2 = 2;
const PRECISION_4 = 4;
const PRECISION = 2;

export default {
  mixins: [validator, InsuranceDetailMixin],
  components: {
    BaseModal,
    BaseButton,
    Table,
    PhTrash,
    PhArrowsLeftRight,
    PhCopy,
    PhArrowBendDoubleUpRight,
    GhostLoading,
    InputCheckBoxItem,
    InputField,
    NoData,
    ComboBox,
  },
  props:{
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      loadingDetails: false, 
      abrechnungItemToDelete: {},
      abrechnungItemToCopy: {},
      deleteLoading: false,
      deleteId: null,
      form: {},
      selectedRowsAbrechnungTable: null,
      detailSelectedRows: null,
      copyLoading: null,
      copyBuchungID: null,
      newAPBemerkung: null,
      selectedBuchungId: null,
      selectedMasterItem: null,
      isNewStruktur: false,
      detailForm: {
        previousLfdNr: null,
        lfdNr: null,
        maklernr: null,
        provSatz: null,
      },
      strukturItemToDelete: {},
      isMaklernrInvalid: false,
    }
  },
  watch: {
    versicherungenDetails(value) {
      this.newAPBemerkung = !value?.insurance?.courtageBemerkung?.length;
    }
  },
  computed: {
    ...mapGetters({
      verteilungStruktur: INSURANCE_BUCHUNG_TYPES.GETTERS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR,
      selectedBuchungDetail: INSURANCE_BUCHUNG_TYPES.GETTERS.SELECTED_INSURANCE_BUCHUNG,
      abrechnungen: VERSICHERUNG_TYPES.GETTERS.INSURANCE_ABRECHNUNGEN,
      versicherungenDetails: VERSICHERUNG_TYPES.GETTERS.INSURANCE_DETAILS,
      isIntern: CORE_TYPES.GETTERS.ORIGINAL_USER_IS_INTERN,
      isMaklerzugang: CORE_TYPES.GETTERS.IS_MAKLERZUGANG,
      isFpPlus: CORE_TYPES.GETTERS.IS_FPP_PLUS,
      isHauptstrukturleitenr: CORE_TYPES.GETTERS.IS_HAUPTSTRUKTURLEITENR,
      hasRoles: CORE_TYPES.GETTERS.HAS_ROLES,
      isFA: CORE_TYPES.GETTERS.IS_FA,
      isFK: CORE_TYPES.GETTERS.IS_FK,
    }),
    detailHeaders() {
      const editableCourtageColumns = [
        TextColumn("maklernr", "Vermittlernr"),
        PercentageColumn("provSatz", "Prov. Satz", PRECISION_4),
        NumberColumn("betrag", "Prov. Betrag", PRECISION_2),
        // TextColumn("aenderung", "Änderung"),
        // NumberColumn("monatsbeitrag", "MB", PRECISION_2),
        NumberColumn("prozentsatz", "Prozent", PRECISION_4),
        NumberColumn("mwst", "MwSt.", PRECISION_2),
        NumberColumn("stornoReserve", "Stornoreserve", PRECISION_2),
        NumberColumn("versicherungseinbehalt", "Vers. Einbehalt", PRECISION_2),
      ];

      const centerColumns = [
          TextColumn("maklernr", "Vermittlernr"),
          NumberColumn("betrag", "Prov. Betrag", PRECISION_2),
          // TextColumn("aenderung", "Änderung"),
          // NumberColumn("monatsbeitrag", "MB", PRECISION_2),
          NumberColumn("mwst", "MwSt.", PRECISION_2),
          NumberColumn("stornoReserve", "Stornoreserve", PRECISION_2),
          NumberColumn("versicherungseinbehalt", "Vers. Einbehalt", PRECISION_2),
        ];

      if(Object.keys(this.selectedBuchungDetail)?.length) {
        const abrechnungenColumns = [
          TextColumn("zeichnungsdatum", "Z.-Datum"),
          TextColumn("courtageart", "Prov. Art"),
          NumberColumn("bewertungssumme", "Bew.Summe", PRECISION_2),
          TextColumn("rechDatum", "Abr. Datum"),
        ]
        editableCourtageColumns.push(...abrechnungenColumns);
        centerColumns.push(...abrechnungenColumns);
      }

      const columns = {
        lockedLeft: [
          TextColumn("lfdNr", "Lfd. Nr.").makeAlwaysVisible(),
        ],
        center: centerColumns,
        lockedRight: this.isIntern ? [ActionColumn("actions", "Aktionen")] : [] ,
      }

      if(this.editable || this.isIntern){
        columns.lockedLeft = [TextColumn("lfdNr", "Lfd. Nr.").makeLink().makeAlwaysVisible()]
        columns.center = editableCourtageColumns;
      }
      return columns;
    },
    detailRows() {
      if (!this.verteilungStruktur) {
          return [];
      }
      const disabled = () => !this.isIntern;

      const actions = [
        LoadingAction("DELETE", PhTrash, "Löschen", disabled, (row) => this.deleteLoading && this.deleteId == row.id),
      ];

      return [...this.verteilungStruktur].map(item => {
        const record = {
          ...item,
          actions,
        };
        record.id = item.lfdNr // necessary to avoid "missing rowId on table warning"
        record.provSatz = (item.provSatz || 0)
        record.betrag = (item.betrag || 0)
        record.monatsbeitrag = (item.monatsbeitrag || 0)
        record.prozentsatz = (item.prozentsatz || 0)
        record.mwst = (item.mwst || 0)
        record.stornoReserve = (item.stornoReserve || 0)
        record.versicherungseinbehalt = (item.versicherungseinbehalt || 0)
        if(Object.keys(this.selectedBuchungDetail)?.length) {
          record.zeichnungsdatum = this.selectedBuchungDetail.zeichnungsdatum
          record.courtageart = this.selectedBuchungDetail.courtageart
          record.bewertungssumme = this.selectedBuchungDetail.bewertungssumme
          record.rechDatum = this.selectedBuchungDetail.rechDatum
        }
        return record;
      });
    },        
    hasSpecialPermission() {
      const customerOrCustomerAsIntern = this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER, VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN]]);
      const notBroker = !this.hasRoles([[VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER]]);
      return customerOrCustomerAsIntern || notBroker;
    },
    headers() {

      const internColumns = [
        TextColumn("zeichnungsdatum", "Z.-Datum"),
        TextColumn("courtageeingDatum", "Prov. Eingang"),
        TextColumn("courtageart", "Prov. Art"),
        PercentageColumn("provisionsSatzProzent", "Prov. Satz %"),
        NumberColumn("bewertungssumme", "Bew.Summe", PRECISION),
        NumberColumn("courtagesumme", "Courtagesumme", PRECISION),
        TextColumn("status", "Status"),
        TextColumn("automatisch", "Auto"),
        TextColumn("rechDatum", "Abr. Datum"),
        TextColumn("istOverhead", "Overhead"),
        TextColumn("userId", "Bearbeiter"),
        TextColumn("bemerkung", "Bemerkung"),
      ];
      
      const editableCourtageColumns = [
        TextColumn("zeichnungsdatum", "Z.-Datum"),
        TextColumn("courtageeingDatum", "Prov. Eingang"),
        TextColumn("courtageart", "Prov. Art"),
        NumberColumn("bewertungssumme", "Bew.Summe",PRECISION),
        NumberColumn("provBetrag", "Prov. Betrag",PRECISION),
        TextColumn("status", "Status"),
        TextColumn("automatisch", "Auto"),
        TextColumn("rechDatum", "Abr. Datum"),
        TextColumn("userId", "Bearbeiter"),
        TextColumn("bemerkung", "Bemerkung"),
      ];

      const columns = {
        center: [
          TextColumn("zeichnungsdatum", "Z.-Datum"),
          // TextColumn("courtageeingDatum", "Prov. Eingang"),
          TextColumn("courtageart", "Prov. Art"),
          NumberColumn("bewertungssumme", "Bew.Summe", PRECISION),
          // NumberColumn("provBetrag", "Prov. Betrag",PRECISION),
          // TextColumn("status", "Status"),
          TextColumn("rechDatum", "Abr. Datum"),
          TextColumn("bemerkung", "Bemerkung"),
        ],
      }

      let lockedLeft = [];
      let lockedRight = [];

      if (this.hasSpecialPermission) {
        lockedLeft = [
          TextColumn("buchungsnr", "Buchungsnr").makeLink().makeAlwaysVisible(),
        ];
        columns.center.splice(1, 0, TextColumn("courtageeingDatum", "Prov. Eingang"))
        columns.center.splice(5, 0, TextColumn("status", "Status"))
      }
      lockedRight = [
        ActionColumn("actions", "Aktionen"),
      ];

      columns['lockedLeft'] = lockedLeft;
      columns['lockedRight'] = lockedRight;

      if(this.isIntern) {
        columns.center = internColumns;
      } else
      if(this.editable){
        columns.center = editableCourtageColumns;
      }
      return columns;
    },
    rows() {
      if (!this.abrechnungen) {
          return [];
      }
      const disabled = (row) => row.status < 3 && row.allowNewBuchung && !this.isIntern;

      const getActions = (row) => {
        const actions = [
          SimpleAction("STRUKTUR", PhArrowsLeftRight , "Verteilung auf die Struktur"),
          SimpleAction("BEARBEITEN", PhPencilLine, "Bearbeiten"),
        ];
        if(this.isIntern) {
          actions.unshift(LoadingAction("DELETE", PhTrash, "Löschen", disabled(row), (row) => this.deleteLoading && this.deleteId == row.id));
        }
        if(this.editable) {
          actions.push(
            LoadingAction("COPY", PhCopy , "Copy", () => row.allowNewBuchung),
            LoadingAction("STORNO", PhArrowBendDoubleUpRight , "Stornieren", () => row.allowNewBuchung)
          )
        }
        return actions;
      }

      return [...this.abrechnungen].map(item => {
        const record = {
          ...item,
          actions: getActions(item),
        };
        record.bewertungssumme = (item.bewertungssumme || 0).toFixed(PRECISION);
        record.courtagesumme = (item.courtagesumme || 0).toFixed(PRECISION);
        record.istOverhead = item.istOverhead === true ? 'Ja' : 'Nein';
        return record;
      });
    },
    canViewCourtageStep(){
      return this.hasRoles([
        [VIEW_ROLES.VIEW_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_BROKER],
        [VIEW_ROLES.VIEW_CUSTOMER_AS_INTERN],
      ]);
    },
    cannotEditAPBemerkung() {
      // Das Feld darf initial von allen Usern befüllt werden, gelöscht oder geändert aber nur von internen Usern oder Hauptstrukturleitenr (z.B. 72103 ind Deutschland).
      return !this.newAPBemerkung && (!this.isIntern && !this.isHauptstrukturleitenr);
    },
    comboboxValues() {
      if(this.verteilungStruktur?.length){
        return [...this.verteilungStruktur].map(item => ({label: item.lfdNr, value: item.lfdNr}))
      }
      return [];
    },    
  },
  methods: {
    deleteStruktur(item){
      if(item){
        this.strukturItemToDelete = {...item};
        this.$refs.deleteModal.open();
      }
    },    
    saveForm() {
      const form = {
        ...this.detailForm,
        vertragId: this.$route.params.id,
      };

      if(!this.isNewStruktur || form.lfdNr){
        this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_UPDATE, form);
        return;
      }
      this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_SAVE, form);
    },
    closeEditStruktur() {
      this.isNewStruktur = false;
      this.$refs.verteilungStrukturModal.close();
    },      
    checkIfMaklerDataIsValid(zubringerData) {
      const FORM_MAKLERNR_PATH = 'detailForm.maklernr';
      this.validation.reset(FORM_MAKLERNR_PATH);
      this.isMaklernrInvalid = false;

      if(!zubringerData && !this.detailForm.maklernr) {
        this.isMaklernrInvalid = true;
        this.$pushErrors(FORM_MAKLERNR_PATH);
      }
    },
    findMaklerDataDebounce: debounce(async function findMaklerData(maklernr) {
      const MAKLERNR_LENGTH = 5;
      try {
        if(maklernr?.length === MAKLERNR_LENGTH) {
          const zubringerData = await this.$store.dispatch(CUSTOMERDATA_TYPES.ACTIONS.GET_ZUBRINGER_DATA, { maklernr });
          this.checkIfMaklerDataIsValid(zubringerData);
          if(!this.isMaklernrInvalid){
            this.detailForm.maklernr = zubringerData?.maklernr;
          }
        }
      } catch(e) { }

    }),    
    deleteStrukturItem(){
      if(this.strukturItemToDelete?.lfdNr) {
        this.deleteLoading = true;
        this.deleteId = this.strukturItemToDelete?.lfdNr;
        this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR_DELETE, {
          lfdNr: this.strukturItemToDelete?.lfdNr,
          buchungsnr: this.selectedMasterItem.buchungsnr,
        }).then(() => {
          this.deleteLoading = false;
          this.deleteId = null;
          this.abrechnungItemToDelete = null;
        });
      }
    },    
    openEditStruktur(event) {
      this.isNewStruktur = false;
      this.detailForm = {...event}
      this.$refs.verteilungStrukturModal.open();
    }, 
    addNewStruktur(event) {
      this.isNewStruktur = true;
      this.detailForm = {...event, buchungId: event.buchungsnr}
      this.$refs.verteilungStrukturModal.open();
    },    
    openNewBuchung(){
      this.$router.push(`/home/versicherungen/insurance-group/versicherung-buchungs/${this.$route.params.id}/neuesBuchung`);
    },
    openEditBuchung(item) {
      this.$router.push(`/home/versicherungen/insurance-group/versicherung-buchungs/${this.$route.params.id}/${item.buchungsnr}`);
    },
    openVerteilungAufDieStruktur(item){
      this.selectedMasterItem = item;
      this.loadingDetails = true;
      this.$store.dispatch(INSURANCE_BUCHUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_VERTEILUNG_STRUKTUR, {buchungId: item.buchungsnr})
      .then(response => {
        this.loadingDetails = false;
      });      
    },
    deleteAbrechnung(event){
      if(event){
        this.openModal('delete', event);
      }
    },
    deleteBuchungItem() {
      if(this.abrechnungItemToDelete?.buchungsnr) {
        this.deleteLoading = true;
        this.deleteId = this.abrechnungItemToDelete?.buchungsnr;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_DELETE, {
          buchungsnr: this.abrechnungItemToDelete?.buchungsnr,
        }).then(() => {
          this.deleteLoading = false;
          this.deleteId = null;
          this.abrechnungItemToDelete = null;

          this.openVerteilungAufDieStruktur(this.selectedMasterItem.buchungsnr);
        });
      }
    },
    openModal(modalName, item) {
      
      if('delete' === modalName) {
        this.abrechnungItemToDelete = {
          ...item,
        };
        this.$refs.buchungDeleteModal.open();
      }
      if('copy' === modalName) {
        this.abrechnungItemToCopy = {
          ...item,
        };
        this.$refs.buchungCopyModal.open();
      }
      if('storno' === modalName) {
        this.abrechnungItemToCopy = {
          ...item,
        };
        this.$refs.buchungStornierenModal.open();
      }
    },
    copyVersicherungBuchung(event) {
      if(event){
        this.openModal('copy', event);
      }
    },
    stornierenVersicherungBuchung(event) {
      if(event){
        this.openModal('storno', event);
      }
    },
    copyBuchungItem() {
      if(this.abrechnungItemToCopy?.buchungsnr) {
        this.copyLoading = true;
        this.copyBuchungID = this.abrechnungItemToCopy?.buchungsnr;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_COPY, {
          vertragId: this.$route.params.id,
          buchungsnr: this.abrechnungItemToCopy?.buchungsnr,
        }).then(() => {
          this.copyLoading = false;
          this.copyBuchungID = null;
          this.abrechnungItemToCopy = null;
        });
      }
    },
    stornierenBuchungItem() {
      if(this.abrechnungItemToCopy?.buchungsnr) {
        this.copyLoading = true;
        this.copyBuchungID = this.abrechnungItemToCopy?.buchungsnr;
        this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.INSURANCE_BUCHUNG_STORNO, {
          vertragId: this.$route.params.id,
          buchungsnr: this.abrechnungItemToCopy?.buchungsnr,
        }).then(() => {
          this.copyLoading = false;
          this.copyBuchungID = null;
          this.abrechnungItemToCopy = null;
        });
      }
    },
  },
  validators: {
    detailForm: {
      previousLfdNr: true ? [required('Lfd. Nummer ist erforderlich!')] : [],
      maklernr: [required('Vermittlernummer ist erforderlich!')],
      provSatz: [required('Courtagesatz ist erforderlich!')],
    }
  },  
  mounted() {
    this.loading = true;
    this.$store.dispatch(VERSICHERUNG_TYPES.ACTIONS.FIND_ALL_INSURANCE_ABRECHNUNGEN, {vertragId: this.$route.params.id})
    .then(response => {
      this.loading = false;
    });

    this.$store.commit(INSURANCE_BUCHUNG_TYPES.MUTATIONS.SELECTED_INSURANCE_BUCHUNG, {});
  },

}
</script>
