var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "label",
    {
      staticClass: "radiobox__container",
      class: {
        "radiobox__check--disabled": _vm.disabled,
        "filter-style": _vm.isFilter
      },
      attrs: { "aria-label": "Radiobutton" }
    },
    [
      _c("span", { staticClass: "radiobox__body" }, [
        _c("input", {
          attrs: { type: "radio", name: _vm.name, disabled: _vm.disabled },
          domProps: { value: _vm.value, checked: _vm.checked },
          on: {
            change: function($event) {
              return _vm.$emit("change", _vm.value)
            }
          }
        }),
        _c("span", {
          staticClass: "radiobox__checkmark",
          class: {
            "radiobox__check--disabled": _vm.disabled,
            "top-space": _vm.isFilter
          }
        })
      ]),
      _c("span", {
        staticClass: "radiobox__label",
        class: {
          bold: _vm.isBold,
          "radiobox__check--disabled": _vm.disabled
        },
        style: _vm.itemStyle,
        domProps: { innerHTML: _vm._s(_vm.sanitize(_vm.label)) },
        on: {
          click: function($event) {
            _vm.disabled ? null : _vm.$emit("change", _vm.value)
          }
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }