import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

const step11 = [{ text: 'In den wirtschaftlichen Angaben hinterlegen Sie, ob Sie die Anlageentscheidung für sich selbst (eigene Rechnung) oder für andere treffen z.B. '
    + 'als gesetzlicher Vertreter für Kinder oder Firmen (fremde Rechnung). Aus steuerlichen Gründen ist es wichtig, den ersten Wohnsitz einzutragen.'}];
const step12 = [{ text: ' Stehen Änderungen in Bezug auf Ihre familiäre oder berufliche Situation an, so können Sie diese hier angeben. Dabei '
    + 'könnte es sich z.B. um Heirat, Scheidung, Ruhestand oder Aufnahmeeiner selbständigen Tätigkeit handeln. Bitte geben Sie dazu ebenfalls an, '
    + 'wann diese Änderung voraussichtlich eintreten wird.' }];
const step13 = [{ label: 'Vermögensverwaltung', text: 'eine Vermögensverwaltung trifft Anlageentscheidungen eigenständig für Sie in Rahmen Ihrer Vorgaben.'},
    { label: 'Anlageberatung', text: 'ein persönlicher Berater berät Sie bei Ihren Anlageentscheidungen, setzt diese Vorschläge aber nicht für Sie um.' },
    { label: 'Beratungsfreie Anlagegeschäfte', text: 'Sie informieren sich eigenständig und treffen Ihre Anlageentscheidungen selbst.' }];
const step21 = [{ label: 'Mit Kenntnissen', text: 'ist das theoretische Wissen zu den genannten Produkten gemeint'},
    { label: 'Die Erfahrungen', text: 'beziehen sich auf die tatsächlich gesammelten Erfahrungen durch die Geldanlage in die genannten Produkte.' },
    { text: 'Handelt es sich um ein Minderjährigen- bzw. Firmendepot, werden die Kenntnisse und Erfahrungen der gesetzlichen Vertreter (§166 BGB) eingetragen.' },
    { label: 'Betrachtungszeitraum', text: 'Der Betrachtungszeitraum muss mindestens drei Jahre betragen. Sollte Ihr Zeitraum '
    + 'kürzer sein, lassen Sie bitte trotzdem die drei Jahre stehen. Haben Sie schon länger Erfahrungen sammeln können, '
    + 'tragen Sie den entsprechenden Zeitraum ein.'},
    { label: 'Wertpapiergeschäfte aus Kreditbasis', text: 'Falls Sie Geschäfte auf Kreditbasis, also zu Lasten z.B. eines Disporahmens oder einer Kreditlinie '
    + 'getätigt haben, so geben Sie dies hier bitte an.' } ];
const step2Card = [{ text: 'Geben Sie hier bitte an, wie hoch Sie Ihre Kenntnisse und Erfahrungen in dem jeweiligen Bereich einschätzen und welche '
    + 'Geldbeträge Sie mit wie vielen Transaktionen getätigt haben. '
    + 'Bitte achten Sie darauf, dass bei einem Geldbetrag auch Transaktionen angegeben werden müssen. Sie können diese Daten '
    + 'dann auf alle weiteren angehakten Anlageformen übernehmen oder einzeln pro Anlageform ausfüllen.' }];
const step51 = [{ text: 'Lesen Sie die näheren Beschreibungen der Risikotypen und bedenken Sie den Zusammenhang mit der '
    + 'gewünschten Rendite, Laufzeit und Verfügbarkeit! Höhere Risikotypen haben eine höhere Schwankungsbreite (Volatilität), niedrige eine geringere.'}];
const step61 = [{ text: 'Hier können Sie die Auswahl welche Risikoklassen bei Ihrem Angebot enthalten sein sollen einschränken, d.h. wenn Sie '
    + 'z.B. eine Anlage in SRI7 (höchstes Risiko) nicht wünsche, so entfernen Sie bitte diesen Haken. Bitte machen Sie auch eine Angabe zu geplanten finanziellen Belastungen.'}];
const anlageZieleHinweis = `Mind. aktueller Depotwert zzgl. Anlagebetrag einmalig: Erfassen Sie hier bitte die Anlageziele für mindestens 
    das aktuelle Depotguthaben zzgl. eventueller Neuanlagesummen.`
const anlageZieleHinweis2 = `Anlagebetrag ratierlich inkl. bestehender 
    Sparpläne: Erfassen Sie hier bitte die Anlageziele für mindestens den bestehenden Sparplan zzgl. eventueller neuer Sparpläne.`
const stepAnlageziele = [
    { text: anlageZieleHinweis},
    { text: anlageZieleHinweis2},
    { text: 'Sie können bis zu fünf Zeithorizonte mit unterschiedlichen Anlagebeträgen, Anlagezielen und Bereitschaft Verluste zu tragen auswählen.'},
    {text: 'Es muss entweder ein einmaliger oder ratierlicher Betrag eingegeben werden, sonst kann nicht gespeichert werden.'}];
const stepAnlagezieleWK = [
    { text: anlageZieleHinweis},
    { text: anlageZieleHinweis2},
    { text: 'Sie können bis zu drei Zeithorizonte mit unterschiedlichen Anlagebeträgen, Anlagezielen und Bereitschaft Verluste zu tragen auswählen.'},
    {text: 'Sehr kurzfristig = unter 1 Jahr, kurzfristig = bis 3 Jahre, mittelfristig = bis 5 Jahre, langfristig = bis 10 Jahre, sehr langfristig = über 10 Jahre'}];
const step3Summe = [{ label: 'Summe des monatlich für Anlagezwecke frei zur Verfügung stehenden Einkommens', text: 'Angaben über derzeit regelmäßiges Einkommen abzüglich finanzieller Verpflichtungen.'},
    { label: 'Summe des für Anlagezwecke frei zur Verfügung stehenden Kapitals', text: 'Das frei zur Verfügung stehende Kapital ist die Summe der unter 2. und 4. fett gedruckten Zeilen.'}];
const confirmHasMoney = '* Mit dem Setzen des Hakens an diesen Punkt wird die Überprüfung Ihrer finanziellen Verhältnisse im System deaktiviert!'
    + ' Gleichzeitig wird eine Bemerkung in den Protokollen gesetzt, in der Sie das Vorhandensein der nötigen finanziellen Mittel bestätigen.'
    + ' Dadurch ist der Makler in der Lage eine Beratung auch bei diesem Kunden durchzuführen, allerdings unter einer niedrigeren juristichen Sicherheit!'
    + ' Wir empfehlen Ihnen, auf alle Fälle den zu investierenden Betrag bei den finanziellen Verhältnissen einzutragen.';

const mixin = {
    mixins: [anlegerprofilMixin],
    computed: {
        step31() {
            return [{ text: 'Bitte geben Sie Ihre finanziellen Verhältnisse so genau wie möglich an.' },
            { text: 'In den finanziellen Verhältnissen machen Sie Angaben zu Ihrer Finanzsituation. Diese sollte zu Art und Umfang Ihrer geplanten Wertpapiergeschäfte passen.' },
            this.hasConfirmMoney && {text: confirmHasMoney }]
        },
        step32() {
            return  [{ text: 'Die Angabe zum Bargeld bezieht sich nur auf Gelder, die Sie z.B. zu Hause oder in einem Banktresor verwahren. '
            + 'Diese Gelder stehen für die Anlage in Wertpapieren nicht zur Verfügung. '},
            { text: 'Gebundene Spareinlagen stehen ebenfalls derzeit nicht der Anlage in Werpaprieren zur Verfügung, da sie ja noch gebunden sind.' },
            { text: 'Die täglich verfügbaren Bankeinlagen können zur Anlage in Wertpapiere genutzt werden.' },
            { text: 'Bei einem Minderjährigen- oder Firmenanlegerprofil werden die finanziellen Verhältnisse des Kindes oder der Firma eingetragen. Steht kein Barvermögen zur Verfügung, tragen '
            + 'Sie bitte unter täglich verfügbare Bankeinlagen eine Null ein.' }, this.hasConfirmMoney && {text: confirmHasMoney }]
        },
        step33() {
            return  [{ text: 'Ihre Angaben zum Immobilienvermögen hilft Ihrem Berater Ihre persönliche Situation besser einzuschätzen, '
            + 'diese Angaben haben keine direkte Auswirkung auf Ihre Anlagen in Wertpapieren.'}, this.hasConfirmMoney && {text: confirmHasMoney }]
        },
        step34() {
            return  [{ text: 'Ihre Angaben zu Kapitalanlagen und sonstigen Vermögenswerten hilft Ihrem Berater, Ihre persönliche Situation besser '
            + 'einzuschätzen. Diese Angaben haben keine direkte Auswirkung auf Ihre Anlagen in Wertpapieren.'},
            { label: 'Alternative Investments', text: 'z.B. geschlossener Investmentfonds, Unternehmensbeteiligungen, Rohstoffe, Hedgefonds etc.' },
            this.hasConfirmMoney && {text: confirmHasMoney }]
        },
        step35() {
            return  [{ text: 'Ihre Angaben zu Verbindlichkeiten und Schulden hilft Ihrem Berater, Ihre persönliche Situation besser einzuschätzen. '
            + 'Diese Angaben haben keine direkte Auswirkung auf Ihre Anlagen in Wertpapieren.'}, this.hasConfirmMoney && {text: confirmHasMoney }]
        },
        step36() {
            return  [{ text: 'In den regelmäßigen finanziellen Verpflichtungen pro Monat tragen Sie alle monatlichen Ausgaben ein. Diese Eingaben '
            + 'reduzieren Ihr monatliches Einkommen und geben Auskunft darüber, welcher Betrag monatlich als Anlage zur Verfügung steht.'},
            { text: 'Bei einem Minderjährigen- oder Firmenanlegerprofil werden die finanziellen Verhältnisse des Kindes oder der Firma eingetragen.'},
            { text: 'Gibt es keine regelmäßigen finanziellen Verpflichtungen pro Monat, können Sie die Felder leer lassen.'}, this.hasConfirmMoney && {text: confirmHasMoney }]
        },
        step37() {
            return  [{ label: 'Zulagenberechtigung', text: 'Geben Sie hier bitte an, welche Subventionsmöglichkeit Sie nutzen können bzw. nutzen möchten.'},
            this.hasConfirmMoney && {text: confirmHasMoney }]
        }
    },
    methods: {
        getHinweise(substepKey, schiene = 'INVESTMENT') {
            if (substepKey) {
                switch (substepKey) {
                    case 'step1-1': 
                        return step11;
                    case 'step1-2': 
                        return step12;
                    case 'step1-3': 
                        return step13;
                    case 'step2-1': 
                        return step21;
                    case 'step2': 
                        return step11;
                    case 'step3-1': 
                        return this.step31;
                    case 'step3-2': 
                        return this.step32;
                    case 'step3-3': 
                        return this.step33;
                    case 'step3-4': 
                        return this.step34;
                    case 'step3-5': 
                        return this.step35;
                    case 'step3-6': 
                        return this.step36;
                    case 'step3-summe': 
                        return step3Summe;
                    case 'step3-7': 
                        return this.step37;
                    case 'step5-1': 
                        return step51;
                    case 'step6-1': 
                        return step61;
                    case 'anlageziele':
                        return this.isWealthKonzept ? stepAnlagezieleWK : stepAnlageziele;
                    default: break;
                }
                if (substepKey?.includes('step2-card')) {
                    return step2Card;
                }
            }
        }
    },
}

export default mixin;
