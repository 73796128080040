<template>
    <div>
        <div class="box__container">
            <div class="box__title">Beschreibungen und Dokumente</div>
            <div v-if="schemaData.isAdvisor || schemaData.isInternVV">
                <InputField
                    v-model="factsheetEmail"
                    label="Factsheet E-Mail"
                    validateUntouched
                    @change="updateStore({factsheetEmail: $event})"
                />
                <InputField
                    v-model="factsheetInternet"
                    label="Factsheet Internet"
                    validateUntouched
                    @change="updateStore({factsheetInternet: $event})"
                />

                <InputTextArea
                    label="automatischer Marktkommentar zur Strategie (Der Marktkommentar wird von Ihrer Struktur vorgegeben und ändert sich jedes Quartal.)"
                    v-model="marktKommentarFactsheet"
                    :disabled="!schemaData.isInternVV"
                    :rows="marktKommentarFactsheet ? 10 : 1"
                    :autoGrow="!marktKommentarFactsheet"
                    @change="updateStore({marktKommentarFactsheet: $event})"
                />
                <InputTextArea
                    label="Beschreibung der Anlagestrategie (kurze Beschreibung Ihrer Strategie zur Infomation an den Kunden)"
                    v-model="anlageStrategieFactsheet"
                    :rows="anlageStrategieFactsheet ? 10 : 1"
                    :autoGrow="!anlageStrategieFactsheet"
                    @change="updateStore({anlageStrategieFactsheet: $event})"
                />
                <InputTextArea
                    label="Vorstellung des Strategieberaters (kurze Beschreibung des Strategieberaters/Advisors)"
                    v-model="strategieBeraterFactsheet"
                    :rows="strategieBeraterFactsheet ? 10 : 1"
                    :autoGrow="!strategieBeraterFactsheet"
                    @change="updateStore({strategieBeraterFactsheet: $event})"
                />
                <InputTextArea
                    label="Portfoliokommentar zur Strategie (für Quartalsberichte und Factsheet)"
                    v-model="portfolioKommentarFactsheet"
                    :rows="portfolioKommentarFactsheet ? 10 : 1"
                    :autoGrow="!portfolioKommentarFactsheet"
                    @change="updateStore({portfolioKommentarFactsheet: $event})"
                />
                <InputTextArea
                    v-model="schlusssatzPortfoliokommentar"
                    :rows="schlusssatzPortfoliokommentar ? 10 : 1"
                    :autoGrow="!schlusssatzPortfoliokommentar"
                    disabled
                    @change="updateStore({schlusssatzPortfoliokommentar: $event})"
                />
            </div>
            <div v-else>
                <div class="label-title">Marktkommentar zur Strategie:</div>
                <div>{{ this.marktKommentarFactsheet }}</div>
                <div class="label-title">Anlagestrategie:</div>
                <div>{{ this.anlageStrategieFactsheet }}</div>
                <div class="label-title">Strategieberater:</div>
                <div>{{ this.strategieBeraterFactsheet }}</div>
                <div class="label-title">Portfoliokommentar zur Strategie:</div>
                <div>{{ this.portfolioKommentarFactsheet }}</div>
                <br />
                <div>{{ this.schlusssatzPortfoliokommentar }}</div>
            </div>

            <div>
                <div class="label-title">Dokumente und Prospekte</div>
                <DragnDropArea
                    hoverText="Neues Dokument hochladen"
                    @files="openDocumentUploadModal($event)"
                    class="simple-additional-documents-table__dropzone"
                >
                    <div class="mt-2 mb-3">
                        <BaseButton @click="openDocumentUploadModal([])">Datei hochladen</BaseButton>
                    </div>
                    <div>
                        <Table
                            v-if="rows.length"
                            tableId="vvstrategienfiles"
                            :headers="headers"
                            :rows="rows"
                            :rowsPerPage="10"
                            rowId="fileId"
                            @click-bezeichnung="showDocument"
                            @action-DELETE="openDeleteModal"
                            @action-EDIT="openEditModal"
                            hidePagination
                        >
                        </Table>
                    </div>

                    <DocumentsUploadModal
                        ref="uploadForm"
                        :files="uploadFiles"
                        :additionalInputs="uploadFields"
                        :uploadType="3"
                        :multipleFileUpload="true"
                        :openOnMount="false"
                        :customUpload="upload"
                    />
                </DragnDropArea>
            </div>
        </div>
        <BaseModal
            ref="editModal"
            modalTitle="Datei hochladen"
            labelButtonConfirm="Ok"
            labelButtonCancel="Abbrechen"
            @onConfirmButton="saveFile()"
            @onCloseButton="closeEditModal()"
            @onCancelButton="closeEditModal()"
            @onEscButton="closeEditModal()"
        >
            <InputField v-model="editFile.fileName" :disabled="true" label="Dateiname" />
            <ComboBox v-model="editFile.typ" :values="typValues" label="Typ" />
            <InputField v-model="editFile.bezeichnung" label="Bezeichnung" />
            <InputTextArea v-model="editFile.bemerkung" label="Bemerkung" />
        </BaseModal>
        <BaseModal ref="hatFehler" labelButtonConfirm="Ok" :showCancelButton="false" :showCloseButton="false">
            <template v-slot:modalTitle>
                <i class="las la-exclamation-triangle mr-2" style="color: orangered"></i>
                Fehler
            </template>
            <div>{{ errorStr }}</div>
        </BaseModal>
    </div>
</template>

<script>
import InputTextArea from "@/components/core/forms/InputTextArea.vue"
import VV_TYPES from "@/store/vermoegensverwaltung/types"
import BaseButton from "@/components/core/BaseButton.vue"
import DocumentsUploadModal from "@/components/fileUpload/DocumentsUploadModal.vue"
import DragnDropArea from "@/components/fileUpload/DragnDropArea.vue"
import Table from "@/components/table2/Table.vue"
import {TextColumn, ActionColumn, SimpleAction} from "@/components/table2/table_util.js"
import {viewDocument} from "@/components/core/download/DownloadLink.vue"
import ComboBox from "@/components/core/forms/ComboBox.vue"
import InputField from "@/components/core/forms/InputField.vue"
import BaseModal from "@/components/core/BaseModal.vue"
import mixin from "@/mixins/vermoegensverwaltung/vv-mixin.js"
import LOG_TYPES from "@/store/log/types"
import {buildMessage} from "@/helpers/log-message-helper"

export default {
    mixins: [mixin],
    components: {
        InputTextArea,
        ComboBox,
        InputField,
        BaseButton,
        Table,
        DocumentsUploadModal,
        DragnDropArea,
        BaseModal,
    },
    data() {
        return {
            factsseetEmail: "",
            factsheetInternet: "",
            marktKommentarFactsheet: "",
            anlageStrategieFactsheet: "",
            strategieBeraterFactsheet: "",
            portfolioKommentarFactsheet: "",
            schlusssatzPortfoliokommentar: "",
            files: null,
            typValues: [
                {label: "Dokument für Vermögensverwaltung", value: "VV_SONSTIGES"},
                {label: "Factsheet für Vermögensverwaltung", value: "VV_PROSPEKT"},
            ],
            uploadFiles: [],
            editFile: {
                bezeichnung: "",
                bemerkung: "",
                typ: "",
                fileName: "",
            },
            errorStr: "",
        }
    },
    mounted() {
        this.initData()
    },
    watch: {
        schemaData(value) {
            this.initData()
        },
    },
    computed: {
        headers() {
            return {
                lockedLeft: [TextColumn("bezeichnung", "Name").makeLink()],
                center: [TextColumn("typStr", "Typ")],
                lockedRight: [ActionColumn("actions", "Aktionen")],
            }
        },
        rows() {
            return (this.schemaData?.files || []).map((row) => {
                const actions = [SimpleAction("EDIT", "PhPencil", "Bearbeiten")]
                if (row.canDelete) {
                    actions.push(SimpleAction("DELETE", "PhTrash", "Löschen"))
                }
                return {
                    ...row,
                    actions,
                }
            })
        },
        uploadFields() {
            return [
                {
                    key: "typ",
                    label: "Typ",
                    type: "COMBOBOX",
                    value: "VV_SONSTIGES",
                    options: this.typValues,
                },
                {key: "bezeichnung", label: "Bezeichnung", type: "STRING", value: ""},
                {key: "bemerkung", label: "Bemerkung", type: "TEXT", value: ""},
            ]
        },
    },
    methods: {
        initData() {
            if (this.schemaData) {
                this.factsheetEmail = this.schemaData.factsheetEmail
                this.factsheetInternet = this.schemaData.factsheetInternet
                this.marktKommentarFactsheet = this.schemaData.marktKommentarFactsheet
                this.anlageStrategieFactsheet = this.schemaData.anlageStrategieFactsheet
                this.strategieBeraterFactsheet = this.schemaData.strategieBeraterFactsheet
                this.portfolioKommentarFactsheet = this.schemaData.portfolioKommentarFactsheet
                this.schlusssatzPortfoliokommentar = this.schemaData.schlusssatzPortfoliokommentar
            }
        },
        showDocument(file) {
            if (file?.schemaFileId) {
                this.$store.dispatch(VV_TYPES.ACTIONS.OPEN_SCHEMA_DOCUMENT_PDF, file.schemaFileId).then((response) => {
                    if (response?.data?.errorMessage) {
                        dispatch(LOG_TYPES.ACTIONS.ADD_MESSAGE, buildMessage(error, "danger"))
                    } else {
                        viewDocument({
                            filename: file.fileName || "unbenannt",
                            data: response?.data?.bytes,
                            contentType: response?.data?.contentType || "application/pdf",
                        })
                    }
                })
            }
        },
        deleteDocument(schemaFileId) {
            this.$store.dispatch(VV_TYPES.ACTIONS.DELETE_SCHEMA_DOCUMENT, schemaFileId)
        },
        openEditModal(file) {
            this.editFile.bezeichnung = file?.bezeichnung || ""
            this.editFile.bemerkung = file?.bemerkung || ""
            this.editFile.typ = file?.typ || ""
            this.editFile.fileName = file?.fileName || ""
            this.editFile.schemaFileId = file?.schemaFileId
            this.editFile.fileId = file?.fileId
            this.$refs.editModal.open()
        },
        closeEditModal() {
            this.editFile.bezeichnung = ""
            this.editFile.bemerkung = ""
            this.editFile.typ = ""
            this.editFile.fileName = ""
            this.editFile.schemaFileId = ""
            this.editFile.fileId = ""
            this.$refs.editModal.close()
        },
        saveFile() {
            if (this.editFile) {
                this.$store.dispatch(VV_TYPES.ACTIONS.UPLOAD_SCHEMA_DOCUMENT, {
                    schemaFileId: this.editFile.schemaFileId,
                    formData: this.editFile,
                    fileId: this.editFile.fileId,
                })
            }
        },
        openDeleteModal(file) {
            if (file.schemaFileId) {
                this.$confirmModal({
                    title: "Dokument löschen",
                    message: file.deleteText,
                    labelButtonConfirm: "Löschen",
                }).then(() => this.$store.dispatch(VV_TYPES.ACTIONS.DELETE_SCHEMA_DOCUMENT, file.schemaFileId))
            }
        },
        openDocumentUploadModal(files) {
            this.$refs.uploadForm.open()
            this.files = files
        },
        upload(data) {
            if (data?.id && data?.formData) {
                const formData = data.formData
                formData.schemaId = this.schemaId
                this.$store.dispatch(VV_TYPES.ACTIONS.UPLOAD_SCHEMA_DOCUMENT, {
                    schemaFileId: "",
                    formData: formData,
                    fileId: data.id,
                })
            }
        },
    },
}
</script>

<style scoped>
.label-title {
    font-weight: 600 !important;
    margin-top: 1rem;
}
</style>