<template>
  <div>
    <FinanceImmobilienvermoegen :isRefusedPage="isDisabled || isRefusedPageFinance" :finance="finance" @updateImmo="updateStoreStep33($event)"/>
    
    <FinanceLineSum  :summe="sumImmobilien" />
  </div>
</template>

<script>

import FinanceImmobilienvermoegen from '@/components/anlegerprofil/FinanceImmobilienvermoegen.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';
import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanceImmobilienvermoegen,
    FinanceLineSum,
  },
  mounted() {
    this.updateWarnings();
  },
  methods: {
    updateStoreStep33(event) {
      if (event?.compId) {
        this.updateStoreCheckBoxGroup('finance', {[event.compId] : event.value})
      }
    }
  },
}
</script>

<style scoped>
</style>