var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "box__container" }, [
        _c("div", { staticClass: "box__title" }, [
          _vm._v("Beschreibungen und Dokumente")
        ]),
        _vm.schemaData.isAdvisor || _vm.schemaData.isInternVV
          ? _c(
              "div",
              [
                _c("InputField", {
                  attrs: { label: "Factsheet E-Mail", validateUntouched: "" },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({ factsheetEmail: $event })
                    }
                  },
                  model: {
                    value: _vm.factsheetEmail,
                    callback: function($$v) {
                      _vm.factsheetEmail = $$v
                    },
                    expression: "factsheetEmail"
                  }
                }),
                _c("InputField", {
                  attrs: { label: "Factsheet Internet", validateUntouched: "" },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({ factsheetInternet: $event })
                    }
                  },
                  model: {
                    value: _vm.factsheetInternet,
                    callback: function($$v) {
                      _vm.factsheetInternet = $$v
                    },
                    expression: "factsheetInternet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "automatischer Marktkommentar zur Strategie (Der Marktkommentar wird von Ihrer Struktur vorgegeben und ändert sich jedes Quartal.)",
                    disabled: !_vm.schemaData.isInternVV,
                    rows: _vm.marktKommentarFactsheet ? 10 : 1,
                    autoGrow: !_vm.marktKommentarFactsheet
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        marktKommentarFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.marktKommentarFactsheet,
                    callback: function($$v) {
                      _vm.marktKommentarFactsheet = $$v
                    },
                    expression: "marktKommentarFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Beschreibung der Anlagestrategie (kurze Beschreibung Ihrer Strategie zur Infomation an den Kunden)",
                    rows: _vm.anlageStrategieFactsheet ? 10 : 1,
                    autoGrow: !_vm.anlageStrategieFactsheet
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        anlageStrategieFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.anlageStrategieFactsheet,
                    callback: function($$v) {
                      _vm.anlageStrategieFactsheet = $$v
                    },
                    expression: "anlageStrategieFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Vorstellung des Strategieberaters (kurze Beschreibung des Strategieberaters/Advisors)",
                    rows: _vm.strategieBeraterFactsheet ? 10 : 1,
                    autoGrow: !_vm.strategieBeraterFactsheet
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        strategieBeraterFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.strategieBeraterFactsheet,
                    callback: function($$v) {
                      _vm.strategieBeraterFactsheet = $$v
                    },
                    expression: "strategieBeraterFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    label:
                      "Portfoliokommentar zur Strategie (für Quartalsberichte und Factsheet)",
                    rows: _vm.portfolioKommentarFactsheet ? 10 : 1,
                    autoGrow: !_vm.portfolioKommentarFactsheet
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        portfolioKommentarFactsheet: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.portfolioKommentarFactsheet,
                    callback: function($$v) {
                      _vm.portfolioKommentarFactsheet = $$v
                    },
                    expression: "portfolioKommentarFactsheet"
                  }
                }),
                _c("InputTextArea", {
                  attrs: {
                    rows: _vm.schlusssatzPortfoliokommentar ? 10 : 1,
                    autoGrow: !_vm.schlusssatzPortfoliokommentar,
                    disabled: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.updateStore({
                        schlusssatzPortfoliokommentar: $event
                      })
                    }
                  },
                  model: {
                    value: _vm.schlusssatzPortfoliokommentar,
                    callback: function($$v) {
                      _vm.schlusssatzPortfoliokommentar = $$v
                    },
                    expression: "schlusssatzPortfoliokommentar"
                  }
                })
              ],
              1
            )
          : _c("div", [
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Marktkommentar zur Strategie:")
              ]),
              _c("div", [_vm._v(_vm._s(this.marktKommentarFactsheet))]),
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Anlagestrategie:")
              ]),
              _c("div", [_vm._v(_vm._s(this.anlageStrategieFactsheet))]),
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Strategieberater:")
              ]),
              _c("div", [_vm._v(_vm._s(this.strategieBeraterFactsheet))]),
              _c("div", { staticClass: "label-title" }, [
                _vm._v("Portfoliokommentar zur Strategie:")
              ]),
              _c("div", [_vm._v(_vm._s(this.portfolioKommentarFactsheet))]),
              _c("br"),
              _c("div", [_vm._v(_vm._s(this.schlusssatzPortfoliokommentar))])
            ]),
        _c(
          "div",
          [
            _c("div", { staticClass: "label-title" }, [
              _vm._v("Dokumente und Prospekte")
            ]),
            _c(
              "DragnDropArea",
              {
                staticClass: "simple-additional-documents-table__dropzone",
                attrs: { hoverText: "Neues Dokument hochladen" },
                on: {
                  files: function($event) {
                    return _vm.openDocumentUploadModal($event)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "mt-2 mb-3" },
                  [
                    _c(
                      "BaseButton",
                      {
                        on: {
                          click: function($event) {
                            return _vm.openDocumentUploadModal([])
                          }
                        }
                      },
                      [_vm._v("Datei hochladen")]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _vm.rows.length
                      ? _c("Table", {
                          attrs: {
                            tableId: "vvstrategienfiles",
                            headers: _vm.headers,
                            rows: _vm.rows,
                            rowsPerPage: 10,
                            rowId: "fileId",
                            hidePagination: ""
                          },
                          on: {
                            "click-bezeichnung": _vm.showDocument,
                            "action-DELETE": _vm.openDeleteModal,
                            "action-EDIT": _vm.openEditModal
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _c("DocumentsUploadModal", {
                  ref: "uploadForm",
                  attrs: {
                    files: _vm.uploadFiles,
                    additionalInputs: _vm.uploadFields,
                    uploadType: 3,
                    multipleFileUpload: true,
                    openOnMount: false,
                    customUpload: _vm.upload
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "BaseModal",
        {
          ref: "editModal",
          attrs: {
            modalTitle: "Datei hochladen",
            labelButtonConfirm: "Ok",
            labelButtonCancel: "Abbrechen"
          },
          on: {
            onConfirmButton: function($event) {
              return _vm.saveFile()
            },
            onCloseButton: function($event) {
              return _vm.closeEditModal()
            },
            onCancelButton: function($event) {
              return _vm.closeEditModal()
            },
            onEscButton: function($event) {
              return _vm.closeEditModal()
            }
          }
        },
        [
          _c("InputField", {
            attrs: { disabled: true, label: "Dateiname" },
            model: {
              value: _vm.editFile.fileName,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "fileName", $$v)
              },
              expression: "editFile.fileName"
            }
          }),
          _c("ComboBox", {
            attrs: { values: _vm.typValues, label: "Typ" },
            model: {
              value: _vm.editFile.typ,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "typ", $$v)
              },
              expression: "editFile.typ"
            }
          }),
          _c("InputField", {
            attrs: { label: "Bezeichnung" },
            model: {
              value: _vm.editFile.bezeichnung,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "bezeichnung", $$v)
              },
              expression: "editFile.bezeichnung"
            }
          }),
          _c("InputTextArea", {
            attrs: { label: "Bemerkung" },
            model: {
              value: _vm.editFile.bemerkung,
              callback: function($$v) {
                _vm.$set(_vm.editFile, "bemerkung", $$v)
              },
              expression: "editFile.bemerkung"
            }
          })
        ],
        1
      ),
      _c(
        "BaseModal",
        {
          ref: "hatFehler",
          attrs: {
            labelButtonConfirm: "Ok",
            showCancelButton: false,
            showCloseButton: false
          },
          scopedSlots: _vm._u([
            {
              key: "modalTitle",
              fn: function() {
                return [
                  _c("i", {
                    staticClass: "las la-exclamation-triangle mr-2",
                    staticStyle: { color: "orangered" }
                  }),
                  _vm._v(" Fehler ")
                ]
              },
              proxy: true
            }
          ])
        },
        [_c("div", [_vm._v(_vm._s(_vm.errorStr))])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }