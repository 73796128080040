var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("BaseModal", {
    ref: "baseFilterSaveModal",
    attrs: { showDefaultButtons: false, modalTitle: "Filterliste", size: "lg" },
    on: {
      onCloseButton: function($event) {
        return _vm.emitCloseModalEvent()
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function() {
          return [
            _vm.hasSearchParameters
              ? _c(
                  "div",
                  [
                    _c(
                      "SortableList",
                      {
                        attrs: {
                          items: _vm.storedSearchParameter.searchParameters
                        },
                        on: {
                          orderChanged: function($event) {
                            return _vm.onOrderChanged($event)
                          }
                        }
                      },
                      [
                        _c(
                          "ul",
                          {
                            staticClass: "list-bordered",
                            attrs: { "data-sortable-container": "" }
                          },
                          _vm._l(
                            _vm.storedSearchParameter.searchParameters,
                            function(item, index) {
                              return _c(
                                "li",
                                {
                                  key: index,
                                  staticClass: "list-bordered-item",
                                  attrs: { "data-sortable-item": "" }
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex parameter-search__input-fields"
                                    },
                                    [
                                      _c(
                                        "div",
                                        [
                                          _vm.currentlyEditingIndex === index
                                            ? _c("InputField", {
                                                model: {
                                                  value: item.searchLabel,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      item,
                                                      "searchLabel",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "item.searchLabel"
                                                }
                                              })
                                            : [
                                                _c(
                                                  "span",
                                                  {
                                                    class: {
                                                      "text-bold":
                                                        item.isDefault
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item.searchLabel +
                                                          (item.isDefault
                                                            ? " (Standard)"
                                                            : "")
                                                      )
                                                    )
                                                  ]
                                                ),
                                                !item.visible
                                                  ? [
                                                      _c("span", [
                                                        _vm._v(" | ")
                                                      ]),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "color-danger"
                                                        },
                                                        [_vm._v("versteckt")]
                                                      )
                                                    ]
                                                  : _vm._e()
                                              ]
                                        ],
                                        2
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex align-items-center justify-content-end",
                                          staticStyle: { flex: "1 0 auto" }
                                        },
                                        [
                                          _c(
                                            "BaseButton",
                                            {
                                              staticClass: "ml-3",
                                              attrs: { isSecondary: "" },
                                              on: {
                                                click: function($event) {
                                                  return _vm.toggleDefaultFilter(
                                                    index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              item && item.isDefault
                                                ? _c("span", [
                                                    _vm._v(" Standard löschen ")
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " Als Standard setzen "
                                                    )
                                                  ])
                                            ]
                                          ),
                                          _c(
                                            "BaseContextMenu",
                                            [
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.editRow(index)
                                                    }
                                                  }
                                                },
                                                [_vm._v("Filter bearbeiten")]
                                              ),
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.exportFilter(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Filter exportieren")]
                                              ),
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteFilterItem(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [_vm._v("Filter löschen")]
                                              ),
                                              _c(
                                                "ContextMenuItem",
                                                {
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.hideOrShow(
                                                        index
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  item.visible
                                                    ? [
                                                        _vm._v(
                                                          " Filter verstecken "
                                                        )
                                                      ]
                                                    : [
                                                        _vm._v(
                                                          " Filter anzeigen "
                                                        )
                                                      ]
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          ),
                                          _c("ph-list", {
                                            staticClass: "ml-2",
                                            attrs: {
                                              "data-sortable-item-handler": "",
                                              size: _vm.iconSize,
                                              weight: "bold"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                ]
                              )
                            }
                          ),
                          0
                        )
                      ]
                    )
                  ],
                  1
                )
              : _c("NoData", { attrs: { noIcon: "" } })
          ]
        },
        proxy: true
      },
      {
        key: "footer",
        fn: function() {
          return [
            _c(
              "BaseFileSelect",
              {
                staticClass: "mr-3",
                attrs: { isSecondary: "", disable: !_vm.filterSaveKey },
                on: { files: _vm.importFilter }
              },
              [_vm._v(" Filter laden ")]
            ),
            _c(
              "BaseButton",
              {
                attrs: { isPrimary: "" },
                on: {
                  click: function($event) {
                    return _vm.emitCloseModalEvent()
                  }
                }
              },
              [_c("span", [_vm._v("Schließen")])]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }