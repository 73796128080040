import store from '@/store';
import CORE_TYPES from '@/store/core/types';
import LOG_TYPES from '@/store/log/types';

import { buildLinkResolvers } from '../utils';

import externeZugangBankLinkResolver from './externe-zugang-bank-link-resolver';
import externeZugangLinkResolver from './externe-zugang-link-resolver';
import formLinkResolver from './form-link-resolver';
import factsheetLinkResolver from './factsheet-link-resolver';
import datenschutzURLResolver from './datenschutz-url-resolver';

const resolvers = {
  externeZugangBankLinkResolver,
  externeZugangLinkResolver,
  formLinkResolver,
  factsheetLinkResolver,
  datenschutzURLResolver,
};
const linkResolvers = buildLinkResolvers(resolvers);

export function _hasLinkResolver(resolverId) {
  if (!(resolverId in linkResolvers) || !linkResolvers[resolverId]) {
    store.dispatch(LOG_TYPES.ACTIONS.WARN, `link-resolvers >>> link resolver not found: "${resolverId}"`);
    store.dispatch(LOG_TYPES.ACTIONS.WARN, `link-resolvers >>> link resolvers available: \n${Object.keys(linkResolvers).join('\n')}`);
    return false;
  }

  return true;
}

export async function _resolveLink(resolverId, data) {
  if (!_hasLinkResolver(resolverId, data)) return;

  // `context` prevents to use router / store code within a link resolve
  // the idea is to evolve it providing more data when needed
  const context = {
    apiAddress: store.getters[CORE_TYPES.GETTERS.API_ADDRESS],
    apiAddressLegacy: store.getters[CORE_TYPES.GETTERS.API_ADDRESS_LEGACY],
  };

  return await linkResolvers[resolverId](context, data);
}
