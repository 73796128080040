<template>
  <div>
    <FinanzenKapitalanlage :disabled="isDisabled || isRefusedPageFinance" :finance="finance" :showBondDepotRelevant="showBondDepotRelevant" 
      @changed="valueChangedFinance"/>

    <FinanceLineSum :summe="summeAnlage" />
  </div>
</template>

<script>

import anlegerprofilMixin from '@/mixins/anlegerprofil/anlegerprofil-mixin.js';
import FinanzenKapitalanlage from '@/components/anlegerprofil/FinanzenKapitalanlage.vue';
import FinanceLineSum from '@/components/anlegerprofil/FinanceLineSum.vue';

export default {
  mixins: [anlegerprofilMixin],
  components: {
    FinanzenKapitalanlage,
    FinanceLineSum,
  },
  computed: {
    showBondDepotRelevant() {
      return this.data?.finance?.showBondDepotRelevant;
    },
  },
  mounted() {
    this.updateWarnings();
  },
}
</script>

<style scoped>
</style>