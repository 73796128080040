<template>
  <div>

    <InsuranceOptionMenu 
      :insuranceId="versicherungId" 
      title="Dokumente"
      :headerActions="headerActions"
      @action-NEW-ACTIVITY="newAppointment()"
      @action-OLD-POLICE-NUMBERS="showOldPoliceNumbers()"
      @action-SCHADEN-MELDEN="createSchaden()"
      @action-EMAIL-KUNDE="sendEmailLocal(false)"
      @action-EMAIL-GESELLSCHAFT="sendEmailLocal(true)"
      @action-BRIEF-ERSTELLEN="briefStart()"
      @action-KUENDIGUNG-VERSICHERUNGEN="emailCancel()"
      @action-AUFGABE-HINZUFUEGEN="handleAddActivity()"
      @action-VERSICHERUNG-KOPIEREN="versicherungKopierenBestaetigung()"
      @action-DOKUMENTE-HOCHLADEN="dateiHochladen()"
      @action-EXTERNEN-ZUGANG-AUFRUFEN="externenZugangOeffnen()"
      @action="executeGenericAction"
      >
      <template #title>
        Dokumente
      </template>
      <template #subtitle>
        <template v-if="gesellschaftName">
          <span class="subtitle-part" v-if="isCustomerOnly" >{{ gesellschaftName }}</span>
          <span class="subtitle-part" v-else ><a @click="navigateToGesellschaft()">{{ gesellschaftName }}</a></span>
          <span> | </span>
        </template>
        <template v-if="bezeichnung">
          <span class="subtitle-part">{{ bezeichnung }}</span>
          <span v-if="nummer || statusAktuell || showKennzeichen"> | </span>
        </template> 
        <template v-if="nummer">
          <router-link class="subtitle-part" :to="overviewLink(versicherungId)">{{ nummer }}</router-link>
          <span v-if="statusAktuell || showKennzeichen"> | </span>
        </template>
        <template v-if="statusAktuell">
          <span class="subtitle-part"><Pill :label="statusAktuell" :type="statusToPillType[statusAktuell]" /></span>
          <span v-if="showKennzeichen"> | </span>
        </template>
        <template v-if="showKennzeichen">
          <span class="subtitle-part">{{ kennzeichen }}</span>
        </template>
      </template>           
    </InsuranceOptionMenu>

  <DragnDropArea hoverText="Datei hier ablegen" @files="dropFile" :disabled="!allowVersUpload">
    <DocumentsFilter />

    <div class="box__container">
      <DocumentsTable 
        ref="documentsTableEl"
        title="Dokumente" 
        :nodeId="lastNodeId" 
        :versicherungId="versicherungId" 
        :rowsPerPage="rowsPerPage"
        @sendEmail="emailStart($event.toCompany, $event.attachments)"
      />
    </div>
  </DragnDropArea>

  <CopyInsuranceConfirmationModal ref="copyInsuranceConfirmationModal" @open-item="openItemSelectedDetail"></CopyInsuranceConfirmationModal>
  <ShowOldPoliceNumbersModal ref="showOldPoliceNumbersModal" @open-item="openItemSelectedDetail"></ShowOldPoliceNumbersModal> 
  <AddDocument ref="addDocumentModal" :nodeId="nodeId" :versicherungId="versicherungId"  @close="goToDownloadArea(versicherungId, true)" />


  <ExterneZugangAskOptions 
      v-if="askOptions"
      :askOptions="askOptions"
      :confirmButtonLabel="actionLabel"
      :data="data"
      :title="externeZugang.companyLabel"
      @close="resetExterneZugang();"
      @onConfirmButton="askOptionsConfirmed($event); navigateToVersicherungstoolsHome();"/>

</div>
</template>

<script>
import { mapGetters } from 'vuex';
import DocumentsTable from '@/components/documents/DocumentsTable.vue';
import DocumentsFilter from '@/components/documents/DocumentsFilter.vue';
import InsuranceOptionMenu from './insurance-group/InsuranceOptionMenu.vue';
import VERSICHERUNG_TYPES from "@/store/versicherungen/types";
import InsuranceDetailMixin from '@/components/versicherungen/InsuranceDetail-mixin.js'
import DragnDropArea from '@/components/fileUpload/DragnDropArea.vue';
import MailContactsModal from '@/views/versicherungen/MailContactsModal.vue';
import Pill from '@/components/core/Pill.vue';
import CopyInsuranceConfirmationModal from '@/views/versicherungen/CopyInsuranceConfirmationModal.vue'
import ShowOldPoliceNumbersModal from '@/views/versicherungen/ShowOldPoliceNumbersModal.vue'
import AddDocument from '@/views/versicherungen//AddDocument.vue';
import DOKUMENTENARCHIV_TYPES from '@/store/dokumentenarchiv/types';
import axios from 'axios';

import ExterneZugangAskOptions from '@/components/externeZugang/ExterneZugangAskOptions.vue'
import vergleichsrechnerMixin from '@/components/externeZugang/vergleichsrechner-mixin';

export default {
  mixins: [vergleichsrechnerMixin, InsuranceDetailMixin],
  components: {
    DocumentsFilter,
    DocumentsTable,
    InsuranceOptionMenu,
    DragnDropArea,
    MailContactsModal,
    Pill,
    CopyInsuranceConfirmationModal,
    ShowOldPoliceNumbersModal,
    AddDocument,
    ExterneZugangAskOptions,
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters({
      nodeId: VERSICHERUNG_TYPES.GETTERS.NODE_ID,
      selectedRowsDokArchiv: DOKUMENTENARCHIV_TYPES.GETTERS.SELECTED_ROWS,
     
    }),
    versicherungId() {
      return this.$route?.params?.versicherungId;
    },
    lastNodeId() {
      return this.nodeId;
    },
  },
  methods: {
    dropFile(files) {
        this.$refs.documentsTableEl.dateiHochladen(files);
      },    
    async sendEmailLocal(toCompany) {
      const attachments = await this.retrieveSelectedRowsAsTempFiles();
      this.emailStart(toCompany, attachments);
    },
    async retrieveSelectedRows() {
      let selectedRows = this.selectedRowsDokArchiv

      if (this.isSelectedAllInFolder) {
        await this.$store.dispatch(DOKUMENTENARCHIV_TYPES.ACTIONS.RETRIEVE_DOCUMENTS, {
          nodeId: this.nodeId,
          offset: 0,
          limit: 200,
          schadenId: this.schadenId,
          keepOldPages: false,
          reloadTree: false,
          linkVertrag: true,
          doAlwaysLoad: true,
        });
        if (this.selectedNode?.documents) {
          selectedRows = []
          Object.fromEntries(Object.entries(this.selectedNode?.documents).map(([key, page]) => {
            return [key, page
                .map(document => {
                  selectedRows.push(document)
                })]
            }));
        }
      }

      return selectedRows
    },
    async retrieveSelectedRowsAsTempFiles() {
      let selectedRows = await this.retrieveSelectedRows()

      let attachments = []
      const params = selectedRows.filter(v => (v?.node || v?.nodeId) && !v.uploaded).map(v => ({nodeId: v?.node || v?.nodeId, fileId: v?.id}))
      await axios.post(`${process.env.VUE_APP_API}/documents/getTempFiles`, params).then(response => {
        response?.data?.map(f => {
          attachments.push({
            id: f.id,
            name: f.name,
          })
        });
      }).catch(error => {
        console.log(error?.errorMessage);
      });

      return attachments
    },    
  },
  async mounted() {


  },
  beforeRouteLeave(to, from, next) {
    this.$addBreadcrumb({
      to, from
    });  

    next();
  }

}
</script>
<style lang="scss" scoped>
.subtitle-part {
  word-break: break-word;
}
</style>