import COMMUNICATION_TYPES from "@/store/communication/types"
import CORE_TYPES from "@/store/core/types"

import axios from "axios"
import dayjs from "dayjs"
import GhostLoading from "@/components/core/loading/GhostLoading.vue"
import NoData from "@/components/core/NoData.vue"
import {DatePickerUtils} from "@/components/core/forms/DatePicker/date-picker-utils"
import OptionMenu from "@/components/core/option-menu/OptionMenu.vue"
import PageHeaderTitleNavigation from "@/components/core/header-title-navigation/PageHeaderTitleNavigation.vue"
import BaseFilter from "@/components/core/BaseFilter.vue"
import {mapGetters} from "vuex"
import {looksLikeKundennr} from "@/views/customer/customer-utils"
import {makeQueryParam} from "@/helpers/utils-helper"

export default {
    components: {
        GhostLoading,
        NoData,
        OptionMenu,
        PageHeaderTitleNavigation,
        BaseFilter,
    },
    data() {
        return {
            records: [],
            loading: false,
            selectedRow: {},
            selectedRows: [],
            filterParams: {},
            defaultOptions: {},
        }
    },
    computed: {
        ...mapGetters({
            isCustomer: CORE_TYPES.GETTERS.IS_CUSTOMER,
            postfachDefaultFilters: COMMUNICATION_TYPES.GETTERS.POSTFACH_DEFAULT_FILTERS,
        }),
        routeStep() {
            return /[^/]*$/.exec(this.$route.path)[0]
        },
    },
    mounted() {
        const {fromDate, toDate} = this.postfachDefaultFilters

        this.defaultOptions = {
            zeitraum: {
                value: [
                    {
                        key: "min",
                        value: fromDate,
                    },
                    {
                        key: "max",
                        value: toDate,
                    },
                ],
            },
        }
    },
    methods: {
        canOpenCustomer(row) {
            return !this.isCustomer && !!row.nummer && looksLikeKundennr(row.nummer)
        },
        openCustomerNewTab(customerId) {
            if (customerId) {
                this.$store.dispatch(CORE_TYPES.ACTIONS.OPEN_CUSTOMER_NEW_TAB, {customerId})
            }
        },
        onFilter(filterConfig) {
            let params = {}

            filterConfig.forEach((fc) => {
                let value = fc.value
                if (fc.key == "zeitraum") {
                    params.fromDate = DatePickerUtils.toDateStr(value.find((v) => v.key == "min").value)
                    params.toDate = DatePickerUtils.toDateStr(value.find((v) => v.key == "max").value)
                } else if (fc.key == "datum") {
                    params.fromDate = DatePickerUtils.toDateStr(value)
                    params.toDate = DatePickerUtils.toDateStr(value)
                } else {
                    params[fc.key] = value
                }
            })

            this.filterParams = params
            this.doSearch(params, 0)
        },
        doSearch(urlParams) {
            this.loading = true

            const params = {
                isInbox: urlParams.postfach != "Ausgang",
                isOutbox: urlParams.postfach != "Eingang",
                fromDate: urlParams.fromDate || "01.01.2000",
                toDate: urlParams.toDate || dayjs(new Date()).format("DD.MM.YYYY"),
                subject: urlParams.subject || "",
                name: urlParams.name || "",
                defaultSearchInput: urlParams.defaultSearchInput || "",
                showOnlyUnreadMessages: urlParams.showOnlyUnreadMessages || false,
            }

            let paramString = ""
            for (let key in params) {
                if (params.hasOwnProperty(key)) {
                    paramString += (paramString.length ? "&" : "?") + key + "=" + params[key]
                }
            }

            axios
                .get(`${process.env.VUE_APP_API}/postfach/list/${this.routeStep}` + paramString, {defaultSpinner: true})
                .then((response) => (this.records = response.data.records))
                .finally(() => {
                    this.selectedRows = []
                    this.loading = false
                })
        },
        deleteRow(row) {
            this.$store
                .dispatch(COMMUNICATION_TYPES.ACTIONS.DELETE_FROM_POSTFACH, {id: row.commId, url: this.category})
                .then(() => this.doSearch(this.filterParams))
        },
        deleteSelected() {
            const payload = this.selectedRows
                .filter((row) => row.deletable)
                .map((v) => ({
                    id: v.commId,
                    category: this.mailboxLabel,
                }))
            axios.post(`${process.env.VUE_APP_API}/postfach/deleteList`, payload, {defaultSpinner: true}).then(() => {
                this.doSearch(this.filterParams)
            })
        },
        doRepeate() {
            console.log('doRepeate')
            this.doneRow(this.selectedRow, true)
        },
        dontRepeate() {
            console.log('dontRepeate')
            this.doneRow(this.selectedRow, false)
        },
        doneRow(data, repeate) {
            //Show status update modal
            if(repeate === undefined) {
                this.selectedRow = data
                this.$refs.modalStatus.open();
                return
            }

            const params = makeQueryParam({status: "ERLEDIGT"})

            axios
                .post(
                    `${process.env.VUE_APP_API}/calendarService/setStatus/${data.commId}?${params}`,
                    {status: "ERLEDIGT", repeate: repeate},
                    {defaultSpinner: true},
                )
                .then(() => {
                    this.loadPage(this.selectedPage)
                })
                .catch((error) => {
                    let msg = error?.data || "Es ist ein unerwarteter Fehler aufgetreten."
                    if (error?.response?.status == 400 || error?.response?.status == 403) {
                        msg = error?.response?.data
                    }
                    this.$store.dispatch(LOG_TYPES.ACTIONS.ERROR, buildMessage(msg, "danger"))
                })
        },
    },
    beforeRouteLeave(to, from, next) {
        this.$addBreadcrumb({to, from, label: "Zurück"})
        next()
    },
}
