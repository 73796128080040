var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: _vm.getClasses,
      on: {
        click: function($event) {
          return _vm.setMinHeight()
        }
      }
    },
    [
      _c(
        "div",
        { staticClass: "dashboard-panel-widget--wrap" },
        [
          _c("div", { class: ["dashboard-panel-widget__header"] }, [
            _c(
              "div",
              {
                staticClass: "dashboard-panel-widget__title box__title",
                on: {
                  click: function($event) {
                    return _vm.doEmitClick("titleClick")
                  }
                }
              },
              [
                _vm.titleComponent
                  ? _c(_vm.titleComponent, {
                      tag: "component",
                      attrs: {
                        name: _vm.widget && _vm.widget.name,
                        title: _vm.title
                      }
                    })
                  : _vm.title
                  ? [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  : _vm._e()
              ],
              2
            ),
            _vm.showToolbar
              ? _c(
                  "div",
                  { staticClass: "dashboard-panel-widget__toolbar" },
                  [
                    !_vm.isSmallOrMediumScreen
                      ? _c(
                          "button",
                          {
                            key: "widget-order",
                            class: [
                              "dashboard-panel-widget__toolbar--order",
                              "btn-clear",
                              "clickable",
                              "d-lg-block"
                            ],
                            attrs: {
                              type: "button",
                              "data-sortable-item-handler": "",
                              alt: "Icon für das Verschieben eines Elements"
                            },
                            on: {
                              click: function($event) {
                                return _vm.doEmitClick()
                              }
                            }
                          },
                          [
                            _c("PhArrowsOutCardinal", {
                              attrs: {
                                size: 20,
                                alt: "Icon für das Verschieben eines Elements"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isNavigable
                      ? _c(
                          "button",
                          {
                            key: "widget-navigate",
                            class: [
                              "dashboard-panel-widget__toolbar--order",
                              "btn-clear",
                              "d-block",
                              { "d-lg-none": _vm.isLargeScreen }
                            ],
                            attrs: {
                              type: "button",
                              alt: "Icon zur Navigation zum Feature"
                            },
                            on: {
                              click: function($event) {
                                return _vm.doEmitClick("titleClick")
                              }
                            }
                          },
                          [
                            _c("PhCaretRight", {
                              attrs: {
                                size: 20,
                                alt: "Icon zur Navigation zum Feature"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isLargeScreen
                      ? _c(
                          "BaseContextMenu",
                          {
                            key: "widget-dropdown-menu",
                            ref: "dropdownMenu",
                            staticClass:
                              "dashboard-panel-widget__base-context-menu",
                            class: [
                              {
                                "has-only-change-size-action":
                                  !_vm.widgetActions.length && !_vm.isRemovable
                              },
                              "d-none",
                              "d-lg-block"
                            ],
                            attrs: {
                              containerClass:
                                "dashboard-panel-widget__dropdown-menu"
                            }
                          },
                          [
                            _vm.widgetActions.length
                              ? [
                                  _vm._l(_vm.widgetActions, function(
                                    action,
                                    index
                                  ) {
                                    return [
                                      _vm.isActionVisible(action)
                                        ? _c(
                                            "ContextMenuItem",
                                            {
                                              key: index,
                                              on: {
                                                click: function($event) {
                                                  return _vm.$emit(
                                                    "executeAction",
                                                    action
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _vm.actionHasIcon(action)
                                                ? _c(_vm.actionIcon(action), {
                                                    tag: "component",
                                                    staticClass: "mr-1",
                                                    attrs: { size: 16 }
                                                  })
                                                : _vm._e(),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(action.legend.label)
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ]
                              : _vm._e(),
                            !_vm.isMasonry && !_vm.is3Columns
                              ? _c(
                                  "ContextMenuItem",
                                  {
                                    staticClass:
                                      "dashboard-panel-widget__dropdown-menu--change-size"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-between align-items-center"
                                      },
                                      [
                                        _c("span", [_vm._v("Größe anpassen:")]),
                                        _c("ComboBox", {
                                          attrs: {
                                            "data-dropdown-not-close": "",
                                            value: _vm.size,
                                            values: _vm.sizes,
                                            ariaLabel: "Größe anpassen"
                                          },
                                          on: {
                                            change: function($event) {
                                              return _vm.changeSize($event)
                                            }
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.overrideCanEdit && _vm.isLargeScreen
                              ? _c(
                                  "ContextMenuItem",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.doEmitClick(
                                          "bearbeitenClick"
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("Bearbeiten ")]
                                )
                              : _vm._e(),
                            _vm.isRemovable
                              ? _c(
                                  "ContextMenuItem",
                                  {
                                    on: {
                                      click: function($event) {
                                        return _vm.openRemoveWidgetModal()
                                      }
                                    }
                                  },
                                  [_vm._v("löschen")]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]),
          _c(
            "div",
            {
              staticClass: "dashboard-panel-widget__content",
              on: {
                click: function($event) {
                  return _vm.doEmitClick()
                }
              }
            },
            [_vm._t("default")],
            2
          ),
          _c("BaseModal", {
            ref: "removeWidgetModal",
            attrs: { modalTitle: _vm.title + " löschen", size: "sm" },
            on: {
              onConfirmButton: function($event) {
                return _vm.$emit("remove")
              }
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function() {
                  return [
                    _vm._v(
                      ' Soll die "' +
                        _vm._s(_vm.title) +
                        '" wirklich gelöscht werden? '
                    )
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }