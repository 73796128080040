export const MUTATION_PREFIX = 'CUSTOMERDATA: ';
export const ACTIONS_PREFIX = 'CUSTOMERDATA_';
export const GETTERS_PREFIX = 'CUSTOMERDATA_';

export default {
  MUTATIONS: {
    GET_CUSTOMER_DATA_SUCCESS: MUTATION_PREFIX + 'GET_CUSTOMER_DATA_SUCCESS',
    GET_CUSTOMER_DATA_CONFIG_SUCCESS: MUTATION_PREFIX + 'GET_CUSTOMER_DATA_CONFIG_SUCCESS',
    SAVE_CUSTOMER_DATA_SUCCESS: MUTATION_PREFIX + 'SAVE_CUSTOMER_DATA_SUCCESS',
    SAVE_PERSON_DATA_SUCCESS: MUTATION_PREFIX + 'SAVE_PERSON_DATA_SUCCESS',
    SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN_SUCCESS: MUTATION_PREFIX + 'SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN_SUCCESS',
    DELETE_CUSTOMER_DATA_SUCCESS: MUTATION_PREFIX + 'DELETE_CUSTOMER_DATA_SUCCESS',
    DELETE_PERSON_DATA_SUCCESS: MUTATION_PREFIX + 'DELETE_PERSON_DATA_SUCCESS',
    GET_CUSTOMER_DATA_ADDITIONAL_PERSONS_SUCCESS: MUTATION_PREFIX + 'GET_CUSTOMER_DATA_ADDITIONAL_PERSONS_SUCCESS',
    UPDATE_SELECTED_CUSTOMER_DATA: MUTATION_PREFIX + 'UPDATE_SELECTED_CUSTOMER_DATA',
    SET_SELECTED_EXTRA_DATA: MUTATION_PREFIX + 'SET_SELECTED_EXTRA_DATA',
    ADD_CUSTOMER_DATA_EDITED: MUTATION_PREFIX + 'ADD_CUSTOMER_DATA_EDITED',
    RESET_CUSTOMER_DATA_EDITED: MUTATION_PREFIX + 'RESET_CUSTOMER_DATA_EDITED',
    SET_ADDITIONAL_VALIDATORS: MUTATION_PREFIX + 'SET_ADDITIONAL_VALIDATORS',
    LAST_SAVED_NEW_PERSON: MUTATION_PREFIX + 'LAST_SAVED_NEW_PERSON',

    RESET_STATE: MUTATION_PREFIX + 'RESET_STATE',
    RESET_STATE_STAMMDATEN: MUTATION_PREFIX + 'RESET_STATE_STAMMDATEN',
    SET_STATE_STAMMDATEN: MUTATION_PREFIX + 'SET_STATE_STAMMDATEN',

    SET_SAVED_CUSTOMER_ID: MUTATION_PREFIX + 'SET_SAVED_CUSTOMER_ID',

    SET_PIN_DATA_SUCCESS: MUTATION_PREFIX + 'SET_PIN_DATA_SUCCESS',

    GET_PERSON_STATES_SUCCESS: MUTATION_PREFIX + 'GET_PERSON_STATES_SUCCESS',
    CLEAR_PERSON_STATES: MUTATION_PREFIX + 'CLEAR_PERSON_STATES',

    GET_PERSON_SUCCESS: MUTATION_PREFIX + 'GET_PERSON_SUCCESS',
    GET_SELECTED_PERSON_DATA_SUCCESS: MUTATION_PREFIX + 'GET_SELECTED_PERSON_DATA_SUCCESS',

    COPY_ADDRESS_SUCCESS: MUTATION_PREFIX + 'COPY_ADDRESS_SUCCESS',

    FIND_PEOPLE_SUCCESS: MUTATION_PREFIX + 'FIND_PEOPLE_SUCCESS',
    FIND_PEOPLE_RELATIONSHIP_SUCCESS: MUTATION_PREFIX + 'FIND_PEOPLE_RELATIONSHIP_SUCCESS',

    ADD_HINT: MUTATION_PREFIX + 'ADD_HINT',
    REMOVE_HINTS: MUTATION_PREFIX + 'REMOVE_HINTS',
    RESET_HINTS: MUTATION_PREFIX + 'RESET_HINTS',

    FIND_COURTAGE_SUCCESS: MUTATION_PREFIX + 'FIND_COURTAGE_SUCCESS',

    FIND_BEMERKUNGEN_SUCCESS: MUTATION_PREFIX + 'FIND_BEMERKUNGEN_SUCCESS',

    FIND_SUB_CUSTOMERS_SUCCESS: MUTATION_PREFIX + 'FIND_SUB_CUSTOMERS_SUCCESS',

    GET_INTERNE_BEMERKUNG_SUCCESS: MUTATION_PREFIX + 'GET_INTERNE_BEMERKUNG_SUCCESS',

    FIND_BIRTHDAYS_SUCCESS: MUTATION_PREFIX + 'FIND_BIRTHDAYS_SUCCESS',
    LEGITIMATIONSURKUNDE_CHANGED: MUTATION_PREFIX + 'LEGITIMATIONSURKUNDE_CHANGED',
    BEMERKUNG_KUNDE: MUTATION_PREFIX + 'BEMERKUNG_KUNDE',
    BEMERKUNG_KUNDE_SICHTBAR: MUTATION_PREFIX + 'BEMERKUNG_KUNDE_SICHTBAR',
    GET_CUSTOMER_CONTACT_PERSON_DATA_SUCCESS: MUTATION_PREFIX + 'GET_CUSTOMER_CONTACT_PERSON_DATA_SUCCESS',
    SELECTED_BEMERKUNG: MUTATION_PREFIX + 'SELECTED_BEMERKUNG',
    UPLOAD_LEGITIMATION_IMAGES_SUCCESS: MUTATION_PREFIX + 'UPLOAD_LEGITIMATION_IMAGES_SUCCESS',
    SET_FA_CHECK_EMAILS: MUTATION_PREFIX + 'SET_FA_CHECK_EMAILS',
    SET_LEGITIMATION_IMAGES_MOBILE_ACTIONS: MUTATION_PREFIX + 'SET_LEGITIMATION_IMAGES_MOBILE_ACTIONS',
    RESET_LEGITIMATION_IMAGES_MOBILE_ACTIONS: MUTATION_PREFIX + 'RESET_LEGITIMATION_IMAGES_MOBILE_ACTIONS',
  },
  ACTIONS: {
    GET_CUSTOMER_DATA: ACTIONS_PREFIX + 'GET_CUSTOMER_DATA',
    GET_SELECTED_CUSTOMER_DATA: ACTIONS_PREFIX + 'GET_SELECTED_CUSTOMER_DATA',
    GET_CUSTOMER_DATA_CONFIG: ACTIONS_PREFIX + 'GET_CUSTOMER_DATA_CONFIG',
    SAVE_CUSTOMER_DATA: ACTIONS_PREFIX + 'SAVE_CUSTOMER_DATA',
    SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN: ACTIONS_PREFIX + 'SAVE_EINKOMMEN_SOZIALVERSICHERUNGEN',
    CHECK_FORMAT_COMMUNICATION: ACTIONS_PREFIX + 'CHECK_FORMAT_COMMUNICATION',
    FORMAT_VALUE: ACTIONS_PREFIX + 'FORMAT_VALUE',
    DELETE_CUSTOMER_DATA: ACTIONS_PREFIX + 'DELETE_CUSTOMER_DATA',
    GET_CUSTOMER_DATA_ADDITIONAL_PERSONS: ACTIONS_PREFIX + 'GET_CUSTOMER_DATA_ADDITIONAL_PERSONS',
    DELETE_PERSON: ACTIONS_PREFIX + 'DELETE_PERSON',
    DELETE_BANKVERBINDUNG: ACTIONS_PREFIX + 'DELETE_BANKVERBINDUNG',
    FIND_IBAN_DETAILS: ACTIONS_PREFIX + 'FIND_IBAN_DETAILS',
    GOTO_CUSTOMER_DATA: ACTIONS_PREFIX + 'GOTO_CUSTOMER_DATA',
    GOTO_BANKVERBINDUNG: ACTIONS_PREFIX + 'GOTO_BANKVERBINDUNG',
    
    UPLOAD_LEGITIMATION_IMAGES: ACTIONS_PREFIX + 'UPLOAD_LEGITIMATION_IMAGES',
    UPLOAD_FIRMA_LEGITIMATION_IMAGES: ACTIONS_PREFIX + 'UPLOAD_FIRMA_LEGITIMATION_IMAGES',
    DELETE_LEGITIMATION_IMAGES: ACTIONS_PREFIX + 'DELETE_LEGITIMATION_IMAGES',
    DELETE_LEGITIMATION_IMAGES_FIRMA: ACTIONS_PREFIX + 'DELETE_LEGITIMATION_IMAGES_FIRMA',
    DELETE_LEGITIMATION_IMAGE_FIRMA: ACTIONS_PREFIX + 'DELETE_LEGITIMATION_IMAGE_FIRMA',
    TOGGLE_E_SIGNATUR_LEGITIMATION: ACTIONS_PREFIX + 'TOGGLE_E_SIGNATUR_LEGITIMATION',

    SAVE_NEW_CUSTOMER_DATA: ACTIONS_PREFIX + 'SAVE_NEW_CUSTOMER_DATA',
    COPY_CUSTOMER_DATA: ACTIONS_PREFIX + 'COPY_CUSTOMER_DATA',
    COPY_CUSTOMER_DATA_AS_GROUP_CUSTOMER: ACTIONS_PREFIX + 'COPY_CUSTOMER_DATA_AS_GROUP_CUSTOMER',
    SAVE_PIN_DATA: ACTIONS_PREFIX + 'SAVE_PIN_DATA',
    GET_PIN_DATA: ACTIONS_PREFIX + 'GET_PIN_DATA',
    RESET_PIN: ACTIONS_PREFIX + 'RESET_PIN',
    RESET_PIN_EMAIL: ACTIONS_PREFIX + 'RESET_PIN_EMAIL',
    CHECK_SET_KUNDE_INACTIVE: ACTIONS_PREFIX + 'CHECK_SET_KUNDE_INACTIVE',
    CUSTOMER_CALC_TAX: ACTIONS_PREFIX + 'CUSTOMER_CALC_TAX',
    GET_PERSON_STATES: ACTIONS_PREFIX + 'GET_PERSON_STATES',
    RECOGNIZE_UPLOADED_FILE: ACTIONS_PREFIX + 'RECOGNIZE_UPLOADED_FILE',
    SAVE_USER_PICTURE: ACTIONS_PREFIX + 'SAVE_USER_PICTURE',
    DELETE_USER_PICTURE: ACTIONS_PREFIX + 'DELETE_USER_PICTURE',
    GET_SELECTED_PERSON_DATA: ACTIONS_PREFIX + 'GET_SELECTED_PERSON_DATA',
    SAVE_CUSTOMER_RELATIONSHIP: ACTIONS_PREFIX + 'SAVE_CUSTOMER_RELATIONSHIP',
    COPY_ADDRESS: ACTIONS_PREFIX + 'COPY_ADDRESS',
    CONVERT_PERSON_TO_CUSTOMER: ACTIONS_PREFIX + 'CONVERT_PERSON_TO_CUSTOMER',
    FIND_PEOPLE: ACTIONS_PREFIX + 'FIND_PEOPLE',
    ADD_PEOPLE_RELATIONSHIP: ACTIONS_PREFIX + 'ADD_PEOPLE_RELATIONSHIP',
    FIND_PEOPLE_RELATIONSHIP: ACTIONS_PREFIX + 'FIND_PEOPLE_RELATIONSHIP',
    SAVE_PEOPLE_RELATIONSHIP: ACTIONS_PREFIX + 'SAVE_PEOPLE_RELATIONSHIP',
    DOWNLOAD_FILE: ACTIONS_PREFIX + 'DOWNLOAD_FILE',
    DELETE_FILE: ACTIONS_PREFIX + 'DELETE_FILE',
    GET_EXPIRATION_DATE: ACTIONS_PREFIX + 'GET_EXPIRATION_DATE',
    SAVE_KOMMUNIKATION_DATEN_PENDING:ACTIONS_PREFIX + 'SAVE_KOMMUNIKATION_DATEN_PENDING',

    FIND_COURTAGE: ACTIONS_PREFIX + 'FIND_COURTAGE',
    SAVE_COURTAGE: ACTIONS_PREFIX + 'SAVE_COURTAGE',
    DELETE_COURTAGE: ACTIONS_PREFIX + 'DELETE_COURTAGE',

    GET_ZUBRINGER_DATA: ACTIONS_PREFIX + 'GET_ZUBRINGER_DATA',

    FIND_BEMERKUNGEN: ACTIONS_PREFIX + 'FIND_BEMERKUNGEN',

    FIND_SUB_CUSTOMERS: ACTIONS_PREFIX + 'FIND_SUB_CUSTOMERS',
    ADD_SUB_CUSTOMERS: ACTIONS_PREFIX + 'ADD_SUB_CUSTOMERS',
    DELETE_SUB_CUSTOMER: ACTIONS_PREFIX + 'DELETE_SUB_CUSTOMER',

    GET_INTERNE_BEMERKUNG: ACTIONS_PREFIX + 'GET_INTERNE_BEMERKUNG',
    SAVE_INTERNE_BEMERKUNG: ACTIONS_PREFIX + 'SAVE_INTERNE_BEMERKUNG',

    FIND_BIRTHDAYS: ACTIONS_PREFIX + 'FIND_BIRTHDAYS',
    GET_BIRTHDAY_EMAIL_DATA: ACTIONS_PREFIX + 'GET_BIRTHDAY_EMAIL_DATA',
    GET_BEMERKUNG_KUNDE: ACTIONS_PREFIX + 'GET_BEMERKUNG_KUNDE',
    PUT_BEMERKUNG_KUNDE: ACTIONS_PREFIX + 'PUT_BEMERKUNG_KUNDE',
    GET_BEMERKUNG_KUNDE_SICHTBAR: ACTIONS_PREFIX + 'GET_BEMERKUNG_KUNDE_SICHTBAR',
    PUT_BEMERKUNG_KUNDE_SICHTBAR: ACTIONS_PREFIX + 'PUT_BEMERKUNG_KUNDE_SICHTBAR',
    GET_CUSTOMER_CONTACT_PERSON_DATA: ACTIONS_PREFIX + 'GET_CUSTOMER_CONTACT_PERSON_DATA',
    
    LOAD_PDF_KUNDENDATEN: ACTIONS_PREFIX + 'LOAD_PDF_KUNDENDATEN',

    LOAD_VCARD_KUNDENDATEN: ACTIONS_PREFIX + 'LOAD_VCARD_KUNDENDATEN',
    LOAD_FA_CHECK_EMAILS: ACTIONS_PREFIX + 'LOAD_FA_CHECK_EMAILS',
  },
  GETTERS: {
    SELECTED_CUSTOMER_ID: GETTERS_PREFIX + 'SELECTED_CUSTOMER_ID',
    SELECTED_CUSTOMER_EXTRA_DATA: GETTERS_PREFIX + 'SELECTED_CUSTOMER_EXTRA_DATA',
    IS_MAIN_PERSON: GETTERS_PREFIX + 'IS_MAIN_PERSON',
    CUSTOMER_DATA: GETTERS_PREFIX + 'CUSTOMER_DATA',
    CUSTOMER_DATA_CONFIG: GETTERS_PREFIX + 'CUSTOMER_DATA_CONFIG',
    CUSTOMER_DATA_ADDITIONAL_PERSONS: GETTERS_PREFIX + 'CUSTOMER_DATA_ADDITIONAL_PERSONS',
    SELECTED_CUSTOMER_DATA: GETTERS_PREFIX + 'SELECTED_CUSTOMER_DATA',
    GET_CUSTOMER_DATA_EDITED: GETTERS_PREFIX + 'GET_CUSTOMER_DATA_EDITED',
    GET_ADDITIONAL_VALIDATORS: GETTERS_PREFIX + 'GET_ADDITIONAL_VALIDATORS',
    GET_SAVED_CUSTOMER_ID: GETTERS_PREFIX + 'GET_SAVED_CUSTOMER_ID',
    PIN_DATA: GETTERS_PREFIX + 'PIN_DATA',
    PERSON_STATES: GETTERS_PREFIX + 'PERSON_STATES',
    SELECTED_PERSON_DATA: GETTERS_PREFIX + 'SELECTED_PERSON_DATA',
    IS_NEW_PERSON: GETTERS_PREFIX + 'IS_NEW_PERSON',
    ADDRESS_TO_COPY: GETTERS_PREFIX + 'ADDRESS_TO_COPY',
    PEOPLE_FOUND: GETTERS_PREFIX + 'PEOPLE_FOUND',
    PEOPLE_RELATIONSHIP: GETTERS_PREFIX + 'PEOPLE_RELATIONSHIP',
    HINTS: GETTERS_PREFIX + 'HINTS',
    LAST_SAVED_NEW_PERSON: GETTERS_PREFIX + 'LAST_SAVED_NEW_PERSON',

    COURTAGE: GETTERS_PREFIX + 'COURTAGE',

    BEMERKUNGEN: GETTERS_PREFIX + 'BEMERKUNGEN',
   
    SUB_CUSTOMERS: GETTERS_PREFIX + 'SUB_CUSTOMERS',

    INTERNE_BEMERKUNG: GETTERS_PREFIX + 'INTERNE_BEMERKUNG',

    IS_FIRMA: GETTERS_PREFIX + 'IS_FIRMA',

    BIRTHDAYS: GETTERS_PREFIX + 'BIRTHDAYS',
    LEGITIMATIONSURKUNDE_CHANGED: GETTERS_PREFIX + 'LEGITIMATIONSURKUNDE_CHANGED',
    BEMERKUNG_KUNDE: GETTERS_PREFIX + 'BEMERKUNG_KUNDE',
    BEMERKUNG_KUNDE_SICHTBAR: GETTERS_PREFIX + 'BEMERKUNG_KUNDE_SICHTBAR',
    GET_CUSTOMER_CONTACT_PERSON_DATA: GETTERS_PREFIX + 'GET_CUSTOMER_CONTACT_PERSON_DATA',
    SELECTED_BEMERKUNG: GETTERS_PREFIX + 'SELECTED_BEMERKUNG',
    GET_STATE_STAMMDATEN: GETTERS_PREFIX + 'GET_STATE_STAMMDATEN',
    GET_FA_CHECK_EMAILS: GETTERS_PREFIX + 'GET_FA_CHECK_EMAILS',
    LEGITIMATION_DOWNLOAD_LINK_FN: GETTERS_PREFIX + 'LEGITIMATION_DOWNLOAD_LINK_FN',
    LEGITIMATION_IMAGES_MOBILE_ACTIONS: GETTERS_PREFIX + 'LEGITIMATION_IMAGES_MOBILE_ACTIONS',
  }
}
